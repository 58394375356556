import React, { useState, useEffect } from 'react';

//Material
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tooltip, IconButton, Chip, Typography, TextField, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';

//Icons
import EmailIcon from '@material-ui/icons/Email';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CloseIcon from '@material-ui/icons/Close';

//Components
import EmailToAddressInput from './email.to';

// Query
import { useMutation } from 'react-query';
import { SendQuoteByEmail, UpdateQuoteStatus } from '../../../../../utils/data';
import { useSettingsContext } from '../../../../../../context/settings-context';

const useStyles = makeStyles((theme) => ({
	modalDialog: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		// margin: '1.75rem auto',
	},
	modalContent: {
		border: '0.5px solid rgba(255,255,255,0.38)',
		borderRadius: 10,
		// boxShadow: '0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15)',
		display: 'flex',
		position: 'relative',
		flexDirection: 'column',

		// width: '100%',
		// pointerEvents: 'auto',
		// backgroundColor: 'darkest',
		// backgroundClip: 'padding-box',
		// outline: 0,
	},
	modalHeader: {
		backgroundColor: theme.palette.secondary.main,
		backgroundImage: `linear-gradient(to bottom right, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
		color: theme.palette.getContrastText(theme.palette.primary.main),

		marginTop: '0.65rem',
		marginLeft: '0.65rem',
		marginRight: '0.65rem',

		paddingTop: '0.5rem',
		paddingBottom: '0.5rem',
		paddingLeft: '1rem',
		paddingRight: '1rem',

		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,

		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'flex-end',
	},
	modalBody: {
		padding: 10,
		// backgroundColor: theme.palette.background.paper,
		color: theme.palette.getContrastText(theme.palette.background.paper),
		overflow: 'auto',
		flex: 1,
	},
	modalFooter: {
		backgroundColor: theme.palette.background.default,
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: '2rem',
		paddingRight: '2rem',
	},
	savebutton: {
		border: `1px solid ${theme.palette.success.light}`,
		color: `1px solid ${theme.palette.success.light}`,
	},
}));

export const EmailButton = ({ open = 'false', setOpen, quote, refetch, quoteNumber }) => {
	const classes = useStyles();

	const { settings } = useSettingsContext();
	const queueEmail = useMutation(SendQuoteByEmail);
	const updateQuoteStatus = useMutation(UpdateQuoteStatus);

	const [errors, setErrors] = useState({
		to: null,
		subject: null,
	});

	const [additionalInformation, setAdditionalInformation] = useState({
		to: [
			{
				contactEmail: quote?.contact?.contactEmail || quote?.business?.businessEmail,
				contactFullname: quote?.contact?.contactFullname || quote?.business?.businessName,
			},
		],
		cc: [],
		subject: `Quote Notification - ${settings.name}`,
	});

	useEffect(() => {
		const contactEmail = quote?.contact?.contactEmail;
		const contactName = quote?.contact?.contactName;
		const businessEmail = quote?.business?.businessEmail;
		const businessName = quote?.business?.businessName;

		if (businessEmail) {
			setAdditionalInformation((prev) => ({
				...prev,
				to: [
					{
						contactEmail: businessEmail,
						contactFullname: businessName,
					},
				],
				cc: [
					{
						contactEmail: contactEmail,
						contactFullname: contactName,
					},
				],
			}));
		}

		if (!businessEmail) {
			setAdditionalInformation((prev) => ({
				...prev,
				to: [
					{
						contactEmail: contactEmail,
						contactFullname: contactName,
					},
				],
			}));
		}
	}, [quote]);

	const sendAndSave = async () => {
		//Remove emptry address
		let to = additionalInformation?.to?.filter((item) => item.contactEmail !== null && item.contactFullname !== null);
		let cc = additionalInformation?.cc?.filter((item) => item.contactEmail !== null && item.contactFullname !== null);

		let toErrors = to?.length === 0 ? true : false;
		let subjectErrors = !additionalInformation?.subject ? true : false;

		if (toErrors || subjectErrors) {
			setErrors({
				to: toErrors ? '* This is a required field' : false,
				subject: subjectErrors ? '* This is a required field' : false,
			});
		}

		if (!errors.to && !errors.subject) {
			const requestApproval = quote?.quoteStatus?.toUpperCase() !== 'DRAFT' && quote?.quoteStatus?.toUpperCase() !== 'REJECTED' ? false : true;
			// Queue email
			await queueEmail.mutate({
				quoteId: quote?._id,
				additionalInformation: {
					...additionalInformation,
					to,
					cc,
					requestApproval,
				},
			});

			if (requestApproval)
				await updateQuoteStatus.mutate({
					_id: quote?._id,
					quoteStatus: 'Awaiting Approval',
				});

			refetch();
			handleClose();
		}
	};

	const handleClose = async () => {
		await setErrors({
			to: null,
			subject: null,
		});
		setOpen(false);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		var existing = additionalInformation;
		existing = {
			...existing,
			[name]: value,
		};
		let to = existing?.to?.filter((item) => item.contactEmail !== null && item.contactFullname !== null);
		let cc = existing?.cc?.filter((item) => item.contactEmail !== null && item.contactFullname !== null);
		setAdditionalInformation({
			...existing,
			to,
			cc,
		});
	};

	return (
		<>
			{quote?.quoteStatus?.toUpperCase() !== 'DRAFT' && quote?.quoteStatus?.toUpperCase() !== 'ARCHIVED' && (
				<Tooltip title="Send quote to customer" key={'email-quote'}>
					<IconButton component="span" size="small" color="inherit" style={{ marginRight: 10 }} onClick={() => setOpen(true)}>
						<EmailIcon />
					</IconButton>
				</Tooltip>
			)}

			{(quote?.quoteStatus?.toUpperCase() === 'DRAFT' || quote?.quoteStatus?.toUpperCase() === 'REJECTED') && (
				<Tooltip title="Submit to customer for online approval" key={'print'}>
					<Button size="small" variant="contained" color="secondary" onClick={() => setOpen(true)} style={{ marginRight: 10 }}>
						Send for approval
					</Button>
				</Tooltip>
			)}

			<Dialog
				fullScreen={true}
				open={open || false}
				onClose={handleClose}
				// scroll="body"
				style={{
					margin: 20,
					overflow: 'hidden',
				}}
			>
				{/* HEADER */}
				<div className={classes.modalHeader}>
					<Grid container alignItems="center">
						<Grid item xs={6}>
							<Typography variant="h6">
								<b>New email</b>
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Grid container justifyContent="flex-end">
								<IconButton
									size="small"
									color="inherit"
									onClick={() => {
										setOpen(false);
									}}
									style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
								>
									<CloseIcon />
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				</div>

				<DialogContent>
					<Grid container spacing={2} style={{ paddingTop: 20 }}>
						{/* TO */}
						<Grid item xs={12}>
							<EmailToAddressInput
								name="to"
								label="To:"
								defaultValues={additionalInformation?.to}
								onChange={(e) => handleChange(e)}
								error={errors?.to}
							/>
						</Grid>

						{/* CC */}
						<Grid item xs={12}>
							<EmailToAddressInput name="cc" label="CC:" defaultValues={additionalInformation?.cc} onChange={(e) => handleChange(e)} />
						</Grid>

						{/* SUBJECT */}
						<Grid item xs={12}>
							<TextField
								name="subject"
								label="Subject"
								variant="outlined"
								size="small"
								color="secondary"
								fullWidth={true}
								defaultValue={additionalInformation?.subject}
								onChange={(e) => handleChange(e)}
								helperText={
									<Typography variant="caption" color="error">
										{errors?.subject}
									</Typography>
								}
							/>
						</Grid>

						{/* ATTACHMENTS */}
						<Grid item xs={12}>
							<Grid container alignItems="center">
								<Chip
									icon={<PictureAsPdfIcon fontSize="small" />}
									label={`Quote_${quoteNumber.replace('/', '_')}.pdf`}
									onDelete={() => alert('delete')}
								/>
							</Grid>
						</Grid>

						{/* BODY */}
						<Grid item xs={12}>
							<TextField
								name="message"
								label="Message"
								placeholder="This will be additional information appended to the standard email notification."
								variant="outlined"
								color="secondary"
								fullWidth={true}
								multiline={true}
								rows={10}
								onChange={(e) => handleChange(e)}
							/>

							{/* <EmailEditor /> */}
						</Grid>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Button variant="outlined" onClick={handleClose}>
						Cancel
					</Button>
					<Button
						// size="small"
						variant="contained"
						color="secondary"
						onClick={sendAndSave}
					>
						Send
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default EmailButton;
