import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import throttle from 'lodash/throttle';

// Material
import { useTheme } from '@material-ui/core/styles';
import { Grid, Link, Typography, TextField } from '@material-ui/core';
import { NumberFormatMileageTextbox } from '../textbox';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { GetAddressSearch } from '../../utils/data';

export const TextBoxDisplay = ({ value, type, link }) => {
	if (type === 'link' && link)
		return (
			<Link href={link} onClick={(e) => e.preventDefault}>
				{value}
			</Link>
		);

	return (
		<Typography variant="body2" style={{ minWidth: '100px' }} noWrap>
			{!type ? value : normaliseValue(value, type)}
		</Typography>
	);
};

export const TextBoxEdit = ({ item, initialValue, onChange }) => {
	return (
		<TextField
			type={item?.type || 'text'}
			name={item?.id || null}
			size="small"
			margin="dense"
			variant="outlined"
			color="secondary"
			style={{ borderWidth: 0 }}
			fullWidth={true}
			disabled={!item?.canEdit}
			defaultValue={initialValue ? initialValue : ''}
			onChange={(e) => onChange(e)}
		/>
	);
};

export const DateEdit = ({ item, initialValue, onChange }) => {
	return (
		<TextField
			type={item.type}
			name={item.id}
			size="small"
			margin="dense"
			variant="outlined"
			color="secondary"
			style={{ borderWidth: 0 }}
			fullWidth={true}
			disabled={!item.canEdit}
			defaultValue={initialValue ? initialValue : ''}
			onChange={(e) => onChange(e)}
		/>
	);
};

export const MileageEdit = ({ item, initialValue, onChange }) => {
	return (
		<Grid container alignItems="center" justifyContent="center" spacing={2}>
			<Grid item xs={8}>
				<TextField
					type={item.type}
					name={item.id}
					size="small"
					margin="dense"
					variant="outlined"
					// style={{ margin: '10', borderWidth: 0 }}
					fullWidth={true}
					disabled={!item.canEdit}
					defaultValue={initialValue ? initialValue : ''}
					onChange={onChange}
					value={initialValue}
					color="secondary"
					InputProps={{
						inputComponent: NumberFormatMileageTextbox,
					}}
				/>
			</Grid>
			<Grid item xs={4}>
				<Typography variant="body2" noWrap>
					{`km's`}
				</Typography>
			</Grid>
		</Grid>
	);
};

export const NumberEdit = ({ item, initialValue, onChange }) => {
	function NumberFormatInput(props) {
		const { inputRef, ...other } = props;

		return (
			<NumberFormat
				{...other}
				getInputRef={inputRef}
				thousandSeparator
				isNumericString
				decimalScale={0}
				onValueChange={(values) =>
					onChange({
						target: {
							name: props.name,
							value: values.value,
						},
					})
				}
			/>
		);
	}

	return (
		<Grid container alignItems="center" justifyContent="center" spacing={2}>
			<Grid item xs={12}>
				<TextField
					name={item.id}
					size="small"
					margin="dense"
					variant="outlined"
					style={{ margin: '10', borderWidth: 0 }}
					fullWidth={true}
					disabled={!item.canEdit}
					onChange={onChange}
					value={initialValue}
					inputComponent={NumberFormatInput}
				/>
			</Grid>
		</Grid>
	);
};

const normaliseValue = (value, type) => {
	if (type === 'list') return value;
	if (type === 'date') return moment(value).format('ddd, DD MMM YYYY');
	if (type === 'referenceTrim') return value.substring(3, 30);
	if (type === 'referenceNumber') return value.substring(3, 20);
	if (type === 'number') return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	if (type === 'mileage') return <NumberFormat value={value} suffix=" km" thousandSeparator isNumericString displayType="text" />;
	if (type === 'number') return <NumberFormat value={value} thousandSeparator isNumericString displayType="text" />;
	return value;
};

export function AddressEditKeyPair({ label, item, initialValue, onChange }) {
	const theme = useTheme();

	return (
		<Grid container spacing={2} alignItems={'center'} justifyContent="flex-start">
			<Grid item sm={12} md={4} style={{ paddingLeft: 15 }}>
				<Typography variant="body2" noWrap style={{ color: theme.palette.text.secondary }}>
					{label}
				</Typography>
			</Grid>

			<Grid item sm={12} md={8} style={{ paddingLeft: 10, paddingRight: 15 }}>
				<AddressEdit item={item} initialValue={initialValue} onChange={onChange} />
			</Grid>
		</Grid>
	);
}

export function AddressEdit({ item, initialValue, onChange }) {
	const [value, setValue] = useState(initialValue);
	const [inputValue, setInputValue] = useState('');
	const [options, setOptions] = React.useState([]);

	const fetch = useMemo(
		() =>
			throttle(async (request) => {
				return await GetAddressSearch(request?.input).then((response) => {
					if (response?.address) setOptions(response?.address);
					if (!response?.address) setOptions([request.input]);
				});
			}, 300),
		[]
	);

	useEffect(() => {
		if (inputValue === '') {
			setOptions(value ? [value] : []);
			return undefined;
		}

		fetch({ input: inputValue });
	}, [value, inputValue, fetch]);

	return (
		<Autocomplete
			name={item.id}
			id={item.id}
			getOptionLabel={(option) => (typeof option === 'string' ? option : option.formatted_address)}
			filterOptions={(x) => x}
			options={options}
			autoComplete
			includeInputInList
			filterSelectedOptions
			value={value}
			onChange={(event, newValue) => {
				setOptions(newValue ? [newValue, ...options] : options);
				setValue(newValue);
			}}
			onInputChange={(event, newInputValue) => {
				onChange({
					target: {
						name: item.id,
						value: newInputValue,
					},
				});
				setInputValue(newInputValue);
			}}
			renderInput={(params) => <TextField {...params} variant="outlined" fullWidth margin="dense" color="secondary" />}
		/>
	);
}
