import React, { useState } from 'react';
// Material
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';
//Context
import { useWorkorderContext } from '../../../../context/workorder-context';
//Pages
import CardNextService from '../../pages/workorder/nextservice';
import CardWarranty from '../../pages/workorder/warranty/card';
import { ContactCard, VehicleCard, CompanyCard } from '../..';
import { StyledTabs, StyledTab, TabPanel } from '../../../components/tabs';
import InvoiceHistoryTimeline from '../../invoice/popup/components/invoice-history';
import CardAttachments from 'app/components/attachments/upload.card';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		border: `1px solid ${theme.palette.divider}`,
	},
	tabBody: {
		minHeight: '75vh',
		overflow: 'auto',
	},
}));

export default function WorkorderDetailTabs(props) {
	const classes = useStyles();
	const [value, setValue] = useState(0);

	const { workorder } = useWorkorderContext();
	const { refetch } = props;

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div className={classes.root}>
			<Paper elevation={24} square={true}>
				<StyledTabs value={value} onChange={handleChange} scrollButtons="auto" variant="scrollable">
					<StyledTab label="INFORMATION" />
					{/* <StyledTab label="OTHER" /> */}
					<StyledTab label="HISTORY" />
				</StyledTabs>

				<TabPanel value={value} index={0} className={classes.tabBody}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<VehicleCard
								vehicleId={workorder?.vehicleID}
								parentId={workorder?._id}
								parentTable="workorders"
								refetchQueryFn={refetch}
								reassign={false}
								noLink={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<ContactCard
								contactId={workorder?.contactID}
								parentId={workorder?._id}
								parentTable="workorders"
								refetchQueryFn={refetch}
								reassign={true}
								noLink={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<CompanyCard
								companyId={workorder?.businessID}
								parentId={workorder?._id}
								parentTable="workorders"
								refetchQueryFn={refetch}
								reassign={true}
								noLink={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<CardNextService workorderId={workorder?._id} />
						</Grid>
						<Grid item xs={6}>
							<CardWarranty parentId={workorder?._id} />
						</Grid>
						<Grid item xs={12}>
							<CardAttachments parentId={workorder?._id} data={workorder} refetch={refetch} />
						</Grid>
					</Grid>
				</TabPanel>

				<TabPanel value={value} index={1} className={classes.tabBody}>
					<InvoiceHistoryTimeline parentId={workorder?._id} />
				</TabPanel>
			</Paper>
		</div>
	);
}
