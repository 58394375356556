//Query
import axios from 'axios';
import download from 'downloadjs';
import config from '../../../config.json';
import process from 'process';

// MaterialUI
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import DeleteIcon from '@material-ui/icons/Delete';

// Iconify Icons
import { Icon } from '@iconify/react';
import fileTypeWord from '@iconify-icons/vscode-icons/file-type-word';
import fileTypeExcel2 from '@iconify-icons/vscode-icons/file-type-excel2';
import fileTypeText from '@iconify-icons/vscode-icons/file-type-text';
import fileTypeImage from '@iconify-icons/vscode-icons/file-type-image';
import fileTypePdf2 from '@iconify-icons/vscode-icons/file-type-pdf2';

// Components
import UploadDialog from './upload.dialog';
import { Box } from '../box';
import { IconButton, Typography } from '@material-ui/core';

const domain = process.env.NODE_ENV === 'production' ? config.api.domain : config.api.domain_dev;

export const CardAttachments = ({ parentId, data, refetch }) => {
	const downloadFile = async (id, fileName, mimetype) => {
		try {
			await axios({
				url: `${domain}/download/${id}`,
				method: 'GET',
				responseType: 'blob',
			}).then((response) => {
				download(response.data, fileName, mimetype);
			});
		} catch (error) {
			if (error.response && error.response.status === 400) {
				alert('Error occured while downloading file.');
			}
		}
	};

	const deleteFile = async (id) => {
		try {
			await axios({
				url: `${domain}/delete_file/${id}`,
				method: 'POST',
			}).then(() => {
				refetch();
			});
		} catch (error) {
			if (error.response && error.response.status === 400) {
				alert('Error occured while deleting file.');
			}
		}
	};

	//* icons
	const fileIcon = (filename) => {
		let ext = filename.split('.').pop();

		switch (ext) {
			case 'doc':
				return <Icon icon={fileTypeWord} />;
			case 'docx':
				return <Icon icon={fileTypeWord} />;

			case 'xls':
				return <Icon icon={fileTypeExcel2} />;
			case 'xlsx':
				return <Icon icon={fileTypeExcel2} />;

			case 'pdf':
				return <Icon icon={fileTypePdf2} />;

			case 'jpg':
				return <Icon icon={fileTypeImage} />;
			case 'jpeg':
				return <Icon icon={fileTypeImage} />;
			case 'png':
				return <Icon icon={fileTypeImage} />;

			default:
				return <Icon icon={fileTypeText} />;
		}
	};

	//* error
	if (!parentId) return <p>No id supplied</p>;
	if (!data) return null;

	return (
		<Box heading="Attachments" rightComponent={<UploadDialog parentId={parentId} refetch={refetch} />}>
			{/* //* NO FILE */}
			{data?.files?.length === 0 && (
				<Grid container>
					<Grid item xs={12} align="center">
						<Typography variant="caption" color="textSecondary">
							No attachments.
						</Typography>
					</Grid>
					<Grid item xs={12} align="center">
						<Typography
							variant="caption"
							color="textSecondary"
							style={{
								fontSize: '0.6rem',
							}}
						>
							(only PDF, images and MS Office documents allowed )
						</Typography>
					</Grid>
				</Grid>
			)}

			{/* //* FILE LIST */}
			{data?.files?.length > 0 &&
				data?.files?.map((file, i) => {
					return (
						<Grid container key={i} alignContent="center" alignItems="center">
							<Grid item xs={2} lg={1}>
								{fileIcon(file.meta_data.originalname)}
							</Grid>
							<Grid item xs={10}>
								<Link
									component="button"
									variant="caption"
									color="textPrimary"
									onClick={() => downloadFile(file._id, file.meta_data.originalname, file.meta_data.mimetype)}
								>
									{file.meta_data.originalname}
								</Link>
							</Grid>
							<Grid item xs={0.5} align="right">
								<IconButton
									size="small"
									onClick={() => {
										var answer = window.confirm(`Permanently delete ${file.meta_data.originalname}?`);
										if (answer) {
											deleteFile(file?._id);
										} else {
											return;
										}
									}}
								>
									<DeleteIcon
										style={{
											fontSize: '1rem',
										}}
									/>
								</IconButton>
							</Grid>
						</Grid>
					);
				})}
		</Box>
	);
};

export default CardAttachments;
