import React, { useState, useEffect } from 'react'

// MaterialUI
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

//Data
import { useMutation } from 'react-query'
import { DeleteInvoicePayment } from '../../../utils/data'

//Context
import { useInvoiceContext } from '../../../../context/invoice-context';

//Shared Components
import { KeyPairInput } from '../../../components/key-pair'

//Styles
const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 500,

        '& .MuiDialogTitle-root': {
            backgroundColor: theme.palette.error.main
        }
    }
}));

//Main Code
export const DeletePayment = ({ paymentData, open, setOpen }) => {
    const classes = useStyles();

    const { refetch } = useInvoiceContext()
    const [updatedPaymentData, setUpdatedPaymentData] = useState(null)
    const [isSaving, setIsSaving] = useState(false)

    const deletePayment = useMutation(DeleteInvoicePayment, {
        onSettled: () => {
            refetch()
        }
    })

    useEffect(() => {
        if (!updatedPaymentData && paymentData) {
            setUpdatedPaymentData({ ...paymentData, deleted: true })
        }
    }, [paymentData])

    //FUNCTIONS
    const handleChange = (e) => {
        setUpdatedPaymentData({
            ...updatedPaymentData,
            [e.target.name]: e.target.value
        })
    }

    const saveClick = async () => {
        await setIsSaving(true)

        await deletePayment.mutate(updatedPaymentData, {
            onSettled: () => {
                setIsSaving(false)
                setUpdatedPaymentData(null)
                refetch()
            }
        })

        await setOpen(false)

    }

    const cancelClick = async () => {
        setUpdatedPaymentData(null)
        setOpen(false)
    }


    return (
        <Dialog
            open={open}
            className={classes.root}
            fullWidth={true}
        >
            <DialogTitle>DELETE PAYMENT</DialogTitle>


            <DialogContent style={{ paddingTop: 30 }}>
                <DialogContentText>

                    {/* <Grid container alignItems="center" justifyContent='center'>
                        <p>{paymentData?.paymentAmount}</p>
                    </Grid>
                     */}
                    <Grid container alignItems="flex-start" justifyContent='center'>
                        <Grid item xs={10}>
                            <Grid container alignItems="flex-start"  spacing={2}>
                                <KeyPairInput
                                    label="Notes"
                                    name="paymentNotes"
                                    defaultValue={updatedPaymentData?.paymentNotes}
                                    onChange={handleChange}
                                    disabled={isSaving}
                                    type='multiline'
                                    focus={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={() => cancelClick()}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => saveClick()}
                    color="primary"
                    startIcon={isSaving ? <CircularProgress size={20} /> : null}
                    disabled={isSaving}
                >
                    {isSaving && 'Deleting'}

                    {!isSaving && 'Delete'}

                </Button>
            </DialogActions>
        </Dialog>
    )
}