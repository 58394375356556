import React, { useState } from 'react';
// material
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';
// components
import { useInvoiceContext } from '../../../../../context/invoice-context';
import { StyledTabs, StyledTab, TabPanel } from '../../../../components/tabs';
import { InvoiceNextServiceReminderCard } from '../../../invoice-next-service-card';
import { InvoiceWarrantyCard } from '../../../invoice-warranty-card';
import { ContactCard, VehicleCard, CompanyCard } from '../../../index';
import InvoiceHistoryTimeline from './invoice-history';
import { PaymentsTable } from '../../../payments';
import { InvoiceTable } from './invoice-items-table';
import { InvoiceComments } from './comments';
import { CardAttachments } from 'app/components/attachments/upload.card';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		border: `1px solid ${theme.palette.divider}`,
	},
	tabBody: {
		minHeight: '75vh',
		overflow: 'auto',
	},
}));

export default function InvoiceDetailTabs(props) {
	const classes = useStyles();
	const [value, setValue] = useState(0);
	const { invoice } = useInvoiceContext();
	const { refetchItem } = props;

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div className={classes.root}>
			<Paper elevation={24} square={true}>
				<StyledTabs value={value} onChange={handleChange} scrollButtons="auto" variant="scrollable">
					<StyledTab label="INFORMATION" />
					<StyledTab label="INVOICE" />
					<StyledTab label="PAYMENTS" />
					<StyledTab label="COMMENTS" />
					<StyledTab label="HISTORY" />
				</StyledTabs>

				<TabPanel value={value} index={0} className={classes.tabBody}>
					<Grid container spacing={1}>
						{/* //* vehicle */}
						<Grid item xs={12}>
							<VehicleCard
								vehicleId={invoice?.vehicleID}
								parentId={invoice?._id}
								parentTable="invoices"
								refetchQueryFn={refetchItem}
								reassign={false}
								noLink={true}
							/>
						</Grid>

						{/* //* contact */}
						<Grid item xs={6}>
							<ContactCard
								contactId={invoice?.contactID}
								parentId={invoice?._id}
								parentTable="invoices"
								refetchQueryFn={refetchItem}
								reassign={true}
								noLink={true}
							/>
						</Grid>

						{/* //* company */}
						<Grid item xs={6}>
							<CompanyCard
								companyId={invoice?.businessID}
								parentId={invoice?._id}
								parentTable="invoices"
								refetchQueryFn={refetchItem}
								reassign={true}
								noLink={true}
							/>
						</Grid>

						{/* //* next service */}
						<Grid item xs={6}>
							<InvoiceNextServiceReminderCard invoiceId={invoice?._id} refetchQueryFn={refetchItem} />
						</Grid>

						{/* //* warranty */}
						<Grid item xs={6}>
							<InvoiceWarrantyCard invoiceId={invoice?._id} refetchQueryFn={refetchItem} />
						</Grid>

						{/* //* attachments */}
						<Grid item xs={12}>
							<CardAttachments parentId={invoice?._id} data={invoice} refetch={refetchItem} />
						</Grid>
					</Grid>
				</TabPanel>

				<TabPanel value={value} index={1} className={classes.tabBody}>
					<InvoiceTable invoiceId={invoice?._id} refreshItem={refetchItem} />
				</TabPanel>

				<TabPanel value={value} index={2} className={classes.tabBody}>
					<PaymentsTable invoice={invoice} />
				</TabPanel>

				<TabPanel value={value} index={3} className={classes.tabBody}>
					<InvoiceComments invoiceId={invoice?._id} />
				</TabPanel>

				<TabPanel value={value} index={4} className={classes.tabBody}>
					<InvoiceHistoryTimeline parentId={invoice?._id} />
				</TabPanel>
			</Paper>
		</div>
	);
}
