import { withStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Tabs, Tab, Badge, Box } from '@material-ui/core';

export const StyledTabs = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main
    },
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 10,
        '& > span': {
            width: '100%',
            // backgroundColor: theme.palette.getContrastText(theme.palette.primary.main),
            backgroundColor: theme.palette.secondary.main,
            height: '3px',
        },
    },
    flexContainer: {
        borderBottom: 0,
    },
}))((props) => <Tabs
    {...props}
    TabIndicatorProps={{ children: <span /> }}
/>);

export const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        marginRight: theme.spacing(1),
    },
}))((props) => {
    const theme = useTheme()
    return (
        <Tab
            disableRipple {...props}
            style={props.label === props.activeTab ? {
                opacity: 1,
                // fontWeight: theme.typography.fontWeightBold,
                fontWeight: 700,
                color: theme.palette.getContrastText(theme.palette.primary.main),
            } : {
                fontWeight: theme.typography.fontWeightNormal,
                color: theme.palette.getContrastText(theme.palette.primary.main),
            }}
            label={
                <Badge
                    overlap="rectangular"
                    badgeContent={props.count}
                    color="default"
                >
                    <Typography variant="caption">{props.label}</Typography>
                </Badge>
            }
        />
    )
});

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id='actinium-modal'
            {...other}
            style={{
                padding: 10,
            }}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}