import { useState } from 'react';
//Material
import { Grid, OutlinedInput, Typography } from '@material-ui/core';
import { Select, FormControl } from '@material-ui/core';

//Date Pickers
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

//Context
import { useNewPaymentsContext } from '../../../../../../context/payments-context';

// Pages
import { NumberFormatCurrency, NumberFormatCurrencyText } from '../../../../../components/textbox';

export const KeyPair = ({ label = '', value = 0 }) => {
	return (
		<Grid
			container
			style={{
				marginTop: 10,
			}}
		>
			<Grid item xs={6}>
				<Typography variant="body2" gutterBottom={false} style={{ margin: 0 }} noWrap>
					{label}
				</Typography>
			</Grid>

			<Grid
				item
				xs={6}
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
				}}
			>
				{NumberFormatCurrencyText(value, 2)}
			</Grid>
		</Grid>
	);
};

export const PaymentTextBox = (props) => {
	const { totals, paymentChange } = useNewPaymentsContext();
	const [maximumReached, setMaximumReached] = useState(false);

	const handleFocusNumerical = (event) => {
		event.preventDefault();
		const { target } = event;
		setTimeout(function () {
			target.setSelectionRange(0, 100);
		}, 10);
	};

	const { max, ...rest } = props;

	return (
		<>
			<OutlinedInput
				{...rest}
				// defaultValue={max}
				defaultValue={totals?.payment}
				inputComponent={NumberFormatCurrency}
				onFocus={(e) => handleFocusNumerical(e)}
				onChange={(e) => {
					if (Number(e.target.value) > max) {
						setMaximumReached(true);
					} else {
						setMaximumReached(false);
					}
					paymentChange(e);
				}}
				size="small"
				display="block"
				fullWidth={true}
				margin="dense"
				error={props.error}
				required={true}
			/>
			{maximumReached && (
				<Typography variant="caption" color="error">
					{`The value is more than the invoice total of ${max}`}
				</Typography>
			)}
			{props.error && (
				<Typography variant="caption" color="error">
					{props.error}
				</Typography>
			)}
		</>
	);
};

export const TextBox = (props) => {
	const { handleChange } = useNewPaymentsContext();

	const { multiline } = props;
	return (
		<OutlinedInput
			{...props}
			onChange={handleChange}
			size="small"
			display="block"
			fullWidth={true}
			margin="dense"
			multiline={multiline || false}
			maxRows={5}
			rows={2}
		/>
	);
};

export const PaymentTypeSelect = (props) => {
	const { handleChange } = useNewPaymentsContext();

	return (
		<FormControl fullWidth={true}>
			<Select
				native
				size="small"
				margin="dense"
				variant="outlined"
				inputProps={{
					name: props.name,
				}}
				onChange={handleChange}
				error={props.error}
			>
				<option value="">Please select ...</option>
				<option value="cash">Cash</option>
				<option value="card">Card</option>
				<option value="eft">EFT</option>
			</Select>
			{props.error && (
				<Typography variant="caption" color="error">
					{props.error}
				</Typography>
			)}
		</FormControl>
	);
};

export const PaymentDateSelect = (props) => {
	const { handleChange } = useNewPaymentsContext();

	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<KeyboardDatePicker
				{...props}
				format="ddd, DD/MM/YYYY"
				variant="inline"
				inputVariant="outlined"
				size="small"
				fullWidth={true}
				autoOk={true}
				onChange={(date) =>
					handleChange({
						target: {
							name: 'date',
							value: date,
						},
					})
				}
			/>
		</MuiPickersUtilsProvider>
	);
};
