import { createContext, useContext } from 'react';

const InvoiceContext = createContext({
    invoice: null,
    refetch: () => { },
});

export function useInvoiceContext() {
    return useContext(InvoiceContext);
}

export default InvoiceContext;