import { useState } from 'react';

//@Material
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { OutlinedInput, Tooltip, IconButton, InputAdornment } from '@material-ui/core';

//Icons
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

// Query
import { useMutation } from 'react-query';
import { UpdateInvoiceDiscount } from '../../utils/data';

// Context
// import { useInvoiceContext } from '../../../context/invoice-context'
// import { useWorkorderContext } from '../../../context/workorder-context'

// Componenets
import { NumberFormatCurrency, NumberFormatPercentageTextBox } from '../textbox';

const useStyles = makeStyles(() => ({
	root: {
		flex: 1,
		marginRight: 6,
		marginTop: 5,
		'& .MuiOutlinedInput-adornedStart': {
			paddingLeft: 0,
		},
		'& .MuiOutlinedInput-inputMarginDense': {
			padding: 5,
		},
	},
}));

export const DiscountTextBox = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	const [discountType, setDiscountType] = useState('amount');
	const [discount, setDiscount] = useState(
		discountType === 'amount' ? props?.totals?.invoiceDiscountAmount || 0 : props?.totals?.invoiceDiscountPercentage || 0
	);
	// const { refetch: refetchInvoice } = useInvoiceContext()
	// const { refetch: refetchWorkorder } = useWorkorderContext()

	const updateDiscount = useMutation(UpdateInvoiceDiscount, {
		onSettled: () => {
			if (props.refetch) props.refetch();
			// if (props.config.query.queryKey === 'invoice') refetchInvoice()
			// if (props.query.queryKey === 'workorder') refetchWorkorder()
		},
	});

	const handleFocusNumerical = (event) => {
		event.preventDefault();
		const { target } = event;
		setTimeout(function () {
			target.setSelectionRange(0, 100);
		}, 10);
	};

	const onDiscountChange = async (e) => {
		setDiscount(e.target.value);
	};

	const onDiscountKeyPress = async (e) => {
		if (e.keyCode == 13) {
			mutateDiscount();
		}
	};

	const onDiscountBlur = async () => {
		mutateDiscount();
	};

	const mutateDiscount = async () => {
		let newDiscountAmount = discount;
		let newDiscountType = discountType;

		let discountAmount = 0;
		let discountPercentage = 0;

		if (newDiscountType === 'percentage') {
			discountPercentage = newDiscountAmount;
			discountAmount = parseFloat(props?.totals?.invoiceSubTotal) * parseFloat(discountPercentage / 100);
		}

		if (newDiscountType === 'amount') {
			discountAmount = newDiscountAmount;
			discountPercentage = parseFloat(discountAmount / props?.totals?.invoiceSubTotal) * 100;
		}

		await updateDiscount.mutate(
			{
				_id: props.id,
				invoiceDiscountAmount: parseFloat(discountAmount),
				invoiceDiscountPercentage: parseFloat(discountPercentage),
			},
			{
				onSuccess: () => {
					if (props.refetch) props.refetch();
				},
			}
		);
	};

	const handleSwop = () => {
		let newDiscountType = discountType === 'percentage' ? 'amount' : 'percentage';
		setDiscountType(newDiscountType);
	};

	return (
		<div className={classes.root}>
			<OutlinedInput
				{...props}
				size="small"
				margin="dense"
				display="block"
				fullWidth={true}
				// defaultValue={discountType === 'amount' ? props?.totals?.invoiceDiscountAmount || 0 : props?.totals?.invoiceDiscountPercentage || 0}
				value={discount}
				onChange={onDiscountChange}
				onKeyDown={onDiscountKeyPress}
				onBlur={onDiscountBlur}
				name={discountType === 'percentage' ? 'discountPercentage' : 'discountAmount'}
				inputComponent={discountType === 'percentage' ? NumberFormatPercentageTextBox : NumberFormatCurrency}
				onFocus={(e) => handleFocusNumerical(e)}
				style={{
					fontSize: theme.typography.body2.fontSize,
				}}
				startAdornment={
					<InputAdornment position="end">
						<Tooltip title="Change between % and currency for calculating the discount" arrow={true} placement="right">
							<IconButton
								style={{
									padding: 0,
								}}
								size="small"
								onClick={handleSwop}
							>
								{<SwapHorizIcon />}
							</IconButton>
						</Tooltip>
					</InputAdornment>
				}
			/>
		</div>
	);
};
