import { Card } from '../components/card'
import {
    GetContact,
    GetContacts,
    UpdateContact,
    ReassignEntity,
} from '../utils/data';



export const ContactCard = ({ contactId, parentId, parentTable, config, refetchQueryNames, refetchQueryFn, reassign = false, noLink = false }) => {
    // Shows the standard contact form
    return (
        <Card
            id={contactId}
            queryFn={GetContact}
            mutationFn={UpdateContact}
            createFn={UpdateContact}
            reassign={reassign}
            reassignGetFn={GetContacts}
            reassignFn={ReassignEntity}
            reassignConfig={{
                parentTable: parentTable,
                parentId: parentId,
                key: 'contactID',
                control: {
                    label: 'Contacts',
                    returnField: 'contacts',
                    placeholder: 'Search or create new contact',
                    listboxDisplayValue: 'contactFullname'
                }
            }}
            refetchQueryNames={refetchQueryNames}
            refetchQueryFn={refetchQueryFn}
            template={{
                heading: 'Contact',
                returnKey: 'contact',
                parentTable: parentTable,
                fields: [
                    {
                        name: 'Name',
                        id: 'contactFullname',
                        canEdit: true,
                        showmore: false,
                        type: noLink ? null : 'link',
                        link: noLink ? null : `/contact/${contactId}`,
                    },
                    {
                        name: 'Email',
                        id: 'contactEmail',
                        type: 'email',
                        canEdit: true,
                        showmore: false,
                    },
                    {
                        name: 'Mobile',
                        id: 'contactMobile',
                        type: 'phone',
                        canEdit: true,
                        showmore: config?.limitedView || false,
                    },
                    {
                        name: 'Phone',
                        id: 'contactPhone',
                        type: 'phone',
                        canEdit: true,
                        showmore: config?.limitedView || false,
                    }
                ],
            }}
        />
    )
}

export default ContactCard