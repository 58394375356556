// Query
import { useQuery } from 'react-query';
import { GetContacts } from 'app/utils/data';
// Pages
import { Page } from 'app/components';
import { Loading } from 'app/components/loading';
import { ContactsTable } from './components/contacts-table';
import NewContact from './components/new-contact';

export default function Contacts() {
	const { data, isLoading, refetch } = useQuery(['contacts'], GetContacts, {
		refetchOnWindowFocus: false,
	});

	// Error Handling
	if (data?.status === 'error') return <p>Error Occured</p>;
	if (data?.status === 'access-denied') return <p>Access Denied</p>;
	if (isLoading) return <Loading />;

	// ----------------------------------------------------------------
	return (
		<Page heading="Contacts" actionButtons={<NewContact refetch={refetch} />}>
			<ContactsTable data={data} />
		</Page>
	);
}
