import { createContext, useContext } from 'react';

const SettingsContext = createContext({
	settings: null,
	lists: null,
	blankedDates: null,
	refreshSettings: () => {},
	handleThemeChange: () => {},
});

export function useSettingsContext() {
	return useContext(SettingsContext);
}

export default SettingsContext;
