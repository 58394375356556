import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// MaterialUI
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Tooltip, MenuItem, Menu, Chip, Typography } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';
// Icons
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineRoundedIcon from '@material-ui/icons/PauseCircleOutlineRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import NewReleasesRoundedIcon from '@material-ui/icons/NewReleasesRounded';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

// Context
import { useSettingsContext } from '../../../context/settings-context';
import { useWorkordersContext } from '../../../context/workorders-context';
import { useQuotesContext } from '../../../context/quotes-context';

// Query
import { useMutation } from 'react-query';
import { UpdateWorkorderStatus, UpdateInvoiceStatus, UpdateQuoteStatus } from '../../utils/data';

const useStylesAppointments = makeStyles((theme) => ({
	root: {
		'& .MuiChip-colorPrimary': {
			color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
			border: `1px solid ${theme.palette.success.dark}`,
			backgroundColor: alpha(theme.palette.success.dark, 0.2),
			boxShadow: `1px 1px 3px ${theme.palette.background.default}`,

			// color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.getContrastText(theme.palette.success.light),
			// border: `1px solid ${theme.palette.success.dark}`,
			// backgroundColor: theme.palette.type === 'dark' ? theme.palette.success.main : theme.palette.success.light,
		},
		'& .MuiChip-colorSecondary': {
			color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
			border: `1px solid ${theme.palette.warning.dark}`,
			backgroundColor: alpha(theme.palette.warning.dark, 0.2),
			boxShadow: `1px 1px 3px ${theme.palette.background.default}`,

			// color: `1px solid ${theme.palette.warning.main}`,
			// border: `1px solid ${theme.palette.warning.dark}`,
			// backgroundColor: `1px solid ${theme.palette.warning.main}`,
		},
	},
	rootRed: {
		'& .MuiChip-colorPrimary': {
			color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.text.primary,
			border: `1px solid ${theme.palette.error.dark}`,
			backgroundColor: alpha(theme.palette.error.dark, 0.2),
			boxShadow: `1px 1px 3px ${theme.palette.background.default}`,
		},
	},
	rootGreen: {
		'& .MuiChip-colorPrimary': {
			color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
			border: `1px solid ${theme.palette.success.dark}`,
			backgroundColor: alpha(theme.palette.success.dark, 0.2),
			boxShadow: `1px 1px 3px ${theme.palette.background.default}`,
		},
	},
	rootOrange: {
		'& .MuiChip-colorPrimary': {
			color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
			border: `1px solid ${theme.palette.warning.dark}`,
			backgroundColor: alpha(theme.palette.warning.dark, 0.2),
			boxShadow: `1px 1px 3px ${theme.palette.background.default}`,
		},
	},
}));

export function WorkorderStatusBadge({ workorderId, initialStatus, disabled = false, chipStyle = { marginTop: 5, marginBottom: 5 } }) {
	const topClasses = useStylesAppointments();
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState(null);

	let { settings } = useSettingsContext();
	const { refreshPage } = useWorkordersContext();

	let statusIcon = <PauseCircleOutlineRoundedIcon style={{ color: theme.palette.warning.dark }} />;
	let statusColor = 'Orange';

	if (initialStatus === 'In progress') {
		statusColor = 'Orange';
		statusIcon = <PlayCircleOutlineIcon style={{ color: theme.palette.warning.dark }} />;
	}

	if (initialStatus === 'Complete') {
		statusColor = 'Green';
		statusIcon = <CheckCircleOutlineRoundedIcon style={{ color: theme.palette.success.dark }} />;
	}

	if (initialStatus === 'Overdue') {
		statusColor = 'Red';
		statusIcon = <ReportProblemIcon style={{ color: theme.palette.success.dark }} />;
	}

	// DATA
	// -------------------------------
	const changeStatus = useMutation(UpdateWorkorderStatus, {
		onSuccess: () => {
			refreshPage();
		},
	});

	// FUNCTIONS
	// -------------------------------
	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleStatusChangeClick = async (event, index) => {
		await changeStatus.mutate({
			_id: workorderId,
			workorderStatus: settings.workorderStatus[index],
		});
		setAnchorEl(null);
		// setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	//MAIN
	// -------------------------------
	return (
		<>
			<div className={topClasses?.[`root${statusColor}`]}>
				<Chip
					id={workorderId}
					icon={statusIcon}
					label={<Typography variant="caption">{initialStatus}</Typography>}
					onClick={handleMenuOpen}
					clickable
					color="primary"
					size="small"
					style={{ ...chipStyle }}
					disabled={disabled}
				/>
			</div>
			<Menu id="workorder-status-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
				{settings.workorderStatus.map((option, index) => {
					if (option !== 'Complete') {
						return (
							<MenuItem key={option} onClick={(event) => handleStatusChangeClick(event, index)}>
								{option}
							</MenuItem>
						);
					}
				})}
			</Menu>
		</>
	);
}

export function InvoiceStatusBadge({ invoiceId, initialStatus, refreshPage = () => {}, chipStyle = { marginTop: 5, marginBottom: 5 } }) {
	const topClasses = useStylesAppointments();
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState(null);

	let { settings } = useSettingsContext();

	let statusIcon = <PlayCircleOutlineIcon style={{ color: theme.palette.success.dark }} />;
	let statusColor = 'Green';

	if (initialStatus === 'New') {
		statusColor = 'Orange';
		statusIcon = <NewReleasesRoundedIcon style={{ color: theme.palette.warning.dark }} />;
	}

	if (initialStatus === 'Paid') {
		statusColor = 'Green';
		statusIcon = <CheckCircleOutlineRoundedIcon style={{ color: theme.palette.success.dark }} />;
	}

	if (initialStatus === 'Write-off') {
		statusColor = 'Red';
		statusIcon = <ReportProblemIcon style={{ color: theme.palette.error.dark }} />;
	}

	// DATA
	// -------------------------------
	const changeStatus = useMutation(UpdateInvoiceStatus, {
		onSuccess: () => {
			refreshPage();
		},
	});

	// FUNCTIONS
	// -------------------------------
	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleStatusChangeClick = async (event, index) => {
		changeStatus.mutate({
			_id: invoiceId,
			invoiceStatus: settings.invoiceStatus[index],
		});
		setAnchorEl(null);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	//MAIN
	// -------------------------------
	return (
		<div className={topClasses?.[`root${statusColor}`]}>
			<Chip
				icon={statusIcon}
				label={<Typography variant="caption">{initialStatus}</Typography>}
				onClick={handleMenuOpen}
				clickable
				color={'primary'}
				size="small"
				style={{ ...chipStyle }}
			/>
			<Menu
				id="status-menu"
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				getContentAnchorEl={null}
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				keepMounted
			>
				{settings.invoiceStatus.map((option, index) => {
					if (option !== 'Paid') {
						return (
							<MenuItem key={option} onClick={(event) => handleStatusChangeClick(event, index)}>
								{option}
							</MenuItem>
						);
					}
				})}
			</Menu>
		</div>
	);
}

export function QuoteStatusBadge({ quoteId, initialStatus, chipStyle = { marginTop: 5, marginBottom: 5 } }) {
	const topClasses = useStylesAppointments();
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState(null);

	let { settings } = useSettingsContext();
	const { refreshPage } = useQuotesContext();

	let statusIcon = <PlayCircleOutlineIcon style={{ color: theme.palette.success.dark }} />;
	let statusColor = 'Green';

	if (initialStatus === 'New') {
		statusColor = 'Orange';
		statusIcon = <NewReleasesRoundedIcon style={{ color: theme.palette.warning.dark }} />;
	}

	if (initialStatus === 'Paid') {
		statusColor = 'Green';
		statusIcon = <CheckCircleOutlineRoundedIcon style={{ color: theme.palette.success.dark }} />;
	}

	// DATA
	// -------------------------------
	const changeStatus = useMutation(UpdateQuoteStatus, {
		onSuccess: () => {
			refreshPage();
		},
	});

	// FUNCTIONS
	// -------------------------------
	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleStatusChangeClick = async (event, index) => {
		changeStatus.mutate({
			_id: quoteId,
			quoteStatus: settings.quoteStatus[index],
		});
		setAnchorEl(null);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	//MAIN
	// -------------------------------
	return (
		<div className={topClasses?.[`root${statusColor}`]}>
			<Chip
				icon={statusIcon}
				label={<Typography variant="caption">{initialStatus}</Typography>}
				onClick={handleMenuOpen}
				clickable
				color={'primary'}
				size="small"
				style={{ ...chipStyle }}
			/>
			<Menu
				id="status-menu"
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				getContentAnchorEl={null}
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				keepMounted
			>
				{settings?.quoteStatus?.map((option, index) => {
					if (option !== 'Paid') {
						return (
							<MenuItem key={option} onClick={(event) => handleStatusChangeClick(event, index)}>
								{option}
							</MenuItem>
						);
					}
				})}
			</Menu>
		</div>
	);
}

export function AppointmentStatusBadge({ initialStatus, chipStyle = { marginTop: 5, marginBottom: 5 } }) {
	const classes = useStylesAppointments();
	const theme = useTheme();

	let statusIcon = <PauseCircleOutlineRoundedIcon style={{ color: theme.palette.success.dark }} />;
	let statusColor = 'Green';

	if (initialStatus === 'Awaiting') {
		statusColor = 'Orange';
		statusIcon = <TimelapseIcon style={{ color: theme.palette.warning.dark }} />;
	}

	if (initialStatus === 'Recieved') {
		statusColor = 'Green';
		statusIcon = <CheckCircleOutlineRoundedIcon style={{ color: theme.palette.success.dark }} />;
	}

	if (initialStatus === 'No Show') {
		statusColor = 'Red';
		statusIcon = <NotInterestedIcon style={{ color: theme.palette.error.dark }} />;
	}

	return (
		<div className={classes?.[`root${statusColor}`]}>
			<Chip
				icon={statusIcon}
				label={<Typography variant="caption">{initialStatus}</Typography>}
				color={'primary'}
				style={{ ...chipStyle }}
				className={classes.status}
				size="small"
			/>
		</div>
	);
}

export const CustomStatusBadge = ({ label = '', itemDescription = '', status = '', url = '', onClick = null, chipStyle = {} }) => {
	const classes = useStylesAppointments();
	let history = useHistory();
	return (
		<Tooltip
			title={
				<span style={{ fontSize: '12px' }}>
					{url && <code style={{ backgroundColor: 'grey' }}>click to open</code>}
					{url && <hr />}
					<b>Item:</b>
					{` `}
					{itemDescription}
					<br />
					<b>Status:</b>
					{` `}
					{status}
				</span>
			}
			arrow={true}
			placement="top"
			disableHoverListener={itemDescription ? false : true}
		>
			<div className={status === 'Complete' || status === 'Paid' ? classes?.[`rootGreen`] : classes?.[`rootOrange`]}>
				<Chip
					icon={
						status === 'Complete' || status === 'Paid' ? (
							<CheckCircleOutlineRoundedIcon fontSize="small" />
						) : (
							<TimelapseIcon fontSize="small" />
						)
					}
					label={label}
					color={'primary'}
					size="small"
					style={onClick ? { ...chipStyle, cursor: 'pointer' } : { chipStyle }}
					clickable={url || onClick ? true : false}
					onClick={url ? () => history.push(`${url}`) : onClick ? () => onClick() : null}
				/>
			</div>
		</Tooltip>
	);
};
