import { createContext, useContext } from 'react';

const InvoicesContext = createContext({
    invoices: null,
    searchCriteria: null,
    view: 'new',
    setSearchCriteria: () => { },
    setView: () => { },
    searchQuery: () => { },
    refreshPage: () => { },
    isLoading: false,

    refetch: () => { },
});

export function useInvoicesContext() {
    return useContext(InvoicesContext);
}

export default InvoicesContext;