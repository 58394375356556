import React, { useState, useEffect } from 'react';

// MaterialUI
import { Grid } from '@material-ui/core';

//Icons
import AddIcon from '@material-ui/icons/Add';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// Config
import {
	newAppointmentMandatoryFields,
	newInvoiceMandatoryFields,
	newQuoteMandatoryFields,
	newWorkorderMandatoryFields,
	mandatoryFields,
	newUserConfig,
	newVehicleConfig,
	newBusinessConfig,
} from '../../../../context/config.mandatory-fields';

//Components
import {
	SelectOrCreate,
	AppointmentDate,
	AppointmentInstructions,
	DeleteAppointmentDialog,
	NoShowAppointmentDialog,
} from './components/new-appointment/';

//Data
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { UpdateAppointment, NewWorkorder, CreateInvoice, CreateQuote } from '../../../../app/utils/data';
import {
	GetAppointment,
	GetPreferedSelectionsByContactId,
	GetPreferedSelectionsByBusinessId,
	GetPreferedSelectionsByVehicleId,
} from '../../../../app/utils/data';

//Context
import NewAppointmentContext from '../../../../context/appointments-new-context';
import { useAppointmentsContext } from '../../../../context/appointments-context';
import { useWorkordersContext } from '../../../../context/workorders-context';
import { useSettingsContext } from '../../../../context/settings-context';

//Pages
import { Loading } from '../../../../app/components/loading';
import { PopOut } from '../../../../app/components/popout';
import { NewItemDialog } from './components/new-item/index';
import { AppointmentMileage } from './components/new-appointment/appointment-mileage';
import { AppointmentQuoteFirst } from './components/new-appointment/appointment-quote-first';
import moment from 'moment';

export const AppointmentNew = ({
	bookingId = null,
	initialDate = new Date(),
	initialData,
	open,
	setOpen,
	pureIcon = false,
	iconOnly = true,
	iconSize = 'default',
	disabled = false,
	hideQuoteFirst = false,
	type = 'appointment',
	refetchFn = null,
	customIcon,
	customTooltip,
}) => {
	const queryClient = new useQueryClient();
	// STATE
	// --------------------------------------------------------------
	const { refreshPage: refreshPageAppointment } = useAppointmentsContext();
	const { refreshPage: refreshPageWorkorder } = useWorkordersContext();

	const { refreshSettings } = useSettingsContext();

	const [appointment, setInternalAppointment] = useState({
		bookingDate: initialDate,
		...initialData,
	});
	const [errors, setInternalErrors] = useState();
	const [initialAssociationedLoaded, setInitialAssociationedLoaded] = useState({
		vehicle: false,
		contact: false,
		business: false,
	});

	//Provides the prefered list in selection when picking a vehicle, contact or business
	const [associations, setAssociations] = useState(null);

	//Manages the state of controls when saving
	const [isSaving, setIsSaving] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const setAppointment = (data) => {
		if (data) setInternalAppointment(data);
	};

	// Input Validation
	const setErrors = (appointment, source) => {
		let isMandatoryFields = mandatoryFields?.[source];
		if (type === 'workorder') isMandatoryFields = newWorkorderMandatoryFields?.[source];
		if (type === 'invoice') isMandatoryFields = newInvoiceMandatoryFields?.[source];
		if (type === 'quote') isMandatoryFields = newQuoteMandatoryFields?.[source];
		if (type === 'appointment') isMandatoryFields = newAppointmentMandatoryFields?.[source];

		let newErrors = null;

		if (isMandatoryFields) {
			Object.keys(isMandatoryFields).map((key) => {
				let isMandatory = isMandatoryFields?.[key];

				let value = appointment?.[source]?.[key];
				if (source === 'main') value = appointment?.[key];

				if (value === undefined || value === null || value.toString().trim() === '') value = null;
				if (isMandatory && !value) {
					newErrors = {
						...newErrors,
						[source]: {
							...newErrors?.[source],
							[key]: 'This is a required field',
						},
					};
				}
			});

			if (!newErrors) {
				if (errors && errors?.[source]) {
					const copyErrors = Object.assign({}, errors);
					delete copyErrors[source];
					setErrors(copyErrors);
					return false;
				}
			}
			if (newErrors) {
				setInternalErrors({
					...errors,
					...newErrors,
				});
				return true;
			}
		}
	};

	let state = {
		appointment,
		setAppointment,
		errors,
		setErrors,
		isSaving,
		setIsSaving,
	};

	// GENERAL
	// ----------------------------------------------------------------
	const [newItemOpen, setNewItemOpen] = useState(false);
	const [newItem, setNewItem] = useState(false);

	// DATA
	// --------------------------------------------------------------
	const { isLoading: queryIsLoading } = useQuery(['appointment', bookingId], GetAppointment, {
		enabled: !!bookingId && !!open,
		refetchOnWindowFocus: false,
		onSuccess: async (data) => {
			setInternalAppointment(data.bookings[0]);
		},

		onSettled: () => {
			setIsLoading(false);
		},
	});

	const createAppointment = useMutation(UpdateAppointment);
	const createWorkorder = useMutation(NewWorkorder);
	const createInvoice = useMutation(CreateInvoice);
	const createQuote = useMutation(CreateQuote);

	// FUNCTIONS
	// --------------------------------------------------------------
	const saveClick = async () => {
		if (type === 'appointment') {
			if (!setErrors(appointment, 'main') && !isSaving) {
				setIsSaving(true);
				setOpen(false);

				const newAppointment = {
					...appointment,
					contactID: appointment?.contact ? appointment.contact._id : null,
					vehicleID: appointment?.vehicle ? appointment.vehicle._id : null,
					businessID: appointment?.business ? appointment.business._id : null,
				};

				await createAppointment.mutate(newAppointment);

				//Reset page
				setAssociations(null);
				setInternalErrors(false);
				setInternalAppointment(null);

				refreshSettings();
				refreshPageAppointment();
        queryClient.refetchQueries(['appointments']);
				if (refetchFn) await refetchFn();

				setIsSaving(false);
			}
		}

		if (type === 'workorder') {
			if (!setErrors(appointment, 'main') && !isSaving) {
				setIsSaving(true);
				setOpen(false);

				let newWorkorder = {
					workorderDate: appointment?.bookingDate,
					workorderOdometer: appointment?.workorderOdometer,
					workorderInstructions: appointment?.bookingInstructions,
					contactID: appointment?.contact?._id || null,
					vehicleID: appointment?.vehicle?._id || null,
					businessID: appointment?.business?._id || null,
				};

				await createWorkorder.mutate(newWorkorder);

				//Reset page
				setAssociations(null);
				setInternalErrors(false);
				setInternalAppointment(null);

				refreshSettings();
				refreshPageWorkorder();
				await refetchFn();

				setIsSaving(false);
			}
		}

		if (type === 'invoice') {
			if (!setErrors(appointment, 'main') && !isSaving) {
				setIsSaving(true);
				setOpen(false);

				let newInvoice = {
					invoiceDate: appointment?.bookingDate,
					workorderOdometer: appointment?.workorderOdometer,
					workorderInstructions: appointment?.bookingInstructions,
					contactID: appointment?.contact?._id || null,
					vehicleID: appointment?.vehicle?._id || null,
					businessID: appointment?.business?._id || null,
				};
				await createInvoice.mutate(newInvoice);

				//Reset page
				setAssociations(null);
				setInternalErrors(false);
				setInternalAppointment(null);
				refreshSettings();

				await refetchFn();

				setIsSaving(false);
			}
		}

		if (type === 'quote') {
			if (!setErrors(appointment, 'main') && !isSaving) {
				setIsSaving(true);
				setOpen(false);

				let newQuote = {
					quoteExpiryDate: moment(new Date()).add(14, 'days'),
					workorderOdometer: appointment?.workorderOdometer,
					workorderInstructions: appointment?.bookingInstructions,
					contactID: appointment?.contact?._id || null,
					vehicleID: appointment?.vehicle?._id || null,
					businessID: appointment?.business?._id || null,
				};

				await createQuote.mutate(newQuote);

				//Reset page
				setAssociations(null);
				setInternalErrors(false);
				setInternalAppointment(null);

				await refreshSettings();
				await refetchFn();

				setIsSaving(false);
			}
		}
	};

	const cancelClick = async () => {
		// await setIsSaving(true)
		await setInternalAppointment(null);
		await setInternalErrors(false);
		// await refreshSettings();
		await setOpen(false);
	};

	// MONITORS
	// --------------------------------------------------------------

	// NEW ITEM Dialog
	useEffect(() => {
		if (appointment)
			Object.keys(appointment).map((key) => {
				if (appointment?.[key]?._id === 'new') {
					setNewItem({
						source: key,
						object: appointment?.[key],
					});
					setNewItemOpen(true);
				}
			});
	}, [appointment]);

	// --------------------------------------------------------------
	// FILTERS
	// --------------------------------------------------------------
	useQuery(['contact-associations', appointment?.contact?._id], GetPreferedSelectionsByContactId, {
		enabled: !!appointment?.contact?._id && !appointment?.business?._id && !appointment?.vehicle?._id && type !== 'invoice',
		refetchOnWindowFocus: false,

		onSuccess: async (associatedData) => {
			setIsLoading(true);
			setAssociations(associatedData.associations);
			if (!initialAssociationedLoaded.contact)
				setInternalAppointment({
					...appointment,
					vehicle:
						!appointment?.vehicle && associatedData?.associations?.vehicles?.length ? associatedData?.associations?.vehicles[0] : null,
					business:
						!appointment?.business && associatedData?.associations?.business?.length ? associatedData?.associations?.business[0] : null,
				});
			setInitialAssociationedLoaded({
				...initialAssociationedLoaded,
				contact: true,
			});
		},

		onSettled: () => {
			setIsLoading(false);
		},
	});

	useQuery(['vehicle-associations', appointment?.vehicle?._id], GetPreferedSelectionsByVehicleId, {
		enabled: !!appointment?.vehicle?._id && !appointment?.contact?._id && !appointment?.business?._id && type !== 'invoice',
		refetchOnWindowFocus: false,

		onSuccess: async (associatedData) => {
			setAssociations(associatedData.associations);
			if (!initialAssociationedLoaded.vehicle)
				setInternalAppointment({
					...appointment,
					business:
						!appointment?.business && associatedData?.associations?.business?.length ? associatedData?.associations?.business[0] : null,
					contact:
						!appointment?.contact && associatedData?.associations?.contacts?.length ? associatedData?.associations?.contacts[0] : null,
				});
			setInitialAssociationedLoaded({
				...initialAssociationedLoaded,
				vehicle: true,
			});
		},
	});

	useQuery(['business-associations', appointment?.business?._id], GetPreferedSelectionsByBusinessId, {
		enabled: !!appointment?.business?._id && !appointment?.contact?._id && !appointment?.vehicle?._id && type !== 'invoice',
		refetchOnWindowFocus: false,
		onSuccess: async (associatedData) => {
			setAssociations(associatedData.associations);
			if (!initialAssociationedLoaded.business)
				setInternalAppointment({
					...appointment,
					vehicle:
						!appointment?.vehicle && associatedData?.associations?.vehicles?.length ? associatedData?.associations?.vehicles[0] : null,
					contact:
						!appointment?.contact && associatedData?.associations?.contacts?.length ? associatedData?.associations?.contacts[0] : null,
				});
			setInitialAssociationedLoaded({
				...initialAssociationedLoaded,
				business: true,
			});
		},
	});

	// Set heading
	let heading = bookingId && type === 'appointment' ? 'Edit appointment' : 'New appointment';
	if (type === 'workorder') {
		heading = 'New work order';
	}
	if (type === 'invoice') {
		heading = 'New invoice';
	}
	if (type === 'quote') {
		heading = 'New Quote';
	}

	// MAIN
	return (
		<NewAppointmentContext.Provider value={state}>
			<PopOut
				// GENERAL
				heading={heading}
				tooltip={customTooltip || heading}
				buttonText="New"
				// STYLE
				noBorder={true}
				// STATE
				open={open}
				setOpen={setOpen}
				disabled={disabled}
				// ICON
				pureIcon={pureIcon}
				iconOnly={iconOnly}
				iconSize={iconSize}
				icon={
					customIcon ? (
						customIcon
					) : bookingId ? (
						<CreateOutlinedIcon fontSize={iconSize || 'default'} />
					) : (
						<AddIcon fontSize={iconSize || 'default'} />
					)
				}
				view={bookingId ? 'edit' : 'new'}
				// FOOTER
				isSaving={isSaving}
				saveButtonText="Save"
				saveButtonFn={saveClick}
				cancelButtonText="Cancel"
				cancelButtonFn={cancelClick}
			>
				<>
					{/* LOADING */}
					{(queryIsLoading || isLoading || isSaving) && <Loading />}
					{/* BODY */}
					{((!queryIsLoading && !isLoading) || !isSaving) && (
						<>
							{/* <PopOutSection label='Customer' isFirst={true}> */}
							{/* VEHICLE */}
							<SelectOrCreate
								config={newVehicleConfig}
								defaultValue={appointment?.vehicle}
								preferedSelectionItems={associations?.vehicles}
								disabled={isSaving}
								isLoading={isLoading || isSaving}
							/>
							{/* CONTACT */}
							<SelectOrCreate
								config={newUserConfig}
								defaultValue={appointment?.contact}
								preferedSelectionItems={associations?.contacts}
								disabled={isSaving}
								isLoading={isLoading || isSaving}
							/>
							{/* BUSINESS */}
							<SelectOrCreate
								config={newBusinessConfig}
								defaultValue={appointment?.business}
								preferedSelectionItems={associations?.business}
								disabled={isSaving}
								isLoading={isLoading || isSaving}
							/>
							{/* DATE */}
							<AppointmentDate
								defaultValue={type !== 'quote' ? appointment?.bookingDate : moment().add(14, 'days')}
								disabled={isSaving}
								label={type === 'quote' ? 'Valid till' : 'Date'}
							/>
							{/* MILEAGE */}
							{(type === 'workorder' || type === 'invoice' || type === 'quote') && appointment?.vehicle && (
								<AppointmentMileage disabled={isSaving} />
							)}
							{(type === 'workorder' || type === 'invoice' || type === 'quote') && !appointment?.vehicle && (
								<AppointmentMileage disabled={true} />
							)}

							{/* INSTRUCTIONS */}
							<AppointmentInstructions
								defaultValue={appointment?.bookingInstructions}
								disabled={isSaving}
								label={type === 'quote' ? 'Description' : 'Instruction'}
							/>

							{/* QUOTE FIRST */}
							{!hideQuoteFirst && type === 'appointment' && (
								<AppointmentQuoteFirst defaultValue={appointment?.quoteFirst} disabled={isSaving} />
							)}
							{!hideQuoteFirst && type === 'workorder' && (
								<AppointmentQuoteFirst defaultValue={appointment?.quoteFirst} disabled={isSaving} />
							)}

							{/* ACTIONS */}
							{bookingId && (
								<>
									{/* <PopOutSection label='Actions' isFirst={true}> */}
									<Grid container justifyContent="flex-start" style={{ paddingTop: 15 }} spacing={2}>
										{/* NO SHOW */}
										<Grid item>
											<NoShowAppointmentDialog
												id={appointment?._id}
												title="Customer did not show?"
												message="Please provide a reason if available. (Optional)"
												tootlip="Mark this customer as a 'no show' and remove the appointment from the list."
												icon={<DeleteForeverIcon />}
												setParentOpen={setOpen}
												disabled={isSaving}
											/>
										</Grid>

										{/* DELETE */}
										<Grid item>
											<DeleteAppointmentDialog
												id={appointment?._id}
												title="Delete this appointment?"
												message="This will permanently delete this appointment. Are you sure you want to continue?"
												tootlip="Permanently delete this appointment."
												icon={<DeleteForeverIcon />}
												setParentOpen={setOpen}
												disabled={isSaving}
											/>
										</Grid>
									</Grid>
									{/* </PopOutSection> */}
								</>
							)}
						</>
					)}
					{/* NEW ITEM Dialog */}
					<NewItemDialog
						table={newItem.source}
						isNew={true}
						isNewInitialItem={newItem?.object}
						open={newItemOpen}
						contactId={null}
						vehicleId={null}
						businessId={null}
						setOpen={setNewItemOpen}
					/>
				</>
			</PopOut>
		</NewAppointmentContext.Provider>
	);
};

export default AppointmentNew;
