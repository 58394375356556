import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

//MaterialUI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { IconButton, Paper, Grid, Typography, InputBase } from '@material-ui/core';

//Icons
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import PersonIcon from '@material-ui/icons/Person';

//Pages
import { useSettingsContext } from '../../../context/settings-context';
import ContactPopup from '../contact';
import VehiclePopup from '../vehicle';
import BusinessPopup from '../business';

//Context
import { useSearchContext } from '../../../context/search-context';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		margin: 5,
	},
	search: {
		position: 'relative',
		borderRadius: 25,
		// border: `1px solid ${theme.palette.primary.dark}`,
		backgroundColor: theme.palette.common.white,
		// color: theme.palette.getContrastText(theme.palette.primary.light),
		'&:hover': {
			// color: theme.palette.getContrastText(theme.palette.common.white),
			// backgroundColor: theme.palette.common.white,
		},
		marginLeft: 0,
		// marginRight: 50,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(1),
			width: 'auto',
		},
	},
	searchIcon: {
		color: theme.palette.common.black,
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	clearIcon: {
		color: 'black',
		marginRight: theme.spacing(2),
	},
	inputRoot: {
		// color: theme.palette.text.primary,
	},
	inputInput: {
		// vertical padding + font size from searchIcon
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,

		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '12ch',
			'&:focus': {
				width: '20ch',
			},
		},
		'&:focus': {
			// color: theme.palette.common.black,
			// backgroundColor: theme.palette.common.white,
		},
		color: theme.palette.common.black,
	},

	popupRoot: {
		flexGrow: 1,
		display: 'block',
		position: 'fixed',

		top: 55,
		right: 210,

		// borderRadius: 20,
		// border: '1px solid black',
		zIndex: 9999,
	},

	/* Add styles to the form container */
	formContainer: {
		border: `1px solid ${theme.palette.secondary.light}`,
		backgroundColor: theme.palette.background.default,
		padding: '10px',
	},

	resultsBox: {
		minWidth: 200,
	},

	row: {
		display: 'flex',
		flex: 1,
		width: '100%',
		padding: 3,
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: theme.palette.divider,
		},
	},
}));

export const SearchGlobal = () => {
	const classes = useStyles();
	const theme = useTheme();
	const { open, setOpen } = useSearchContext();

	const { lists } = useSettingsContext();
	const [results, setResults] = useState();
	// const [open, setOpen] = useState(false);

	const onKeyDown = (event) => {
		if (event.code === 'Escape') handleClose();
	};

	const handleClose = () => {
		document.getElementById('global-search').value = '';
		setResults(null);
		setOpen(false);
	};

	const handleSearch = (searchTerm) => {
		if (searchTerm === '') {
			setOpen(false);
			setResults();
			return null;
		}
		let contacts = lists.contacts.filter((contact) => contact.contactFullname.toLowerCase().includes(searchTerm.toLowerCase()));
		let vehicles = lists.vehicles.filter((vehicle) => vehicle.vehicleRegistration.toLowerCase().includes(searchTerm.toLowerCase()));
		let business = lists.business.filter((business) => business.businessName.toLowerCase().includes(searchTerm.toLowerCase()));
		let bookings = lists.bookings.filter((booking) => booking.bookingNumber.toLowerCase().includes(searchTerm.toLowerCase()));
		let workorders = lists.workorders.filter((workorder) => workorder.workorderNumber.toLowerCase().includes(searchTerm.toLowerCase()));
		let invoices = lists.invoices.filter((invoice) => invoice.invoiceNumber.toLowerCase().includes(searchTerm.toLowerCase()));

		let combinedResults = {};

		if (contacts.length > 0)
			combinedResults = { ...combinedResults, contacts, moreCount: { ...combinedResults.moreCount, contacts: contacts.length - 5 } };
		if (vehicles.length > 0)
			combinedResults = { ...combinedResults, vehicles, moreCount: { ...combinedResults.moreCount, vehicles: vehicles.length - 5 } };
		if (business.length > 0)
			combinedResults = { ...combinedResults, business, moreCount: { ...combinedResults.moreCount, business: business.length - 5 } };
		if (bookings.length > 0)
			combinedResults = { ...combinedResults, bookings, moreCount: { ...combinedResults.moreCount, bookings: bookings.length - 5 } };
		if (workorders.length > 0)
			combinedResults = { ...combinedResults, workorders, moreCount: { ...combinedResults.moreCount, workorders: workorders.length - 5 } };
		if (invoices.length > 0)
			combinedResults = { ...combinedResults, invoices, moreCount: { ...combinedResults.moreCount, invoices: invoices.length - 5 } };

		setResults({
			...combinedResults,
		});

		setOpen(true);
	};

	return (
		<>
			{/* SEARCH BOX */}
			<div className={classes.root}>
				<div className={classes.search}>
					{/* Icon */}
					<div className={classes.searchIcon}>
						<SearchIcon />
					</div>

					{/* Input */}
					<InputBase
						id="global-search"
						placeholder="Quick search…"
						classes={{
							root: classes.inputRoot,
							input: classes.inputInput,
						}}
						onChange={(e) => handleSearch(e.target.value)}
						onKeyDown={onKeyDown}
						endAdornment={
							<IconButton className={classes.clearIcon} size="small" onClick={handleClose}>
								<ClearIcon style={{ '&:hover': { mouse: 'pointer' } }} />
							</IconButton>
						}
					/>
				</div>
			</div>

			{/* SEARCH RESULTS */}
			{open && results && (
				<div className={classes.popupRoot} id="search-popup">
					<Grid container alignItems="center" style={{ backgroundColor: theme.palette.secondary.light, padding: 10, paddingRight: 0 }}>
						<Grid item xs={8}>
							<Typography variant="body1">Search results...</Typography>
						</Grid>
						<Grid item xs={4}>
							<Grid container justifyContent="flex-end">
								<IconButton className={classes.clearIcon} size="small" onClick={handleClose}>
									<ClearIcon />
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
					<Paper square={true} elevation={24} className={classes.formContainer}>
						<Grid container spacing={2}>
							<SearchBox
								data={results?.contacts}
								moreCount={results?.moreCount?.contacts}
								dbKey="contactFullname"
								label="Contacts"
								editUrl="contact"
							/>
							<SearchBox
								data={results?.vehicles}
								moreCount={results?.moreCount?.vehicles}
								dbKey="vehicleRegistration"
								label="Vehicles"
								editUrl="vehicle"
							/>
							<SearchBox data={results?.business} moreCount={results?.moreCount?.business} dbKey="businessName" label="Business" />
							<SearchBox
								data={results?.invoices}
								moreCount={results?.moreCount?.invoices}
								dbKey="invoiceNumber"
								label="Invoices"
								editUrl="invoice"
							/>
							<SearchBox
								data={results?.workorders}
								moreCount={results?.moreCount?.workorders}
								dbKey="workorderNumber"
								label="Workorders"
								editUrl="workorder"
							/>
							<SearchBox data={results?.bookings} dbKey="bookingNumber" label="Appointments" />
						</Grid>
					</Paper>
				</div>
			)}
		</>
	);
};

const SearchBox = ({ data, dbKey, label, moreCount, editUrl }) => {
	if (!data || data?.length === 0) return null;

	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();

	return (
		<>
			<Grid item style={{ width: 200, margin: 10, padding: 0, border: `1px solid ${theme.palette.divider}` }}>
				<Grid container align="center" style={{ backgroundColor: theme.palette.background.paper, padding: theme.spacing(1) }}>
					<PersonIcon fontSize="small" style={{ marginRight: 5 }} />
					<Typography variant="subtitle2" color="secondary">
						{label}
					</Typography>
				</Grid>

				<Grid container direction="column" className={classes.resultsBox} spacing={0} style={{ padding: 10 }}>
					{data?.map((item, idx) => {
						if (idx >= 5) return null;

						if (label === 'Contacts') {
							return <ContactPopup key={`contacts-search-global-${idx}`} contactId={item?._id} contactFullname={item?.[dbKey]} />;
						}

						if (label === 'Vehicles') {
							return <VehiclePopup key={`vehicles-search-global-${idx}`} vehicleId={item?._id} vehicleRegistration={item?.[dbKey]} />;
						}

						if (label === 'Business') {
							return (
								<BusinessPopup
									key={`business-search-global-${idx}`}
									businessId={item?._id}
									businessName={item?.[dbKey]}
									forceAPICall={true}
								/>
							);
						}

						if (label !== 'Contacts' && label !== 'Vehicles') {
							return (
								<Grid
									item
									xs={12}
									className={classes.row}
									key={idx}
									style={{ width: 200 }}
									onClick={() => history.push(`/${editUrl}/${item?._id}`)}
								>
									{item?.[dbKey]}
								</Grid>
							);
						}
					})}
				</Grid>
				{moreCount > 0 && (
					<Grid
						container
						direction="row"
						spacing={0}
						justifyContent="flex-end"
						style={{ paddingRight: 10, borderTop: `1px solid ${theme.palette.divider}` }}
					>
						<Typography variant="caption">{`${moreCount} more results...`}</Typography>
					</Grid>
				)}
			</Grid>
		</>
	);
};
