import { httpGET, httpPOST } from './data-handler';

// GET
// -------------------

// Get invoice(s) by different methods
// ------------------------------------------------------------------------------------------------------------------------------
// [invoices]
export const GetInvoices = async () => await httpGET({ path: 'invoices', data: null });
// [invoice]
export const GetInvoiceById = async (req) => await httpGET({ path: `invoice/${req.queryKey[1]}`, data: null });
// [invoices_active]
export const GetActiveInvoices = async () => await httpGET({ path: 'invoices/active', data: null });
// [invoice]
export const GetInvoice = async (req) => await httpGET({ path: `invoice/${req.queryKey[1]}`, data: null, retKey: 'invoice' });
// [invoice]
export const GetInvoiceByWorkorderId = async (req) => await httpGET({ path: `invoice/workorder/${req.queryKey[1]}`, data: null });
// [invoice]
export const GetInvoiceItemsByInvoiceId = async (req) => await httpGET({ path: `invoice/items/${req.queryKey[1]}`, data: null });

// [invoice-pdf]
export const GeneratePdfByInvoiceId = async (req) => await httpGET({ path: `invoice/${req.queryKey[1]}/pdf`, data: null });

// [contact_invoices]
export const GetContactInvoices = async (req) => await httpGET({ path: `invoice/contact/${req.queryKey[1]}`, data: null });

// Get sub-items for a specific invoice
// ------------------------------------------------------------------------------------------------------------------------------
// [vehicle-invoice]
export const GetInvoiceVehicle = async (req) => await httpGET({ path: `invoice/${req.queryKey[1]}/vehicle`, data: null });
// [business-invoice]
export const GetInvoiceBusiness = async (req) => await httpGET({ path: `invoice/${req.queryKey[1]}/business`, data: null });
// [contact-invoice]
export const GetInvoiceContact = async (req) => await httpGET({ path: `invoice/${req.queryKey[1]}/contact`, data: null });
// [reminder-invoice]
export const GetInvoiceReminder = async (req) => await httpGET({ path: `invoice/${req.queryKey[1]}/reminder`, data: null });
// [warranty-invoice]
export const GetInvoiceWarranty = async (req) => await httpGET({ path: `invoice/${req.queryKey[1]}/warranty`, data: null });

// POST
// -------------------
export const CreateInvoice = async (data) => await httpPOST({ path: 'invoice', data: data });
export const CreateInvoiceFromWorkorder = async (data) => await httpPOST({ path: 'invoice/import', data: data });
export const ImportQuoteToInvoice = async (data) => await httpPOST({ path: 'invoice/import-quote', data: data });
export const CreateInvoiceItem = async (data) => await httpPOST({ path: 'invoice/item/create', data: data });

export const SendInvoiceByEmail = async (data) => await httpPOST({ path: 'invoice/send', data: data });

export const DeleteInvoiceItem = async (data) => await httpPOST({ path: 'invoice/delete/item', data: data });
export const DeleteInvoice = async (data) => await httpPOST({ path: 'invoice/delete/item', data: data });

export const UpdateInvoiceItem = async (data) => await httpPOST({ path: 'invoice/item/update', data: data });

export const UpdateInvoiceDiscount = async (data) => await httpPOST({ path: 'invoice/discount', data: data });
export const UpdateInvoiceStatus = async (data) => await httpPOST({ path: 'invoice/status', data: data });
export const UpdateInvoiceDate = async (data) => await httpPOST({ path: 'invoice/date', data: data });
export const UpdateInvoiceVehicle = async (data) => await httpPOST({ path: `invoice/vehicle`, data: data });
export const UpdateInvoiceBusiness = async (data) => await httpPOST({ path: `invoice/business`, data: data });
export const UpdateInvoiceContact = async (data) => await httpPOST({ path: `invoice/contact`, data: data });
export const UpdateInvoiceReminder = async (data) => await httpPOST({ path: `invoice/reminder`, data: data });
export const UpdateInvoiceWarranty = async (data) => await httpPOST({ path: `invoice/warranty`, data: data });
export const UpdateBatchInvoiceItems = async (data) => await httpPOST({ path: 'invoice/items/update', data: data });

export const AssignInvoiceTemplate = async (data) => await httpPOST({ path: 'invoice/costs/assign', data: data });
export const CreateInvoiceGroupHeaderSaveAsTemplate = async (data) => await httpPOST({ path: 'invoice/costs/template', data: data });
export const LoadInvoiceTemplate = async (data) => await httpGET({ path: `invoice/costs/template/${data.templateName}`, data: data });
export const LoadInvoiceTemplateNames = async () => await httpGET({ path: `invoice/costs/templates`, data: null });
export const DeleteInvoiceGroup = async (data) => await httpPOST({ path: 'invoice/costs/deletegroup', data: data });
