//Material
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

//Pages
import FinanceTable from '../../../../components/finance-table';
import { QuoteCostItemsTable } from '../../../../../context/config.tables';
import { useQuoteContext } from '../../../../../context/quote-context';

const useStyles = makeStyles((theme) => ({
	root: {
		overflow: 'hidden',
	},

	header: {
		// backgroundColor: theme.palette.background.default,
	},

	body: {
		// minHeight: '60vh',
		// maxHeight: '60vh',
		height: '53vh',
		overflowY: 'none',
		overflowX: 'none',
	},

	footer: {
		backgroundColor: theme.palette.background.default,
	},

	button: {
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
}));

export const QuoteItemsTable = ({ quoteId }) => {
	const classes = useStyles();
	const { refetch } = useQuoteContext();

	const config = QuoteCostItemsTable({ quoteId, refetch });

	return (
		<Grid container className={classes.root}>
			{/* TABLE HEADER */}
			<Grid item xs={12} className={classes.header}>
				<div style={{ marginLeft: 15, marginRight: 15 }}>
					<FinanceTable config={config} headers="only" refreshItem={refetch} />
				</div>
			</Grid>

			{/* TABLE */}
			<Grid item xs={12} className={classes.body}>
				<FinanceTable config={config} headers="none" newRow="none" subTotal="hide" refreshItem={refetch} />
			</Grid>

			{/* FOOTER */}
			<Grid item xs={12} className={classes.footer}>
				<div style={{ marginLeft: 15, marginRight: 15, marginBottom: 15 }}>
					<FinanceTable config={config} newRow="only" refreshItem={refetch} />
				</div>
			</Grid>
		</Grid>
	);
};
