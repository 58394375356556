import MomentUtils from '@date-io/moment';
import moment from 'moment';

// @MaterialUI
import { useTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Grid, FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Data
import { useWorkordersContext } from '../../../../../context/workorders-context'
import { useSettingsContext } from '../../../../../context/settings-context'

export const SearchWorkordersInSideBar = () => {
    const { workorders: data } = useWorkordersContext()
    const { lists } = useSettingsContext()

    return (
        <Grid container spacing={0}>
            {/* <SearchQueryFilterFreeForm /> */}

            <SearchQueryFilterInput
                name="Ref #"
                searchKey='workorderNumber'
                primaryKey='workorderNumber'
                options={lists?.workorders}
                values={data?.query?.workorderNumber}
            />

            <SearchQueryFilterInput
                name="Status"
                searchKey='workorderStatus'
                primaryKey='workorderStatus'
                options={lists?.workorderStatus}
                values={data?.query?.workorderStatus}
            />

            <SearchQueryFilterInput
                name="Contact"
                searchKey='contactIDs'
                primaryKey='contactFullname'
                options={lists?.contacts}
                values={data?.query?.contactIDs}
            />

            <SearchQueryFilterInput
                name="Vehicle"
                searchKey='vehicleIDs'
                primaryKey='vehicleRegistration'
                options={lists?.vehicles}
                values={data?.query?.vehicleIDs}
            />

            <SearchQueryFilterInput
                name="Business"
                searchKey='businessIDs'
                primaryKey='businessName'
                options={lists?.business}
                values={data?.query?.businessIDs}
            />

            <Grid item xs={12}>
                <SearchQueryFilterDate
                    name="From"
                    searchKey='workorderDateFrom'
                    values={data?.query?.workorderDateFrom}
                />

                <SearchQueryFilterDate
                    name="To"
                    searchKey='workorderDateTo'
                    values={data?.query?.workorderDateTo}
                />
            </Grid>
        </Grid>
    )
}

// Search item with INPUT SELECT
export const SearchQueryFilterInput = ({ name, primaryKey, searchKey, options = [], values = [] }) => {
    const theme = useTheme();
    const { searchQuery, setView } = useWorkordersContext();

    return (
        <Grid container alignItems='center'>
            <Grid item xs={12}>
                <FormControl
                    hiddenLabel={true}
                    fullWidth={true}
                >
                    <Autocomplete
                        id={`SearchQueryFilterInput_${name}`}
                        multiple
                        clearOnBlur
                        value={values}
                        options={options}
                        getOptionLabel={(option) => option?.[primaryKey] || ''}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth={true}
                                variant="outlined"
                                size='small'
                                label={name}
                                InputProps={{
                                    ...params.InputProps,
                                    style: {
                                        fontSize: '0.8rem',
                                        borderRadius: 0,
                                        maxWidth: 200,
                                        backgroundColor: theme.palette.background.paper,
                                    }
                                }}
                                style={{
                                    transition: theme.transitions.create(['border-color', 'box-shadow']),
                                    marginBottom: 5,
                                }}
                            />
                        )
                        }

                        type="search"
                        size="small"

                        onChange={(event, newValue) => {
                            searchQuery(searchKey, newValue)
                            setView('search')
                        }}
                    />
                </FormControl>
            </Grid>
        </Grid>
    )
}

// Search item with DATE
const SearchQueryFilterDate = ({ name, searchKey, values = null }) => {
    const theme = useTheme();
    const { searchQuery, setView } = useWorkordersContext();

    return (
        <Grid container alignItems='center'>

            {/* Date picker */}
            <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        id={`SearchQueryFilterDate_${name}`}
                        disableToolbar
                        clearable={true}
                        variant="dialog"
                        format="DD MMM YYYY"
                        inputVariant="outlined"
                        autoOk={true}
                        value={values}
                        inputProps={{
                            style: {
                                borderRadius: 0,
                                padding: 10
                            },
                            placeholder: name,
                        }}
                        InputProps={{
                            size: 'small',
                            style: {
                                color: theme.palette.text.primary,
                                paddingLeft: 5,
                                borderRadius: 0,
                                maxWidth: 200,
                                backgroundColor: theme.palette.background.paper,
                                margin: 0
                            },
                        }}
                        KeyboardButtonProps={{
                            size: 'small',
                        }}
                        onChange={(date, newValue) => {
                            setView('search')
                            searchQuery(searchKey, moment(newValue))
                        }}
                        style={{
                            transition: theme.transitions.create(['border-color', 'box-shadow']),
                            marginBottom: 5,
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
        </Grid>
    )
}