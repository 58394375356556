import { useState } from 'react';
// material
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { KeyPairInput } from 'app/components/key-pair';
import { useSettingsContext } from 'context/settings-context';
import { UpdateContact } from 'app/utils/data-layer/contacts';
import { useMutation } from 'react-query';

export const NewContact = ({ refetch }) => {
	const [contact, setContact] = useState(null);
	const [open, setOpen] = useState(false);
	const [errors, setErrors] = useState({});
	const [isSaving, setIsSaving] = useState(false);

	const { lists } = useSettingsContext();

	const createContact = useMutation(UpdateContact, {
		onSuccess: async () => {
			setIsSaving(false);
			setOpen(false);
			refetch();
		},
		onErrors: async () => {
			setIsSaving(false);
		},
	});

	//* handlers
	const handleCreate = async () => {
		setIsSaving(true);
		setErrors(null);

		let errorOccured = false;
		if (!contact?.contactFullname) {
			setErrors((error) => ({ ...error, contactFullname: 'Required' }));
			errorOccured = true;
		}

		if (!contact?.contactEmail) {
			setErrors((error) => ({ ...error, contactEmail: 'Required' }));
			errorOccured = true;
		}

		if (!errorOccured) {
			lists.contacts.map((listContact) => {
				if (listContact?.contactEmail?.toLowerCase() === contact?.contactEmail?.toLowerCase()) {
					setErrors((errors) => ({ ...errors, contactEmail: 'Email already exists' }));
					errorOccured = true;
				}
			});
		}

		if (!errorOccured) {
			await createContact.mutate(contact);
		} else {
			setIsSaving(false);
		}
	};

	const handleCancel = () => {
		setOpen(false);
	};

	return (
		<>
			<Tooltip title="Add new contact">
				<IconButton size="medium" onClick={() => setOpen(true)}>
					<AddIcon style={{ fontSize: '2rem' }} />
				</IconButton>
			</Tooltip>

			<Dialog open={open} onClose={handleCancel}>
				<DialogTitle>New contact</DialogTitle>
				<DialogContent>
					<Grid container alignItems="center" spacing={2}>
						<KeyPairInput
							label="Name"
							name="contactFullname"
							focus={true}
							onChange={(e) => setContact((contact) => ({ ...contact, [e.target.name]: e.target.value }))}
							error={errors?.contactFullname || false}
							disabled={isSaving}
							required={true}
						/>

						<KeyPairInput
							label="Email"
							name="contactEmail"
							type="email"
							onChange={(e) => {
								setContact((contact) => ({ ...contact, [e.target.name]: e?.target?.value?.toString().toLowerCase() }));
							}}
							error={errors?.contactEmail || false}
							disabled={isSaving}
							required={true}
						/>

						<KeyPairInput
							label="Mobile"
							name="contactMobile"
							type="phone"
							onChange={(e) => setContact((contact) => ({ ...contact, [e.target.name]: e.target.value }))}
							error={errors?.contactMobile || false}
							disabled={isSaving}
						/>

						<KeyPairInput
							label="Phone"
							name="contactPhone"
							type="phone"
							onChange={(e) => setContact((contact) => ({ ...contact, [e.target.name]: e.target.value }))}
							error={errors?.contactPhone || false}
							disabled={isSaving}
						/>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Button onClick={handleCancel} color="default" disabled={isSaving}>
						Cancel
					</Button>
					<Button onClick={handleCreate} color="secondary" disabled={isSaving}>
						Create
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default NewContact;
