import { useState, useEffect } from 'react';

//Data
import { useMutation } from 'react-query';
import { AddPayment } from '../../../../utils/data';

//Context
import NewPaymentsContext from '../../../../../context/payments-context';

//Shared Components
import PopOutNoButton from '../../../../components/popout/popout-no-button';

//Components
import PaymentBody from './components/payment-body';

// Main Code -->
export function NewPayment({ invoiceId, initialTotals, open, setOpen, refetch }) {
	// const { refetch } = useInvoiceContext();

	//INITIAL STATE
	const [totals, setInternalTotals] = useState({
		balanceBroughtForward: initialTotals?.totals?.invoiceBalance || 0,
		payment: initialTotals?.totals?.invoiceBalance,
		date: initialTotals.date || new Date(),
		ref: '',
		notes: '',
	});

	useEffect(() => {
		if (initialTotals) {
			setInternalTotals({
				balanceBroughtForward: initialTotals?.totals?.invoiceBalance || 0,
				payment: initialTotals?.totals?.invoiceBalance || initialTotals?.totals?.invoiceTotal,
				date: initialTotals.date || new Date(),
				ref: '',
				notes: '',
			});
		}
	}, [open, initialTotals]);

	const [errors, setErrors] = useState({
		payment: null,
		paymentType: null,
	});

	const addPayment = useMutation(AddPayment, {
		onSuccess: async () => {
			refetch();
		},
	});

	//FUNCTIONS
	const handleChange = (e) => {
		setInternalTotals({
			...totals,
			[e?.target?.name]: e?.target?.value,
		});
	};

	const paymentChange = (e) => {
		let payment = parseFloat(e?.target?.value) || 0;

		setInternalTotals({
			...totals,
			payment,
		});
	};

	//STATE
	const [state, setState] = useState({
		totals,
		handleChange,
		paymentChange,
	});

	useEffect(() => {
		setState({
			totals,
			handleChange,
			paymentChange,
		});
	}, [totals]);

	//FUNCTIONS
	const saveClick = async () => {
		let foundErrors = false;
		if (!totals?.payment) {
			setErrors({ ...errors, payment: '* Required field' });
			foundErrors = true;
		}
		if (!totals?.paymentType) {
			setErrors({ ...errors, paymentType: '* Required field' });
			foundErrors = true;
		}

		if (!foundErrors) {
			await addPayment.mutate({
				invoiceID: invoiceId,
				paymentAmount: totals?.payment,
				paymentType: totals?.paymentType,
				paymentRef: totals?.ref,
				paymentNotes: totals?.notes,
				paymentDate: totals?.date,
				paymentOriginalTotal: totals?.balanceBroughtForward,
			});

			setInternalTotals(null);
			setErrors({
				payment: null,
				paymentType: null,
			});
			initialTotals = null;

			setOpen(false);
		}
	};
	const cancelClick = async () => {
		setInternalTotals(null);
		setErrors({
			payment: null,
			paymentType: null,
		});
		initialTotals = null;
		setOpen(false);
	};

	// MAIN
	// --------------------------------------------------------------------------------------------------
	return (
		<PopOutNoButton
			heading="New Payment"
			buttonText="Load Payment"
			buttonDefault={true}
			readonly={true}
			tooltip="Load a new payment against this invoice"
			helpBoxText="This allows you to load a payment against this specific invoice. "
			open={open}
			setOpen={setOpen}
			buttonFullWidth={true}
			saveButtonText="Load Payment"
			saveButtonFn={saveClick}
			cancelButtonFn={cancelClick}
		>
			<NewPaymentsContext.Provider value={state}>
				<PaymentBody errors={errors} max={initialTotals?.totals?.invoiceBalance} />
			</NewPaymentsContext.Provider>
		</PopOutNoButton>
	);
}

export default NewPayment;
