import { useHistory } from 'react-router-dom';

// MaterialUI
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, MenuItem, MenuList, Typography, Avatar } from '@material-ui/core';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

//Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { deepOrange, grey } from '@material-ui/core/colors';

// Context
import { useWorkordersContext } from '../../../../../context/workorders-context';

// Pages
import { SearchWorkordersInSideBar } from './workorders-search-sidebar';

const Accordion = withStyles({
	root: {
		boxShadow: 'none',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
	root: {
		backgroundColor: 'transparent',
		marginBottom: -1,
		minHeight: 36,
		paddingLeft: theme.spacing(2),
		'&$expanded': {
			backgroundColor: 'rgba(255, 255, 255, 0.16)',
			minHeight: 36,
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
	menuContainer: {
		backgroundImage: `linear-gradient(${theme.palette.background.default}, ${theme.palette.background.paper})`,
	},
	menu: {
		width: '100%',
	},
	menuItem: {
		// width: '100%'
	},
	itemCount: {
		backgroundColor: deepOrange[500],
		color: theme.palette.getContrastText(deepOrange[500]),
		width: theme.spacing(3),
		height: theme.spacing(3),
		marginLeft: 10,
	},
	itemCountDisabled: {
		backgroundColor: grey[400],
		color: theme.palette.getContrastText(grey[400]),
		width: theme.spacing(3),
		height: theme.spacing(3),
		marginLeft: 10,
	},
}));

export const WorkordersMenu = () => {
	const classes = useStyles();
	let history = useHistory();

	const { workorders: data, view, setView, searchCriteria, setSearchCriteria } = useWorkordersContext();

	const handleViewChange = async ({ view }) => {
		await setView(view);
		await setSearchCriteria(null);
		history.push({
			pathname: '/workorders',
			search: '?view=' + view,
		});
	};

	return (
		<Grid container className={classes.menuContainer}>
			<MenuList className={classes.menu}>
				<MenuItem selected={view === 'today' ? true : false} className={classes.menuItem} onClick={() => handleViewChange({ view: 'today' })}>
					<Typography variant="subtitle2" noWrap>
						Today
					</Typography>
					<Avatar className={data?.workorders?.counts?.today ? classes.itemCount : classes.itemCountDisabled}>
						<Typography variant="caption" noWrap>
							{data?.workorders?.counts?.today || 0}
						</Typography>
					</Avatar>
				</MenuItem>

				<MenuItem
					selected={view === 'yesterday' ? true : false}
					className={classes.menuItem}
					onClick={() => handleViewChange({ view: 'yesterday' })}
				>
					<Typography variant="subtitle2" noWrap>
						Yesterday
					</Typography>
					<Avatar className={data?.workorders?.counts?.yesterday ? classes.itemCount : classes.itemCountDisabled}>
						<Typography variant="caption" noWrap>
							{data?.workorders?.counts?.yesterday || 0}
						</Typography>
					</Avatar>
				</MenuItem>

				<MenuItem
					selected={view === 'thismonth' ? true : false}
					className={classes.menuItem}
					onClick={() => handleViewChange({ view: 'thismonth' })}
				>
					<Typography variant="subtitle2" noWrap>
						This month
					</Typography>
					<Avatar
						className={
							data?.workorders?.counts?.thismonth - data?.workorders?.counts?.today ? classes.itemCount : classes.itemCountDisabled
						}
					>
						<Typography variant="caption" noWrap>
							{data?.workorders?.counts?.thismonth - data?.workorders?.counts?.today || 0}
						</Typography>
					</Avatar>
				</MenuItem>

				<MenuItem selected={view === 'older' ? true : false} className={classes.menuItem} onClick={() => handleViewChange({ view: 'older' })}>
					<Typography variant="subtitle2" noWrap>
						Older
					</Typography>
					<Avatar className={data?.workorders?.counts?.older ? classes.itemCount : classes.itemCountDisabled}>
						<Typography variant="caption" noWrap>
							{data?.workorders?.counts?.older || 0}
						</Typography>
					</Avatar>
				</MenuItem>
			</MenuList>

			{/* SEARCH */}
			<Grid container>
				<Grid item xs={12}>
					<Accordion square={true} defaultExpanded={searchCriteria !== null ? true : false} style={{ backgroundColor: 'transparent' }}>
						<AccordionSummary expandIcon={<ExpandMoreIcon fontSize="small" />} IconButtonProps={{ size: 'small' }}>
							<Typography variant="subtitle2" noWrap>
								Search
							</Typography>
							<Avatar className={data?.workorders?.counts?.search ? classes.itemCount : classes.itemCountDisabled}>
								<Typography variant="caption" noWrap>
									{data?.workorders?.counts?.search || 0}
								</Typography>
							</Avatar>
						</AccordionSummary>

						<AccordionDetails>
							<SearchWorkordersInSideBar />
						</AccordionDetails>
					</Accordion>
				</Grid>
			</Grid>
		</Grid>
	);
};
