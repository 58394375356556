import React, { useState } from 'react';
import axios from 'axios';
import config from '../../../config.json';
import process from 'process';
// MaterialUI
import { Tooltip, IconButton } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { ButtonLoading } from 'app/components/loading';

const domain = process.env.NODE_ENV === 'production' ? config.api.domain : config.api.domain_dev;

export const AppointmentPrintButton = ({ appointmentId }) => {
	const [loading, setIsLoading] = useState(false);

	const openInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
		if (newWindow) newWindow.opener = null;
	};

	const showPDFinNewWindows = async () => {
		try {
			setIsLoading(true);
			await axios
				.get(`${domain}/appointment/${appointmentId}/pdf`, {
					responseType: 'blob',
				})
				.then((response) => {
					const file = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
					openInNewTab(file);
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} catch (error) {
			setIsLoading(false);
			alert('Error while downloading file. Try again later');
		}
	};

	return (
		<Tooltip title="Print appointment" key={'print'}>
			{loading ? (
				<ButtonLoading label="" />
			) : (
				<IconButton component="span" size="small" color="inherit" onClick={() => showPDFinNewWindows()} style={{ marginRight: 5 }}>
					<PrintIcon />
				</IconButton>
			)}
		</Tooltip>
	);
};
