import React, { useState } from 'react';
import { Link } from 'react-router-dom';

//Material
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, Divider, IconButton } from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';

//Icons
import MenuIcon from '@material-ui/icons/Menu';
import ScheduleIcon from '@material-ui/icons/Schedule';
import BuildIcon from '@material-ui/icons/Build';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import DriveEtaIcon from '@material-ui/icons/DriveEta';

//Styles
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
	root: {
		zIndex: theme.zIndex.tooltip,
		display: 'flex',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
}));

export const MobileDrawer = () => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	return (
		<div className={classes.root}>
			<IconButton color="inherit" aria-label="open drawer" onClick={() => setOpen(true)}>
				<MenuIcon />
			</IconButton>

			<Drawer
				anchor="right"
				open={open}
				onClose={() => setOpen(false)}
				className={classes.drawerOpen}
				classes={{
					paper: classes.drawerOpen,
				}}
			>
				<Divider />
				<List>
					<Tooltip title="Appointments">
						<ListItem button component={Link} to="/appointments" onClick={() => setOpen(false)}>
							<ListItemIcon>
								<ScheduleIcon />
							</ListItemIcon>
							<ListItemText primary="Appointments" />
						</ListItem>
					</Tooltip>
					<Tooltip title="Work orders">
						<ListItem button component={Link} to="/workorders" onClick={() => setOpen(false)}>
							<ListItemIcon>
								<BuildIcon />
							</ListItemIcon>
							<ListItemText primary="Workorders" />
						</ListItem>
					</Tooltip>
					<Tooltip title="Invoices">
						<ListItem button component={Link} to="/invoices" onClick={() => setOpen(false)}>
							<ListItemIcon>
								<ReceiptIcon />
							</ListItemIcon>
							<ListItemText primary="Invoices" />
						</ListItem>
					</Tooltip>
				</List>

				<Divider />

				<List>
					<Tooltip title="Contacts">
						<ListItem button component={Link} to="/contacts" onClick={() => setOpen(false)}>
							<ListItemIcon>
								<ContactMailIcon />
							</ListItemIcon>
							<ListItemText primary="Contacts" />
						</ListItem>
					</Tooltip>
					<Tooltip title="Vehicles">
						<ListItem button component={Link} to="/vehicles" onClick={() => setOpen(false)}>
							<ListItemIcon>
								<DriveEtaIcon />
							</ListItemIcon>
							<ListItemText primary="Vehicles" />
						</ListItem>
					</Tooltip>
				</List>
			</Drawer>
		</div>
	);
};
