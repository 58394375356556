import { useState, useEffect } from 'react';

// MaterialUI
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

export default function ShowMoreTextBox({ text, variant }) {
	if (!text) return null;
	const theme = useTheme();
	const [show, setShow] = useState(false);
	const [showExpand, setShowExpand] = useState(false);

	let textLengthChars = text?.length;
	let textLengthLines = text.split(/\r\n|\r|\n/).length;

	let textShort = text;
	if (textLengthLines > 1) {
		textShort = text.split(/\r\n|\r|\n/).map((line, i) => {
			if (i <= 2) return `${line}\n`;
		});
	}
	if (textLengthChars > 250) textShort = `${text.slice(0, 250)} ...`;

	useEffect(() => {
		if (textLengthLines >= 4 || textLengthChars > 250) {
			setShowExpand(true);
		}
	}, []);

	return (
		<>
			<Typography variant={variant || 'body2'} display="block" style={{ whiteSpace: 'pre-wrap' }}>
				{!show ? textShort : text.toString().trim()}
			</Typography>

			{showExpand && (
				<Button
					size="small"
					style={{ paddingTop: 5, paddingLeft: 0, fontSize: '0.6rem', color: theme.palette.text.secondary, textTransform: 'none' }}
					startIcon={show == false ? <ExpandMoreIcon /> : <ExpandLessIcon />}
					onClick={() => {
						setShow(!show);
					}}
				>
					{show == false ? 'show more' : 'show less'}
				</Button>
			)}
		</>
	);
}
