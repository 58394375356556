import React from 'react';

// Material
import { Tooltip, IconButton } from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

export const InvoicePaymentButton = ({ onClick, disabled }) => {
	return (
		<Tooltip title="Add payment" key={'add_payment'}>
			<IconButton component="span" size="small" color="inherit" disabled={disabled} onClick={onClick} style={{ marginRight: 10 }}>
				<MonetizationOnIcon />
			</IconButton>
		</Tooltip>
	);
};
