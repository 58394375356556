// MaterialUI
import MaterialTable from 'material-table';
import { useTheme } from '@material-ui/core/styles';
import { TablePagination } from '@material-ui/core';

export const Table = ({ title, columns, data, props }) => {
	const theme = useTheme();

	return (
		<>
			<MaterialTable
				{...props}
				title={title}
				data={data}
				columns={columns}
				components={{
					Pagination: PatchedPagination,
				}}
				options={{
					// GENERAL
					padding: 'dense',
					toolbar: false,
					// grouping: true,

					//HEADER
					headerStyle: {
						backgroundColor: theme.palette.background.default,
						borderLeft: `1px solid ${theme.palette.divider}`,
						borderTop: `1px solid ${theme.palette.divider}`,
					},

					//BODY
					maxBodyHeight: '44vh',

					//ROW
					selection: false,
					rowStyle: {
						verticalAlign: 'top',
					},
					cellStyle: {
						borderLeft: `1px solid ${theme.palette.divider}`,
					},

					//PAGING
					paging: false,
					emptyRowsWhenPaging: false,
				}}
			/>
		</>
	);
};

export function PatchedPagination(props) {
	const { ActionsComponent, onChangePage, onChangeRowsPerPage, ...tablePaginationProps } = props;

	return (
		<TablePagination
			{...tablePaginationProps}
			// @ts-expect-error onChangePage was renamed to onPageChange
			onPageChange={onChangePage}
			onRowsPerPageChange={onChangeRowsPerPage}
			ActionsComponent={(subprops) => {
				const { onPageChange, ...actionsComponentProps } = subprops;
				return (
					// @ts-expect-error ActionsComponent is provided by material-table
					<ActionsComponent {...actionsComponentProps} onChangePage={onPageChange} />
				);
			}}
		/>
	);
}
