import { useQuery } from 'react-query';

//Material
import { Grid, Typography } from '@material-ui/core';

//Pages
import Box from '../box';
import { Loading } from '../loading';
import EditData from './edit';
import DisplayData from './view';

const Card = ({
	id,
	queryFn,
	mutationFn,
	createFn,
	compact = false,
	reassign = false,
	reassignFn,
	reassignGetFn,
	reassignConfig,
	template,
	additionalButtons,
	readOnly,
	refetchQueryNames,
	refetchQueryFn,
	primaryKey = null,
	helpText,
}) => {
	//Get Data
	const { data, isLoading } = useQuery([template.returnKey, id], queryFn, { enabled: !!id, refetchOnWindowFocus: false });

	// ERROR HANDLING - Reviewed 2
	if (data?.status === 'error') return <p>Error Occured</p>;
	if (data?.status === 'access-denied') return <p>Access Denied</p>;
	if (isLoading) return <Loading />;

	return (
		<Box
			heading={template.heading}
			compact={compact}
			rightComponent={
				!readOnly ? (
					<>
						{additionalButtons}
						<EditData
							id={id}
							template={template}
							queryFn={queryFn}
							mutationFn={mutationFn}
							createFn={createFn}
							reassign={reassign}
							reassignFn={reassignFn}
							reassignGetFn={reassignGetFn}
							reassignConfig={reassignConfig}
							refetchQueryNames={refetchQueryNames}
							refetchQueryFn={refetchQueryFn}
							primaryKey={primaryKey}
							helpText={helpText}
						/>
					</>
				) : (
					''
				)
			}
		>
			{/* Read Only Data Display ONLY if data exists*/}
			{data?.[template.returnKey] && <DisplayData template={template} data={data} />}

			{!data?.[template.returnKey] && (
				<Grid container alignItems="center" justifyContent="center" style={{ height: 30 }}>
					<Typography align="center" variant="caption" display="block" color="textSecondary">
						You can add/assign a {template.heading} by clicking the plus sign.
					</Typography>
				</Grid>
			)}
		</Box>
	);
};

export { Card };
export default Card;
