import React, { useState } from 'react';
// MaterialUI
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid } from '@material-ui/core';
import { Tooltip, IconButton, Badge, Divider, Modal } from '@material-ui/core';
import { Typography, Paper } from '@material-ui/core';
// Icons
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import TextsmsIcon from '@material-ui/icons/Textsms';
import TocIcon from '@material-ui/icons/Toc';
import CloseIcon from '@material-ui/icons/Close';
//Data
import { useQuery, QueryClient } from 'react-query';
import { GetInvoiceById } from '../../../../../app/utils/data';
//Context
import { useWorkordersContext } from '../../../../../context/workorders-context'; //Components
import ContactPopup from '../../../../../app/widgets/contact';
import BusinessPopup from '../../../../../app/widgets/business';
import FinanceTable from '../../../../../app/components/finance-table';
//Config and Context
import { WorkorderCostItemsTable } from '../../../../../context/config.tables';
import WorkorderContext from '../../../../../context/workorder-context';
//Components
import { WorkorderMarkComplete } from './workorder-mark-complete';
import { CommentsV2 } from '../../../../../app/components/comments';
import { WorkorderStatusBadge, CustomStatusBadge } from '../../../../../app/components/card/status';
import { QuoteFirstChipWorkorder } from 'app/components/quote-first/index.workorder';
import { WorkorderPrintButton } from 'pages/workorders/component/print';

const useStyles = makeStyles((theme) => ({
	cardfooter: {
		// border: 0,
	},
	cardfooterContainer: {
		padding: 8,
		backgroundColor: theme.palette.divider,
	},

	// MODAL
	modalDialog: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		maxWidth: '30%',
		maxHeight: '80vh',
		margin: '1.75rem auto',
		overflow: 'auto',
	},
	modalFinanceDialog: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		maxWidth: '60vw',
		// maxHeight: '80vh',
		margin: '1.75rem auto',
		// overflow: 'auto',
	},
	modalContent: {
		boxShadow: '0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15)',
		display: 'flex',
		position: 'relative',
		flexDirection: 'column',
		width: '100%',
		pointerEvents: 'auto',
		backgroundColor: 'darkest',
		backgroundClip: 'padding-box',
		outline: 0,
	},
	modalBody: {
		backgroundColor: theme.palette.background.paper,
	},

	successBadge: {
		backgroundColor: theme.palette.success.dark,
	},
	errorBadge: {
		backgroundColor: theme.palette.error.light,
	},

	// Modal
	headerContainer: {
		height: 50,
		paddingLeft: 15,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	headerActions: {
		paddingRight: 15,
	},

	financeTableHeader: {
		backgroundColor: theme.palette.background.default,
	},
	financeTableBody: {
		maxHeight: '50vh',
		overflowY: 'auto',
		padding: 10,
	},
	financeTableFooter: {
		backgroundColor: theme.palette.background.default,
	},

	infoContainer: {
		backgroundColor: theme.palette.background.default,
		marginTop: 5,
		marginBottom: 15,
	},
	infoBox: {
		padding: 15,
		border: `5px solid ${theme.palette.background.paper}`,
	},

	button: {
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
}));

export const TableRowActions = ({ workorder }) => {
	const classes = useStyles();
	const theme = useTheme();
	const queryClient = new QueryClient();

	let disabled = workorder.workorderStatus === 'Complete' ? true : false;

	//Context
	const { refreshPage } = useWorkordersContext();

	//Modals
	const [openComments, setOpenComments] = useState(false);
	const [openMarkComplete, setOpenMarkComplete] = useState(false);
	const [openEditFinances, setOpenEditFinances] = useState(false);

	const [openPrintInvoice, setOpenPrintInvoice] = useState(false);
	const [invoiceIdToPrint, setInvoiceIdToPrint] = useState(null);

	const { data: invoiceData } = useQuery(['invoice', invoiceIdToPrint], GetInvoiceById, {
		enabled: !!invoiceIdToPrint,
		refetchOnWindowFocus: false,
		onSuccess: async () => {
			setOpenPrintInvoice(true);
		},
	});

	const refetchMasterData = () => {
		queryClient.refetchQueries(['workorder', workorder._id]);
	};

	return (
		<WorkorderContext.Provider
			value={{
				// workorder: workorderData,
				refetch: refetchMasterData,
			}}
		>
			{/* <> */}
			<Grid item xs={12}>
				<Grid container alignItems="center" className={classes.cardfooterContainer}>
					{/* LEFT COL */}
					<Grid item xs={10} style={{ paddingLeft: 5 }}>
						<Grid container spacing={1} alignItems="center">
							{/* STATUS */}
							<WorkorderStatusBadge
								workorderId={workorder?._id}
								initialStatus={workorder?.workorderStatus}
								chipStyle={{ marginRight: 5 }}
								disabled={disabled}
							/>

							{workorder?.quoteFirst && <QuoteFirstChipWorkorder row={workorder} table="workorder" refreshPage={refreshPage} />}

							{/* BOOKING REFERENCE */}
							{workorder?.workorderStatus === 'Complete' &&
								workorder?.bookings?.length > 0 &&
								workorder?.bookings.map((booking, idx) => {
									return (
										<>
											<CustomStatusBadge
												key={idx}
												label={`B: ${booking.bookingNumber}`}
												itemDescription="Booking"
												status={booking.bookingStatus}
											/>
										</>
									);
								})}

							{/* INVOICE REFERENCE */}
							{workorder?.workorderStatus === 'Complete' &&
								workorder?.invoices?.length > 0 &&
								workorder?.invoices.map((invoice, idx) => {
									if (invoice?.invoiceStatus !== 'Void') {
										return (
											<>
												<CustomStatusBadge
													key={idx}
													label={`I: ${invoice.invoiceNumber}`}
													itemDescription="Invoice"
													status={invoice.invoiceStatus}
													// url={`/invoice/${invoice._id}`}
													chipStyle={{ marginLeft: 5 }}
													onClick={() => setInvoiceIdToPrint(invoice._id)}
												/>
											</>
										);
									}
								})}

							<ButtonDivider />

							{/* CONTACT */}
							<ContactPopup
								contactId={workorder?.contact?._id}
								contactFullname={workorder?.contact?.contactFullname}
								type="action"
								meta={workorder?.contact}
							/>

							{/* BUSINESS */}
							{workorder?.business && (
								<BusinessPopup
									businessId={workorder?.business?._id}
									businessName={workorder?.business?.businessName}
									type="action"
									meta={workorder?.business}
								/>
							)}

							{/* COMMENTS */}
							<ButtonDivider />
							<>
								<Tooltip
									arrow={true}
									placement="top"
									title={
										!workorder.comments.length
											? 'No comments yet. Click to add.'
											: `${workorder.comments.length} comments. Click to edit`
									}
								>
									<IconButton size="small" onClick={() => setOpenComments(!openComments)}>
										<Badge
											overlap="rectangular"
											size="small"
											variant="dot"
											badgeContent={workorder?.comments ? workorder?.comments?.length : 0}
											color="secondary"
											classes={
												workorder?.comments?.length === 0 ? { badge: classes.errorBadge } : { badge: classes.successBadge }
											}
											// style={!workorder.comments.length ? { color: theme.palette.warning.light } : { color: theme.palette.success.light }}
										>
											<TextsmsIcon color={!workorder.comments?.length ? 'disabled' : 'action'} fontSize="small" />
										</Badge>
									</IconButton>
								</Tooltip>
							</>
						</Grid>
					</Grid>

					{/* ACTIONS */}
					<Grid item xs={2} style={{ paddingRight: 10 }}>
						<Grid container spacing={1} alignItems="center" justifyContent="flex-end">
							{/* PRINT */}
							<WorkorderPrintButton workorderID={workorder._id} />

							{/* FINANCES */}
							<ButtonDivider />
							<Tooltip
								arrow={true}
								placement="top"
								title={
									!workorder?.costItems?.length
										? 'No cost items yet. Click to add.'
										: `${workorder?.costItems?.length} cost items. Click to ${disabled ? 'view' : 'edit'}`
								}
							>
								<IconButton size="small" disabled={disabled} onClick={() => setOpenEditFinances(!openComments)}>
									<Badge
										overlap="rectangular"
										size="small"
										variant="dot"
										showZero={true}
										color="primary"
										classes={workorder?.costItems?.length === 0 ? { badge: classes.errorBadge } : { badge: classes.successBadge }}
									>
										<TocIcon color={!workorder?.costItems?.length || disabled ? 'disabled' : 'action'} fontSize="small" />
									</Badge>
								</IconButton>
							</Tooltip>

							{/* MARK COMPLETE */}
							<ButtonDivider />
							<Tooltip arrow={true} placement="top" title="Mark this job complete">
								<IconButton
									disabled={disabled || openMarkComplete}
									size="small"
									style={
										!disabled && !openMarkComplete
											? { color: theme.palette.success.main }
											: { color: theme.palette.text.disabled }
									}
									onClick={() => {
										setOpenMarkComplete((prev) => !prev);
									}}
								>
									{openMarkComplete && <CircularProgress size={25} thickness={5} disableShrink={true} />}
									{!openMarkComplete && <CheckCircleOutlineIcon fontSize="small" />}
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{/* COMMENTS */}
			<Modal
				className={classes.modalDialog}
				open={openComments || false}
				onClose={(e, reason) => {
					if (reason === 'backdropClick') return null;
					setOpenComments(false);
				}}
				disablePortal
				closeAfterTransition
			>
				<div className={classes.modalContent}>
					<div className={classes.modalBody}>
						<CommentsV2
							comments={workorder.comments}
							parentId={workorder?._id}
							handleClick={() => setOpenComments(false)}
							open={openComments}
							setOpen={setOpenComments}
							refreshPageFn={refreshPage}
						/>
					</div>
				</div>
			</Modal>

			{/* CLOSE WORKORDER */}
			<Modal
				className={classes.modalDialog}
				open={openMarkComplete || false}
				onClose={(e, reason) => {
					if (reason === 'backdropClick') return null;
					setOpenMarkComplete(false);
				}}
				disablePortal
				closeAfterTransition
			>
				<div className={classes.modalContent}>
					<div className={classes.modalBody}>
						<WorkorderMarkComplete workorder={workorder} handleClick={() => setOpenMarkComplete(false)} />
					</div>
				</div>
			</Modal>

			{/* FINANCE POPUP */}
			<Modal
				className={classes.modalFinanceDialog}
				open={openEditFinances || false}
				onClose={(e, reason) => {
					if (reason === 'backdropClick') return null;
					refreshPage();
					setOpenEditFinances(false);
				}}
				disablePortal
				closeAfterTransition
				BackdropProps={{ style: { backgroundColor: 'rgba(127,127,127, 0.7' } }}
			>
				<Paper elevation={24} style={{ cursor: 'default' }}>
					<Grid container>
						{/* CARD HEADER */}
						<Grid item xs={12}>
							<Grid container alignItems="center" className={classes.headerContainer}>
								<Grid item xs={8}>
									<Typography variant="h5">{`Work Order - ${workorder.workorderNumber}`}</Typography>
								</Grid>

								<Grid item xs={4}>
									<Grid container alignItems="center" justifyContent="flex-end" className={classes.headerActions}>
										<IconButton
											onClick={() => {
												setOpenEditFinances(false);
												refreshPage();
											}}
											size="small"
										>
											<CloseIcon />
										</IconButton>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid container>
							{/* TABLE HEADER */}
							<Grid item xs={12} className={classes.financeTableHeader}>
								<FinanceTable
									config={WorkorderCostItemsTable({ workorderId: workorder._id })}
									headers="only"
									refreshItem={refetchMasterData}
								/>
							</Grid>

							{/* TABLE */}
							<Grid item xs={12} style={{ padding: theme.spacing(1) }} className={classes.financeTableBody}>
								<FinanceTable
									config={WorkorderCostItemsTable({ workorderId: workorder._id })}
									headers="none"
									newRow="none"
									subTotal="hide"
									refreshItem={refetchMasterData}
								/>
							</Grid>

							{/* FOOTER */}
							<Grid item xs={12} className={classes.financeTableFooter}>
								<div style={{ marginLeft: 15, marginRight: 15 }}>
									<FinanceTable
										config={WorkorderCostItemsTable({ workorderId: workorder._id })}
										newRow="only"
										refreshItem={refetchMasterData}
									/>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Modal>

			{invoiceData &&
				openPrintInvoice &&
				//!! THIS IS THE PDF ERROR */}
				// TODO  REPLACE THIS WITH v2 */}
				{
					/* <InvoiceV2PDF data={invoiceData.invoice} iconSize="small" company={settings} open={openPrintInvoice} setOpen={setOpenPrintInvoice} /> */
				}}
			{/* </> */}
		</WorkorderContext.Provider>
	);
};

function ButtonDivider() {
	return <Divider flexItem={true} orientation="vertical" variant="fullWidth" style={{ marginRight: 5, marginLeft: 5 }} />;
}
