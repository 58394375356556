import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom'
import { Loading } from '../../app/components/loading'

const useStyles = makeStyles((theme) => ({
    pageNotFound: {
        height: '75vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    header: {
        fontSize: '50px',
        color: theme.palette.error.main
    },
    description: {
        // fontSize: '50px',       
    },

}));

export const NotFound = () => {
    const classes = useStyles();
    const location = useLocation();

    return (
        <div className={classes.pageNotFound}>
            {location.pathname === '/logout' && (
                <Loading />
            )}

            {location.pathname !== '/logout' && (
                <>
                    <span className={classes.header}>404 - PAGE NOT FOUND</span>

                    <span className={classes.description}>We can&#39;t seem to find the page you are looking for. Try going back to the previous page</span>
                </>
            )}
        </div>
    )
}

export default NotFound