import { httpPOST, httpPUT } from './data-handler';

// GET
// -------------------



export const AddWarranty = async (data) => await httpPOST({ path: `warranties`, data })
export const GetWarrantyByCustomValue = async (data) => await httpPOST({ path: `warranties/search`, data })
export const UpdateWarrantyByCustomValue = async (data) => await httpPUT({ path: `warranty`, data })
