import moment from 'moment';
// material
import { useTheme } from '@material-ui/core/styles';
import { Link, Typography, Grid } from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
// context
import { useContactContext } from '../../../../context/contact-context';
// components
import { Table } from './table';
import { NumberFormatCurrencyText, NumberFormatMileageText } from '../../../components/textbox';
import { CustomStatusBadge } from '../../../components/card/status';
import { useHistory } from 'react-router-dom';

export const ContactInvoices = () => {
	const theme = useTheme();
	const history = useHistory();
	const { contact } = useContactContext();

	// COLUMNS
	const columns = [
		{
			title: 'Status',
			field: 'invoiceStatus',
			cellStyle: {
				maxWidth: 200,
				borderLeft: `1px solid ${theme.palette.divider}`,
				borderRight: `1px solid ${theme.palette.divider}`,
				borderBottom: `1px solid ${theme.palette.divider}`,
			},
			render: function render(row) {
				return <CustomStatusBadge label={row?.invoiceStatus} status={row?.invoiceStatus} />;
			},
		},
		{
			title: 'Inv #',
			field: 'invoiceNumber',
			cellStyle: {
				maxWidth: 200,
				borderRight: `1px solid ${theme.palette.divider}`,
				borderBottom: `1px solid ${theme.palette.divider}`,
			},
			render: function render(row) {
				return (
					<Link
						component="button"
						variant="caption"
						color="textPrimary"
						onClick={() => history.push(`/invoices?search=${row.invoiceNumber}`)}
					>
						{row.invoiceNumber}
					</Link>
				);
			},
		},
		{
			title: 'Date',
			field: 'invoiceDate',
			cellStyle: {
				maxWidth: 200,
				borderRight: `1px solid ${theme.palette.divider}`,
				borderBottom: `1px solid ${theme.palette.divider}`,
			},
			render: function render(row) {
				return (
					<Typography variant="body2" display="block">
						{moment(row?.invoiceDate).format('DD MMM YYYY')}
					</Typography>
				);
			},
		},
		{
			title: 'Vehicle',
			field: 'vehicle.vehicleRegistration',
			cellStyle: {
				maxWidth: 200,
				borderRight: `1px solid ${theme.palette.divider}`,
				borderBottom: `1px solid ${theme.palette.divider}`,
			},
			render: function render(row) {
				return (
					<>
						<Typography variant="body2" display="block">
							{row?.vehicle?.vehicleRegistration}
						</Typography>

						<Typography variant="caption" display="block" color="textSecondary">
							{row?.vehicle?.vehicleMake} {row?.vehicle?.vehicleModel}
						</Typography>
					</>
				);
			},
		},
		{
			title: 'Mileage',
			field: 'workorderOdometer',
			cellStyle: {
				maxWidth: 200,
				borderRight: `1px solid ${theme.palette.divider}`,
				borderBottom: `1px solid ${theme.palette.divider}`,
			},
			render: function render(row) {
				return (
					<Typography variant="body2" display="block">
						{NumberFormatMileageText(row?.workorderOdometer)}
					</Typography>
				);
			},
		},
		{
			title: 'Total',
			field: 'invoiceSubTotal',
			cellStyle: {
				maxWidth: 200,
				borderRight: `1px solid ${theme.palette.divider}`,
				borderBottom: `1px solid ${theme.palette.divider}`,
			},
			render: function render(row) {
				return (
					<Typography variant="body2" display="block">
						{NumberFormatCurrencyText(row?.invoiceSubTotal)}
					</Typography>
				);
			},
		},
		{
			title: 'Balance',
			field: 'invoiceBalance',
			cellStyle: {
				maxWidth: 200,
				// borderRight: `1px solid ${theme.palette.divider}`,
				borderBottom: `1px solid ${theme.palette.divider}`,
			},
			render: function render(row) {
				return (
					<>
						<Grid container alignItems="center">
							<Typography variant="body2" style={{ paddingRight: 10 }}>
								{NumberFormatCurrencyText(row?.invoiceBalance)}
							</Typography>

							{row?.invoiceBalance > 0 && <ReportProblemIcon style={{ fontSize: '15px', color: yellow[500] }} />}
						</Grid>
					</>
				);
			},
		},
	];

	return <Table title="Invoices" data={contact?.invoices?.invoices} columns={columns} url="invoice" />;
};
