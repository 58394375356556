import React, { useState } from 'react';
// material
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';
// context
import { useQuoteContext } from '../../../../../context/quote-context';
// shared components
import { StyledTabs, StyledTab, TabPanel } from '../../../../components/tabs';
import { ContactCard, VehicleCard, CompanyCard } from '../../../index';
import QuoteHistoryTimeline from './quote-history';
import { QuoteItemsTable } from './quote-items-table';
import { QuoteComments } from './comments';
import CardAttachments from 'app/components/attachments/upload.card';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		border: `1px solid ${theme.palette.divider}`,
		// maxHeight: 20,
	},
	tabBody: {
		height: '75vh',
		// maxHeight: '55vh',
		// minHeight: '55vh',
		overflow: 'auto',
	},
}));

export default function QuoteDetailTabs() {
	const classes = useStyles();
	const [value, setValue] = useState(0);

	const { refetch, quote } = useQuoteContext();

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div className={classes.root}>
			<Paper elevation={24} square={true}>
				<StyledTabs value={value} onChange={handleChange} scrollButtons="auto" variant="scrollable">
					<StyledTab label="INFORMATION" />
					<StyledTab label="QUOTE" />
					<StyledTab label="COMMENTS" />
					<StyledTab label="HISTORY" />
				</StyledTabs>

				<TabPanel value={value} index={0} className={classes.tabBody}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							{/* //* vehicle */}
							<VehicleCard
								vehicleId={quote?.vehicleID}
								parentId={quote?._id}
								parentTable="quotes"
								refetchQueryFn={refetch}
								reassign={false}
								noLink={true}
							/>
						</Grid>

						{/* //* contact */}
						<Grid item xs={6}>
							<ContactCard
								contactId={quote?.contactID}
								parentId={quote?._id}
								parentTable="quotes"
								refetchQueryFn={refetch}
								reassign={true}
								noLink={true}
							/>
						</Grid>

						{/* //* company */}
						<Grid item xs={6}>
							<CompanyCard
								companyId={quote?.businessID}
								parentId={quote?._id}
								parentTable="quotes"
								refetchQueryFn={refetch}
								reassign={true}
								noLink={true}
							/>
						</Grid>

						{/* //* attachments */}
						<Grid item xs={12}>
							<CardAttachments parentId={quote?._id} data={quote} refetch={refetch} />
						</Grid>
					</Grid>
				</TabPanel>

				<TabPanel value={value} index={1} className={classes.tabBody}>
					<QuoteItemsTable quoteId={quote?._id} />
				</TabPanel>

				<TabPanel value={value} index={2} className={classes.tabBody}>
					<QuoteComments quoteId={quote?._id} />
				</TabPanel>

				<TabPanel value={value} index={3} className={classes.tabBody}>
					<QuoteHistoryTimeline parentId={quote?._id} />
				</TabPanel>
			</Paper>
		</div>
	);
}
