// MaterialUI
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// Query
import { useQuery } from 'react-query';
import { GetWorkorderById, UpdateBatchCostItems, UpdateCostItem, DeleteCostItem, CreateCostItem } from '../../../../app/utils/data';
// Pages
import CardNextService from '../../../../app/widgets/pages/workorder/nextservice';
import Box from '../../../../app/components/box';
import Comments from '../../../../app/components/comments';
import Loading from '../../../../app/components/loading';
import { WorkorderWarrantyCard } from '../../../../app/widgets';
import FinanceTable from '../../../../app/components/finance-table';
import Totals from '../../../../app/components/finance-table/ActionBar/Totals';
import CardAttachments from 'app/components/attachments/upload.card';

const WorkorderEditData = ({ workorderId }) => {
	const theme = useTheme();
	const { data, isLoading, refetch } = useQuery(['workorder', workorderId], GetWorkorderById, { refetchOnWindowFocus: false });
	const config = {
		type: 'cost',
		messages: {
			nodata: 'Start by adding new cost items below.',
		},
		query: {
			queryFn: GetWorkorderById,
			mutationFn: UpdateBatchCostItems,
			updateFn: UpdateCostItem,
			deleteFn: DeleteCostItem,
			createFn: CreateCostItem,
			id: workorderId,
			queryKey: 'workorder',
			dbReturnKey: 'workorder',
			dbReturnSubKey: 'costitems',
			dbSubTotalKey: 'costAmount',
			hideVAT: true,
			hideTotal: true,
			select: {
				parent: '_id',
				child: 'workorderID',
			},
		},
		headers: [
			{
				label: 'Qty',
				placeholder: 'Qty',
				dbField: 'costQty',
				type: 'numeric',
				align: 'center',
				size: { xs: 2 },
				defaultValue: 0,
			},
			{
				label: 'Description',
				placeholder: 'Description',
				dbField: 'costDescription',
				required: true,
				type: 'text',
				size: { xs: 6 },
				focusAfterNew: true,
			},
			{
				label: 'Cost',
				placeholder: 'Unit Price',
				dbField: 'costUnitPrice',
				type: 'financial',
				align: 'center',
				size: { xs: 2 },
				defaultValue: 0,
			},
			{
				label: 'Total',
				dbField: 'costAmount',
				type: 'total',
				align: 'center',
				size: { xs: 2 },
				defaultValue: 0,
				isTotal: true,
			},
		],
	};

	// --------------------------------------------------------------------------------------------------
	// ERROR HANDLING - Reviewed 2
	// --------------------------------------------------------------------------------------------------
	if (data?.status === 'error') return <p>Error Occured</p>;
	if (data?.status === 'access-denied') return <p>Access Denied</p>;
	if (isLoading) return <Loading />;

	return (
		<Grid container spacing={3}>
			{/* COLUMN 1 */}
			<Grid item xs={12} sm={6}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Totals config={config} />
					</Grid>

					{/* FINANCE TABLE 
					----------------------------------------------------------------*/}
					<Grid item xs={12}>
						<Box heading="Costs">
							<FinanceTable config={config} refreshItem={refetch} />
						</Box>
					</Grid>
				</Grid>
			</Grid>

			{/* COLUMN 2 */}
			<Grid item xs={12} sm={3}>
				<Grid container spacing={3}>
					{/* //* attachements */}
					<Grid item xs={12}>
						<CardAttachments parentId={workorderId} data={data.workorder} refetch={refetch} />
					</Grid>

					{/* //* warranty */}
					<Grid item xs={12}>
						<WorkorderWarrantyCard workorderId={data.workorder._id} />
					</Grid>

					{/* //* next service reminder */}
					<Grid item xs={12}>
						<CardNextService workorderId={workorderId} config={config} />
					</Grid>
				</Grid>
			</Grid>

			{/* COLUMN 3 */}
			<Grid item xs={12} sm={3} style={{ backgroundColor: theme.palette.background.default }}>
				<Comments parentId={workorderId} />
			</Grid>
		</Grid>
	);
};

export default WorkorderEditData;
