import moment from 'moment';
// MaterialUI
import MaterialTable, { MTableBodyRow } from 'material-table';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { alpha, Grid, Paper, Typography } from '@material-ui/core';
//Context
import { useWorkordersContext } from '../../../../../context/workorders-context';
//Pages
import { TableRowActions } from './workorders-table-actions.row';
import { WorkordersTableColumns } from '../../../../../context/config.tables';

const useStyles = makeStyles((theme) => ({
	table: {
		// ROW HOVER
		'& tbody': {
			'& tr:nth-child(1n - 1):hover, tr:nth-child(1n-1):hover + tr': {
				transition: 'none',
				backgroundColor: alpha(theme.palette.background.default, 0.5),
			},
			'& td:first-child': {
				transition: 'none',
				borderLeft: `1px solid ${theme.palette.divider}`,
			},
			'& td:last-child': {
				borderRight: `1px solid ${theme.palette.divider}`,
			},
		},

		'& thead': {
			border: 0,
		},

		// TOOLBAR
		'& .MuiToolbar-root': {
			paddingLeft: 10,
			backgroundColor: theme.palette.background.default,
			border: 0,
			minHeight: 30,
		},
	},
	card: {
		'& .MuiToolbar-root': {
			backgroundColor: theme.palette.background.paper,
			border: 0,
		},
	},
}));

export const WorkorderTable = ({ isLoading }) => {
	const classes = useStyles();
	return (
		<div className={classes.table}>
			<WorkorderTableContainer isLoading={isLoading} />
		</div>
	);
};

function WorkorderTableContainer({ isLoading }) {
	const classes = useStyles();
	const theme = useTheme();

	const { workorders: data, view } = useWorkordersContext();
	let workorders = data?.workorders?.[view];

	//TITLE
	// ----------------------------------------------------------------
	const title = (view) => (
		<Typography color="secondary" variant="h6" style={{ marginLeft: 0 }}>
			<b>{TitleText(view)}</b>
		</Typography>
	);

	// ----------------------------------------------------------------
	// MAIN
	// ----------------------------------------------------------------
	return (
		<Grid container spacing={0} alignItems="flex-start" className={classes.card}>
			<Grid item xs={12}>
				<MaterialTable
					name="workorder_table"
					elevation={0}
					title={title(view)}
					isLoading={isLoading}
					data={workorders}
					columns={WorkordersTableColumns()}
					options={{
						// GENERAL
						padding: 'dense',
						minBodyHeight: '60vh',
						maxBodyHeight: '60vh',

						//SEARCH
						searchFieldVariant: 'outlined',
						searchFieldStyle: {
							maxHeight: 30,
							borderRadius: 20,
						},

						//HEADER
						headerStyle: {
							backgroundColor: theme.palette.background.default,
							border: 0,
						},

						//ROW
						selection: false,
						rowStyle: {
							border: 0,
							verticalAlign: 'top',
						},

						// EXPORT
						exportFileName: `Workorders-${moment().format('YYYY-MM-DD')}`,
						exportButton: true,
						exportAllData: true,

						//PAGING
						pageSize: 10,
						paging: workorders?.length > 10 ? true : false,
						emptyRowsWhenPaging: false,
					}}
					components={{
						Container: (props) => <Paper {...props} elevation={0} />,
						Row: (props) => (
							<>
								<tr
									style={{
										border: 0,
										borderBottom: `1px solid ${theme.palette.divider}`,
										height: 15,
									}}
								/>
								<MTableBodyRow {...props} />
								<tr
									style={{
										border: 0,
									}}
								>
									<td
										colSpan="4"
										style={{
											padding: 0,
											border: `1px solid ${theme.palette.divider}`,
										}}
									>
										<TableRowActions workorder={props.data} />
									</td>
								</tr>
							</>
						),
					}}
					localization={{
						body: {
							emptyDataSourceMessage: (
								<Typography variant="body2" style={{ textAlign: 'center' }}>
									No work orders to display
								</Typography>
							),
						},
						toolbar: {
							searchPlaceholder: 'Filter results',
						},
					}}
				/>
			</Grid>
		</Grid>
	);
}

const TitleText = (view = '') => {
	let viewText = '';

	if (view === 'today') viewText = 'TODAY';
	if (view === 'yesterday') viewText = 'YESTERDAY';
	if (view === 'thismonth') viewText = moment().format('MMMM');
	if (view === 'older') viewText = 'OLDER';
	if (view === 'search') viewText = 'SEARCH';

	return viewText.toUpperCase();
};
