//Material
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton, Button } from '@material-ui/core';

//Query
import { useMutation } from 'react-query';
import { CreateInvoiceFromWorkorder } from '../../../../../app/utils/data'

//Icons
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

//Context
import { useWorkordersContext } from '../../../../../context/workorders-context';

//Controls
import { Box } from '../../../../../app/components/box';

const useStyles = makeStyles((theme) => ({
    modalFooter: {
        backgroundColor: theme.palette.background.default
    },
    saveButton: {
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.success.main : theme.palette.success.light,
        borderColor: theme.palette.success.dark,
        borderRadius: 0
    },
    cancelButton: {
        borderColor: theme.palette.warning.dark,
        borderRadius: 0
    },
    redDots: {
        color: theme.palette.error.dark,
        marginRight: 5,
    },
    greenDots: {
        color: theme.palette.success.dark,
        marginRight: 5,
    },

    header: {
        // border: '2px solid',
        // borderColor: theme.palette.error.main,
        // height: '300px',
        padding: 20,
        position: 'relative',
        '& :before': {
            content: 'Header',
            position: 'absolute',
            top: '-10px',
            left: '50px',
            background: '#fff',
            padding: '0 20px'
        }
    }
}))

export const WorkorderMarkComplete = ({ workorder, handleClick }) => {
    const classes = useStyles();
    const { refreshPage } = useWorkordersContext()

    const yesMutation = useMutation(CreateInvoiceFromWorkorder, {
        onSuccess: () => {
            refreshPage()
        },
    });

    const saveClick = async () => {
        yesMutation.mutate({ workorderId: workorder._id })
        handleClick()
    };

    return (
        <Box
            heading="Close work order"
            rightComponent={handleClick ?
                <IconButton
                    size='small'
                    color='inherit'
                    component="span"
                    onClick={handleClick}
                >
                    <CloseIcon />
                </IconButton>
                : null
            }
        >
            <Grid container>
                <Grid item xs={12} style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <Typography variant='body2'>
                        This action will <b>close</b> the work order and send a request for <b>invoicing</b>.
                    </Typography>
                </Grid>

                <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 40 }}>
                    <AlertRow
                        label={workorder.costItems.length === 0 ? 'This work order does not contain any content.' : 'Work order contains sufficient data'}
                        isError={workorder.costItems.length === 0}
                    />
                </Grid>

                <Grid container spacing={2} style={{ paddingTop: 10 }} className={classes.modalFooter}>
                    <Grid item xs={6}>
                        <Button
                            variant="outlined"
                            className={classes.cancelButton}
                            onClick={handleClick}
                            fullWidth={true}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="outlined"
                            className={classes.saveButton}
                            onClick={saveClick}
                            fullWidth={true}
                        >
                            {workorder.costItems.length === 0 ? 'Close anyway' : 'Close Work order'}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

const AlertRow = ({ label, isError }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.header}>
            <Grid item xs={12}>
                {/* <Typography color='error' variant='body2'>Issues detected</Typography> */}
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={10}>
                <Grid container alignItems='center'>
                    {!isError && (
                        <CheckCircleIcon className={classes.greenDots} />
                    )}
                    {isError && (
                        <CancelIcon className={classes.redDots} />
                    )}
                    {label}
                </Grid>
            </Grid>
            <Grid item xs={1} />
        </Grid>
    )
}