import { useContext } from 'react';
import ContactContext from '../../../../context/contact-context';
import { Box } from '../../../components/box';
import { KeyPairDisplay } from '../../../components/key-pair';
import { Divider } from '@material-ui/core';

export default function ContactAccountStatus() {
	const { contact } = useContext(ContactContext);

	return (
		<Box heading="Account Status">
			<KeyPairDisplay label="Account Balance" value={contact?.invoices?.overallBalance || '0'} template={{ type: 'currency' }} />

			<br />
			<Divider />
			<br />

			<KeyPairDisplay label="Last visit" value={contact?.stats?.lastVisit} template={{ type: 'dateAgo' }} />

			<KeyPairDisplay label="Last Invoice" value={contact?.stats?.lastInvoice} template={{ type: 'dateAgo' }} />

			<KeyPairDisplay label="# of visits" value={contact?.stats?.numberOfVisits} />
		</Box>
	);
}
