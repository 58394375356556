import React, { useState } from 'react';
import moment from 'moment';

//MaterialUI
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Tooltip, IconButton } from '@material-ui/core';
import { TableContainer, Table, TableHead, TableCell, TableRow, TableBody } from '@material-ui/core';

//Icons
import DeleteIcon from '@material-ui/icons/Delete';

//Shared Components
import { NumberFormatCurrencyText } from '../../../components/textbox/currency';
import { DurationDescription } from '../../../components/textbox/dates';

//Components
import { NewPayment } from './new-payment';
import { EditPayment } from './edit-payment';
import { DeletePayment } from './delete-payment';

//Styles -->
const useStyles = makeStyles((theme) => ({
	container: {
		height: '73vh',
	},
	table: {
		paddingTop: 15,
	},
	tableHeader: {
		border: `1px solid ${theme.palette.divider}`,
	},
	tableHeaderItem: {
		backgroundColor: theme.palette.background.default,
		borderRight: `1px solid ${theme.palette.divider}`,
		borderTop: `1px solid ${theme.palette.divider}`,
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(2),
	},
	tableHeaderItemLast: {
		backgroundColor: theme.palette.background.default,
		borderTop: `1px solid ${theme.palette.divider}`,
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(2),
	},
	tableBody: {},
	tableCell: {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
	tableRow: {
		'&:hover': { cursor: 'pointer' },
		border: `1px solid ${theme.palette.divider}`,
	},
	tableRowNoData: {
		height: '20vh',
		border: `1px solid ${theme.palette.divider}`,
		alignItems: 'center',
		flex: 1,
	},
	tableRowDeleted: {
		border: `1px solid ${theme.palette.divider}`,
		color: theme.palette.text.disabled,
		textDecoration: 'line-through',
	},
	strikeout: {
		'& td:before': {
			content: ' ',
			position: 'absolute',
			top: '50%',
			left: 0,
			borderBottom: '1px solid #111',
			width: '100%',
		},
	},
}));

// Main Code -->
export const PaymentsTable = ({ invoice }) => {
	const classes = useStyles();
	const theme = useTheme();

	const [openEditPayment, setOpenEditPayment] = useState(false);
	const [openDeletePayment, setOpenDeletePayment] = useState(false);
	const [openPaymentNew, setOpenPaymentNew] = useState(false);
	const [editPaymentData, setEditPaymentData] = useState(null);

	const rowClick = async (row) => {
		if (!row.deleted) {
			await setEditPaymentData(row);
			setOpenEditPayment(true);
		}
	};

	const deleteClick = async (row) => {
		if (!row.deleted) {
			await setEditPaymentData(row);
			setOpenDeletePayment(true);
		}
	};
	return (
		<>
			<Grid container className={classes.container}>
				<Grid item xs={12}>
					<TableContainer className={classes.container}>
						<Table size="small" stickyHeader>
							<TableHead className={classes.tableHeader}>
								<TableRow>
									<TableCell align="right" className={classes.tableHeaderItem}>
										<Typography variant="body2" noWrap display="block" align="left">
											DATE
										</Typography>
									</TableCell>
									<TableCell align="right" className={classes.tableHeaderItem}>
										<Typography variant="body2" noWrap display="block" align="center">
											TYPE
										</Typography>
									</TableCell>
									<TableCell align="right" className={classes.tableHeaderItem}>
										<Typography variant="body2" noWrap display="block" align="right">
											AMOUNT
										</Typography>
									</TableCell>
									<TableCell align="right" className={classes.tableHeaderItem}>
										<Typography variant="body2" noWrap display="block" align="right">
											BALANCE
										</Typography>
									</TableCell>
									<TableCell align="right" className={classes.tableHeaderItem}>
										<Typography variant="body2" noWrap display="block" align="left">
											REFERENCE
										</Typography>
									</TableCell>
									<TableCell align="right" className={classes.tableHeaderItemLast}>
										<Typography variant="body2" noWrap display="block" align="left">
											NOTES
										</Typography>
									</TableCell>
									<TableCell align="right" className={classes.tableHeaderItemLast}>
										<Typography variant="body2" noWrap display="block" align="left"></Typography>
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody className={classes.tableBody}>
								<>
									{invoice?.payments?.length === 0 && (
										<TableRow key={1} className={classes.tableRowNoData}>
											<TableCell colSpan={7} style={{ height: '100%' }}>
												<Typography variant="body2" align="center">
													No payments to display
												</Typography>
											</TableCell>
										</TableRow>
									)}

									{invoice?.payments?.map((row, idx) => {
										return (
											<TableRow key={idx} className={row.deleted ? classes.tableRowDeleted : classes.tableRow}>
												{/* DATE */}
												<TableCell onClick={() => rowClick(row)} className={classes.tableCell}>
													<Typography variant="body2" display="block" color={row.deleted ? 'textSecondary' : 'inherit'}>
														{moment(row?.paymentDate).format('DD MMM YYYY')}
													</Typography>

													<DurationDescription value={row.paymentDate} />
												</TableCell>

												{/* TYPE */}
												<TableCell onClick={() => rowClick(row)} className={classes.tableCell}>
													<Typography variant="body2" display="block" color={row.deleted ? 'textSecondary' : 'inherit'}>
														{row.paymentType === 'cash' && 'Cash'}
														{row.paymentType === 'card' && 'Card'}
														{row.paymentType === 'eft' && 'EFT'}
													</Typography>
												</TableCell>

												{/* PAYMENT AMOUNT */}
												<TableCell align="right" onClick={() => rowClick(row)} className={classes.tableCell}>
													<Typography
														variant="body2"
														display="block"
														align="right"
														color={row.deleted ? 'textSecondary' : 'inherit'}
													>
														{NumberFormatCurrencyText(row?.paymentAmount, 2)}
													</Typography>
												</TableCell>

												{/* PAYMENT BALANCE */}
												<TableCell align="right" onClick={() => rowClick(row)} className={classes.tableCell}>
													<Typography
														variant="body2"
														align="right"
														display="block"
														color={row.deleted ? 'textSecondary' : 'error'}
													>
														{NumberFormatCurrencyText(row?.paymentOriginalTotal - row?.paymentAmount, 2)}
													</Typography>
												</TableCell>

												{/* REFERENCE */}
												<TableCell align="right" onClick={() => rowClick(row)} className={classes.tableCell}>
													<Typography variant="body2" display="block" color={row.deleted ? 'textSecondary' : 'inherit'}>
														{row?.paymentRef}
													</Typography>
												</TableCell>

												{/* NOTES */}
												<TableCell align="right" onClick={() => rowClick(row)}>
													<Typography variant="body2" display="block" color={row.deleted ? 'textSecondary' : 'inherit'}>
														{row?.paymentNotes}
													</Typography>
												</TableCell>

												{/* ACTIONS */}
												<TableCell align="right">
													{!row.deleted && (
														<Tooltip title="Delete this item" arrow={true} placement="right">
															<IconButton
																color="primary"
																component="span"
																onClick={() => deleteClick(row)}
																size="small"
																style={{ marginLeft: 5, color: theme.palette.error.dark }}
															>
																<DeleteIcon className={classes.actionButtonsDelete} style={{ fontSize: '1.2rem' }} />
															</IconButton>
														</Tooltip>
													)}
												</TableCell>
											</TableRow>
										);
									})}
								</>
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>

			<EditPayment paymentData={editPaymentData} open={openEditPayment} setOpen={setOpenEditPayment} />

			<DeletePayment paymentData={editPaymentData} open={openDeletePayment} setOpen={setOpenDeletePayment} />

			<NewPayment invoiceId={invoice?._id} initialTotals={invoice?.totals} open={openPaymentNew} setOpen={setOpenPaymentNew} />
		</>
	);
};
