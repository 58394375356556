import React from 'react';

//Material
import { Grid, Checkbox } from '@material-ui/core';

//Shared Components
import { KeyPairInput } from '../key-pair'
import { Section } from '../card/section'
import { TextBoxLabel } from '../card/label'


// Main Code -->
export const SaveInvoiceTemplate = ({ onChange, defaultValues }) => {
    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12}>
                <Grid container alignItems="flex-start" >
                    <KeyPairInput
                        label="Name"
                        name="templateName"
                        defaultValue={defaultValues?.templateName || null}
                        type='text'
                        onChange={onChange}
                        focus={true}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems="flex-start">
                    <KeyPairInput
                        label="Description"
                        name="templateDescription"
                        defaultValue={defaultValues?.templateDescription || null}
                        type='multiline'
                        onChange={onChange}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Section text="Include" />
                <Grid container alignItems="center">
                    <Grid item xs={4}>
                        <TextBoxLabel label="Include Qty" />
                    </Grid>
                    <Grid item xs={8}>
                        <Checkbox
                            name="includeQty"
                            onChange={(e) => onChange({
                                target: {
                                    name: 'includeQty',
                                    value: e.target.checked
                                }
                            })}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextBoxLabel label="Include Unit Price" />
                    </Grid>
                    <Grid item xs={8}>
                        <Checkbox
                            name="includeUnitPrice"
                            onChange={(e) => onChange({
                                target: {
                                    name: 'includeUnitPrice',
                                    value: e.target.checked
                                }
                            })}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextBoxLabel label="Include Discount" />
                    </Grid>
                    <Grid item xs={8}>
                        <Checkbox
                            name="includeDiscount"
                            onChange={(e) => onChange({
                                target: {
                                    name: 'includeDiscount',
                                    value: e.target.checked
                                }
                            })}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}