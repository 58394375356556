import React from 'react';

//@material
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Grid, Card, CardHeader, FormControlLabel, Checkbox } from '@material-ui/core';

//Context
import { useCheckInContext } from '../../../../../context/appointments-checkin-context';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		input: {
			[`& fieldset`]: {
				borderRadius: 0,
			},
		},
	})
);

export const AppointmentCheckInQuoteFirst = ({ disabled = false }) => {
	const classes = useStyles();

	const { appointment, setAppointment, errors } = useCheckInContext();

	return (
		<Card className={classes.root} elevation={0}>
			<CardHeader
				title={
					<Grid container spacing={0} alignItems="flex-end">
						<Grid item xs={12}>
							<FormControlLabel
								label="Quote first"
								disabled={disabled}
								control={
									<Checkbox
										color="primary"
										onChange={(event) => {
											setAppointment({
												...appointment,
												quoteFirst: event.target.checked,
											});
										}}
									/>
								}
							/>
						</Grid>

						{errors?.root?.quoteFirst && (
							<Typography variant="caption" color="error">
								{errors?.root?.quoteFirst}
							</Typography>
						)}
					</Grid>
				}
			/>
		</Card>
	);
};

export default AppointmentCheckInQuoteFirst;
