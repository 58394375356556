import React from 'react';

//@Material
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Drawer, IconButton, Tooltip, Grid, CircularProgress } from '@material-ui/core';

//Icons
import CloseIcon from '@material-ui/icons/Close';

//Shared Components
import HelpBox from '../help';
import Loading from '../loading';

const MODAL_WIDTH = 500;

const useStyles = makeStyles((theme) => ({
	drawerOpen: {
		overflowX: 'hidden',
		width: MODAL_WIDTH,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},

	drawerHeaderNew: {
		backgroundColor: theme.palette.success.dark,
		color: 'white',
	},
	drawerHeaderEdit: {
		backgroundColor: theme.palette.warning.dark,
		color: 'white',
	},
	drawerBody: {
		overflowX: 'hidden',
		overflowY: 'auto',
	},

	// BUTTONS
	menuButton: {
		color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.common.black,
	},

	//BODY
	bodyRoot: {
		flexGrow: 1,
		paddingBottom: 75,
		paddingLeft: 20,
		paddingRight: 20,
		paddingTop: 20,
	},

	//FOOTER
	footer: {
		paddingLeft: 20,
		paddingRight: 20,
		paddingTop: 10,
		paddingBottom: 10,

		height: 60,
		width: MODAL_WIDTH + 20,

		position: 'fixed',
		bottom: 0,

		borderTop: '1px solid',
		borderTopColor: theme.palette.divider,
		backgroundColor: theme.palette.background.default,
		zIndex: 999,
	},
	footerCancelButton: {
		borderColor: theme.palette.divider,
		borderRadius: 0,
	},
	footerSaveButton: {
		backgroundColor: theme.palette.type === 'dark' ? theme.palette.success.dark : 'inherit',
		borderColor: theme.palette.success.main,
		borderRadius: 0,
	},
}));

export const PopOut = ({
	children,
	heading = 'HEADING',
	buttonText = 'BUTTON',
	tooltip = 'TOOLTIP',
	view = 'new',

	open = false,
	setOpen,

	icon = null,
	iconSize = 'medium',
	iconOnly = true,

	noBorder = false,
	disabled = false,
	helpBoxText = '',

	buttonStyle,
	buttonFullWidth = false,
	buttonDefault = false,

	isSaving = false,
	saveButtonText = 'Save',
	saveButtonFn = null,
	cancelButtonText = 'Cancel',
	cancelButtonFn = null,
}) => {
	const classes = useStyles();

	const toggleDrawer = (anchor, open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
		setOpen(open);
	};

	return (
		<>
			{/* ICON ONLY */}
			{/* {!buttonDefault && pureIcon && (
				<AddIcon
					color="inherit"
					onClick={() => setOpen(true)}
					fontSize={iconSize || 'inherit'}
					disabled={disabled}
				/>
			)} */}

			{/* ICON BUTTON ONLY - no text*/}
			{!buttonDefault && icon && iconOnly && (
				<Tooltip title={tooltip} arrow={true} placement="bottom">
					<IconButton
						color="inherit"
						component="span"
						onClick={() => setOpen(true)}
						size={iconSize === 'large' ? 'medium' : iconSize || 'medium'}
						fontSize={iconSize || 'inherit'}
						className={noBorder ? classes.aloneButton : classes.menuButton}
						style={buttonStyle}
						disabled={disabled}
					>
						{icon}
					</IconButton>
				</Tooltip>
			)}

			{/* ICON BUTTON WITH TEXT */}
			{!buttonDefault && icon && !iconOnly && (
				<Tooltip title={tooltip} arrow={true} placement="right">
					<Button
						size={iconSize}
						fullWidth={buttonFullWidth}
						className={classes.menuButton}
						endIcon={icon}
						onClick={() => setOpen(true)}
						style={buttonStyle}
						disabled={disabled}
					>
						{buttonText}
					</Button>
				</Tooltip>
			)}

			{/* BUTTON WITH TEXT */}
			{!buttonDefault && !icon && !iconOnly && buttonText && (
				<Tooltip title={tooltip} arrow={true} placement="right">
					<Button
						// variant="outlined"
						size={iconSize}
						fullWidth={buttonFullWidth}
						className={noBorder ? classes.aloneButton : classes.menuButton}
						onClick={() => setOpen(true)}
						style={buttonStyle}
						disabled={disabled}
					>
						{buttonText}
					</Button>
				</Tooltip>
			)}

			{/* BUTTON WITH TEXT AND NO BRANDING*/}
			{buttonDefault && (
				<Button onClick={() => setOpen(true)} disabled={disabled}>
					{buttonText}
				</Button>
			)}

			{/* DRAWER */}
			<Drawer
				anchor="right"
				open={open}
				onClose={() => {
					cancelButtonFn();
					toggleDrawer('right', false);
				}}
				ModalProps={{
					disableEscapeKeyDown: true,
					onClose: (e, reason) => {
						if (reason === 'backdropClick') return null;
					},
				}}
				container={document.getElementById('page-window')}
				className={classes.drawerOpen}
				classes={{ paper: classes.drawerOpen }}
			>
				<Grid container className={view === 'new' ? classes.drawerHeaderNew : classes.drawerHeaderEdit}>
					<Grid
						container
						spacing={2}
						justifyContent="center"
						alignItems="center"
						style={{
							marginLeft: 10,
							marginTop: 1,
							marginBottom: 1,
						}}
					>
						<Grid item xs={10}>
							<Typography variant="h5" color="inherit">
								{String(heading).toUpperCase()}
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Grid container justifyContent="flex-end" alignItems="center">
								<Grid item xs={12}>
									<IconButton
										color="inherit"
										component="span"
										onClick={() => {
											setOpen(false);
											cancelButtonFn();
										}}
									>
										<CloseIcon />
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid container className={classes.drawerBody}>
					{!isSaving && helpBoxText && <HelpBox text={helpBoxText} />}

					<div className={classes.bodyRoot}>
						{isSaving && <Loading />}
						{!isSaving && children}
					</div>
				</Grid>

				<PopOutFooter
					isSaving={isSaving}
					saveButtonText={saveButtonText}
					saveButtonFn={saveButtonFn}
					cancelButtonText={cancelButtonText}
					cancelButtonFn={cancelButtonFn}
				/>
			</Drawer>
		</>
	);
};

export const PopOutSection = ({ label = null, isFirst = false, children }) => {
	return (
		<Grid container spacing={0}>
			{label && (
				<Grid item xs={12} md={12}>
					<div style={!isFirst ? { marginTop: 20 } : {}}>
						<Typography variant="button" color="textPrimary">
							<b>{label}</b>
						</Typography>
					</div>
				</Grid>
			)}

			<Grid item xs={12} md={12}>
				{children}
			</Grid>
		</Grid>
	);
};

export const PopOutFooter = ({
	isSaving = false,
	saveButtonText = 'Save',
	saveButtonFn = null,
	cancelButtonText = 'Cancel',
	cancelButtonFn = null,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.footer}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<Button variant="outlined" className={classes.footerCancelButton} onClick={cancelButtonFn} fullWidth={true}>
						{cancelButtonText}
					</Button>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Button
						variant="outlined"
						className={classes.footerSaveButton}
						onClick={saveButtonFn}
						fullWidth={true}
						disabled={isSaving}
						startIcon={isSaving ? <CircularProgress size={24} /> : null}
					>
						{isSaving ? 'Saving' : saveButtonText}
					</Button>
				</Grid>
			</Grid>
		</div>
	);
};

export default PopOut;
