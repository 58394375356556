import { createContext, useContext } from 'react';

const AppointmentsContext = createContext({
    appointments: null,
    searchCriteria: null,
    view: 'today',
    setSearchCriteria: () => { },
    setView: () => { },
    searchQuery: () => { },
    refreshPage: () => { },
});

export function useAppointmentsContext() {
    return useContext(AppointmentsContext);
}

export default AppointmentsContext;