// import React, { useEffect } from 'react';
import moment from 'moment';

//@material
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Card, CardHeader } from '@material-ui/core';

//@Material - Pickers
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

//Context
import NewAppointmentContext from '../../../../../../context/appointments-new-context';
import SettingsContext from '../../../../../../context/settings-context';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			marginTop: theme.spacing(1),
		},
		icon: {
			color: theme.palette.secondary.main,
		},
		dayWithDotContainer: {
			position: 'relative',
		},
		dayWithDot: {
			position: 'absolute',
			height: 0,
			width: 0,
			border: '2px solid',
			borderRadius: 4,
			borderColor: theme.palette.success.light,
			right: '50%',
			transform: 'translateX(1px)',
			top: '80%',
		},
		dayBlanked: {
			backgroundColor: 'rgba(244, 67, 54, 0.4)',
		},
		input: {
			[`& fieldset`]: {
				borderRadius: 0,
			},
		},
	})
);

export const AppointmentDate = ({ defaultValue = new Date(), variant = 'inline', disabled = false, label = null }) => {
	const classes = useStyles();
	const theme = useTheme();

	const handleDateChange = (date, appointment, setAppointment) => {
		setAppointment({
			...appointment,
			bookingDate: moment(date).format('YYYY-MM-DD'),
		});
	};

	// Blanks the dates as specified in thee database and public holidays API
	const renderDayInPicker = ({ data, day, dayInCurrentMonth, dayComponent }) => {
		// --------------------------------------------------------------------------------------------
		// BLANKED DAYS - PUBLIC HOLIDAYS
		// --------------------------------------------------------------------------------------------
		const isPublic = data?.blanked?.public?.findIndex((date) => date === moment(day).format('YYYY-MM-DD'));
		if (isPublic > -1 && dayInCurrentMonth) return <div className={classes.dayBlanked}>{dayComponent}</div>;

		// --------------------------------------------------------------------------------------------
		// BLANKED DAYS - RECURRING DAYS
		// --------------------------------------------------------------------------------------------
		const isBlanked = data?.blanked?.blockedDays?.findIndex((date) => Number(date) === Number(moment(day).format('e')));
		if (isBlanked > -1 && dayInCurrentMonth) return <div className={classes.dayBlanked}>{dayComponent}</div>;

		// --------------------------------------------------------------------------------------------
		// BLANKED DAYS - COMPANY HOLIDAYS
		// --------------------------------------------------------------------------------------------
		const isCompanyHoliday = data?.blanked?.blockedDates?.findIndex((date) => date === moment(day).format('YYYY-MM-DD'));
		if (isCompanyHoliday > -1 && dayInCurrentMonth) return <div className={classes.dayBlanked}>{dayComponent}</div>;

		// --------------------------------------------------------------------------------------------
		// APPOINTMENTS
		// --------------------------------------------------------------------------------------------
		const countIndex = data?.blanked?.appointment_counts.findIndex(
			(date) => moment(date.bookingDate).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD')
		);

		const appointmentCount = countIndex > -1 ? data?.blanked?.appointment_counts[countIndex].count : -1;

		if (appointmentCount > 0 && dayInCurrentMonth) {
			//Add the dots for each of the appointments
			let busyDots = [];
			let one = ['50%'];
			let two = ['40%', '60%'];
			let three = ['30%', '50%', '70%'];
			let four = ['20%', '40%', '60%', '80%'];
			let five = ['10%', '30%', '50%', '70%', '90%'];

			if (appointmentCount === 1) {
				one.map((size, i) => {
					busyDots.push(
						<div
							key={`one_${i}`}
							className={classes.dayWithDot}
							style={{
								right: size,
							}}
						/>
					);
				});
			}
			if (appointmentCount === 2) {
				two.map((size) => {
					busyDots.push(
						<div
							className={classes.dayWithDot}
							style={{
								right: size,
							}}
						/>
					);
				});
			}
			if (appointmentCount === 3) {
				three.map((size, i) => {
					busyDots.push(
						<div
							key={`two_${i}`}
							className={classes.dayWithDot}
							style={{
								right: size,
							}}
						/>
					);
				});
			}

			if (appointmentCount === 4) {
				let dotColor = theme.palette.success.light;
				four.map((size, i) => {
					if (i === Number(data?.blanked?.max) - 1) dotColor = theme.palette.warning.dark;
					if (i >= Number(data?.blanked?.max)) dotColor = theme.palette.error.light;
					busyDots.push(
						<div
							key={`three_${i}`}
							className={classes.dayWithDot}
							style={{
								right: size,
								borderColor: dotColor,
							}}
						/>
					);
				});
			}

			if (appointmentCount >= 5) {
				let dotColor = theme.palette.success.light;
				five.map((size, i) => {
					if (i === Number(data?.blanked?.max) - 1) dotColor = theme.palette.error.light;
					if (i === Number(data?.blanked?.max)) dotColor = theme.palette.error.light;
					busyDots.push(
						<div
							key={`max_${i}`}
							className={classes.dayWithDot}
							style={{
								right: size,
								borderColor: dotColor,
							}}
						/>
					);
				});
			}

			// --------------------------------------------------------------------------------------------
			// FINAL RESULT
			// --------------------------------------------------------------------------------------------
			return (
				<div className={classes.dayWithDotContainer}>
					{dayComponent}
					{busyDots.map((item) => {
						return item;
					})}
				</div>
			);
		}

		return dayComponent;
	};

	return (
		<SettingsContext.Consumer>
			{({ blankedDates }) => (
				<NewAppointmentContext.Consumer>
					{({ appointment, setAppointment, errors }) => (
						<Card className={classes.root} elevation={0}>
							<CardHeader
								style={{ marginBottom: 0, paddingBottom: 0 }}
								title={
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<Grid container spacing={0} alignItems="flex-end">
											<Grid item xs={12}>
												<KeyboardDatePicker
													// disableToolbar
													autoOk={true}
													variant={variant || 'inline'}
													format="ddd, DD/MM/YYYY"
													size="small"
													label={label || 'Date'}
													inputVariant="outlined"
													className={classes.input}
													color="secondary"
													value={defaultValue}
													allowKeyboardControl={true}
													error={errors?.main?.bookingDate}
													disabled={disabled}
													onChange={(date) => handleDateChange(date, appointment, setAppointment)}
													renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) =>
														renderDayInPicker({
															data: blankedDates,
															day,
															selectedDate,
															dayInCurrentMonth,
															dayComponent,
														})
													}
													KeyboardButtonProps={{
														'aria-label': 'change date',
													}}
												/>
											</Grid>
											{errors?.main?.bookingDate && (
												<Typography variant="caption" color="error">
													{errors?.main?.bookingDate}
												</Typography>
											)}
										</Grid>
									</MuiPickersUtilsProvider>
								}
							/>
						</Card>
					)}
				</NewAppointmentContext.Consumer>
			)}
		</SettingsContext.Consumer>
	);
};

export default AppointmentDate;
