import { createContext, useContext } from 'react';

const QuotesContext = createContext({
    quotes: null,
    searchCriteria: null,
    view: 'new',
    setSearchCriteria: () => { },
    setView: () => { },
    searchQuery: () => { },
    refreshPage: () => { },
    isLoading: false,

    refetch: () => { },
});

export function useQuotesContext() {
    return useContext(QuotesContext);
}

export default QuotesContext;