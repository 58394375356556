import moment from 'moment';

// MaterialUI
import MaterialTable from 'material-table';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ContactPopup from '../../../app/widgets/contact';

const useStyles = makeStyles((theme) => ({
	table: {
		// ROW HOVER
		'& tbody': {
			'& tr:hover': {
				transition: 'none',
				cursor: 'pointer',
				backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.08)' : theme.palette.grey[200],
			},
		},

		'& thead': {
			border: '1px solid',
			borderColor: theme.palette.divider,
			borderTop: 0,
			borderBottom: 0,
		},

		// TOOLBAR
		'& .MuiToolbar-root': {
			paddingLeft: 10,
			backgroundColor: theme.palette.background.default,
			border: '1px solid',
			borderColor: theme.palette.divider,
		},
	},
	mainContainer: {
		overflowY: 'auto',
	},
}));

export const ContactsTable = ({ data }) => {
	const classes = useStyles();

	return (
		<div className={classes.table}>
			<ContactsTableContainer contacts={data?.contacts.filter((contact) => (contact?.contactFullname ? true : false))} />
		</div>
	);
};

const ContactsTableContainer = ({ contacts }) => {
	const theme = useTheme();

	// COLUMNS
	// -------------------------------
	const columns = [
		{
			title: 'Name',
			field: 'contactFullname',
			render: function render(row) {
				return <ContactPopup contactId={row?._id} contactFullname={row?.contactFullname} meta={row} type="link" />;
			},
		},
		{
			title: 'Email',
			field: 'contactEmail',
			render: function render(row) {
				if (row?.contactEmail)
					return (
						<Typography variant="body2" display="block">
							{row?.contactEmail}
						</Typography>
					);
			},
		},
		{
			title: 'Mobile',
			field: 'contactMobile',
			render: function render(row) {
				if (row?.contactMobile)
					return (
						<Typography variant="body2" display="block">
							{row?.contactMobile}
						</Typography>
					);
			},
		},
		{
			title: 'Phone',
			field: 'contactPhone',
			render: function render(row) {
				if (row?.contactPhone)
					return (
						<Typography variant="body2" display="block">
							{row?.contactPhone}
						</Typography>
					);
			},
		},
		// {
		// 	title: '',
		// 	field: '',
		// 	width: '1%',
		// 	align: 'center',
		// 	alignHeader: 'center',
		// 	render: function render(row) {
		// 		return <DeleteContact id={row?.contact?._id} contactFullname={row?.contactFullname} />;
		// 	},
		// },
	];

	return (
		<MaterialTable
			title="Contacts"
			data={contacts}
			columns={columns}
			// onRowClick={(event, row) => history.push('/contact/' + row?._id)}

			options={{
				// GENERAL
				padding: 'dense',

				//SEARCH
				searchFieldVariant: 'outlined',
				searchFieldStyle: {
					maxHeight: 35,
					borderRadius: 20,
				},

				//HEADER
				headerStyle: {
					backgroundColor: theme.palette.background.default,
				},

				//ROW
				selection: false,
				rowStyle: {
					verticalAlign: 'top',
				},

				// EXPORT
				exportFileName: `Contacts-${moment().format('YYYY-MM-DD')}`,
				exportButton: true,
				exportAllData: true,

				//PAGING
				pageSize: 50,
				emptyRowsWhenPaging: false,
			}}
		/>
	);
};
