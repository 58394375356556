import { useState } from 'react';

//@Material
import { useTheme } from '@material-ui/core/styles';
import { Button, Tooltip } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

// Icons
import BlockIcon from '@material-ui/icons/Block';

// Query
import { useMutation } from 'react-query';
import {
    UpdateAppointmentStatus,
} from '../../../../../../app/utils/data';

// State
import { useAppointmentsContext } from '../../../../../../context/appointments-context'
import { useNewAppointmentContext } from '../../../../../../context/appointments-new-context'


// Page
import { MultiLineEdit } from '../../../../../../app/components/card/multiline'

export default function NoShowAppointmentDialog({
    id,
    title,
    message,
    icon,
    setParentOpen,
    tootlip = '',
    disabled = false
}) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const { refreshPage } = useAppointmentsContext()
    const { setAppointment, setErrors } = useNewAppointmentContext()
    const [reason, setReason] = useState(false);

    const update = useMutation(UpdateAppointmentStatus);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleNoShow = async () => {
        await update.mutate({
            id,
            bookingStatus: 'No Show',
            bookingCancelReason: reason
        })
        await setAppointment(null)
        await setErrors(null)

        await refreshPage()

        setOpen(false);
        setParentOpen(false)
    };

    return (
        <>
            <Tooltip title={tootlip} arrow={true} placement='right'>
                <Button
                    onClick={handleClickOpen}
                    fullWidth={true}
                    disabled={disabled}
                    size="small"
                    variant='outlined'
                    startIcon={icon || <BlockIcon />}
                >
                    No Show
                </Button>
            </Tooltip>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <>
                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {message}

                            <MultiLineEdit
                                item={{
                                    id: 'no-show-reason',
                                    type: 'text',
                                    canEdit: true
                                }}
                                autoFocus={true}
                                initialValue=''
                                onChange={(e) => setReason(e.target.value)}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: theme.palette.background.default, maringTop: 20 }}>
                        <Button onClick={handleClose} color="textPrimary" variant="outlined">
                            Cancel
                        </Button>
                        <Button onClick={handleNoShow} color="textPrimary" variant="outlined">
                            Save
                        </Button>
                    </DialogActions>
                </>
            </Dialog>
        </>
    );
}
