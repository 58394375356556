import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { GetVehicleDetailed } from '../../utils/data';
// material
import { CircularProgress, Grid, Link, Tooltip, useTheme } from '@material-ui/core';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
// context
import VehicleContext from '../../../context/vehicle-context';
// components
import VehicleDetailTabs from './components/tabs';
import VehicleAccountStatus from './components/vehicle-account-status';
import Modal from '../../components/modal';
import VehicleCard from '../vehicle-card';
import { Loading } from '../../components/loading';

export const VehiclePopup = ({ vehicleId, vehicleRegistration, type = 'link', meta = {} }) => {
	const [open, setOpen] = useState(false);
	const theme = useTheme();

	//Get Data
	const { data, isLoading } = useQuery(['vehicle_detailed', vehicleId], GetVehicleDetailed, {
		refetchOnWindowFocus: false,
		enabled: !!vehicleId && open,
	});

	return (
		<>
			{type === 'link' && (
				<Link
					component="button"
					onClick={() => setOpen(true)}
					variant="body2"
					display="block"
					color="secondary"
					style={{ marginTop: 0, color: theme.palette.secondary.main }}
				>
					{vehicleRegistration}
				</Link>
			)}

			{type === 'action' && (
				<>
					{!isLoading && <DriveEtaIcon fontSize="small" style={{ paddingRight: 5 }} />}
					{isLoading && <CircularProgress fontSize="small" style={{ display: 'inline-block', width: 15, height: 15 }} />}
					<Tooltip
						title={
							<span style={{ fontSize: '12px' }}>
								<b>Vehicle</b>: {vehicleRegistration} <br />
								<b>Make</b>: {meta?.vehicleMake} <br />
								<b>Model</b>: {meta?.vehicleModel} <br />
							</span>
						}
						placement="top"
						arrow={true}
					>
						<Link
							component="button"
							onClick={() => setOpen(true)}
							variant="caption"
							color="textSecondary"
							display="block"
							style={{
								marginTop: 0,
								paddingRight: 10,
								marginLeft: isLoading ? 5 : 0,
								color: !isLoading ? theme.palette.text.secondary : theme.palette.primary.main,
							}}
						>
							{vehicleRegistration.toString().toUpperCase()}
						</Link>
					</Tooltip>
				</>
			)}

			{data && (
				<VehicleContext.Provider value={{ vehicle: data?.vehicle }}>
					<Modal header={vehicleRegistration} size="lg" open={open} setOpen={setOpen} fixedSize={true}>
						<Grid container spacing={2}>
							{data?.status === 'error' && <p>Error Occured</p>}
							{isLoading && (
								<Grid container spacing={2} justifyContent="center" alignItems="center">
									<Loading />
								</Grid>
							)}
							{!isLoading && (
								<>
									<Grid item xs={4} style={{ paddingRight: 25 }}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<VehicleCard reassign={false} vehicleId={vehicleId} noLink={true} />
											</Grid>
											<Grid item xs={12}>
												<VehicleAccountStatus />
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={8}>
										<VehicleDetailTabs />
									</Grid>
								</>
							)}
						</Grid>
					</Modal>
				</VehicleContext.Provider>
			)}
		</>
	);
};

export default VehiclePopup;
