import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

//Material
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Container, Typography, Paper, IconButton, Tooltip } from '@material-ui/core';

//Icons
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';

const useStyles = makeStyles((theme) => ({
	container: {
		paddingLeft: 0,
		paddingRight: 0,
		minHeight: '40vh',
	},
	paper: {
    minHeight: '78vh',
    display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		borderRadius: 0,
	},
	fullScreen: {
		display: 'flex',
		overflow: 'auto',
	},
	bodyArea: {
		padding: theme.spacing(2),
		overflow: 'hidden',
	},
	bodyAreaWithActions: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(0),
		overflow: 'hidden',
	},
	actionArea: {
		boxShadow: theme.shadows[3],
		border: 0,
		// backgroundColor: theme.palette.divider,
		// backgroundColor: theme.palette.secondary.main,
		backgroundColor: theme.palette.divider,
		marginBottom: theme.spacing(2),
	},
	emailButton: {
		borderRadius: 0,
		padding: 0,
		margin: 0,
	},
}));

const Page = ({
	heading = '',
	subheading = '',
	actions = null,
	children,
	transparent = false,
	subheadingIsControl = false,
	refreshPageFn = null,
	actionButtons = null,
}) => {
	let history = useHistory();
	const classes = useStyles();
	const theme = useTheme();
	const fixedHeightPaper = clsx(classes.paper, classes.fullScreen);

	return (
		<Container id="page-window" maxWidth={false} className={classes.container}>
			<Grid container>
				<Grid item xs={8}>
					{/* Heading */}
					<Grid container alignItems="center" style={{ paddingBottom: 5, marginTop: 15 }}>
						{history.length > 0 && (
							<Tooltip title="Go back" arrow={true} placement="right">
								<IconButton size="small" onClick={() => history.goBack()}>
									<ArrowBackIosIcon fontSize="small" style={{ color: theme.palette.text.disabled }} />
								</IconButton>
							</Tooltip>
						)}
						<Typography
							variant="h5"
							color="secondary"
							// style={{ paddingLeft: 5, paddingRight: 10, textShadow: '2px 2px 3px rgba(255,255,255,0.2)' }}
							style={{ paddingLeft: 5, paddingRight: 10, fontSize: '1.8rem' }}
						>
							<b>{heading.toUpperCase()}</b>
						</Typography>
						{refreshPageFn && (
							<Tooltip title="Refresh page" arrow={true} placement="right">
								<IconButton size="small" onClick={refreshPageFn}>
									<CachedOutlinedIcon fontSize="small" style={{ color: theme.palette.text.disabled }} />
								</IconButton>
							</Tooltip>
						)}
					</Grid>

					{/* Sub Heading*/}
					{!subheadingIsControl && subheading && (
						<Typography variant="body2" color="textSecondary" gutterBottom>
							{subheading.toUpperCase()}
						</Typography>
					)}

					{/* Custom Controls*/}
					{subheadingIsControl && subheading && subheading}
				</Grid>

				<Grid item xs={4}>
					<Grid container justifyContent="flex-end">
						{actionButtons}
					</Grid>
				</Grid>
			</Grid>

			<Paper
				id="page-window-paper"
				className={fixedHeightPaper}
				variant="outlined"
				style={transparent ? { backgroundColor: 'transparent' } : {}}
			>
				{actions && (
					<>
						<div className={classes.actionArea}>{actions}</div>
						<div className={classes.bodyAreaWithActions}>{children}</div>
					</>
				)}

				{!actions && <div className={classes.bodyArea}>{children}</div>}
			</Paper>
		</Container>
	);
};

export default Page;
