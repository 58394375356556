import React from 'react';

//@Material
import { makeStyles } from '@material-ui/core/styles';
import { Button, Divider, Typography, Drawer, IconButton, Grid, CircularProgress } from '@material-ui/core';

//Icons
import CloseIcon from '@material-ui/icons/Close';

import HelpBox from '../help';

const MODAL_WIDTH = 500;

const useStyles = makeStyles((theme) => ({
	drawerOpen: {
		overflowX: 'hidden',
		width: MODAL_WIDTH,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},

	drawerHeaderNew: {
		backgroundColor: theme.palette.success.dark,
		color: 'white',
	},
	drawerHeaderEdit: {
		backgroundColor: theme.palette.warning.dark,
		color: 'white',
	},
	drawerBody: {
		overflowX: 'hidden',
		overflowY: 'auto',
	},

	// BUTTONS
	menuButton: {
		borderRight: '1px solid',
		borderRightColor: theme.palette.divider,
		borderRadius: 0,
		paddingLeft: 10,
		paddingRight: 10,
	},

	//BODY
	bodyRoot: {
		flexGrow: 1,
		paddingBottom: 75,
		paddingLeft: 20,
		paddingRight: 20,
		paddingTop: 20,
	},

	//FOOTER
	footer: {
		paddingLeft: 20,
		paddingRight: 20,
		paddingTop: 10,
		paddingBottom: 10,

		height: 60,
		width: MODAL_WIDTH + 20,

		position: 'fixed',
		bottom: 0,

		borderTop: '1px solid',
		borderTopColor: theme.palette.divider,
		backgroundColor: theme.palette.background.default,
		zIndex: 999,
	},
	footerCancelButton: {
		borderColor: theme.palette.divider,
		borderRadius: 0,
	},
	footerSaveButton: {
		backgroundColor: theme.palette.type === 'dark' ? theme.palette.success.dark : 'inherit',
		borderColor: theme.palette.success.main,
		borderRadius: 0,
	},
}));

export const PopOutNoButton = ({
	children,
	heading = 'HEADING',
	view = 'new',

	open = false,
	setOpen,

	helpBoxText = '',

	isSaving = false,
	saveButtonText = 'Save',
	saveButtonFn = null,
	cancelButtonText = 'Cancel',
	cancelButtonFn = null,
}) => {
	const classes = useStyles();

	const toggleDrawer = (anchor, open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
		setOpen(open);
	};

	return (
		<>
			{/* DRAWER */}
			<Drawer
				anchor="right"
				open={open}
				onClose={toggleDrawer('right', false)}
				ModalProps={{
					onClose: (e, reason) => {
						if (reason === 'backdropClick') return null;
					},
					disableEscapeKeyDown: true,
				}}
				container={document.getElementById('page-window')}
				className={classes.drawerOpen}
				classes={{ paper: classes.drawerOpen }}
			>
				<Grid container className={view === 'new' ? classes.drawerHeaderNew : classes.drawerHeaderEdit}>
					{/* HEADER */}
					<Grid
						container
						spacing={2}
						justifyContent="center"
						alignItems="center"
						style={{
							marginLeft: 10,
							marginTop: 1,
							marginBottom: 1,
						}}
					>
						<Grid item xs={10}>
							<Typography variant="h5" color="inherit">
								{String(heading).toUpperCase()}
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Grid container justifyContent="flex-end" alignItems="center">
								<Grid item xs={12}>
									<IconButton color="inherit" component="span" onClick={() => setOpen(false)}>
										<CloseIcon />
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				{/* BODY */}
				<Grid container className={classes.drawerBody}>
					{/* Help box */}
					{helpBoxText && <HelpBox text={helpBoxText} />}

					{/* Body Root */}
					<div className={classes.bodyRoot}>{children}</div>
				</Grid>

				<PopOutFooter
					isSaving={isSaving}
					saveButtonText={saveButtonText}
					saveButtonFn={saveButtonFn}
					cancelButtonText={cancelButtonText}
					cancelButtonFn={cancelButtonFn}
				/>
			</Drawer>
		</>
	);
};

export const PopOutSection = ({ label = null, isFirst = false, children }) => {
	return (
		<Grid container spacing={0}>
			{label && (
				<Grid item xs={12} md={12}>
					<div style={!isFirst ? { marginTop: 20 } : {}}>
						<Typography variant="button" color="primary">
							{label}
						</Typography>
						<Divider variant="fullWidth" />
					</div>
				</Grid>
			)}

			<Grid item xs={12} md={12}>
				{children}
			</Grid>
		</Grid>
	);
};

export const PopOutFooter = ({
	isSaving = false,
	saveButtonText = 'Save',
	saveButtonFn = null,
	cancelButtonText = 'Cancel',
	cancelButtonFn = null,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.footer}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<Button variant="outlined" className={classes.footerCancelButton} onClick={cancelButtonFn} fullWidth={true}>
						{cancelButtonText}
					</Button>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Button
						variant="outlined"
						className={classes.footerSaveButton}
						onClick={saveButtonFn}
						fullWidth={true}
						disabled={isSaving}
						startIcon={isSaving ? <CircularProgress size={24} /> : null}
					>
						{isSaving ? 'Saving' : saveButtonText}
					</Button>
				</Grid>
			</Grid>
		</div>
	);
};

export default PopOutNoButton;
