// MaterialUI
import MaterialTable from 'material-table';
import { useTheme } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

export const Table = ({ title, columns, data, props }) => {
    const theme = useTheme()

    return (
        <>
            <MaterialTable
                {...props}
                title={title}
                data={data}
                columns={columns}

                components={{
                    Container: props => <Paper {...props} elevation={0} />
                }}

                options={{
                    // GENERAL
                    padding: 'dense',
                    toolbar: false,
                    // grouping: true,

                    //HEADER
                    headerStyle: {
                        backgroundColor: theme.palette.background.default,
                        borderLeft: `1px solid ${theme.palette.divider}`,
                        borderTop: `1px solid ${theme.palette.divider}`,
                    },

                    //BODY
                    maxBodyHeight: '44vh',

                    //ROW
                    selection: false,
                    rowStyle: {
                        verticalAlign: 'top',
                    },
                    cellStyle: {
                        borderLeft: `1px solid ${theme.palette.divider}`
                    },

                    //PAGING
                    paging: false,
                    emptyRowsWhenPaging: false,
                }}
            />
        </>
    )
}