import { alpha, Box, Typography, useTheme } from '@material-ui/core';

export const DashboardContainer = ({ title, invert = false, children }) => {
	const theme = useTheme();
	return (
		<Box
			style={{
				border: `1px solid ${theme.palette.divider}`,
				borderRadius: 10,
				backgroundColor: invert ? theme.palette.background.default : theme.palette.background.paper,
			}}
		>
			<Box
				style={{
					flex: 1,
					padding: 5,
					paddingLeft: 15,
					backgroundColor: invert ? alpha(theme.palette.background.paper, 0.6) : alpha(theme.palette.background.default, 0.6),
					borderBottom: `1px solid ${theme.palette.divider}`,
					borderTopLeftRadius: 10,
					borderTopRightRadius: 10,
				}}
			>
				<Typography style={{ fontSize: '0.9rem' }}>
					<b>{title}</b>
				</Typography>
			</Box>
			<Box style={{ padding: 10 }}>{children}</Box>
		</Box>
	);
};

export default DashboardContainer;
