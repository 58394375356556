import React, { useState } from 'react';

// @MaterialUI
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Card, CardHeader, CircularProgress } from '@material-ui/core/';
import { Grid, Link, TextField, InputAdornment, FormControl } from '@material-ui/core/';
import { ListItemText } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

// @Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NewReleasesIcon from '@material-ui/icons/NewReleases';

//Context
import { useSettingsContext } from '../../../../../../context/settings-context';
import { useNewAppointmentContext } from '../../../../../../context/appointments-new-context';

//Pages
import { NewItemDialog } from '../new-item/';

const filter = createFilterOptions();

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		invoices: {
			color: 'red',
		},
		icon: {
			color: theme.palette.secondary.main,
		},
		editIcon: {
			border: '1px solid black',
		},
		action: {
			display: 'stretch',
			alignItems: 'center !important',
			justifyContent: 'center !important',
		},
		input: {
			[`& fieldset`]: {
				borderRadius: 0,
			},
		},
	})
);

export const SelectOrCreate = ({ config = null, defaultValue = '', preferedSelectionItems = [], disabled = false, isLoading = false }) => {
	const classes = useStyles();
	const theme = useTheme();

	const { lists } = useSettingsContext();
	const { appointment, setAppointment, errors } = useNewAppointmentContext();
	const [openEditing, setOpenEditing] = useState(false);

	const endIcon = (appointment) => {
		if (isLoading) {
			return <CircularProgress size={20} />;
		}
		if (appointment?.[config.tableName] && appointment?.[config.tableName]?._id === 'new')
			return (
				<InputAdornment position="start">
					<NewReleasesIcon
						style={{
							color: theme.palette.warning.main,
							border: '1px solid ',
							borderColor: theme.palette.warning.main,
							borderRadius: 25,
						}}
					/>
				</InputAdornment>
			);

		if (appointment?.[config.tableName] && appointment?.[config.tableName]?._id !== 'new')
			return (
				<InputAdornment position="start">
					<CheckCircleIcon
						style={{
							color: theme.palette.success.main,
							border: '1px solid ',
							borderColor: theme.palette.success.main,
							borderRadius: 25,
						}}
					/>
				</InputAdornment>
			);
	};

	// MAIN
	// --------------------------------------------------------------------------------------------------
	return (
		<Card className={classes.root} elevation={0}>
			<CardHeader
				style={{ marginBottom: 0, paddingBottom: 0 }}
				title={
					<div>
						<Grid container spacing={0}>
							<Grid item xs={12}>
								<Autocomplete
									//GENERAL
									size="small"
									autoComplete={true}
									autoHighlight={true}
									freeSolo={true}
									fullWidth={true}
									disabled={disabled || isLoading}
									//CLEARING
									// disableClearable={false}
									clearOnBlur={true}
									// clearOnEscape={true}

									// STYLE
									style={{ paddingBottom: 0 }}
									// DATA
									value={defaultValue || null}
									options={lists?.[config.listName]}
									// FUNCTIONS
									getOptionLabel={(option) => option?.[config.getOptionLabel]}
									renderOption={(option) => {
										if (option?.inputValue === 'prefered') {
											return (
												<ListItemText
													primary={`* ${option?.[config.getOptionLabel]}`}
													style={{
														color: theme.palette.primary.main,
														margin: 0,
													}}
												/>
											);
										}
										return option?.[config.getOptionLabel];
									}}
									onChange={(event, newValue) => {
										//CLEAR ITEM
										if (!newValue) {
											setAppointment({
												...appointment,
												[config.tableName]: null,
											});
											return 0;
										}

										//NEW ITEM
										if (!newValue?._id) {
											setAppointment({
												...appointment,
												[config.tableName]: {
													_id: 'new',
													[config.getOptionLabel]:
														newValue.inputValue !== 'prefered' ? newValue.inputValue : newValue[config.getOptionLabel],
												},
											});
											return 0;
										}

										// EXISTING ITEM
										if (newValue?._id) {
											setAppointment({
												...appointment,
												[`${config.tableName}ID`]: newValue?._id,
												[config.tableName]: {
													// ...appointment?.[config.tableName],
													...newValue,
												},
											});
											return 0;
										}
									}}
									filterOptions={(options, params) => {
										const filtered = filter(options, params);

										//Add prefered contacts
										preferedSelectionItems?.map((option) => {
											filtered.unshift({
												inputValue: 'prefered',
												_id: option?._id,
												[config.getOptionLabel]: option?.[config.getOptionLabel],
											});
										});

										//Add click to create
										if (params.inputValue !== '' && filtered.length <= 1) {
											filtered.push({
												inputValue: params.inputValue,
												[config.getOptionLabel]: `Click to create "${params.inputValue}"`,
											});
										}
										return filtered;
									}}
									renderInput={(params) => (
										<FormControl style={{ display: 'flex' }}>
											<TextField
												{...params}
												label={config.label}
												placeholder={config.placeholder}
												variant="outlined"
												fullWidth={true}
												color="secondary"
												error={errors?.main?.[config.tableName] || false}
												InputLabelProps={{
													shrink: true,
												}}
												className={classes.input}
												InputProps={{
													...params.InputProps,
													startAdornment: endIcon(appointment),
												}}
											/>
										</FormControl>
									)}
								/>
							</Grid>

							{!!appointment?.[config.tableName] && (
								<Grid container justifyContent="flex-end">
									{!disabled && !isLoading && (
										<Link
											component="button"
											variant="caption"
											size="small"
											color="textSecondary"
											style={{ maring: 0 }}
											onClick={() => setOpenEditing(true)}
										>
											Edit
										</Link>
									)}
								</Grid>
							)}
							{errors?.main?.[config.tableName] && (
								<Typography variant="caption" color="error">
									{errors?.main?.[config.tableName]}
								</Typography>
							)}
						</Grid>

						<NewItemDialog
							table={config.tableName}
							contactId={config.tableName === 'contact' ? appointment?.[config.tableName]?._id : null}
							vehicleId={config.tableName === 'vehicle' ? appointment?.[config.tableName]?._id : null}
							businessId={config.tableName === 'business' ? appointment?.[config.tableName]?._id : null}
							open={openEditing}
							setOpen={setOpenEditing}
							isEditing={true}
						/>
					</div>
				}
			/>
		</Card>
	);
};
export default SelectOrCreate;
