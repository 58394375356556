import { createContext, useContext } from 'react';

const QuoteContext = createContext({
	quote: null,
	refetch: () => {},
});

export function useQuoteContext() {
	return useContext(QuoteContext);
}

export default QuoteContext;
