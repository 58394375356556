import { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// MaterialUI
import { Grid } from '@material-ui/core';

// Shared Components
import { Page } from '../../app/components';
import { Loading } from '../../app/components/loading';
import { AppointmentsView } from './views/appointments';
import { AppointmentNew } from './views/new';

// Components
// import { AppointmentMultiPDF } from './views/appointments/components/appointments-print-multi-pdf';

// Config & Context
import AppointmentsContext from '../../context/appointments-context';

// Query
import { useQuery, useQueryClient } from 'react-query';
import { SearchAppointments } from '../../app/utils/data';
import { WorkorderMulitplePrintButton } from 'pages/workorders/component/print-muliple';

export default function Appointments() {
	const queryClient = new useQueryClient();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	let viewName = searchParams.get('view') || 'active';
	const { search } = useLocation();
	const params = useMemo(() => new URLSearchParams(search), [search]);
	let history = useHistory();

	//* context state
	const [state, setState] = useState({
		appointments: null,
		searchCriteria: null,
		view: 'today',
		setSearchCriteria: () => {},
		setView: () => {},
		searchQuery: () => {},
	});

	//* state
	const [open, setOpen] = useState(false);
	const [reload, setReload] = useState(true);
	const [searchCriteria, setSearchCriteria] = useState(null);
	const [view, setInternalView] = useState(viewName || 'active');
	const [isReloading, setisReloading] = useState(false);

	//* data
	const { data, isLoading, refetch } = useQuery(['appointments', searchCriteria], SearchAppointments, {
		refetchOnWindowFocus: false,
	});

	//* functions
	// Build/update search queries
	const searchQuery = async (source, value) => {
		if (source === null || value === null || value.length === 0) {
			delete searchCriteria[source];
			if (Object.keys(searchCriteria).length === 0) {
				setSearchCriteria(null);
				await setInternalView('active');
				history.replace({
					pathname: '/appointments',
					search: '?view=active',
				});
			}
		} else {
			history.replace({
				pathname: '/appointments',
				search: '?view=search',
			});
			setInternalView('search');
			await setSearchCriteria({
				...searchCriteria,
				[source]: value,
			});
		}

		//refresh screen
		setReload(true);
	};

	const setView = async (newView) => {
		await setInternalView(newView);
		setReload(true);
	};

	// refresh
	const refreshPage = async () => {
		setisReloading(true);
		setReload(true);
		await refetch();
		setisReloading(false);
		queryClient.refetchQueries(['appointments']);
	};

	//* state
	useEffect(() => {
		if (reload)
			if (!searchCriteria || !view) {
				setReload(false);
			}
	}, [searchCriteria, view]);

	useEffect(() => {
		if (data !== state?.appointments || reload) {
			setState({
				appointments: data,
				searchCriteria,
				view,
				setSearchCriteria,
				setView,
				searchQuery,
				refreshPage,
			});
		}
	}, [data, reload]);

	//* error
	if (data?.status === 'error') return <p>Error Occured</p>;
	if (data?.status === 'access-denied') return <p>Access Denied</p>;
	if (isLoading) return <Loading />;

	//- MAIN
	return (
		<AppointmentsContext.Provider value={state}>
			<Page
				heading="Appointments"
				refreshPageFn={refreshPage}
				subheadingIsControl={true}
				actionButtons={
					<Grid container alignItems="center" justifyContent="flex-end">
						<AppointmentNew
							open={open}
							setOpen={setOpen}
							iconSize="large"
							type="appointment"
							refetchFn={refreshPage}
							initialDate={params.get('date') || searchCriteria?.bookingDateFrom || new Date()}
						/>

						<WorkorderMulitplePrintButton />
					</Grid>
				}
			>
				{isReloading && <Loading />}

				{!isLoading && !isReloading && <AppointmentsView />}
			</Page>
		</AppointmentsContext.Provider>
	);
}
