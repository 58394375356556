// MaterialUi
import { Typography, Grid, OutlinedInput } from '@material-ui/core';
import { TextBoxLabel } from '../card/label';

//Context
import { useSettingsContext } from '../../../context/settings-context';

//Masks
import MaskedInput from 'react-text-mask';
import emailMask from 'text-mask-addons/dist/emailMask';
import NumberFormat from 'react-number-format';

function YearMask(props) {
	const { inputRef, ...other } = props;
	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={[/\d/, /\d/, /\d/, /\d/]}
			// showMask
			guide={true}
		/>
	);
}

function PhoneMask(props) {
	const { settings } = useSettingsContext();
	const { inputRef, ...other } = props;

	let mask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
	if (settings.locale.locale === 'en-AU') mask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={mask}
			// showMask
			guide={true}
		/>
	);
}

function EmailMask(props) {
	const { inputRef, ...other } = props;
	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={emailMask}
			showMask
			guide={true}
		/>
	);
}

function NumberMask(props) {
	const { onChange, inputRef, ...other } = props;

	return <NumberFormat {...other} getInputRef={inputRef} thousandSeparator isNumericString decimalScale={0} onValueChange={onChange} />;
}

function CurrencyMask(props) {
	const { settings } = useSettingsContext();
	const { onChange, inputRef, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			thousandSeparator={true}
			decimalScale={0}
			fixedDecimalScale={true}
			prefix={`${settings.locale?.currencySymbol} ` || 'R '}
			onValueChange={onChange}
		/>
	);
}

export const KeyPairInput = ({
	label,
	name,
	defaultValue,
	onChange,
	type = 'text',
	focus = false,
	error = false,
	disabled = false,
	required = false,
}) => {
	let mask = 'input';
	if (type === 'phone') mask = PhoneMask;
	if (type === 'email') mask = EmailMask;
	if (type === 'number') mask = NumberMask;
	if (type === 'year') mask = YearMask;
	if (type === 'currency') mask = CurrencyMask;

	return (
		<>
			<Grid item xs={4}>
				<TextBoxLabel label={label} required={required} />
			</Grid>
			<Grid item xs={8}>
				<OutlinedInput
					name={name}
					onChange={onChange}
					value={defaultValue}
					autoFocus={focus}
					error={error ? true : false}
					disabled={disabled}
					multiline={type === 'multiline' ? true : false}
					rows={type === 'multiline' ? 6 : null}
					color="secondary"
					size="small"
					margin="dense"
					variant="outlined"
					fullWidth={true}
					inputComponent={mask}
				/>
				{error && (
					<Typography variant="caption" color="error">
						{error}
					</Typography>
				)}
			</Grid>
		</>
	);
};

export const Input = ({ name, defaultValue, onChange, type = 'text', focus = false, error = false, disabled = false }) => {
	let mask = 'input';
	if (type === 'phone') mask = PhoneMask;
	if (type === 'email') mask = EmailMask;
	if (type === 'number') mask = NumberMask;
	if (type === 'year') mask = YearMask;
	if (type === 'currency') mask = CurrencyMask;

	return (
		<Grid item xs={12}>
			<OutlinedInput
				name={name}
				onChange={onChange}
				value={defaultValue}
				autoFocus={focus}
				error={error ? true : false}
				disabled={disabled}
				multiline={type === 'multiline' ? true : false}
				rows={type === 'multiline' ? 6 : null}
				color="secondary"
				size="small"
				margin="dense"
				variant="outlined"
				fullWidth={true}
				inputComponent={mask}
			/>
			{error && (
				<Typography variant="caption" color="error">
					{error}
				</Typography>
			)}
		</Grid>
	);
};
