import React from 'react';
// Database
import { QueryClient, QueryClientProvider } from 'react-query';
// Security
import { useAuth0 } from '@auth0/auth0-react';
// @Material
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import colours from './colour-schemes.json';
// Routes
import { Router, Route, Switch } from 'react-router-dom';
import Routes from './pages/Routes';
import history from './app/utils/history';
// Pages
import Layout from './theme/Layout';
import Home from './pages/home/Home';
// Components & Utls
import { MainLoading } from './app/components/loading';
import config from './config.json';
import PublicApprovalPage from 'pages/public/approval';
import PublicInvoicesPage from 'pages/public/invoice';
import process from 'process';
import { useMediaQuery, useTheme } from '@material-ui/core';
const domain = process.env.NODE_ENV === 'production' ? config.api.domain : config.api.domain_dev;

// APP
const App = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const queryClient = new QueryClient();
	const { isLoading, error } = useAuth0();

	// THEME
	const customTheme = {
		type: 'dark',
		...colours.uptune,
	};

	const appliedTheme = createTheme({
		palette: customTheme,
		typography: {
			fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
		},
		overrides: {
			MuiTypography: {
				body1: { fontSize: '0.765rem' },
				body2: { fontSize: '0.765rem' },
				subtitle1: { fontSize: '0.865rem' },
				subtitle2: { fontSize: '0.765rem' },
			},
			MuiTooltip: {
				tooltip: {
					fontSize: '1rem',
					color: 'white',
					backgroundColor: customTheme.secondary.main,
				},
				arrow: {
					'&:before': {
						border: `1px solid ${customTheme.secondary.main}`,
					},
					color: customTheme.secondary.main,
				},
			},
		},
	});

	// ERROR HANDLING - Reviewed 2
	if (error) return <p>Authentication error. Try logging out and back in again.</p>;
	if (isLoading) return <MainLoading />;

	console.info('WELCOME TO GLOVEBOX');
	console.info('developed by: Alan Carstens (alan.carstens@ac2.co.za)');
	console.info('server:', domain, process.env.NODE_ENV);
	console.info('isMobile:', isMobile);

	// Main Application
	return (
		<Router history={history}>
			<Switch>
				<Route path="/" exact component={Home} />
				<Route path="/public/approval" exact component={PublicApprovalPage} />
				<Route path="/public/invoice" exact component={PublicInvoicesPage} />

				<ThemeProvider theme={appliedTheme}>
					<CssBaseline />

					<QueryClientProvider client={queryClient}>
						<Layout>
							<Routes />
						</Layout>
					</QueryClientProvider>
				</ThemeProvider>
			</Switch>
		</Router>
	);
};

export default App;
