import { useAuth0 } from '@auth0/auth0-react'

export const CheckAccess = (role) => {
    const { user } = useAuth0();

    if (user?.["https://actinium.azurewebsites.net/roles"]?.roles?.includes('admin')) {
        return true
    }

    if (user?.["https://actinium.azurewebsites.net/roles"]?.roles?.includes(role)) {
        return true
    } else {
        return false
    }
}

export default CheckAccess