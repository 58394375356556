import React, { useState } from 'react'

// Material
import { Grid } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';


//Context
import { useSettingsContext } from '../../../context/settings-context'

//Pages
import { TextBoxLabel } from '../../../app/components/card/label'
import { Section } from '../../../app/components/card/section'

export const WorkingDaysSettings = () => {
    const { settings } = useSettingsContext()

    const [newSettings, setNewSettings] = useState({
        blockedRecurringDays: settings.blockedRecurringDays,
    })

    const [formats, setFormats] = useState(settings.blockedRecurringDays || ['0', '6']);

    const handleFormat = (event, newFormats) => {
        setFormats(newFormats);
    };

    const onChange = (e) => {
        setNewSettings({
            ...newSettings,
            [e.target.name]: e.target.value
        })
    }

    // const onBlockedDatesChange = (e) => {
    //     setNewSettings({
    //         ...newSettings,
    //         [e.target.name]: e.target.value
    //     })
    // }

    return (
        <Grid container alignItems='center' spacing={1}>
            {/* COMPANY DETAILS */}
            <Grid item xs={12}><Section text="Blocked days/dates" /></Grid>

            <Grid item xs={4}><TextBoxLabel label='Non working days' /></Grid>
            <Grid item xs={8}>
                <ToggleButtonGroup value={formats} onChange={handleFormat}>
                    <ToggleButton value="1">
                        <p>M</p>
                    </ToggleButton>
                    <ToggleButton value="2">
                        <p>T</p>
                    </ToggleButton>
                    <ToggleButton value="3">
                        <p>W</p>
                    </ToggleButton>
                    <ToggleButton value="4">
                        <p>T</p>
                    </ToggleButton>
                    <ToggleButton value="5">
                        <p>F</p>
                    </ToggleButton>
                    <ToggleButton value="6">
                        <p>S</p>
                    </ToggleButton>
                    <ToggleButton value="0">
                        <p>S</p>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>


            <Grid item xs={4}><TextBoxLabel label='Blocked Dates' /></Grid>
            <Grid item xs={8}>
                <Grid container>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            autoOk={true}
                            variant={"inline"}
                            format="ddd, DD/MM/YYYY"
                            size="small"
                            inputVariant="outlined"
                            allowKeyboardControl={true}
                            onChange={(date) => onChange({ target: { name: 'blockedDatesValue', value: date } })}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>

            </Grid>
        </Grid>
    )
}