import { createContext, useContext } from 'react';

const VehicleContext = createContext({
    vehicle: null,
});

export function useVehicleContext() {
    return useContext(VehicleContext);
}

export default VehicleContext;