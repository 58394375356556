import { httpGET, httpPOST } from './data-handler';

// GET
export const GetWorkorderById = async (req) => await httpGET({ path: `workorder/${req.queryKey[1]}`, data: null });
export const GetWorkorderWarranty = async (req) => await httpGET({ path: `workorder/${req.queryKey[1]}/warranty`, data: null });

// POST
export const DeleteWorkorder = async (data) => await httpPOST({ path: `workorder/delete`, data });
export const DeleteWorkorderAndReinstateBooking = async (data) => await httpPOST({ path: `workorder/reinstate`, data });

export const UpdateWorkorderWarranty = async (data) => await httpPOST({ path: `workorder/update/warranty`, data: data });
export const CreateCostItem = async (data) => await httpPOST({ path: `workorder/costs/create`, data });
export const UpdateBatchCostItems = async (data) => await httpPOST({ path: `workorder/costs/update`, data });
export const DeleteCostItem = async (data) => await httpPOST({ path: `workorder/cost/delete`, data });
export const UpdateCostItem = async (data) => await httpPOST({ path: `workorder/cost/update`, data });
export const ImportQuoteToWorkorder = async (data) => await httpPOST({ path: 'workorder/import-quote', data: data });

// groups
export const CreateWorkorderGroupHeader = async (data) => await httpPOST({ path: `workorder/costs/addgroup`, data });
export const DeleteWorkorderGroup = async (data) => await httpPOST({ path: 'workorder/costs/deletegroup', data: data });

// templates
export const AssignWorkorderTemplate = async (data) => await httpPOST({ path: 'workorder/costs/assign', data: data });
export const CreateWorkorderGroupHeaderSaveAsTemplate = async (data) => await httpPOST({ path: `workorder/costs/template`, data });

export const CreateWorkorder = async (data) =>
	await httpPOST({
		path: `workorder/create`,
		data: {
			workorderInstructions: data.workorderInstructions,
			workorderOdometer: data.workorderOdometer,
			workorderInsuranceCompany: data.workorderInsuranceCompany,
			workorderInsurancePolicy: data.workorderInsurancePolicy,
			workorderInsuranceClaim: data.workorderInsuranceClaim,
			workorderInsuranceAuthorization: data.workorderInsuranceAuthorization,
			bookingID: data.bookingID,
			...(data.quoteFirst && { quoteFirst: data.quoteFirst }),
		},
	});

export const NewWorkorder = async (data) => await httpPOST({ path: `workorder`, data: data });

export const UpdateWorkorderStatus = async (data) =>
	await httpPOST({
		path: `workorder/update/status`,
		data: {
			_id: data._id,
			workorderStatus: data.workorderStatus,
		},
	});

export const UpdateWorkorderInsurance = async (data) =>
	await httpPOST({
		path: `workorder/update/insurance`,
		data: {
			_id: data._id,
			workorderInsuranceCompany: data.workorderInsuranceCompany,
			workorderInsurancePolicy: data.workorderInsurancePolicy,
			workorderInsuranceClaim: data.workorderInsuranceClaim,
			workorderInsuranceAuthorization: data.workorderInsuranceAuthorization,
		},
	});

export const UpdateWorkorderNextServiceReminder = async (data) =>
	await httpPOST({
		path: `workorder/update/nextservice`,
		data: {
			_id: data._id || data.id,
			workorderNextServiceReminder: data.workorderNextServiceReminder,
		},
	});

export const UpdateWorkorderGeneralInformation = async (data) =>
	await httpPOST({
		path: `workorder/update/general`,
		data: {
			id: data._id,
			workorderOdometer: data.workorderOdometer,
			workorderDate: data.workorderDate,
			workorderStatus: data.workorderStatus,
			workorderInstructions: data.workorderInstructions,
			quoteFirst: data.quoteFirst,
		},
	});
