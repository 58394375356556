import { Card } from '../components/card'
import {
    GetWorkorderById,
    UpdateWorkorderInsurance,
} from '../utils/data';

export const InsuranceCard = ({ workorderId, compact = false }) => {
    // --------------------------------------------------------------------------------------------------
    // MAIN
    // --------------------------------------------------------------------------------------------------

    // Shows the standard contact form
    return (
        <Card
            id={workorderId}
            queryFn={GetWorkorderById}
            mutationFn={UpdateWorkorderInsurance}
            primaryKey='workorderInsuranceCompany'
            reassign={false}
            compact={compact}
            template={{
                heading: 'Insurance',
                returnKey: 'workorder',
                fields: [
                    {
                        name: 'Insurer',
                        id: 'workorderInsuranceCompany',
                        canEdit: true,
                        showmore: false,
                    },
                    {
                        name: 'Policy #',
                        id: 'workorderInsurancePolicy',
                        canEdit: true,
                        showmore: false,
                    },
                    {
                        name: 'Claim #',
                        id: 'workorderInsuranceClaim',
                        canEdit: true,
                        showmore: false,
                    },
                    {
                        name: 'Authorization #',
                        id: 'workorderInsuranceAuthorization',
                        canEdit: true,
                        showmore: false,
                    },
                ],
            }}
        />
    )
}

export default InsuranceCard