import React from 'react';
import moment from 'moment';

import { Grid, Typography } from '@material-ui/core';

export const DurationDescription = ({ value, redIfOld = false, daysOnly = false }) => {
	return (
		<>
			{moment(new Date()).startOf('day').diff(moment(value).startOf('day'), 'days') === 0 && (
				<Grid item>
					<Typography variant="caption" color="textSecondary" display="block">
						(today)
					</Typography>
				</Grid>
			)}

			{moment(new Date()).startOf('day').diff(moment(value).startOf('day'), 'days') === 1 && (
				<Grid item>
					<Typography variant="caption" color={redIfOld ? 'error' : 'textSecondary'} display="block">
						(1 day ago)
					</Typography>
				</Grid>
			)}

			{daysOnly && moment(new Date()).startOf('day').diff(moment(value).startOf('day'), 'days') > 1 && (
				<Grid item>
					<Typography variant="caption" color={redIfOld ? 'error' : 'textSecondary'} display="block">
						({moment(new Date()).diff(moment(value), 'day')} days ago)
					</Typography>
				</Grid>
			)}

			{!daysOnly &&
				moment(new Date()).startOf('day').diff(moment(value).startOf('day'), 'days') > 1 &&
				moment(new Date()).startOf('day').diff(moment(value).startOf('day'), 'months') < 1 && (
					<Grid item>
						<Typography variant="caption" color={redIfOld ? 'error' : 'textSecondary'} display="block">
							({moment(new Date()).diff(moment(value), 'day')} days ago)
						</Typography>
					</Grid>
				)}

			{!daysOnly && moment(new Date()).startOf('day').diff(moment(value).startOf('day'), 'months') === 1 && (
				<Grid item>
					<Typography variant="caption" color={redIfOld ? 'error' : 'textSecondary'} display="block">
						(a month ago)
					</Typography>
				</Grid>
			)}

			{!daysOnly &&
				moment(new Date()).startOf('day').diff(moment(value).startOf('day'), 'months') > 1 &&
				moment(new Date()).startOf('day').diff(moment(value).startOf('day'), 'months') < 12 && (
					<Grid item>
						<Typography variant="caption" color={redIfOld ? 'error' : 'textSecondary'} display="block">
							({moment(new Date()).startOf('day').diff(moment(value).startOf('day'), 'months')} months ago)
						</Typography>
					</Grid>
				)}

			{!daysOnly &&
				moment(new Date()).startOf('day').diff(moment(value).startOf('day'), 'months') > 11 &&
				moment(new Date()).startOf('day').diff(moment(value).startOf('day'), 'months') < 24 && (
					<Grid item>
						<Typography variant="caption" color={redIfOld ? 'error' : 'textSecondary'} display="block">
							(1 year ago)
						</Typography>
					</Grid>
				)}

			{!daysOnly && moment(new Date()).startOf('day').diff(moment(value).startOf('day'), 'months') > 23 && (
				<Grid item>
					<Typography variant="caption" color={redIfOld ? 'error' : 'textSecondary'} display="block">
						(2+ year ago)
					</Typography>
				</Grid>
			)}
		</>
	);
};

export default DurationDescription;
