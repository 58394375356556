// data
import { useState } from 'react';
import { useQuery } from 'react-query';
import { GetContact } from '../../../../../../app/utils/data';
// material
import { Grid } from '@material-ui/core';
import ContactMailIcon from '@material-ui/icons/ContactMail';
// components
import { KeyPairInput } from '../../../../../../app/components/key-pair';
import { useNewAppointmentContext } from '../../../../../../context/appointments-new-context';
import { Loading } from '../../../../../../app/components/loading';

export const NewContact = ({ isNew = false, isNewInitialItem = '', contactId = null }) => {
	const { setAppointment, errors, isSaving } = useNewAppointmentContext();

	const { data, isLoading } = useQuery(['contact', contactId], GetContact, {
		enabled: !!contactId && !isNew,
		refetchOnWindowFocus: false,
		onSuccess: (data) => {
			setData(data);
		},
	});

	const [_data, setData] = useState(isNew ? { contact: isNewInitialItem } : { contact: data?.contact });

	const handleChange = (e) => {
		if (e.target.value === '') e.target.value = null;
		if (e.target.value === '___-___-____') e.target.value = null;

		setAppointment((appointment) => ({
			...appointment,
			contact: {
				...appointment?.contact,
				[e.target.name]: e.target.value,
			},
		}));

		setData((appointment) => ({
			...appointment,
			contact: {
				...appointment?.contact,
				[e.target.name]: e.target.value,
			},
		}));
	};

	//* Error Handling
	if (contactId && !isNew) {
		if (data?.status === 'error') return <p>Error Occured</p>;
		if (data?.status === 'access-denied') return <p>Access Denied</p>;

		if (isLoading) return <Loading />;
	}

	return (
		<Grid container alignItems="center" justifyContent="center">
			{/* NAME */}
			<Grid item xs={2}>
				<Grid container alignItems="flex-start" justifyContent="center">
					<Grid item xs={12}>
						<ContactMailIcon style={{ fontSize: '40px' }} />
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={10}>
				<Grid container alignItems="center" spacing={2}>
					<KeyPairInput
						label="Name"
						name="contactFullname"
						defaultValue={_data?.contact?.contactFullname || ''}
						onChange={(e) => handleChange(e)}
						error={errors?.contact?.contactFullname || false}
						disabled={isSaving}
					/>

					<KeyPairInput
						label="Email"
						name="contactEmail"
						type="email"
						focus={true}
						defaultValue={_data?.contact?.contactEmail || ''}
						onChange={(e) => handleChange(e)}
						error={errors?.contact?.contactEmail || false}
						disabled={isSaving}
					/>

					<KeyPairInput
						label="Mobile"
						name="contactMobile"
						type="phone"
						defaultValue={_data?.contact?.contactMobile || ''}
						onChange={(e) => handleChange(e)}
						error={errors?.contact?.contactMobile || false}
						disabled={isSaving}
					/>

					<KeyPairInput
						label="Phone"
						name="contactPhone"
						type="phone"
						defaultValue={_data?.contact?.contactPhone || ''}
						onChange={(e) => handleChange(e)}
						error={errors?.contact?.contactPhone || false}
						disabled={isSaving}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};
