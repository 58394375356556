import moment from 'moment';
import { httpGET, httpPOST } from './data-handler';

// GET
// -------------------

// [appointment]
export const GetAppointment = async (req) => await httpGET({ path: `booking/${req.queryKey[1]}`, data: null });

// [appointments_specific_days]
export const GetOpenAppointmentsBetweenDates = async (req) =>
	await httpGET({
		path: `bookings/${moment(req.queryKey[1].from).format('YYYY-MM-DD')}/${moment(req.queryKey[1].to).format('YYYY-MM-DD')}`,
		data: null,
	});

// POST
// -------------------
export const CreateAppointment = async (data) => await httpPOST({ path: `booking/create`, data });
export const DeleteAppointment = async (req) => await httpPOST({ path: `booking/delete`, data: { bookingID: req.id } });
export const UpdateAppointment = async (data) =>
	await httpPOST({
		path: `booking/update`,
		data,
	});
export const UpdateAppointmentStatus = async (req) =>
	await httpPOST({
		path: `booking/status`,
		data: {
			_id: req.id,
			bookingStatus: req.bookingStatus,
			bookingCancelReason: req.bookingCancelReason || null,
		},
	});
