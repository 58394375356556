import { useState, useEffect } from 'react';

// import moment from 'moment'

//Query
import { useQuery, useMutation, useQueryClient } from 'react-query';

//@Material
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Checkbox, Button } from '@material-ui/core';

//@Material Icons
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import UndoIcon from '@material-ui/icons/Undo';

// Controls
import { PopOut } from '../popout';
import HelpBox from '../help';
import { TextBoxLabel } from './label';
import { MileageEdit } from './textbox';
import { AddressEdit } from './textbox';
import { MultiLineEdit } from './multiline';
import { SelectOrCreate } from './select-create';
import { DateEdit } from './date';
// import { WorkorderStatusBadge } from './status'

import { KeyPairInput } from '../key-pair';

const MODAL_WIDTH = 480;

const useStyles = makeStyles((theme) => ({
	// MODAL
	root: {
		flexGrow: 1,
		paddingBottom: 75,
		paddingLeft: 20,
		paddingRight: 20,
		paddingTop: 20,
	},
	modalFooter: {
		paddingLeft: 20,
		paddingRight: 20,
		paddingTop: 10,
		paddingBottom: 10,

		height: 60,
		width: MODAL_WIDTH + 20,

		position: 'fixed',
		bottom: 0,

		borderTop: '1px solid',
		borderTopColor: theme.palette.divider,
		backgroundColor: theme.palette.background.default,
		zIndex: 999,
	},

	//BUTTONS
	saveButton: {
		backgroundColor: theme.palette.success.dark,
		borderColor: theme.palette.success.main,
		borderRadius: 0,
	},
	cancelButton: {
		borderColor: theme.palette.divider,
		borderRadius: 0,
	},
	assignButton: {
		backgroundColor: theme.palette.warning.dark,
		borderColor: theme.palette.warning.main,
		borderRadius: 0,
	},
}));

export const EditData = ({
	id,
	template,
	queryFn,
	mutationFn,
	createFn,
	reassign,
	reassignFn,
	reassignGetFn,
	reassignConfig,
	refetchQueryNames,
	refetchQueryFn = () => {},
	helpText,
}) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [isNew, setIsNew] = useState(false);
	const [reassigning, setReassigning] = useState(false);
	const [reassignToBeSave, setReassignToBeSave] = useState(false);
	const [localReassignConfig, setLocalReassignConfig] = useState(reassignConfig);

	//------------------------------------------------------------------------
	// DATA
	//------------------------------------------------------------------------
	const queryClient = useQueryClient();
	const [uiData, setUIData] = useState();

	// Read
	const { data } = useQuery([template.returnKey, id], queryFn, { enabled: !!id && open, refetchOnWindowFocus: false });

	useEffect(() => {
		if (!data?.[template.returnKey]) {
			setIsNew(true);
		}

		if (!loaded && data) {
			setUIData(data);
			setLoaded(true);
		}
	}, [data, loaded]);

	useEffect(() => {
		if (!loaded && data !== uiData) {
			setLoaded(false);
		}
	}, [data]);

	//* mutations
	// Update
	const update = useMutation(mutationFn, {
		onSuccess: () => {
			queryClient.refetchQueries([template.returnKey, id], { active: true });

			if (refetchQueryNames) queryClient.refetchQueries(refetchQueryNames, { active: true });
			refetchQueryFn();
		},
	});

	// Create
	const create = useMutation(createFn, {
		onSuccess: (newEntityData) => {
			setUIData(newEntityData);
			setReassignToBeSave(true);
			setReassigning(false);

			if (refetchQueryNames) queryClient.refetchQueries(refetchQueryNames, { active: true });
			refetchQueryFn();
		},
	});

	// Re-Assign
	const assign = useMutation(reassignFn, {
		onSuccess: () => {
			if (refetchQueryNames) queryClient.refetchQueries(refetchQueryNames, { active: true });
			refetchQueryFn();
		},
	});

	//* handlers
	const assignClick = async () => {
		assign.mutate({ reassignConfig: localReassignConfig });
		setOpen(false);
		refetchQueryFn();
	};

	const saveClick = async () => {
		if (!reassignToBeSave) {
			await update.mutateAsync(uiData?.[template.returnKey]);
		}

		if (reassignToBeSave) {
			// Set new entity to b updated
			let updatedEntity = uiData?.[template.returnKey];
			// Set new config for re-assignment call
			let updatedConfig = { ...reassignConfig, value: updatedEntity._id };

			assign.mutate({ reassignConfig: updatedConfig });
			update.mutate(updatedEntity);
		}

		setReassignToBeSave(false);
		setOpen(false);
		refetchQueryFn();
	};

	const cancelClick = async () => {
		//Revert dayta back to commited values
		setOpen(false);
		setLoaded(false);
		// setIsNew(false);
		setReassigning(false);
		setReassignToBeSave(false);
		setLocalReassignConfig(null);
		setUIData(data);
	};

	const onChange = (e) => {
		setUIData({
			[template.returnKey]: {
				...uiData?.[template.returnKey],
				[e.target.name]: e.target.value,
			},
		});
	};

	const handleReassignment = async (type, newEntity) => {
		// If it's a new contact create it and then assign it
		if (type === 'new') {
			let newItem = await create.mutate(newEntity);
			const newConfig = { ...localReassignConfig, value: newItem };
			setLocalReassignConfig(newConfig);
			setIsNew(false);
		}

		// If this is a existing contact then just assign it
		if (type === 'existing') {
			const newConfig = { ...localReassignConfig, value: newEntity._id };
			setLocalReassignConfig(newConfig);
		}
		// refetchQueryFn();
	};

	// Set Header text
	let header = `Editing ${template.heading}`;
	if (reassigning) header = `Editing ${template.heading}`;
	if (isNew) header = `Assign ${template.heading}`;

	// Set Save Button  text
	let saveButtonText = 'Save';
	if (!reassigning && !isNew)
		if (reassignToBeSave) {
			saveButtonText = 'Save and Assign';
		} else {
			saveButtonText = 'Save';
		}
	if (reassigning || isNew)
		if (isNew) {
			saveButtonText = 'Re-assign';
		} else {
			saveButtonText = 'Assign';
		}

	return (
		<PopOut
			// GENERAL
			heading={header}
			tooltip={isNew ? `Assign ${template.heading}` : `Edit ${template.heading}`}
			// STYLE
			icon={isNew ? <AddIcon style={{ fontSize: '1.2rem' }} /> : <EditIcon style={{ fontSize: '1.2rem' }} />}
			iconSize="small"
			// STATE
			open={open}
			setOpen={setOpen}
			disabled={false}
			// FOOTER
			isSaving={false}
			saveButtonText={saveButtonText}
			saveButtonFn={reassigning || isNew ? assignClick : saveClick}
			cancelButtonText="Cancel"
			cancelButtonFn={cancelClick}
		>
			{/* ------------------------------------------------------------------------ */}
			{/* BODY */}
			{/* ------------------------------------------------------------------------ */}

			{/* <div style={{ margin: 3, paddingLeft: 20, paddingRight: 20 }}> */}
			<div className={classes.root}>
				<Grid container alignItems="center" justifyContent="flex-start">
					{/* HELP */}
					{helpText && <HelpBox text={helpText} />}

					{/* SELECT OR CREATE CONTROL */}
					{/* {(reassigning || (reassigning && isNew)) && ( */}
					{(reassigning || (reassign && isNew)) && (
						<Grid item xs={12} lg={12}>
							<SelectOrCreate
								id={reassignConfig?.control?.returnField}
								queryListBoxFn={reassignGetFn}
								returnField={reassignConfig?.control?.returnField}
								template={template}
								config={{
									label: reassignConfig?.control?.label,
									placeholder: reassignConfig?.control?.placeholder,
									listboxDisplayValue: reassignConfig?.control?.listboxDisplayValue,
								}}
								handleReassignment={handleReassignment}
							/>
						</Grid>
					)}

					{/* STANDARD CONTROLS */}
					{!reassigning &&
						!isNew &&
						template.fields.map((item, i) => {
							//Exit if the item is set to be readonly
							if (item?.canEdit === false) return null;

							// Set initial value
							let initialValue = uiData?.[template.returnKey]?.[item?.id];
							if (item?.level === 1) {
								try {
									initialValue = uiData?.[template.returnKey];
								} catch {
									initialValue = '';
								}
							} else {
								try {
									initialValue = uiData?.[template.returnKey]?.[item?.id];
								} catch {
									initialValue = '';
								}
							}

							// MULTILINE CONTROL
							if (item?.type === 'multiline') {
								return (
									<Grid container key={`multiline_${i}`}>
										<Grid item xs={12} md={4} key={`title_${item?.id}`}>
											<TextBoxLabel label={item?.name} />
										</Grid>
										<Grid item xs={12} md={8} key={`value_${item?.id}`}>
											<MultiLineEdit item={item} initialValue={initialValue} onChange={onChange} />
										</Grid>
									</Grid>
								);
							}

							// DATE CONTROL
							if (item?.type === 'date') {
								return (
									<Grid container key={`date_${i}`} alignItems="center">
										<Grid item xs={12} sm={4} key={`title_${item?.id}`}>
											<TextBoxLabel label={item?.name} />
										</Grid>
										<Grid item xs={12} sm={8} key={`value_${item?.id}`}>
											<DateEdit field={item} selectedDate={initialValue} onChange={onChange} />
										</Grid>
									</Grid>
								);
							}

							// MILEAGE CONTROL
							if (item?.type === 'mileage') {
								return (
									<Grid container key={`multiline_${i}`} alignItems="center">
										<Grid item xs={12} sm={4} key={`title_${item?.id}`}>
											<TextBoxLabel label={item?.name} />
										</Grid>
										<Grid item xs={12} sm={8} key={`value_${item?.id}`}>
											<MileageEdit item={item} initialValue={initialValue} onChange={onChange} />
										</Grid>
									</Grid>
								);
							}

							// CHCEKBOX
							if (item?.type === 'checkbox') {
								return (
									<Grid container key={`multiline_${i}`} alignItems="center">
										<Grid item xs={12} sm={4} key={`title_${item?.id}`}>
											<TextBoxLabel label={item?.name} />
										</Grid>
										<Grid item xs={12} sm={8} key={`value_${item?.id}`}>
											{/* <MileageEdit item={item} initialValue={initialValue} onChange={onChange} /> */}
											<Checkbox
												initialValue={initialValue}
												onChange={(e) => {
													onChange({
														target: {
															name: item?.id,
															value: e.target.checked,
														},
													});
												}}
											/>
										</Grid>
									</Grid>
								);
							}

							// ADDRESS CONTROL
							if (item?.type === 'address') {
								return (
									<Grid container key={`address_${i}`} alignItems="center">
										<Grid item xs={12} sm={4} key={`title_${item?.id}`}>
											<TextBoxLabel label={item?.name} />
										</Grid>
										<Grid item xs={12} sm={8} key={`value_${item?.id}`}>
											<AddressEdit item={item} initialValue={initialValue} onChange={onChange} />
										</Grid>
									</Grid>
								);
							}

							// Divider
							if (item?.type === 'divider') {
								return (
									<Grid container key={`address_${i}`} alignItems="center">
										{/* <Divider /> */}
									</Grid>
								);
							}

							// TEXT BOX CONTROL
							return (
								<Grid container key={`text_${i}`} alignItems="center" style={{ margin: 5 }}>
									<KeyPairInput
										label={item?.name}
										name={item?.id}
										type={item?.type}
										defaultValue={initialValue}
										onChange={onChange}
									/>
								</Grid>
							);
						})}
				</Grid>
				{/* RE-ASSIGN BUTTON */}
				{reassign && reassignConfig && !reassigning && !isNew && !reassignToBeSave && (
					<Grid container direction="row" justifyContent="flex-end" alignItems="center" style={{ paddingTop: 15 }}>
						<Grid item>
							<Button color="textPrimary" size="small" startIcon={<UndoIcon />} onClick={() => setReassigning(true)}>
								Reassign {template.heading}
							</Button>
						</Grid>
					</Grid>
				)}
			</div>
		</PopOut>
	);
};

export default EditData;
