//Material
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

//Pages
import Comments from '../../../../components/comments';

const useStyles = makeStyles((theme) => ({
	headerContainer: {
		height: 50,
		paddingLeft: 15,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	headerActions: {
		paddingRight: 15,
	},
	financeTableHeader: {
		// backgroundColor: theme.palette.background.default,
	},
	financeTableFooter: {
		backgroundColor: theme.palette.background.default,
	},

	infoContainer: {
		backgroundColor: theme.palette.background.default,
		marginTop: 5,
		marginBottom: 15,
	},
	infoBox: {
		padding: 15,
		border: `5px solid ${theme.palette.background.paper}`,
	},

	body: {
		height: '42vh',
		maxHeight: '60vh',
		overflowY: 'auto',
	},

	button: {
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
}));

export const InvoiceComments = ({ invoiceId }) => {
	const classes = useStyles();

	return (
		<Grid container>
			{/* TABLE HEADER */}
			<Grid item xs={12} className={classes.financeTableHeader}>
				<Comments parentId={invoiceId} />
			</Grid>
		</Grid>
	);
};
