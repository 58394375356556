import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useContactContext } from '../../../../context/contact-context';
import { Table } from './table';

export const ContactVehicles = () => {
	const theme = useTheme();
	const { contact } = useContactContext();

	// COLUMNS
	// -------------------------------
	const columns = [
		{
			title: 'Registration',
			field: 'vehicleRegistration',
			cellStyle: {
				maxWidth: 200,
				borderLeft: `1px solid ${theme.palette.divider}`,
				borderRight: `1px solid ${theme.palette.divider}`,
				borderBottom: `1px solid ${theme.palette.divider}`,
			},
			render: function render(row) {
				return (
					<Typography variant="body2" display="block">
						{row.vehicleRegistration}
					</Typography>
				);
			},
		},
		{
			title: 'Vehicle',
			field: 'vehicleMake',
			cellStyle: {
				maxWidth: 200,
				borderBottom: `1px solid ${theme.palette.divider}`,
			},
			render: function render(row) {
				return (
					<>
						{row?.vehicleMake && (
							<Typography variant="body2" display="block">
								{row?.vehicleMake} {row?.vehicleModel}
							</Typography>
						)}
					</>
				);
			},
		},
		{
			title: 'Colour',
			field: 'vehicleColor',
			cellStyle: {
				maxWidth: 200,
				borderBottom: `1px solid ${theme.palette.divider}`,
			},
			render: function render(row) {
				return (
					<Typography variant="body2" display="block">
						{row?.vehicleColor}
					</Typography>
				);
			},
		},
	];

	return <Table title="Vehicles" data={contact?.vehicles} columns={columns} url="vehicle" />;
};
