import { createContext, useContext } from 'react';

const WorkordersContext = createContext({
    workorders: null,
    searchCriteria: null,
    view: 'today',
    setSearchCriteria: () => { },
    setView: () => { },
    searchQuery: () => { },
    refreshPage: () => { },
});

export function useWorkordersContext() {
    return useContext(WorkordersContext);
}

export default WorkordersContext;