import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

//MaterialUI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		backgroundColor: theme.palette.background.default,
		height: '100vh',
	},
	cardRoot: {
		// maxWidth: '500px',
	},
	title: {
		fontSize: 14,
	},
}));

export const NewProfile = () => {
	const classes = useStyles();
	const { logout } = useAuth0();

	const logoutUser = () => {
		deleteAllCookies();
		sessionStorage.removeItem('token');
		logout({ returnTo: window.location.origin });
	};

	return (
		<Grid container spacing={4} alignItems="center" justifyContent="center" className={classes.root}>
			<Grid item xs={3} />
			<Grid item xs={6}>
				<Card className={classes.cardRoot}>
					<CardContent>
						<Typography className={classes.title} color="textSecondary" gutterBottom>
							Error 500
						</Typography>
						<Typography variant="h5" component="h2">
							Something serious happened.
						</Typography>
						<Typography variant="body2" component="p">
							Check you internet connection or try loggin in again
						</Typography>
					</CardContent>
					<CardActions>
						<Button size="small" onClick={logoutUser} variant="outlined">
							Logout
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<Grid item xs={3} />
		</Grid>
	);
};

export const deleteAllCookies = () => {
	sessionStorage.removeItem('token');

	var cookies = document.cookie.split(';');

	for (var i = 0; i < cookies.length; i++) {
		var cookie = cookies[i];
		var eqPos = cookie.indexOf('=');
		var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
	}
};
