import React, { useState } from 'react';
//Query
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { GetQuote } from '../../../utils/data';
import { UpdateQuoteStatus, DeleteQuote } from '../../../utils/data';
//Material
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Link, Tooltip, Button } from '@material-ui/core';
//Icons
import ReceiptIcon from '@material-ui/icons/Receipt';
import BlockIcon from '@material-ui/icons/Block';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
//Context
import QuoteContext from '../../../../context/quote-context';
import { QuoteCostItemsTable } from '../../../../context/config.tables';
//Shared Components
import FinanceTable from '../../../components/finance-table';
import Modal from '../../../components/modal';
import { Box } from '../../../components/box';
import { Loading } from '../../../components/loading';
import { KeyPairDisplay } from '../../../components/key-pair';
//Components
import QuoteDetailTabs from './components/tabs';
//Buttons
import { QuoteDownloadButton } from './components/buttons/download';
import { QuotePrintButton } from './components/buttons/print';
import { EmailButton } from './components/buttons/email';
import { CreateAppointmentFromQuoteButton } from './components/buttons/create-appointment';

const useStyles = makeStyles(() => ({
	root: {
		width: 'fit-content',
	},
}));

// Main Code -->
const QuotePopup = ({ quoteId, quoteNumber, type = 'link', meta = {}, refreshPage }) => {
	const classes = useStyles();
	const theme = useTheme();
	const queryClient = new useQueryClient();

	// STATE
	const [open, setOpen] = useState(false);
	const [openEmail, setOpenEmail] = useState(false);

	// DATA
	const updateStatus = useMutation(UpdateQuoteStatus);
	const deleteQuote = useMutation(DeleteQuote);
	const { data, isLoading, refetch } = useQuery(['quote_detailed', quoteId], GetQuote, {
		refetchOnWindowFocus: false,
		enabled: !!quoteId,
	});

	const refetchMasterData = () => {
		queryClient.refetchQueries(['quote']);
		// queryClient.refetchQueries(['quote_detailed'])
		refetch();
	};

	const onClose = async () => {
		await refreshPage();
		// queryClient.refetchQueries(['quotes'])
	};

	const ActionButtons = (
		<Grid key={1} container alignItems="center" className={classes.root}>
			{/* NEW APPOINTMENT */}
			<CreateAppointmentFromQuoteButton quote={data} />

			{/* PRINT */}
			<QuotePrintButton quoteId={quoteId} />

			{/* DOWNLOAD */}
			<QuoteDownloadButton quoteId={quoteId} refetch={refetch} />

			<div
				style={{
					borderRight: '1px solid white',
					width: 1,
					height: '80%',
					marginRight: 10,
				}}
			/>

			{/* EMAIL */}
			<EmailButton setOpen={setOpenEmail} open={openEmail} quote={data} refetch={refetch} quoteNumber={quoteNumber} />
		</Grid>
	);

	// Main Return ->
	return (
		<>
			{type === 'link' && (
				<Link component="button" onClick={() => setOpen(true)} variant="body2" display="block" color="secondary" style={{ marginTop: 0 }}>
					{quoteNumber}
				</Link>
			)}

			{type === 'action' && (
				<>
					<ReceiptIcon fontSize="small" style={{ paddingRight: 5 }} />
					<Tooltip
						title={
							<span style={{ fontSize: '12px' }}>
								<b>Name</b>: {quoteNumber} <br />
								<b>Mobile</b>: {meta?.quoteMobile} <br />
								<b>Email</b>: {meta?.quoteEmail} <br />
							</span>
						}
						placement="top"
						arrow={true}
					>
						<Link
							component="button"
							onClick={() => setOpen(true)}
							variant="caption"
							color="textSecondary"
							display="block"
							style={{ marginTop: 0, paddingRight: 10 }}
							gutterBottom={false}
						>
							{toProperCase(quoteNumber)}
						</Link>
					</Tooltip>
				</>
			)}

			{data && (
				<QuoteContext.Provider value={{ quote: data, refetch: refetchMasterData }}>
					<Modal
						header={`Quote: ${quoteNumber}`}
						size="xxl"
						open={open}
						setOpen={setOpen}
						onClose={() => onClose()}
						fixedSize={true}
						additionalButtons={ActionButtons}
					>
						<Grid container spacing={2}>
							{data?.status === 'error' && <p>Error Occured</p>}
							{isLoading && (
								<Grid container spacing={2} justifyContent="center" alignItems="center">
									<Loading />
								</Grid>
							)}
							{!isLoading && (
								<>
									<Grid item xs={4} style={{ paddingRight: 25 }}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<Box heading="Totals">
													<FinanceTable
														config={QuoteCostItemsTable({
															quoteId,
															refetchMasterData,
														})}
														subTotal="only"
														refreshItem={refetchMasterData}
													/>
												</Box>
											</Grid>

											{/* //* Quote */}
											<Grid item xs={12}>
												<Box heading="Quote">
													<KeyPairDisplay label="Quote #" value={data?.quoteNumber} />
													<KeyPairDisplay
														label="Status"
														value={data?.quoteStatus}
														template={{
															type: 'status',
														}}
													/>
													<KeyPairDisplay label="App/Rej By" value={data?.approvedBy} />
													<KeyPairDisplay
														label="Approved on"
														value={data?.quoteApproveDate}
														template={{
															type: 'dateAgo',
														}}
													/>
													<KeyPairDisplay
														label="Expires on"
														value={data?.quoteExpiryDate}
														template={{
															type: 'dateAgo',
														}}
													/>
													<KeyPairDisplay
														label="Quote date"
														value={data?.quoteDate}
														template={{
															type: 'dateAgo',
														}}
													/>
												</Box>
											</Grid>

											{/* //* Linked Items */}
											<Grid item xs={12}>
												<Box heading="Linked items">
													<KeyPairDisplay label="Booking #" value={data?.bookingNumber} />
													<KeyPairDisplay label="Workorder #" value={data?.workorderNumber} />
													<KeyPairDisplay label="Invoice #" value={data?.invoiceNumber} />
												</Box>
											</Grid>

											{/* //* STATUS BUTTONS */}
											<Grid item xs={12}>
												{data?.quoteStatus !== 'Void' && (
													<Box heading="Mark quote as">
														{data?.quoteStatus !== 'Approved' &&
															data?.quoteStatus !== 'Rejected' &&
															data?.quoteStatus !== 'Void' &&
															data?.quoteStatus !== 'Draft' && (
																<Button
																	size="small"
																	variant="contained"
																	color="primary"
																	startIcon={<ThumbUpIcon />}
																	style={{
																		marginRight: 5,
																		backgroundColor: theme.palette.success.main,
																	}}
																	onClick={async () => {
																		await updateStatus.mutate({
																			_id: quoteId,
																			quoteStatus: 'Approved',
																		});
																		refetch();
																	}}
																>
																	Approved
																</Button>
															)}

														{data?.quoteStatus !== 'Approved' &&
															data?.quoteStatus !== 'Rejected' &&
															data?.quoteStatus !== 'Void' &&
															data?.quoteStatus !== 'Draft' && (
																<Button
																	size="small"
																	variant="contained"
																	startIcon={<ThumbDownIcon />}
																	style={{
																		marginRight: 5,
																		backgroundColor: theme.palette.error.light,
																		color: 'white',
																	}}
																	onClick={async () => {
																		await updateStatus.mutate({
																			_id: quoteId,
																			quoteStatus: 'Rejected',
																		});
																		refetch();
																	}}
																>
																	Rejected
																</Button>
															)}

														{data?.quoteStatus !== 'Draft' && (
															<Button
																size="small"
																variant="contained"
																startIcon={<BlockIcon />}
																style={{
																	marginRight: 5,
																}}
																onClick={async () => {
																	await updateStatus.mutate({
																		_id: quoteId,
																		quoteStatus: 'Void',
																	});
																	refetch();
																}}
															>
																Void
															</Button>
														)}

														{data?.quoteStatus === 'Draft' && (
															<Button
																size="small"
																variant="contained"
																startIcon={<BlockIcon />}
																style={{
																	marginRight: 5,
																	backgroundColor: theme.palette.error.main,
																	color: 'white',
																}}
																onClick={async () => {
																	await deleteQuote.mutate({
																		_id: quoteId,
																	});
																	setOpen(false);
																	refreshPage();
																}}
															>
																Delete
															</Button>
														)}
													</Box>
												)}
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={8}>
										<QuoteDetailTabs />
									</Grid>
								</>
							)}
						</Grid>
					</Modal>
				</QuoteContext.Provider>
			)}
		</>
	);
};

function toProperCase(str) {
	if (str)
		return str.replace(/(^|\s)\S/g, function (t) {
			return t.toUpperCase();
		});
	return null;
}

export default QuotePopup;
