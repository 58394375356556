import { useTheme, Box, CircularProgress, Typography } from '@material-ui/core';

export const ButtonLoading = ({ label }) => {
	const theme = useTheme();

	return (
		<Box position="relative" display="inline-flex">
			<CircularProgress size={25} thickness={7} disableShrink={true} />
			<Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
				<Typography
					variant="caption"
					component="div"
					style={{
						color: theme.palette.text.disabled,
					}}
				>{`${label}`}</Typography>
			</Box>
		</Box>
	);
};
