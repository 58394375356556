import { Card } from '../components/card'
import {
    GetWorkorderWarranty,
    UpdateWorkorderWarranty,
} from '../utils/data';

export const WorkorderWarrantyCard = ({ workorderId }) => {
    // --------------------------------------------------------------------------------------------------
    // MAIN
    // --------------------------------------------------------------------------------------------------

    // Shows the standard contact form
    return (
        <Card
            id={workorderId}
            queryFn={GetWorkorderWarranty}
            mutationFn={UpdateWorkorderWarranty}
            refetchQueryNames={['workorder']}
            reassign={false}
            template={{
                heading: 'Warranty',
                returnKey: 'warranty',
                fields: [
                    {
                        name: 'First service required at',
                        id: 'firstService',
                        type: 'mileage',
                        canEdit: true,
                        showmore: false,
                        isRequired: true,
                    },
                    {
                        name: 'Subsequent services every',
                        id: 'subsequentServices',
                        type: 'mileage',
                        canEdit: true,
                        showmore: false,
                        isRequired: true,
                    },
                    {
                        name: 'Up to a maximum of',
                        id: 'upUntil',
                        type: 'mileage',
                        canEdit: true,
                        isRequired: true,
                    },
                ],
            }}
        />
    )
}

export default WorkorderWarrantyCard