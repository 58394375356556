//Material
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

//Pages
import FinanceTable from '../../../../components/finance-table';
import { InvoiceCostItemsTable } from '../../../../../context/config.tables';
// import { useInvoiceContext } from '../../../../../context/invoice-context';

const useStyles = makeStyles((theme) => ({
	root: {
		overflow: 'hidden',
	},
	body: {
		overflowY: 'none',
		overflowX: 'none',
	},

	footer: {
		backgroundColor: theme.palette.background.default,
	},

	button: {
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	financeTableBody: {
		height: '53vh',
		overflowY: 'auto',
		padding: 10,
	},
}));

export const InvoiceTable = ({ invoiceId, refreshItem }) => {
	const classes = useStyles();

	// const { refetch } = useInvoiceContext();

	const config = InvoiceCostItemsTable({ invoiceId, refetch: refreshItem });

	return (
		<Grid container className={classes.root}>
			{/* TABLE HEADER */}
			<Grid item xs={12} className={classes.header}>
				<div style={{ marginLeft: 15, marginRight: 15 }}>
					<FinanceTable config={config} headers="only" refreshItem={refreshItem} />
				</div>
			</Grid>

			{/* TABLE */}
			<Grid item xs={12} className={classes.financeTableBody}>
				<FinanceTable config={config} headers="none" newRow="none" subTotal="hide" refreshItem={refreshItem} />
			</Grid>

			{/* FOOTER */}
			<Grid item xs={12} className={classes.footer}>
				<div style={{ marginLeft: 15, marginRight: 15, marginBottom: 15 }}>
					<FinanceTable config={config} newRow="only" refreshItem={refreshItem} />
				</div>
			</Grid>
		</Grid>
	);
};
