import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export const TextBoxLabel = ({ label = '', required = false }) => {
	const theme = useTheme();
	return (
		<Typography
			variant="body2"
			style={{
				color: theme.palette.text.secondary,
			}}
		>
			{!required && label}
			{required && `${label} *`}
		</Typography>
	);
};

export default TextBoxLabel;
