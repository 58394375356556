import { useState } from 'react';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { Chip, useTheme } from '@material-ui/core';
import { useMutation, useQuery } from 'react-query';
import { GetQuote, CreateQuoteFromWorkorder } from '../../utils/data-layer/quotes';
import { Tooltip } from '@material-ui/core';

import CreateIcon from '@material-ui/icons/Create';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import history from 'app/utils/history';
import moment from 'moment/moment';

export const QuoteFirstChipWorkorder = ({ table, row, refreshPage }) => {
	const theme = useTheme();
	const createQuote = useMutation(CreateQuoteFromWorkorder, {
		onSettled: () => {
			refetch();
			refreshPage();
		},
	});

	const quoteId = row?.quoteID || null;
	const [creating, setCreating] = useState(false);

	const { data, isLoading, refetch } = useQuery(['quote_detailed', quoteId], GetQuote, {
		refetchOnWindowFocus: false,
		enabled: !!quoteId,
	});

	if (!quoteId)
		return (
			<Tooltip title="Click to create a draft quote" arrow={true} placement="right">
				<Chip
					color="secondary"
					icon={<WarningOutlinedIcon />}
					label="Quote First"
					size="small"
					disabled={creating || false}
					style={{
						color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
						border: `1px solid ${theme.palette.error.dark}`,
						backgroundColor: alpha(theme.palette.error.light, 1),
						boxShadow: `1px 1px 3px ${theme.palette.background.default}`,
					}}
					onClick={async () => {
						if (creating) return;
						setCreating(true);

						await createQuote.mutate({
							quoteExpiryDate: row?.quoteExpiryDate || moment(new Date()).add(7, 'days'),

							...(table === 'appointment' && {
								bookingID: row?._id,
								bookingNumber: row?.bookingNumber,
								workorderOdometer: row?.bookingOdometer,
								quoteInstructions: row?.bookingInstructions,
							}),

							...(table === 'workorder' && {
								workorderID: row?._id,
								workorderNumber: row?.workorderNumber,
								workorderOdometer: row?.workorderOdometer,
								quoteInstructions: row?.workorderInstructions,
								quoteNumber: row?.workorderNumber.replace('WO-', ''),
							}),
							...(table === 'invoice' && {
								invoiceID: row?._id,
								workorderNumber: row?.workorderNumber,
								workorderOdometer: row?.workorderOdometer,
								quoteInstructions: row?.workorderInstructions,
							}),

							contactID: row?.contactID,
							vehicleID: row?.vehicleID,
							businessID: row?.businessID,
						});

						refetch();
						refreshPage();
						setCreating(false);
					}}
				/>
			</Tooltip>
		);

	if (isLoading) return null;

	// if (data.quoteStatus.toLowerCase() === 'draft')
	// return <QuotePopup quoteId={row?._id} quoteNumber={row?.quoteNumber} meta={row} type="link" refreshPage={refetch} />;

	if (data?.quoteStatus?.toLowerCase() === 'draft')
		return (
			<Chip
				color="primary"
				icon={<CreateIcon />}
				label={`Quote - Draft - ${data?.quoteNumber}`}
				size="small"
				style={{
					color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
					border: `1px solid ${theme.palette.warning.dark}`,
					backgroundColor: alpha(theme.palette.warning.dark, 0.2),
					boxShadow: `1px 1px 3px ${theme.palette.background.default}`,
				}}
				onClick={() => {
					history.push('/quotes?status=draft');
				}}
			/>
		);

	if (data?.quoteStatus?.toLowerCase() === 'awaiting approval')
		return (
			<Chip
				color="secondary"
				icon={<TimelapseIcon style={{ color: theme.palette.warning.dark }} />}
				label={`Quote - Wait appr. - ${data?.quoteNumber}`}
				size="small"
				style={{
					color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
					border: `1px solid ${theme.palette.warning.dark}`,
					backgroundColor: alpha(theme.palette.warning.dark, 0.2),
					boxShadow: `1px 1px 3px ${theme.palette.background.default}`,
				}}
			/>
		);

	if (data?.quoteStatus?.toLowerCase() === 'approved')
		return (
			<Chip
				color="secondary"
				icon={<TimelapseIcon style={{ color: theme.palette.success.dark }} />}
				label={`Quote - approved. - ${data?.quoteNumber}`}
				size="small"
				style={{
					color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
					border: `1px solid ${theme.palette.success.dark}`,
					backgroundColor: alpha(theme.palette.success.dark, 0.2),
					boxShadow: `1px 1px 3px ${theme.palette.background.default}`,
				}}
			/>
		);

	return null;
};
