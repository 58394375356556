import { createContext, useContext } from 'react';

const CheckInContext = createContext({
    appointment: null,
    setAppointment: () => { },
    errors: null,
    setErrors: () => { },
    isSaving: false,
    setIsSaving: () => { },
});

export function useCheckInContext() {
    return useContext(CheckInContext);
}

export default CheckInContext;