//Material
import { useTheme } from '@material-ui/core/styles';
import { Grid, Hidden } from '@material-ui/core';
// Components
import { AppointmentsTable } from './components/appointments-table';
import { AppointmentsMenu } from './components/appointments-menu';
import { SideBarCalendar } from './components/appointments-calendar-sidebar';

export const AppointmentsView = () => {
	const theme = useTheme();

	return (
		<Grid container spacing={2}>
			{/* MENU */}
			<Grid item xs={2} style={{ backgroundColor: theme.palette.background.paper }}>
				<AppointmentsMenu />
			</Grid>

			{/* TABLE */}
			<Grid item xs={10} lg={7}>
				<AppointmentsTable />
			</Grid>

			{/* CALENDAR */}
			<Hidden mdDown>
				<Grid item xs={3}>
					<SideBarCalendar />
				</Grid>
			</Hidden>
		</Grid>
	);
};
