//@Material
import { Grid, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

export const Section = ({ text }) => {
    return (
        <Grid item xs={12}>
            <Typography
                variant="button"
                color='primary'
                style={{ fontWeight: 700 }}
            >
                {text}
            </Typography>
            <Divider style={{ marginBottom: 5 }} />
        </Grid>
    )
}

export default Section