import { useVehicleContext } from '../../../../context/vehicle-context'
import { Box } from '../../../components/box';
import { KeyPairDisplay } from '../../../components/key-pair';
import { Divider } from '@material-ui/core';

export default function ContactAccountStatus() {
    const { vehicle } = useVehicleContext()

    return (
        <Box heading="Account Status">
            <>
                {/* {vehicle?.invoices?.overallBalance > 0 && (
                    <> */}
                        <KeyPairDisplay
                            label="Owed to you"
                            value={vehicle?.invoices?.overallBalance || "0"}
                            template={{ type: 'currency' }}
                        />

                        {/* <KeyPairDisplay
                            label="Last payment"
                            value={vehicle?.stats?.lastPayment || new Date()}
                            template={{ type: 'dateAgo' }}
                        /> */}
                        <br />
                        <Divider />
                        <br />
                    {/* </>
                )} */}


                <KeyPairDisplay
                    label="Last visit"
                    value={vehicle?.stats?.lastVisit}
                    template={{ type: 'dateAgo' }}
                />

                <KeyPairDisplay
                    label="Last Invoice"
                    value={vehicle?.stats?.lastInvoice}
                    template={{ type: 'dateAgo' }}
                />

                <KeyPairDisplay
                    label="# of visits"
                    value={vehicle?.stats?.numberOfVisits}
                />
            </>
        </Box>
    )
}