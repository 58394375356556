//Material
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	container: {
		borderWidth: '1px',
		borderStyle: 'solid',
		borderColor: theme.palette.divider,
		height: '100%',
	},
	containerCompact: {
		borderColor: theme.palette.divider,
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	heading: {
		color: theme.palette.type === 'dark' ? theme.palette.getContrastText(theme.palette.background.default) : theme.palette.primary.main,
		paddingLeft: theme.spacing(1),
	},
	headingCompact: {
		color: theme.palette.type === 'dark' ? theme.palette.getContrastText(theme.palette.background.default) : theme.palette.primary.main,
		paddingLeft: theme.spacing(0),
	},
	headerBox: {
		padding: 3,
		content: '',
		display: 'flex',
		clear: 'both',
		backgroundColor: theme.palette.background.default,
		alignItems: 'center',
	},
	headerBoxCompact: {
		content: '',
		display: 'flex',
		clear: 'both',
		backgroundColor: theme.palette.background.default,
		borderBottom: '1px solid',
		borderBottomColor: theme.palette.divider,
		marginBottom: theme.spacing(2),
		alignItems: 'center',
	},
	headerBoxLeft: {
		float: 'left',
		flex: 1,
		width: '60%',
	},
	headerBoxRight: {
		display: 'flex',
	},
	headerBoxSpacer: {
		flexGrow: 1,
	},
}));

export const Box = ({ heading, rightComponent, children, compact = false, noBodyPadding = false }) => {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<div className={compact ? classes.containerCompact : classes.container}>
			<div className={compact ? classes.headerBoxCompact : classes.headerBox}>
				<div className={classes.headerBoxLeft}>
					<Typography variant="body2" display="block" noWrap className={compact ? classes.headingCompact : classes.heading}>
						<b>{toProperCase(heading)}</b>
					</Typography>
				</div>

				<div className={classes.headerBoxSpacer} />

				<div className={classes.headerBoxRight}>{rightComponent}</div>
			</div>

			{children && <div style={compact || noBodyPadding ? { padding: theme.spacing(0) } : { padding: theme.spacing(1.25) }}>{children}</div>}
		</div>
	);
};

function toProperCase(str) {
	if (str)
		return str.replace(/(^|\s)\S/g, function (t) {
			return t.toUpperCase();
		});
	return null;
}

export default Box;
