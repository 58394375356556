import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from 'react-query';
import { CreateComment } from '../../../../../utils/data';
//material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DialogTitle, OutlinedInput } from '@material-ui/core';

export const StatusUpdateButton = ({ parentId, label, startIcon, backgroundColor, onClick }) => {
	const { user } = useAuth0();
	const [open, setOpen] = useState(false);
	const [update, setUpdate] = useState();

	const addComment = useMutation(CreateComment);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSubmit = async () => {
		await addComment.mutate({
			comment: `Status updated to ${label} : \n\n ${update || ''}`,
			parentId: parentId,
			loggedBy: user.name,
		});
		await onClick();
		setOpen(false);
	};

	return (
		<div>
			<Button
				size="small"
				variant="contained"
				startIcon={startIcon}
				color="primary"
				onClick={handleClickOpen}
				style={{
					marginRight: 5,
					marginBottom: 5,
					backgroundColor,
					color: 'white',
					textTransform: 'none',
				}}
			>
				{label}
			</Button>

			<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={true}>
				<DialogTitle>Update status</DialogTitle>

				<DialogContent>
					<DialogContentText>Please provide an update for this change</DialogContentText>
					<OutlinedInput autoFocus id="name" fullWidth multiline={true} rows={5} onChange={(e) => setUpdate(e.target.value)} />
				</DialogContent>

				<DialogActions>
					<Button size="small" onClick={handleClose} variant="contained">
						Cancel
					</Button>
					<Button size="small" onClick={handleSubmit} color="secondary" variant="contained">
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default StatusUpdateButton;
