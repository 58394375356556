import React from 'react';

//@material
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Grid, Card, CardHeader, TextField } from '@material-ui/core';

//Context
import { useCheckInContext } from '../../../../../context/appointments-checkin-context'

//Pages
import { NumberFormatMileageTextBox } from '../../../../../app/components/textbox/currency'

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		input: {
			[`& fieldset`]: {
				borderRadius: 0
			},
		}
	})
);

export const AppointmentCheckInMileage = ({ defaultValue = '', disabled = false }) => {
	const classes = useStyles();

	const { appointment, setAppointment, errors } = useCheckInContext()

	return (
		<Card className={classes.root} elevation={0}>
			<CardHeader
				title={
					<Grid container spacing={0} alignItems="flex-end">
						<Grid item xs={12}>
							<TextField
								label="Mileage"
								// InputLabel="Mileage"
								placeholder="Mileage"
								fullWidth={true}
								variant="outlined"
								color='secondary'
								className={classes.input}

								autoFocus={true}

								defaultValue={defaultValue}
								// inputError={errors?.root?.workorderOdometer}
								error={errors?.root?.workorderOdometer || false}
								disabled={disabled}

								InputProps={{
									inputComponent: NumberFormatMileageTextBox
								}}

								onChange={(event) => {
									setAppointment({
										...appointment,
										workorderOdometer: event.target.value
									})
								}}

								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>

						{errors?.root?.workorderOdometer && <Typography variant='caption' color='error'>{errors?.root?.workorderOdometer}</Typography>}
					</Grid>
				}
			/>
		</Card>
	);
};

export default AppointmentCheckInMileage;
