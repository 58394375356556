import React, { useState } from 'react';
//Material
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from '@material-ui/core';

//Icons
import PrintIcon from '@material-ui/icons/Print';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import { useMutation } from 'react-query';
import { DeleteWorkorder, DeleteWorkorderAndReinstateBooking } from 'app/utils/data-layer/workorders';

export const WorkorderActions_Print = ({ setOpen }) => {
	return (
		<div>
			<Button
				size="small"
				startIcon={<PrintIcon fontSize="small" />}
				fullWidth={true}
				variant="contained"
				color="primary"
				onClick={() => setOpen(true)}
				style={{
					color: 'white',
					textTransform: 'none',
				}}
			>
				Print
			</Button>
		</div>
	);
};

export const WorkorderActions_Delete = ({ workorderId, closeAndRefresh }) => {
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const deleteMutation = useMutation(DeleteWorkorder);

	return (
		<div
			style={{
				marginLeft: 5,
			}}
		>
			<Button
				size="small"
				startIcon={<DeleteIcon fontSize="small" />}
				fullWidth={true}
				variant="contained"
				onClick={() => setOpen(true)}
				style={{
					color: 'white',
					backgroundColor: theme.palette.error.main,
					textTransform: 'none',
				}}
			>
				Delete
			</Button>

			<Dialog open={open} onClose={() => setOpen(false)}>
				<DialogTitle>Delete Work Order</DialogTitle>
				<DialogContent>
					<DialogContentText>This will permanently delete the Work Order. Are you sure?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							deleteMutation.mutate({ _id: workorderId });
							closeAndRefresh();
						}}
						variant="contained"
						color="secondary"
					>
						Yes
					</Button>
					<Button onClick={() => setOpen(false)} color="secondary" variant="outlined" autoFocus>
						No
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export const WorkorderActions_Delete_ReninstateBooking = ({ workorderId, closeAndRefresh }) => {
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const reinstateMutation = useMutation(DeleteWorkorderAndReinstateBooking);

	return (
		<div
			style={{
				marginLeft: 5,
			}}
		>
			<Button
				size="small"
				startIcon={<DeleteIcon fontSize="small" />}
				fullWidth={true}
				variant="contained"
				onClick={() => {
					setOpen(true);
				}}
				style={{
					color: 'white',
					backgroundColor: theme.palette.error.main,
					textTransform: 'none',
				}}
			>
				Delete & Reinstate Booking
			</Button>

			<Dialog open={open} onClose={() => setOpen(false)}>
				<DialogTitle>Delete Work Order</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{`This will permanently delete the Work Order BUT re-instate the Appointment back to "Awaiting" status. Continue?`}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							reinstateMutation.mutate({ _id: workorderId });
							closeAndRefresh();
						}}
						variant="contained"
						color="secondary"
					>
						Yes
					</Button>
					<Button onClick={() => setOpen(false)} color="secondary" variant="outlined" autoFocus>
						No
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
