import React, { useState } from 'react';

//Query
import { useQuery } from 'react-query';
import { GetContactDetailed } from '../../utils/data';

//Material
import { useTheme } from '@material-ui/core/styles';
import { CircularProgress, Grid, Link, Tooltip } from '@material-ui/core';

//Icons
import PersonIcon from '@material-ui/icons/Person';

//Context
import ContactContext from '../../../context/contact-context';

//Pages
import ContactDetailTabs from './components/tabs';
import ContactAccountStatus from './components/contact-account-status';
import Modal from '../../components/modal';
import ContactCard from '../contact-card';
import { Loading } from '../../components/loading';

const ContactPopup = ({ contactId, contactFullname, type = 'link', meta = {} }) => {
	const [open, setOpen] = useState(false);
	const theme = useTheme();

	//Get Data
	const { data, isLoading } = useQuery(['contact_detailed', contactId], GetContactDetailed, {
		refetchOnWindowFocus: false,
		enabled: !!contactId && open,
	});

	// MAIN
	// --------------------------------------------------------------------------------------------------
	return (
		<>
			{type === 'link' && (
				<Link
					component="button"
					onClick={() => setOpen(true)}
					variant="subtitle1"
					color="primary"
					style={{ marginTop: 0, color: theme.palette.secondary.main }}
					noWrap={true}
					display="inline"
				>
					{toProperCase(contactFullname, true)}
				</Link>
			)}

			{type === 'table' && (
				<Link
					component="button"
					onClick={() => setOpen(true)}
					variant="subtitle1"
					color="primary"
					style={{
						marginTop: 0,
						color: theme.palette.text.primary,
						textDecorationLine: 'underline',
						textDecorationStyle: 'dotted',
						textDecorationColor: theme.palette.text.disabled,
						'& :hover': {
							textDecorationColor: theme.palette.text.primary,
						},
					}}
					noWrap={true}
					display="inline"
				>
					{toProperCase(contactFullname, true)}
				</Link>
			)}

			{type === 'action' && (
				<>
					{!isLoading && <PersonIcon fontSize="small" style={{ paddingRight: 5 }} />}
					{isLoading && <CircularProgress fontSize="small" style={{ display: 'inline-block', width: 15, height: 15 }} />}
					<Tooltip
						title={
							<span style={{ fontSize: '12px', linHeight: 2 }}>
								<b>Name</b>: {toProperCase(contactFullname)} <br />
								{meta?.contactMobile && (
									<>
										<b>Mobile</b>: {meta.contactMobile || null} <br />
									</>
								)}
								{meta?.contactPhone && (
									<>
										<b>Phone</b>: {meta.contactPhone || null} <br />
									</>
								)}
								{meta?.contactEmail && (
									<>
										<b>Email</b>: {meta.contactEmail || null} <br />
									</>
								)}
								{!meta?.contactPhone && !meta?.contactMobile && <b>No phone numbers available</b>}
							</span>
						}
						placement="top"
						arrow={true}
					>
						<Link
							disabled={isLoading}
							component="button"
							onClick={() => setOpen(true)}
							variant="caption"
							color="textSecondary"
							style={{
								marginTop: 0,
								paddingRight: 10,
								marginLeft: isLoading ? 5 : 0,
								color: !isLoading ? theme.palette.text.secondary : theme.palette.primary.main,
							}}
							gutterBottom={false}
							noWrap={true}
							display="inline"
						>
							{toProperCase(contactFullname, true)}
						</Link>
					</Tooltip>
				</>
			)}

			{data && open && (
				<ContactContext.Provider value={{ contact: data?.contact }}>
					<Modal header={toProperCase(contactFullname)} size="lg" open={open} setOpen={setOpen} fixedSize={true}>
						<Grid container spacing={2}>
							{data?.status === 'error' && <p>Error Occured</p>}
							{isLoading && (
								<Grid container spacing={2} justifyContent="center" alignItems="center">
									<Loading />
								</Grid>
							)}
							{!isLoading && (
								<>
									<Grid item xs={4} style={{ paddingRight: 25 }}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<ContactCard reassign={false} contactId={contactId} noLink={true} />
											</Grid>
											<Grid item xs={12}>
												<ContactAccountStatus />
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={8}>
										<ContactDetailTabs />
									</Grid>
								</>
							)}
						</Grid>
					</Modal>
				</ContactContext.Provider>
			)}
		</>
	);
};

function toProperCase(str, truncate = false) {
	if (truncate && str && str.length > 20) str = str.substr(0, 20) + '...';
	if (str)
		return str.replace(/(^|\s)\S/g, function (t) {
			return t.toUpperCase();
		});
	return null;
}

export default ContactPopup;
