import React, { useEffect, useState, useRef } from 'react';
import MomentUtils from '@date-io/moment';

//Material
import { IconButton, Paper, Popper, MenuItem, MenuList, Grid, Typography, Button, Tooltip } from '@material-ui/core';
import { CircularProgress, ClickAwayListener, Grow } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

//Icons
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Modal from '../../../app/components/modal';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

export default function ExportQuotesButton({ downloading, exportCSV, exportXero, exportCSVRange }) {
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

	const [openExportDateRange, setOpenExportDateRange] = useState(false);

	const anchorRef = useRef(null);
	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	function handleListKeyDown(event) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		} else if (event.key === 'Escape') {
			setOpen(false);
		}
	}

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = useRef(open);
	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	const handleExportCSV = async (event) => {
		exportCSV();
		handleClose(event);
	};

	const handleExportCSVCustom = async (startDate, endDate) => {
		exportCSVRange(startDate, endDate);
		handleClose(event);
	};

	const handleExportXero = async (event) => {
		exportXero();
		handleClose(event);
	};

	return (
		<>
			<Tooltip arrow={true} placement="bottom" title="Export data to Excel (.csv)">
				<IconButton
					ref={anchorRef}
					id="composition-button"
					aria-controls={open ? 'composition-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-haspopup="true"
					onClick={handleToggle}
				>
					{downloading ? <CircularProgress color="inherit" fontSize="large" /> : <CloudDownloadIcon color="inherit" fontSize="large" />}
				</IconButton>
			</Tooltip>

			<Popper
				style={{
					zIndex: 9999,
				}}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				placement="bottom-start"
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
						}}
					>
						<Paper
							elevation={12}
							style={{
								border: `1px solid ${theme.palette.secondary.main}`,
								// backgroundColor: 'white',
								// color: 'black',
							}}
						>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList
									autoFocusItem={open}
									id="composition-menu"
									aria-labelledby="composition-button"
									onKeyDown={handleListKeyDown}
								>
									<MenuItem
										onClick={handleExportCSV}
										style={{
											'&:hover': {
												backgroundColor: 'red',
											},
										}}
									>
										Export to CSV
									</MenuItem>

									<MenuItem
										onClick={() => {
											setOpen(false);
											setOpenExportDateRange(true);
										}}
										style={{
											'&:hover': {
												backgroundColor: 'red',
											},
										}}
									>
										Export to CSV (Custom Date Range)
									</MenuItem>

									<MenuItem onClick={handleExportXero}>Export as Xero Quote Set</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>

			<Modal
				header="Select date range"
				open={openExportDateRange}
				onClose={(e, reason) => {
					if (reason === 'backdropClick') return null;
					setOpenExportDateRange(false);
				}}
				size="sm"
			>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Typography variant="body2">From</Typography>
							<KeyboardDatePicker
								id={`SearchQueryFilterDate_${name}`}
								disableToolbar={true}
								clearable={true}
								value={startDate}
								format="DD MMM YYYY"
								inputVariant="outlined"
								autoOk={true}
								inputProps={{
									style: {
										borderRadius: 0,
										padding: 10,
									},
									placeholder: name,
								}}
								InputProps={{
									size: 'small',
									style: {
										color: theme.palette.text.primary,
										paddingLeft: 5,
										borderRadius: 0,
										backgroundColor: theme.palette.background.paper,
										margin: 0,
									},
								}}
								KeyboardButtonProps={{
									size: 'small',
								}}
								onChange={(date, newValue) => {
									setStartDate(newValue);
								}}
								style={{
									transition: theme.transitions.create(['border-color', 'box-shadow']),
									marginBottom: 5,
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<Typography variant="body2">To</Typography>
							<KeyboardDatePicker
								id={`SearchQueryFilterDate_${name}`}
								disableToolbar={true}
								clearable={true}
								format="DD MMM YYYY"
								inputVariant="outlined"
								autoOk={true}
								fullWidth
								value={endDate}
								inputProps={{
									style: {
										borderRadius: 0,
										padding: 10,
									},
									placeholder: name,
								}}
								InputProps={{
									size: 'small',
									style: {
										color: theme.palette.text.primary,
										paddingLeft: 5,
										borderRadius: 0,
										backgroundColor: theme.palette.background.paper,
										margin: 0,
									},
								}}
								KeyboardButtonProps={{
									size: 'small',
								}}
								onChange={(date, newValue) => {
									setEndDate(newValue);
								}}
								style={{
									transition: theme.transitions.create(['border-color', 'box-shadow']),
									marginBottom: 5,
								}}
							/>
						</Grid>

						<Grid item xs={6} />
						<Grid item xs={6} align="right">
							<Button
								variant="outlined"
								startIcon={<CloudDownloadIcon />}
								onClick={() => {
									setOpenExportDateRange(false);
									handleExportCSVCustom(startDate, endDate);
								}}
							>
								Download
							</Button>
						</Grid>
					</Grid>
				</MuiPickersUtilsProvider>
			</Modal>
		</>
	);
}
