import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

// Pages
import { Row } from './Row';
import { HeaderRow } from './GroupHeaderRow';
import { ItemTypes } from './ItemTypes';

const style = {
	cursor: 'move',
	width: '100%',
};

export const Card = ({ id, row, index, moveCard, itemsCount, config, disabled = false, refetch = null }) => {
	const ref = useRef(null);
	const [{ handlerId }, drop] = useDrop({
		accept: ItemTypes.CARD,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}
			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			// Determine mouse position
			const clientOffset = monitor.getClientOffset();
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}
			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}
			moveCard(dragIndex, hoverIndex);
			item.index = hoverIndex;
		},
	});
	const [{ isDragging }, drag] = useDrag({
		type: ItemTypes.CARD,
		item: () => {
			return { id, index };
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const opacity = isDragging ? 0 : 1;
	drag(drop(ref));

	return (
		<div ref={ref} style={row.isGroupHeader ? { width: '100%', opacity } : { ...style, opacity }} data-handler-id={handlerId}>
			{row.isGroupHeader && (
				<HeaderRow
					key={row._id}
					rowItem={row}
					rowIndex={index}
					itemsCount={itemsCount}
					config={config}
					disabled={disabled}
					refetch={refetch}
				/>
			)}
			{!row.isGroupHeader && (
				<Row key={row._id} rowItem={row} rowIndex={index} itemsCount={itemsCount} config={config} disabled={disabled} refetch={refetch} />
			)}
		</div>
	);
};
