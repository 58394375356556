//Query
import { useQuery } from 'react-query';
//Data
import {
    GetWarrantyByCustomValue,
} from '../../../../utils/data';

// MaterialUI
import { Grid } from '@material-ui/core/';

// Pages
import { Box } from '../../../../components/box'
import { Label } from '../../../../components/label'
import { Loading } from '../../../../components/loading'
import WarrantyNew from './new'

export default function CardWarranty({ parentId }) {
    //Get Data
    const { data, error, isLoading } = useQuery(
        ['warranty-detailed', { parentId }],
        GetWarrantyByCustomValue,
        {
            refetchOnWindowFocus: false
        }
    );

    // --------------------------------------------------------------------------------------------------
    // ERROR HANDLING - Reviewed 2
    // --------------------------------------------------------------------------------------------------
    if (error === 'error') return <p>Error Occured</p>;
    if (data?.status === 'error') return <p>Error Occured</p>;
    if (data?.status === 'access-denied') return <p>Access Denied</p>;
    if (isLoading) return <Loading />;
    // if (!data) return null;

    return (
        <Box
            heading="Warranty"
            rightComponent={<WarrantyNew warrantyData={data?.warranty || null} parentId={parentId} />}
        >
            {data?.warranty?.upUntil && (
                <Grid container>
                    <Label xs={8} text='Up to maximum of' />
                    <Label isValue={true} xs={4} text={`${data.warranty.upUntil} km's`} />
                    <Label xs={8} text='First Service' />
                    <Label isValue={true} xs={4} text={`${data.warranty.firstService} km's`} />
                    <Label xs={8} text='Service Intervals' />
                    <Label isValue={true} xs={4} text={`${data.warranty.subsequentServices} km's`} />
                    <Label xs={8} text='Allow external service' />
                    <Label isValue={true} xs={4} text={data.warranty.otherWorkshopsAllowed ? 'Yes' : 'No'} />
                </Grid>
            )}
        </Box>
    )
}