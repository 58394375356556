import moment from 'moment'

// MaterialUI
import MaterialTable from 'material-table';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// Query
import { useQuery } from 'react-query';
import { GetVehicles } from '../../../app/utils/data';

// Pages
import { Loading } from '../../../app/components/loading';
import VehiclePopup from '../../../app/widgets/vehicle'

const useStyles = makeStyles((theme) => ({
    table: {
        // ROW HOVER
        '& tbody': {
            '& tr:hover': {
                transition: 'none',
                cursor: 'pointer',
                backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.08)' : theme.palette.grey[200],
            },
        },

        '& thead': {
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderTop: 0,
            borderBottom: 0,
        },

        // TOOLBAR
        '& .MuiToolbar-root': {
            paddingLeft: 10,
            backgroundColor: theme.palette.background.default,
            border: '1px solid',
            borderColor: theme.palette.divider,
        }
    },
    mainContainer: {
        overflowY: 'auto',
    },
}));

export const VehiclesTable = () => {
    const classes = useStyles()

    const { data, isLoading } = useQuery(
        ['vehicles'],
        GetVehicles, {
        refetchOnWindowFocus: false
    });

    // ----------------------------------------------------------------
    // Error Handling
    // ----------------------------------------------------------------
    if (data?.status === 'error') return <p>Error Occured</p>;
    if (data?.status === 'access-denied') return <p>Access Denied</p>;
    if (isLoading) return <Loading />;

    return (
        <div className={classes.table} >
            <VehiclesTableContainer vehicles={data?.vehicles} />
        </div>
    )
}

const VehiclesTableContainer = ({ vehicles }) => {
    const theme = useTheme()

    // COLUMNS
    // -------------------------------
    const columns = [
        {
            title: 'Registration', field: 'vehicleRegistration',
            render: function render(row) {
                return (
                    <VehiclePopup
                        vehicleId={row?._id}
                        vehicleRegistration={row?.vehicleRegistration}
                        meta={row}
                        type='link'
                    />
                )
            }
        },
        {
            title: 'Vehicle', field: 'vehicleMake',
            render: function render(row) {
                return (
                    <>
                        <Typography variant='body2' display='block'>
                            {row?.vehicleMake}
                        </Typography>

                        <Typography variant='body2' display='block'>
                            {row?.vehicleModel}
                        </Typography>
                    </>
                )
            }
        }
    ]


    return (
        <MaterialTable
            title='Vehicles'
            data={vehicles}
            columns={columns}

            // onRowClick={(event, row) => history.push('/vehicle/' + row?._id)}

            options={{
                // GENERAL
                padding: 'dense',

                //SEARCH
                searchFieldVariant: 'outlined',
                searchFieldStyle: {
                    maxHeight: 35,
                    borderRadius: 20,
                },

                //HEADER
                headerStyle: {
                    backgroundColor: theme.palette.background.default,
                },

                //ROW
                selection: false,
                rowStyle: {
                    verticalAlign: 'top',
                },

                // EXPORT
                exportFileName: `Vehicles-${moment().format('YYYY-MM-DD')}`,
                exportButton: true,
                exportAllData: true,

                //PAGING
                pageSize: 10,
                emptyRowsWhenPaging: false,
            }}
        />
    )
}