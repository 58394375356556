import { httpGET, httpPOST } from './data-handler';

// ----------------------------------------------------------------------------------------------------------------------------------------------------------
// GET
// ----------------------------------------------------------------------------------------------------------------------------------------------------------

// [contact]
export const GetContact = async (req) => await httpGET({ path: `contact/${req.queryKey[1]}`, data: null });

// ['contact_detailed']
export const GetContactDetailed = async (req) => await httpGET({ path: `contact/${req.queryKey[1]}/detailed`, data: null });

// ['contact-associations']
export const GetPreferedSelectionsByContactId = async (req) => await httpGET({ path: `contact/assoc/${req.queryKey[1]}`, data: null });

// ['contacts']
export const GetContacts = async () => await httpGET({ path: `contacts`, data: null });

// ----------------------------------------------------------------------------------------------------------------------------------------------------------
// POST
// ----------------------------------------------------------------------------------------------------------------------------------------------------------
export const UpdateContact = async (data) => await httpPOST({ path: `contact`, data });
