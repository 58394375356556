import React from 'react';
//Material
import { Grid, OutlinedInput } from '@material-ui/core';

//Pages
import { KeyPairInput } from '../../../app/components/key-pair'
import { Section } from '../../../app/components/card/section'
import { TextBoxLabel } from '../../../app/components/card/label'


export const EditUser = ({ user }) => {
    // const [updatedUserData, setUpdatedUserData] = useState(user)


    // const handleChange = (event) => {
    //     setUpdatedUserData({ ...updatedUserData, [event.target.name]: event.target.checked });
    // };

    return (
        <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
            <Section text="Account" />
            <KeyPairInput
                label="Full name"
                name="name"
                defaultValue={user.name}
                type='text'
            />

            <KeyPairInput
                label="E-mail"
                name="email"
                defaultValue={user.email}
                type='email'
            />

            <Grid item xs={4}>
                <TextBoxLabel label="Password" />
            </Grid>

            <Grid item xs={8}>
                <OutlinedInput
                    size="small"
                    margin="dense"
                    variant="outlined"
                    fullWidth={true}
                    type="password"
                    defaultValue="fgkjagliuakjsdnkds"
                />
            </Grid>

            <Section text="Permissions" />

            <Grid item xs={12}>
                <Grid container alignItems="flex-start">
                    <Grid item xs={4}>
                        <TextBoxLabel label="Appointments" />
                    </Grid>
                    <Grid item xs={8}>
                        {/* <Checkbox checked={user.app_metdata.roles.includes("write-appointments")} onChange={handleChange} name="appointments" /> */}
                    </Grid>

                    <Grid item xs={4}>
                        <TextBoxLabel label="Work orders" />
                    </Grid>
                    <Grid item xs={8}>
                        {/* <Checkbox checked={user.app_metdata.roles.includes("write-workorders")} onChange={handleChange} name="workorders" /> */}
                    </Grid>

                    <Grid item xs={4}>
                        <TextBoxLabel label="Invoices" />
                    </Grid>
                    <Grid item xs={8}>
                        {/* <Checkbox checked={user.app_metdata.roles.includes("write-finance")} onChange={handleChange} name="invoices" /> */}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default EditUser