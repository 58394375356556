import { useState } from 'react';

//Query
import { useQuery } from 'react-query';

// @MaterialUI
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

//  Components
import { TextBoxLabel } from './label';
import { TextBoxDisplay } from './textbox';

const filter = createFilterOptions();

export const SelectOrCreate = ({ id, template, queryListBoxFn, returnField, config, handleReassignment }) => {
	const [selectedEntity, setSelectedEntity] = useState();
	const [showSelectedEntity, setShowSelectedEntity] = useState(false);
	const [value, setValue] = useState(null);

	//Get data
	const { data, isLoading } = useQuery([id], queryListBoxFn, { refetchOnWindowFocus: false });

	const handleChange = (value) => {
		setShowSelectedEntity(true);
		setSelectedEntity(value);

		// new contact
		if (!value._id) {
			handleReassignment('new', value?.[config.listboxDisplayValue]);
		}
		if (!value?.[config.listboxDisplayValue]) {
			handleReassignment('new', value);
		}

		// existing contact
		if (value?.[config.listboxDisplayValue]) handleReassignment('existing', value);
	};

	return (
		<>
			{/* <Info text={`This will change the current ${String(template.heading).toLowerCase()} on the ${String(template.parentTable).substring(0, String(template.parentTable).length - 1).toLowerCase()} to the one selected below. If the ${String(template.heading).toLowerCase()} does not exist it will be created.`} /> */}
			<Autocomplete
				id={`${id}-search`}
				autoComplete
				autoHighlight
				disableClearable
				freeSolo
				fullWidth={true}
				clearOnBlur={false}
				size="small"
				loading={isLoading}
				options={data?.[returnField]}
				value={value}
				getOptionLabel={(option) => {
					// Value selected with enter, right from the input
					if (typeof option === 'string') {
						return option;
					}
					// Add "xxx" option created dynamically
					if (option.inputValue) {
						return option.inputValue;
					}
					// Regular option
					if (!option?.[config.listboxDisplayValue]) return '';
					return option?.[config.listboxDisplayValue];
				}}
				renderOption={(option) => option?.[config.listboxDisplayValue]}
				onChange={(event, newValue) => {
					if (typeof newValue === 'string') {
						setValue({
							[config.listboxDisplayValue]: newValue,
						});
						handleChange({
							[config.listboxDisplayValue]: newValue,
						});
					} else if (newValue && newValue.inputValue) {
						// Create a new value from the user input
						setValue({
							[config.listboxDisplayValue]: newValue.inputValue,
						});
						handleChange({
							[config.listboxDisplayValue]: newValue.inputValue,
						});
					} else {
						setValue(newValue);
						handleChange(newValue);
					}
				}}
				filterOptions={(options, params) => {
					const filtered = filter(options, params);

					// Suggest the creation of a new value
					if (params.inputValue !== '') {
						filtered.push({
							inputValue: params.inputValue,
							[config.listboxDisplayValue]: `Click to create "${params.inputValue}"`,
						});
					}

					return filtered;
				}}
				renderInput={(params) => (
					<FormControl style={{ display: 'flex', paddingTop: 20 }}>
						<TextField
							{...params}
							placeholder={config.placeholder}
							variant="outlined"
							fullWidth={true}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								...params.InputProps,
							}}
						/>
					</FormControl>
				)}
			/>

			{showSelectedEntity && (
				<Grid container spacing={1} style={{ padding: '40px 20px 20px 20px' }}>
					{template.fields.map((item) => {
						return (
							<>
								<Grid item xs={4} lg={4} key={`title_${item?.id}`}>
									<TextBoxLabel label={item.name} />
								</Grid>

								<Grid item xs={8} lg={8} key={`value_${item.id}`}>
									<TextBoxDisplay value={selectedEntity?.[item.id]} type="anything" />
								</Grid>
							</>
						);
					})}
				</Grid>
			)}
		</>
	);
};

export default SelectOrCreate;
