import React, { useState, useCallback, useEffect } from 'react';
import update from 'immutability-helper';

// MaterialUI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Menu, MenuItem, Grid, Typography, Link, Divider } from '@material-ui/core';

// Query
import { useQuery, useMutation } from 'react-query';
import { ImportQuoteToWorkorder, ImportQuoteToInvoice } from '../../utils/data';

// Context
import { useInvoiceContext } from '../../../context/invoice-context';

// Shared Components
import { NewRowInvoice } from './NewRow';
import { Card } from './Card';
import { NumberFormatCurrencyText } from '../textbox';
import { DiscountTextBox } from './discount';

// Styles
const useStyles = makeStyles((theme) => ({
	rootmenu: {
		'& .MuiMenu-paper': {
			// borderRadius: 0,
			border: `1px solid ${theme.palette.primary.main}`,
			// color: 'black',
			// backgroundColor: 'white',
		},
	},
	container: {
		// backgroundColor: theme.palette.background.paper,
		width: '100%',
	},
	tableHeaderContainer: {
		border: `1px solid ${theme.palette.divider}`,
		borderRight: 0,
	},
	tableTotals: {
		padding: 15,
	},
	tableHeaderItem: {
		backgroundColor: theme.palette.background.default,
		borderRight: `1px solid ${theme.palette.divider}`,
		padding: theme.spacing(1),
	},
	tableBody: {
		// marginTop: 10,
		// marginBottom: 5,
		// border: '1px solid blue'
	},
	newRow: {
		paddingTop: 10,
		paddingBottom: 4,
		// backgroundColor: theme.palette.background.default
	},
}));

export const InvoiceContainer = ({ config, headers = 'show', newRow = 'show', subTotal, disabled = false, refreshItem }) => {
	const { refetch: refetchInvoicesPage } = useInvoiceContext();
	const classes = useStyles();
	const theme = useTheme();

	const [anchorEl, setAnchorEl] = useState(null);

	// Calculate if its read only based on status
	const isDisabled = () => {
		const item = data?.[config.query.queryKey];
		if (item?.quoteStatus && item?.quoteStatus?.toUpperCase() === 'AWAITING APPROVAL') return true;
		if (item?.quoteStatus && item?.quoteStatus?.toUpperCase() === 'APPROVED') return true;
		if (item?.quoteStatus && item?.quoteStatus?.toUpperCase() === 'VOID') return true;

		if (item?.invoiceStatus && item?.invoiceStatus?.toUpperCase() === 'IN PROGRESS') return true;
		if (item?.invoiceStatus && item?.invoiceStatus?.toUpperCase() === 'PAID') return true;
		if (item?.invoiceStatus && item?.invoiceStatus?.toUpperCase() === 'VOID') return true;
		if (item?.invoiceStatus && item?.invoiceStatus?.toUpperCase() === 'WRITE-OFF') return true;
		if (item?.invoiceStatus && item?.invoiceStatus?.toUpperCase() === 'PARKING') return true;
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	// Save the selected group as a template with given names and descriptions
	const assignTemplate = useMutation(config.query.assignTemplateFn, {
		onSettled: async () => {
			refetch();
		},
	});

	if (headers === 'only') {
		return (
			<Grid container className={classes.tableHeaderContainer}>
				{config.headers.map((header, i) => {
					return <Header key={i} idx={i} header={header} />;
				})}
			</Grid>
		);
	}

	const {
		data,
		isLoading,
		refetch: refetchQuery,
	} = useQuery([config.query.queryKey, config.query.id], config.query.queryFn, { refetchOnWindowFocus: false });
	const { data: templateNames } = useQuery(['invoice_templates', 1], config.query.loadGroupTemplateNamesFn, { refetchOnWindowFocus: false });

	const [cards, setCards] = useState(
		config.query.dbReturnSubKey ? data?.[config.query.dbReturnKey]?.[config.query.dbReturnSubKey] : data?.[config.query.dbReturnKey]
	);

	const refetch = async () => {
		refreshItem();
		refetchQuery();
	};

	const updateAll = useMutation(config.query.mutationFn, {
		onSettled: async () => {
			refetch();
		},
	});

	// Add Group Header before this row
	const addGroupHeader = useMutation(config.query.addGroupHeaderFn, {
		onSettled: async () => {
			refetch();
		},
	});

	const importQuoteToInvoice = useMutation(ImportQuoteToInvoice, {
		onSettled: async () => {
			refetch();
		},
	});

	const importQuoteToWorkorder = useMutation(ImportQuoteToWorkorder, {
		onSettled: async () => {
			refetch();
		},
	});

	useEffect(() => {
		if (config.query.dbReturnSubKey) {
			setCards(data?.[config.query.dbReturnKey]?.[config.query.dbReturnSubKey]);
		} else {
			setCards(data?.[config.query.dbReturnKey]);
		}
	}, [data]);

	const moveCard = useCallback(
		(dragIndex, hoverIndex) => {
			const dragCard = cards[dragIndex];
			let newCards = update(cards, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragCard],
				],
			});

			setCards(newCards);

			updateAll.mutate(newCards);
		},
		[cards]
	);

	const renderCard = (card, index) => {
		return (
			<Card
				key={card._id}
				index={index}
				id={card._id}
				row={card}
				moveCard={moveCard}
				itemsCount={cards.length}
				config={config}
				disabled={isDisabled() || disabled}
				refetch={refetch}
			/>
		);
	};

	const loadTemplate = async (template) => {
		if (data) {
			await assignTemplate.mutate({ parentId: data?.[config.query.dbReturnKey]?._id, templateName: template });
			setAnchorEl(false);
		}
	};

	if (!data || isLoading) return <p>Loading...</p>;

	if (!cards) return <p>No data</p>;

	if (newRow === 'only') {
		return (
			<Grid item xs={12} className={classes.newRow}>
				<Grid container spacing={1} alignItems="center">
					<Grid container spacing={1} alignItems="center">
						<Link
							disabled={isDisabled() || disabled}
							component="button"
							variant="caption"
							color="textSecondary"
							style={{ padding: 5 }}
							onClick={() =>
								addGroupHeader.mutate({
									id: data?.[config.query.dbReturnKey]?._id,
									addAboveId: 'new',
								})
							}
						>
							+ ADD GROUP
						</Link>

						<Link
							disabled={isDisabled() || disabled}
							component="button"
							variant="caption"
							color="textSecondary"
							style={{ padding: 5, borderLeft: `1px solid ${theme.palette.divider}` }}
							onClick={handleClick}
						>
							+ LOAD FROM TEMPLATE
						</Link>

						{data?.[config.query.queryKey]?.quoteID && (
							<Link
								disabled={isDisabled() || disabled}
								component="button"
								variant="caption"
								color="textPrimary"
								style={{ padding: 5, borderLeft: `1px solid ${theme.palette.divider}` }}
								onClick={async () => {
									const table = config?.query?.dbReturnKey;
									const item = data?.[config.query.queryKey];

									if (table === 'invoice') {
										await importQuoteToInvoice.mutate({
											invoiceID: item?._id,
											quoteID: item?.quoteID,
										});
									}

									if (table === 'workorder') {
										await importQuoteToWorkorder.mutate({
											workorderID: item?._id,
											quoteID: item?.quoteID,
										});
									}
								}}
							>
								{`> IMPORT QUOTE`}
							</Link>
						)}

						<Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} className={classes.rootmenu}>
							<MenuItem dense disabled>
								Select a template to load
							</MenuItem>
							{templateNames?.templates?.map((template, idx) => {
								return (
									<MenuItem key={idx} dense onClick={() => loadTemplate(template)}>
										{template}
									</MenuItem>
								);
							})}
						</Menu>
					</Grid>

					<NewRowInvoice
						config={config}
						parentId={data?.[config.query.dbReturnKey]?._id}
						itemsCount={data?.[config.query.dbReturnKey]?.[config.query.dbReturnSubKey]?.length}
						refetch={refetch}
						disabled={isDisabled() || disabled}
					/>
				</Grid>
			</Grid>
		);
	}

	//* TOTALS
	if (subTotal === 'only') {
		return (
			<Grid item xs={12} className={classes.newRow}>
				<Grid container spacing={1} alignItems="center">
					<Totals
						data={data}
						config={config}
						refetch={async () => {
							refetch();
							await refetchInvoicesPage();
						}}
					/>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid container alignContent="stretch">
			{/* //* ADD ITEMS */}
			{(config.newRowPlacement === 'top' || config.newRowPlacement === 'both') && (
				<Grid item xs={12} className={classes.newRow}>
					<Grid container spacing={1} alignItems="center">
						<NewRowInvoice
							config={config}
							parentId={data?.[config.query.dbReturnKey]?._id}
							itemsCount={data?.[config.query.dbReturnKey]?.[config.query.dbReturnSubKey]?.length}
							refetch={refetch}
							disabled={isDisabled() || disabled}
						/>
					</Grid>
				</Grid>
			)}

			{/* //* HEADERS  */}
			{headers === 'show' && (
				<Grid item xs={12}>
					<Grid container alignItems="center">
						{config.headers.map((header, i) => {
							return <Header key={i} idx={i} header={header} />;
						})}
					</Grid>
				</Grid>
			)}

			{/* //* ITEMS CONTAINER  */}
			{cards.length > 0 && (
				<Grid item xs={12} style={{ minHeight: '35vh' }}>
					<Grid
						container
						alignItems="flex-start"
						style={{
							maxHeight: '50vh',
							overflowX: 'hidden',
							paddingBottom: 7.5,
							paddingTop: 7.5,
						}}
					>
						{cards.map((card, i) => renderCard(card, i))}
					</Grid>
				</Grid>
			)}

			{cards.length === 0 && (
				<Grid item xs={12}>
					<Grid
						container
						alignItems="center"
						justifyContent="center"
						style={{
							minHeight: '35vh',
							maxHeight: '50vh',

							overflowX: 'hidden',
						}}
					>
						<Typography variant="body2">{config.messages.nodata}</Typography>
					</Grid>
				</Grid>
			)}

			{/* //* ADD ITEMS */}
			{(!config.newRowPlacement || config.newRowPlacement === 'bottom' || config.newRowPlacement === 'both') && newRow === 'show' && (
				<Grid item xs={12} className={classes.newRow}>
					<Grid container spacing={1} alignItems="center">
						<NewRowInvoice
							config={config}
							parentId={data?.[config.query.dbReturnKey]?._id}
							itemsCount={data?.[config.query.dbReturnKey]?.[config.query.dbReturnSubKey]?.length}
							refetch={refetch}
							disabled={isDisabled() || disabled}
						/>
					</Grid>
				</Grid>
			)}

			{/* //* TOTALS */}
			{subTotal === 'show' && (
				<Totals
					data={data}
					config={config}
					refetch={async () => {
						refetch();
						await refetchInvoicesPage();
					}}
				/>
			)}
		</Grid>
	);
};

function Header({ header, idx }) {
	const classes = useStyles();
	return (
		<Grid key={idx} item xs={header.size.xs} className={classes.tableHeaderItem}>
			<Typography variant="body2" noWrap display="block" align={header.align || 'left'} style={header.isTotal ? { paddingRight: 25 } : {}}>
				{header.label.toUpperCase()}
			</Typography>
		</Grid>
	);
}

function Totals({ data, config, refetch }) {
	const classes = useStyles();

	return (
		<Grid item xs={12} className={classes.tableTotals}>
			<Grid container spacing={1} alignItems="center">
				{/* Totals */}
				<Grid item xs={12}>
					<Grid container alignItems="center">
						<Grid item xs={8}>
							<Typography variant="h6" color="textPrimary" style={{ paddingRight: 10 }}>
								<b>Sub Total</b>
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="h6" color="textPrimary" align="right" style={{ paddingRight: 10 }}>
								{config.query.queryKey === 'workorder' && NumberFormatCurrencyText(data?.[config.query.dbReturnKey]?.costTotal, 2)}
								{config.query.queryKey === 'quote' &&
									NumberFormatCurrencyText(data?.[config.query.dbReturnKey]?.totals?.quoteSubTotal, 2)}
								{config.query.queryKey === 'invoice' &&
									NumberFormatCurrencyText(data?.[config.query.dbReturnKey]?.totals?.invoiceSubTotal, 2)}
							</Typography>
						</Grid>

						{config.query.queryKey === 'invoice' && (
							<>
								<Grid item xs={8}>
									<Typography variant="body2" color="textSecondary" style={{ paddingRight: 10 }}>
										Disc. (%)
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<DiscountTextBox
										id={data?.[config.query.dbReturnKey]?._id}
										config={config}
										totals={data?.[config.query.dbReturnKey]?.totals}
										disabled={data?.[config.query.dbReturnKey]?.totals?.invoiceSubTotal ? false : true}
										refetch={refetch}
									/>
								</Grid>

								<Grid item xs={8}>
									<Typography variant="body2" color="textSecondary" style={{ paddingRight: 10 }}>
										VAT
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant="body2" color="textSecondary" align="right" style={{ paddingRight: 10 }}>
										{NumberFormatCurrencyText(data?.[config.query.dbReturnKey]?.totals?.invoiceVATAmount, 2)}
									</Typography>
								</Grid>

								<Grid item xs={12}>
									<Divider style={{ marginTop: 5, marginBottom: 5 }} />
								</Grid>
								<Grid item xs={8}>
									<Typography variant="body2" style={{ paddingRight: 10 }}>
										<b>Total</b>
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant="body2" align="right" style={{ paddingRight: 10 }}>
										<b>{NumberFormatCurrencyText(data?.[config.query.dbReturnKey]?.totals?.invoiceTotal, 2)}</b>
									</Typography>
								</Grid>

								<Grid item xs={12}>
									<Divider style={{ marginTop: 5, marginBottom: 5 }} />
								</Grid>
								<Grid item xs={8}>
									<Typography variant="body2" style={{ paddingRight: 10 }}>
										<b>Owed to you</b>
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant="body2" align="right" style={{ paddingRight: 10 }}>
										<b>{NumberFormatCurrencyText(data?.[config.query.dbReturnKey]?.totals?.invoiceBalance, 2)}</b>
									</Typography>
								</Grid>
							</>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
