import React, { useState } from 'react';
import CheckAccess from '../app/utils/access';

import { Link } from 'react-router-dom';
// Data
// import { useQuery, useMutation, useQueryClient } from 'react-query';
// import { GetCompanySettings, UpdateCompanyTheme } from '../utils/data';

//@Material
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

//Import Icons
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

//Security
import { useAuth0 } from '@auth0/auth0-react';

const StyledMenu = withStyles({
	paper: {
		border: '1px solid #d3d4d5',
		borderRadius: 0,
		marginTop: 10,
		width: '250px',
	},
})((props) => (
	<Menu
		width="200px"
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
		}}
		{...props}
	/>
));

const StyledMenuItem = withStyles((theme) => ({
	root: {
		'&:focus': {
			backgroundColor: theme.palette.primary.main,
			'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
				color: theme.palette.common.white,
			},
		},
	},
}))(MenuItem);

const useStyles = makeStyles(() => ({
	avatar: {
		marginLeft: '5px',
		'&:hover': {
			cursor: 'pointer',
		},
	},
}));

export default function UserMenu() {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const [helpmode, setHelpMode] = useState(localStorage.getItem('help-mode') === 'true' ? true : false);
	const { user, isAuthenticated, logout } = useAuth0();

	const handleHelpChange = (e) => {
		setHelpMode(e?.target?.checked || false);
		localStorage.setItem('help-mode', e?.target?.checked || false);
		handleClose();
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const logoutUser = () => {
		sessionStorage.removeItem('token');
		logout({ returnTo: window.location.origin });
		handleClose();
	};

	//MAIN
	return (
		<div>
			{isAuthenticated && (
				<Tooltip title="Click to open menu" arrow={true} placement="bottom">
					<Avatar alt={user.name} src={user.picture} className={classes.avatar} onClick={handleClick} />
				</Tooltip>
			)}
			<StyledMenu id="user-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} size="small">
				{/* PROFILE */}
				<StyledMenuItem button component={Link} to="/profile" onClick={handleClose}>
					<ListItemIcon>
						<AccountCircleIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Profile" />
				</StyledMenuItem>

				{/* SETTINGS */}
				{CheckAccess('admin') && (
					<StyledMenuItem button component={Link} to="/settings" onClick={handleClose}>
						<ListItemIcon>
							<SettingsIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText primary="Settings" />
					</StyledMenuItem>
				)}

				{/* HELP MODE */}
				<StyledMenuItem>
					<ListItemIcon>
						<LiveHelpIcon fontSize="small" />
					</ListItemIcon>
					<FormControlLabel
						// value="end"
						control={
							<Checkbox checked={Boolean(helpmode) || false} name="helpmode" color="primary" onChange={(e) => handleHelpChange(e)} />
						}
						label="Show help"
					/>
				</StyledMenuItem>

				<Divider />

				<StyledMenuItem button component={Link} to="/logout" onClick={() => logoutUser()}>
					<ListItemIcon>
						<ExitToAppIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Logout" />
				</StyledMenuItem>
			</StyledMenu>
		</div>
	);
}
