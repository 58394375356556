// data
import { useState } from 'react';
import { useQuery } from 'react-query';
import { GetBusiness } from '../../../../../../app/utils/data';
// material
import { Grid } from '@material-ui/core';
// components
import { KeyPairInput } from '../../../../../../app/components/key-pair';
import { useNewAppointmentContext } from '../../../../../../context/appointments-new-context';
import { AddressEditKeyPair } from '../../../../../../app/components/card/textbox';
import { Loading } from '../../../../../../app/components/loading';

// Context

export const NewBusiness = ({ isNew = false, isNewInitialItem = '', businessId = null }) => {
	const { setAppointment, errors, isSaving } = useNewAppointmentContext();

	const { data, isLoading } = useQuery(['business', businessId], GetBusiness, {
		enabled: !!businessId && !isNew,
		refetchOnWindowFocus: false,
		onSuccess: (data) => {
			setData(data);
		},
	});

	const [_data, setData] = useState(isNew ? { business: isNewInitialItem } : { business: data?.business });

	const handleChange = (e) => {
		if (e.target.value === '') e.target.value = null;
		if (e.target.value === '___-___-____') e.target.value = null;

		setAppointment((appointment) => ({
			...appointment,
			business: {
				...appointment?.business,
				[e.target.name]: e.target.value,
			},
		}));

		setData((appointment) => ({
			...appointment,
			business: {
				...appointment?.business,
				[e.target.name]: e.target.value,
			},
		}));
	};

	//* Error Handling
	if (businessId && !isNew) {
		if (data?.status === 'error') return <p>Error Occured</p>;
		if (data?.status === 'access-denied') return <p>Access Denied</p>;

		if (isLoading) return <Loading />;
	}

	return (
		<Grid container alignItems="center" justifyContent="center">
			<Grid item xs={10}>
				<Grid container alignItems="center" spacing={2}>
					<KeyPairInput
						label="Name"
						name="businessName"
						defaultValue={_data?.business?.businessName || ''}
						onChange={handleChange}
						error={errors?.business?.businessName || false}
						disabled={isSaving}
					/>

					<KeyPairInput
						label="Email"
						name="businessEmail"
						type="email"
						focus={true}
						defaultValue={_data?.business?.businessEmail || ''}
						onChange={handleChange}
						error={errors?.business?.businessEmail || false}
						disabled={isSaving}
					/>

					<KeyPairInput
						label="Phone"
						name="businessPhone"
						type="phone"
						defaultValue={_data?.business?.businessPhone || ''}
						onChange={handleChange}
						error={errors?.business?.businessPhone || false}
						disabled={isSaving}
					/>

					<AddressEditKeyPair
						label="Address"
						item={{ id: 'businessAddress' }}
						initialValue={_data?.business?.businessAddress || ''}
						onChange={handleChange}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};
