import { useMemo } from 'react';
// Page
import { SearchQueryFilterDate } from './appointments-search-sidebar';
import { useAppointmentsContext } from '../../../../../context/appointments-context';
import { useLocation } from 'react-router-dom';

// MAIN
export const SideBarCalendar = () => {
	let { appointments } = useAppointmentsContext();
	const { search } = useLocation();
	const params = useMemo(() => new URLSearchParams(search), [search]);

	return (
		<SearchQueryFilterDate
			name="From"
			searchKey="bookingDateFrom"
			values={params.get('date') || appointments?.query?.bookingDateFrom}
			variant="static"
		/>
	);
};

export default SideBarCalendar;
