// MaterialUI
import { Typography, Grid } from '@material-ui/core/';

export const Label = ({ text, xs, isValue = false }) => (
    <Grid item xs={xs || 6}>
        <Typography
            variant="subtitle2"
            style={!isValue ? {
                color: '#757575',
                fontWeight: 'bold',
            } : {}}
        >
            {text}
        </Typography>
    </Grid>
)

export default Label