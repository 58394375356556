import { useState } from 'react';

// MaterialUI
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton, Tooltip } from '@material-ui/core';

// Icons
import { yellow } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import GradeIcon from '@material-ui/icons/Grade';

// Components
import { TextBox } from './TextBox';
import { SaveInvoiceTemplate } from './SaveInvoiceTemplate';
import Modal from '../modal';

// Context
// import { useInvoiceContext } from '../../../context/invoice-context'
// import { useWorkorderContext } from '../../../context/workorder-context'

// Query
import { useMutation, useQueryClient } from 'react-query';

// Styles
const useStyles = makeStyles((theme) => ({
	container: {
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
		},
		'&:hover span': {
			visibility: 'visible',
		},
	},
	hide: {
		visibility: 'hidden',
	},
	div_left: {
		float: 'left',
		flex: 0.8,
	},
	div_right: {
		float: 'left',
		flex: 0.2,
	},
	actionButtons: {
		color: yellow[500],
	},
	actionButtonsDelete: {
		color: theme.palette.error.main,
	},
}));

export const HeaderRow = ({ rowItem, rowIndex, itemsCount, config, refetch, disabled }) => {
	const queryClient = useQueryClient();
	const classes = useStyles();

	// const { refetch: refetchInvoice } = useInvoiceContext()
	// const { refetch: refetchWorkorder } = useWorkorderContext()

	// ---------------------------------------------------------------------------------------------------------------
	// HOOKS and STATE
	// ---------------------------------------------------------------------------------------------------------------
	const [row, setRow] = useState(rowItem);
	const [editing, setEditing] = useState(false);
	const [newTemplate, setNewTemplate] = useState({
		invoiceId: rowItem?.invoiceID,
		workorderId: rowItem?.workorderId,
		groupId: rowItem?._id,
		templateName: rowItem?.costDescription,
	});
	const [showSaveTemplate, setShowSaveTemplate] = useState(false);

	// ---------------------------------------------------------------------------------------------------------------
	// DATA
	// ---------------------------------------------------------------------------------------------------------------

	// Add Group Header before this row
	const addGroupHeader = useMutation(config.query.addGroupHeaderFn, {
		onSettled: () => {
			if (refetch) refetch();
			// if (config.query.queryKey === 'invoice') refetchInvoice()
			// if (config.query.queryKey === 'workorder') refetchWorkorder()
		},
	});

	// Update Row
	const update = useMutation(config.query.updateFn, {
		onSettled: () => {
			if (refetch) refetch();
			// if (config.query.queryKey === 'invoice') refetchInvoice()
			// if (config.query.queryKey === 'workorder') refetchWorkorder()
		},
	});

	// Delete Group
	const removeGroup = useMutation(config.query.deleteGroupFn, {
		onSettled: () => {
			if (refetch) refetch();
			// if (config.query.queryKey === 'invoice') refetchInvoice()
			// if (config.query.queryKey === 'workorder') refetchWorkorder()
		},
	});

	// Save the selected group as a template with given names and descriptions
	const saveAsTemplate = useMutation(config.query.saveAsTemplateFn, {
		onSettled: () => {
			queryClient.refetchQueries(['invoice_templates'], { active: true });
		},
	});

	// ---------------------------------------------------------------------------------------------------------------
	// EVENTS
	// ---------------------------------------------------------------------------------------------------------------
	// Set the state
	// ---------------------------------------------------------------------
	const onChange = (e) => {
		setRow({
			...row,
			[e.target.name]: e.target.value,
		});
	};

	//Save on blur when value is different
	// ---------------------------------------------------------------------
	const onBlur = () => {
		if (row !== rowItem) {
			update.mutate(row);
			setEditing(false);
		}
	};

	// Keyboard Events
	// ---------------------------------------------------------------------
	const onKeyPress = async (e) => {
		// Save on Enter key if value is different
		// ---------------------------------------------------------------------
		if (e.keyCode == 13) {
			if (row !== rowItem) {
				// Do not save if the required fields are empty
				config.headers.map((header) => {
					if (header.required) {
						if (config.query.dbReturnSubKey) {
							if (!row?.[config.query.dbReturnKey]?.[config.query.dbReturnSubKey]?.[header.dbField]) {
								return null;
							}
						}
					}
				});

				//Submit value to db
				update.mutate(row);
				setEditing(false);
			}
		}

		// Move Cursor UP when Up Arrow pressed
		// ---------------------------------------------------------------------
		if (e.keyCode == 38) {
			//Get row and col from the fields id
			let row = Number(e.target.id.split('_')[0]) - 1;
			let col = Number(e.target.id.split('_')[1]);

			// Get the element
			let el = document.getElementById(`${row}_${col}`);

			// Move up until it reaches the first line
			if (row >= 0) el.focus();

			// Set focus to end of text
			if (row >= 0)
				setTimeout(function () {
					el.selectionStart = el.selectionEnd = 500;
				}, 0);
		}

		// Move Cursor DOWN when Down Arrow pressed
		// ---------------------------------------------------------------------
		if (e.keyCode == 40) {
			//Get row and col from the fields id and set to new row to focus
			let row = Number(e.target.id.split('_')[0]) + 1;
			let col = Number(e.target.id.split('_')[1]);

			// Move up until it reaches the last line
			if (row < itemsCount) document.getElementById(`${row}_${col}`).focus();

			//Gone past thelast lie, so skip to 'New Line' control
			if (row === itemsCount) document.getElementById(`new_${col}`).focus();
		}
	};

	const addGroupHeaderOnClick = async () => {
		await addGroupHeader.mutate({
			workorderId: row.workorderID,
			addAboveId: row._id,
		});
	};

	// Templates
	// ---------------------------------------------------------------------
	const saveTemplate = async () => {
		// SAVE TEMPLATE
		if (config.query.queryKey === 'invoice') {
			await saveAsTemplate.mutate({
				invoiceId: rowItem.invoiceID,
				groupId: rowItem._id,
				...newTemplate,
			});
		}

		if (config.query.queryKey === 'workorder') {
			await saveAsTemplate.mutate({
				workorderId: rowItem.workorderID,
				groupId: rowItem._id,
				...newTemplate,
			});
		}

		setShowSaveTemplate(false);
	};

	const onChangeTemplate = (e) => {
		setNewTemplate({
			...newTemplate,
			[e.target.name]: e.target.value,
		});
	};

	const handleSaveAsTemplate = () => {
		if (config.query.queryKey === 'invoice') {
			setNewTemplate({
				...newTemplate,
				invoiceId: rowItem.invoiceID,
				groupId: rowItem._id,
			});
		}

		if (config.query.queryKey === 'workorder') {
			setNewTemplate({
				...newTemplate,
				workorderId: rowItem.workorderID,
				groupId: rowItem._id,
			});
		}
		setShowSaveTemplate(true);
	};

	// MAIN
	// ---------------------------------------------------------------------------------------------------------------
	return (
		<>
			{/* ROW
            ---------------------------------------------------------------------------  */}
			<Grid container id={`row_${rowIndex}_item`} spacing={1} alignContent="center" style={{ marginBottom: 1 }} className={classes.container}>
				{/* MAP COLUMNS 
                --------------------------------------------------------------------------- */}
				{config.headers.map((header, i) => {
					if (header.isRowHeaderField)
						return (
							<Grid
								container
								alignItems="center"
								key={i}
								style={{
									paddingTop: 25,
									paddingBottom: 5,
								}}
							>
								<Grid item xs={8}>
									{/* READ ONLY */}
									{!editing && (
										<Typography style={{ marginLeft: 35 }} variant="body2" onClick={() => setEditing(true)}>
											<b>{rowItem?.[header.dbField]}</b>
										</Typography>
									)}

									{/* EDITING */}
									{editing && (
										<TextBox
											name={header.dbField}
											id={`${rowIndex}_${i}`}
											defaultValue={rowItem?.[header.dbField]}
											onChange={onChange}
											onKeyDown={onKeyPress}
											onBlur={onBlur}
											showDragger={i === 0 ? true : false}
											addGroupHeaderOnClick={addGroupHeaderOnClick}
											center={header.align || false}
											align={header.align}
											type={'text'}
											disabled={disabled}
										/>
									)}
								</Grid>

								<Grid item xs={4} style={{ paddingRight: 25 }}>
									<Grid container justifyContent="flex-end" direction="row">
										<span className={classes.hide}>
											{/* SAVE AS TEMPLATE */}
											{config.query.queryKey === 'invoice' && (
												<Tooltip title="Save this group as a template" arrow={true} placement="top">
													<IconButton
														onClick={() => handleSaveAsTemplate()}
														size="small"
														style={{ paddingLeft: 5 }}
														disabled={disabled}
													>
														<GradeIcon className={classes.actionButtons} style={{ fontSize: '1.2rem' }} />
													</IconButton>
												</Tooltip>
											)}

											{config.query.queryKey === 'workorder' && (
												<Tooltip title="Save group as a template" arrow={true} placement="right">
													<IconButton
														onClick={() => handleSaveAsTemplate()}
														size="small"
														style={{ paddingLeft: 5 }}
														disabled={disabled}
													>
														<GradeIcon className={classes.actionButtons} style={{ fontSize: '1.2rem' }} />
													</IconButton>
												</Tooltip>
											)}

											{/* DELETE */}
											<Tooltip title="Delete group" arrow={true} placement="top">
												<IconButton
													onClick={() => removeGroup.mutate({ ...rowItem, groupId: rowItem?._id })}
													size="small"
													style={{ paddingLeft: 5 }}
													disabled={disabled}
												>
													<DeleteIcon className={classes.actionButtonsDelete} style={{ fontSize: '1.2rem' }} />
												</IconButton>
											</Tooltip>
										</span>
									</Grid>
								</Grid>
							</Grid>
						);
				})}
			</Grid>

			<Modal header={'CREATE TEMPLATE'} size="sm" open={showSaveTemplate} setOpen={setShowSaveTemplate} fixedSize={true} saveFn={saveTemplate}>
				<SaveInvoiceTemplate onChange={onChangeTemplate} defaultValues={newTemplate} />
			</Modal>
		</>
	);
};
