import React from 'react';

//@material
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Grid, Card, CardHeader, TextField } from '@material-ui/core';

//Context
import { useNewAppointmentContext } from '../../../../../../context/appointments-new-context'

//Pages
import { NumberFormatMileageTextBox } from '../../../../../../app/components/textbox/currency'

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		input: {
			[`& fieldset`]: {
				borderRadius: 0
			},
		}
	})
);

export const AppointmentMileage = ({ defaultValue = '', disabled = false }) => {
	const classes = useStyles();

	const { appointment, setAppointment, errors } = useNewAppointmentContext()

	return (
		<Card className={classes.root} elevation={0}>
			<CardHeader
				title={
					<Grid container spacing={0} alignItems="flex-end" style={{ paddingBottom: 0 }}>
						<Grid item xs={12}>
							<TextField
								label="Mileage"
								InputLabel="Mileage"
								placeholder="Mileage"
								fullWidth={true}
								variant="outlined"
								className={classes.input}
								size='small'
								color='secondary'

								defaultValue={defaultValue}
								inputError={errors?.main?.workorderOdometer}
								error={errors?.main?.workorderOdometer || false}
								disabled={disabled}

								onChange={(event) => {
									setAppointment({
										...appointment,
										workorderOdometer: event.target.value
									})
								}}

								InputProps={{
									inputComponent: NumberFormatMileageTextBox,
								}}

								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>

						{errors?.main?.workorderOdometer && <Typography variant='caption' color='error'>{errors?.main?.workorderOdometer}</Typography>}
					</Grid>
				}
			/>
		</Card>
	);
};

export default AppointmentMileage;
