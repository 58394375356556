import {
	Typography,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	LinearProgress,
	withStyles,
	Box,
	Link,
	useTheme,
	alpha,
} from '@material-ui/core';
import { NumberFormatCurrencyText } from 'app/components/textbox';
import { useHistory } from 'react-router-dom';

export const AgeingTable = (props) => {
	const { data } = props;
	const theme = useTheme();
	const history = useHistory();

	return (
		<TableContainer
			style={{
				width: '100%',
			}}
		>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell size="small">
							<Typography variant="body2">
								<b>Age</b>
							</Typography>
						</TableCell>
						<TableCell align="center" size="small">
							<Typography variant="body2">
								<b># Invoices</b>
							</Typography>
						</TableCell>
						<TableCell align="right" size="small">
							<Typography variant="body2">
								<b>Balance</b>
							</Typography>
						</TableCell>
						<TableCell align="right" size="small" style={{ minWidth: 80 }}></TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{data?.map((row, rowIdx) => {
						const dotColor = () => {
							if (rowIdx === 0) return 'green';
							if (rowIdx === 1) return 'orange';
							return 'red';
						};
						return (
							<TableRow key={`ageing-row-${rowIdx}`}>
								<TableCell size="small" style={{ border: 0 }}>
									<Link
										component="button"
										variant="caption"
										color="textPrimary"
										onClick={() => row.link && history.push(row.link.trim().slice(0, -1))}
										style={{
											marginTop: 0,
											color: theme.palette.text.primary,
											textDecorationLine: 'underline',
											textDecorationStyle: 'dotted',
											textDecorationColor: theme.palette.text.disabled,
											'& :hover': {
												textDecorationColor: theme.palette.text.primary,
											},
										}}
										noWrap={true}
										display="inline"
									>
										{row?.label}
									</Link>
								</TableCell>

								<TableCell align="center" size="small" style={{ border: 0 }}>
									<Typography>{row?.count}</Typography>
								</TableCell>

								<TableCell align="right" size="small" style={{ border: 0 }}>
									<Typography>{NumberFormatCurrencyText(row?.balance)}</Typography>
								</TableCell>

								<TableCell size="small" style={{ border: 0 }}>
									<LinearProgressWithLabel value={row?.percentage} barColor={dotColor()} />
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

function LinearProgressWithLabel(props) {
	const theme = useTheme();
	const { barColor, ...rest } = props;
	return (
		<Box display="flex" alignItems="center">
			<Box width="100%" mr={1}>
				<BorderLinearProgress variant="determinate" {...rest} style={{ color: barColor }} />
			</Box>
			<Box minWidth={10}>
				<Typography
					variant="body2"
					style={{
						fontSize: '0.6rem',
						color: Math.round(props.value) > 0 ? theme.palette.text.secondary : alpha(theme.palette.divider, 0.2),
					}}
				>{`${Math.round(props.value)}%`}</Typography>
			</Box>
		</Box>
	);
}

const BorderLinearProgress = withStyles((theme) => ({
	root: {
		height: 10,
		borderRadius: 5,
	},
	colorPrimary: {
		backgroundColor: alpha(theme.palette.background.default, 0.8),
	},
	bar: {
		borderRadius: 5,
		backgroundColor: (props) => props.barColor,
	},
}))(LinearProgress);

export default AgeingTable;
