import React, { forwardRef } from 'react';
// material
import { makeStyles } from '@material-ui/core/styles';
import { Typography, useTheme } from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiTypography-root': {
			color: '#000',
		},
		'& .MuiOutlinedInput-input': {
			color: '#000',
			padding: 6,
			fontSize: '0.8rem',
		},
	},
	newCell: {
		borderColor: theme.palette.divider,
		borderStyle: 'solid',
		borderWidth: '1px',
		fontSize: '0.8rem',
		padding: '0px 10px 0px 10px',
		backgroundColor: 'white',
		color: 'black',
	},
}));

export const TextBoxFinance = forwardRef((props, ref) => {
	const classes = useStyles();
	const theme = useTheme();
	const { id, name, value, placeholder, onChange, onKeyDown } = props;

	return (
		<div
			style={{
				width: '100%',
				overflow: 'hidden',
				display: 'flex',
				flex: 1,
			}}
		>
			<div style={{ float: 'left', flex: 1 }} className={classes.root}>
				<Typography variant="caption" style={{ color: theme.palette.text.disabled }}>
					{placeholder}
				</Typography>
				<CurrencyTextField
					ref={ref}
					id={id || name}
					name={name}
					variant="outlined"
					currencySymbol="R "
					outputFormat="string"
					className={classes.newCell}
					size="small"
					placeholder={placeholder || 0}
					onKeyDown={onKeyDown}
					onChange={(event, value) => {
						onChange({
							target: {
								name,
								value,
							},
						});
					}}
				/>
			</div>
		</div>
	);
});
