import React from 'react';
import moment from 'moment';

//@Material
import { useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

// this controls
import { ListBoxDisplay } from '../card/listbox';
import { TextBoxDisplay } from '../card/textbox';
import { MultiLineDisplay } from '../card/multiline';
import { NumberFormatCurrencyText } from '../textbox/currency';
import { DurationDescription } from '../textbox/dates';

export const KeyPairDisplay = ({ label = '', value = '', showingMore = false, template = {}, userHasChangedOriginal = false }) => {
	// SHOW NOTHING for these value combinations
	if (template.showmore && !showingMore) return null;
	if (!value && !showingMore && !template.showEmpty) return null;

	const theme = useTheme();

	// RETURN MAIN
	return (
		<Grid container spacing={2} alignItems={'flex-start'} justifyContent="flex-start">
			{/* LABEL */}
			{!template.noLabel && (
				<Grid item sm={12} md={4}>
					<Typography variant="body2" noWrap style={{ color: theme.palette.text.secondary }}>
						{label}
					</Typography>
				</Grid>
			)}

			{/* ITEMS */}
			<Grid item xs={template.noLabel ? 12 : 12} md={template.noLabel ? 12 : 8}>
				<Grid container justifyContent={template?.alignRight ? 'flex-end' : 'flex-start'}>
					{template.type === 'multiline' && <MultiLineDisplay name={template.id} value={value} template={template} />}

					{template.type === 'address' && <MultiLineDisplay name={template.id} value={value} template={template} />}

					{template.type === 'list' && <ListBoxDisplay value={value} template={template} />}

					{template.type === 'currency' && <Typography variant="body2">{NumberFormatCurrencyText(value)}</Typography>}

					{template.type === 'date' && <Typography variant="body2">{moment(value).format('ddd, DD MMMM YYYY')}</Typography>}

					{template.type === 'dateAgo' && (
						<>
							<Grid container spacing={1} alignItems="center">
								<Grid item>
									<Typography variant="body2">{moment(value).format('ddd, DD MMMM YYYY')}</Typography>
								</Grid>

								<DurationDescription value={value} />
							</Grid>
						</>
					)}

					{template.type !== 'list' &&
						template.type !== 'multiline' &&
						template.type !== 'currency' &&
						template.type !== 'date' &&
						template.type !== 'dateAgo' &&
						template.type !== 'address' && (
							<TextBoxDisplay
								value={value}
								template={template}
								type={template.type}
								link={template.link && !userHasChangedOriginal ? template.link : null}
							/>
						)}
				</Grid>
			</Grid>
		</Grid>
	);
};
