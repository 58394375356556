import React from 'react';
import moment from 'moment';
import { formatDistanceToNow } from 'date-fns';
//Material
import { useTheme } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
// Shared Components
import { NumberFormatCurrencyText } from '../../../app/components/textbox/currency';
import { QuoteStatusBadge } from '../../../app/components/card/status';
// import { CommentsV2Contained } from '../../../ui-components/controls/comments'
import QuotePopup from '../../../app/widgets/quote/popup';
import VehiclePopup from '../../../app/widgets/vehicle';
import ContactPopup from '../../../app/widgets/contact';
import BusinessPopup from '../../../app/widgets/business';

// LISTS ALL QUOTES
export const QuotesTableColumns = (query, refreshPage) => {
	const theme = useTheme();

	const styles = {
		blueColumn: {
			backgroundColor: 'rgba(0, 88, 155, 0.3)',
		},
		orangeColumn: {
			backgroundColor: theme.palette.type === 'dark' ? theme.palette.warning.dark : theme.palette.warning.main,
		},
		redColumn: {
			backgroundColor: 'rgba(255, 77, 65, 0.3)',
		},
		normalCell: {},
		header: {
			backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.primary.main,
			color: theme.palette.type === 'dark' ? 'inherit' : theme.palette.common.white,
			border: 0,
		},
	};

	return [
		{
			title: 'Status',
			field: 'quoteStatus',
			disableClick: true,
			cellStyle: { ...styles.normalCell },
			headerStyle: { ...styles.header },
			defaultSort: query.orderColumn === 'quoteStatus' ? query.orderDirection : null,
			render: function render(row) {
				return (
					<Grid container alignItems="center" spacing={2}>
						<Grid item xs={8}>
							<QuoteStatusBadge
								quoteId={row?._id}
								initialStatus={row?.quoteStatus}
								chipStyle={{
									marginTop: 5,
									marginBottom: 5,
									fontSize: '0.8rem',
								}}
							/>
						</Grid>
					</Grid>
				);
			},
		},
		//* quote
		{
			title: 'Quote',
			field: 'quoteNumber',
			disableClick: true,
			cellStyle: {
				...styles.normalCell,
				minWidth: 200,
			},
			headerStyle: { ...styles.header },
			defaultSort: query.orderColumn === 'quoteNumber' ? query.orderDirection : null,
			render: function render(row) {
				return (
					<>
						<QuotePopup quoteId={row?._id} quoteNumber={row?.quoteNumber} meta={row} type="link" refreshPage={refreshPage} />
						<Box
							style={{
								flexDirection: 'row',
							}}
						>
							{row?.quoteStatus === 'Approved' && (
								<>
									<Typography variant="body2" display="block" noWrap color="textSecondary">
										<b>Approved by</b>
									</Typography>
									<Typography variant="body2" display="block" noWrap>
										{row?.approvedBy}
									</Typography>
								</>
							)}
						</Box>
					</>
				);
			},
		},
		//* contact
		{
			title: 'Contact',
			field: 'contact.contactFullName',
			disableClick: true,
			cellStyle: {
				...styles.normalCell,
				minWidth: 200,
			},
			headerStyle: { ...styles.header },
			defaultSort: query.orderColumn === 'quoteNumber' ? query.orderDirection : null,
			render: function render(row) {
				return (
					<>
						{/* <QuotePopup quoteId={row?._id} quoteNumber={row?.quoteNumber} meta={row} type="link" refreshPage={refreshPage} /> */}

						<ContactPopup contactId={row?.contactId} contactFullname={row?.contact.contactFullname} />

						{row?.vehicle?.vehicleRegistration && (
							<Grid container>
								<VehiclePopup
									vehicleId={row?.vehicle?._id}
									vehicleRegistration={row?.vehicle?.vehicleRegistration}
									meta={row?.vehicle}
									type="action"
								/>
							</Grid>
						)}

						{row?.contact?.contactFullname && (
							<Grid container>
								<ContactPopup
									contactId={row?.contact?._id}
									contactFullname={row?.contact?.contactFullname}
									meta={row?.contact}
									type="action"
								/>
							</Grid>
						)}

						{row?.business?.businessName && (
							<Grid container>
								<BusinessPopup
									businessId={row?.business?._id}
									businessName={row?.business?.businessName}
									meta={row?.business}
									type="action"
								/>
							</Grid>
						)}
					</>
				);
			},
		},
		//* dates
		{
			title: 'Dates',
			field: 'quoteDate',
			cellStyle: {
				...styles.normalCell,
			},
			width: '40%',
			headerStyle: { ...styles.header },
			defaultSort: query.orderColumn === 'quoteDate' ? query.orderDirection : null,
			render: function render(row) {
				return (
					<>
						<DateDistance label="Issued on:" date={row?.quoteDate} />
						{['Awaiting Approval', 'Draft'].includes(row?.quoteStatus) && (
							<DateDistance label="Expires on:" date={row?.quoteExpiryDate} />
						)}
						{row?.quoteStatus === 'Approved' && <DateDistance label="Approved on:" date={row?.quoteApproveDate} />}
					</>
				);
			},
		},
		{
			title: 'Discount',
			field: 'totals.quoteDiscount',
			align: 'right',
			cellStyle: { ...styles.normalCell },
			headerStyle: { ...styles.header },
			defaultSort: query.orderColumn === 'totals.quoteDiscount' ? query.orderDirection : null,
			render: function render(row) {
				return (
					<>
						<Typography variant="body2" display="block" color={row?.totals.quoteDiscount > 0 ? 'inherit' : 'textSecondary'}>
							{NumberFormatCurrencyText(row?.totals.quoteDiscount, 2)}
						</Typography>
					</>
				);
			},
		},
		{
			title: 'Total',
			field: 'totals.quoteTotal',
			align: 'right',
			headerStyle: { ...styles.header },
			defaultSort: query.orderColumn === 'quoteSubTotal' ? query.orderDirection : null,
			render: function render(row) {
				return (
					<>
						<Typography variant="body2" display="block" color={row?.totals.quoteSubTotal > 0 ? 'inherit' : 'textSecondary'}>
							{NumberFormatCurrencyText(row?.totals.quoteSubTotal, 2)}
						</Typography>
					</>
				);
			},
		},
		{ field: 'contact.contactFullname', hidden: true, searchable: true },
		{ field: 'contact.contactEmail', hidden: true, searchable: true },
		{ field: 'vehicle.vehicleRegistration', hidden: true, searchable: true },
		{ field: 'vehicle.vehicleMake', hidden: true, searchable: true },
		{ field: 'vehicle.vehicleModel', hidden: true, searchable: true },
		{ field: 'totals.quoteBalance', hidden: true, searchable: true },
		{ field: 'totals.quoteSubtotal', hidden: true, searchable: true },
		{ field: 'totals.payments.cash', hidden: true, searchable: true },
		{ field: 'totals.payments.card', hidden: true, searchable: true },
		{ field: 'totals.payments.eft', hidden: true, searchable: true },
	];
};

export const DateDistance = ({ label, date }) => {
	const theme = useTheme();

	return (
		<>
			<Typography variant="body2" display="block" noWrap color="textSecondary">
				<b>{label}</b>
			</Typography>
			<Box
				style={{
					display: 'flex',
					flex: 1,
					flexDirection: 'row',
					borderBottom: `1px solid ${theme.palette.divider}`,
					marginBottom: 10,
				}}
			>
				{!date && <Typography variant="body2">-</Typography>}
				{date && (
					<Typography variant="body2">
						{`${moment(date).format('DD MMM YYYY')} (${formatDistanceToNow(new Date(date), {
							addSuffix: true,
						})})`}
					</Typography>
				)}
			</Box>
		</>
	);
};
