import { httpGET, httpPOST } from './data-handler';


// GET
// -------------------
export const GetVehicle = async (req) => await httpGET({ path: `vehicle/${req.queryKey[1]}`, data: null })
export const GetVehicleDetailed = async (req) => await httpGET({ path: `vehicle/${req.queryKey[1]}/detailed`, data: null })
export const GetVehicles = async () => await httpGET({ path: `vehicles`, data: null })

export const GetPreferedSelectionsByVehicleId = async (req) => await httpGET({ path: `vehicle/assoc/${req.queryKey[1]}`, data: null })


// POST
// -------------------
export const UpdateVehicle = async (data) => await httpPOST({
    path: `vehicle/update`,
    data: {
        vehicleID: data._id,
        vehicleMake: data.vehicleMake,
        vehicleModel: data.vehicleModel,
        vehicleRegistration: data.vehicleRegistration,
        vehicleColor: data.vehicleColor,
        vehicleYear: data.vehicleYear,
        vehicleVINNumber: data.vehicleVINNumber,
        vehicleEngineNumber: data.vehicleEngineNumber,
        vehicleChassisNumber: data.vehicleChassisNumber,
    }
})

