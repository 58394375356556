// import { useState, useEffect } from 'react';
import NumberFormat from "react-number-format"

export function NumberFormatMileageText(value) {
    return (
        <NumberFormat
            value={value}
            thousandSeparator
            suffix=" km's"
            displayType='text'
        />
    );
}

export function NumberFormatMileageTextbox(props) {
    const { inputRef, defaultValue, value, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            defaultValue={value || defaultValue}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value || 0
                    }
                });
            }}
            thousandSeparator
            isNumericString
            fixedDecimalScale={true}
            style={{ textAlign: 'left' }}
        />
    );
}