import { createContext, useContext } from 'react';

const BusinessContext = createContext({
    Business: null,
});

export function useBusinessContext() {
    return useContext(BusinessContext);
}

export default BusinessContext;