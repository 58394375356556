import { httpGET, httpPOST } from './data-handler';

// GET
// -------------------

// Get quote(s) by different methods
// ------------------------------------------------------------------------------------------------------------------------------
export const GetPublicQuote = async (req) => await httpGET({ path: `approve/getQuote/${req.queryKey[1]}`, data: null, retKey: 'quote' });
export const GetQuote = async (req) => await httpGET({ path: `quote/${req.queryKey[1]}`, data: null, retKey: 'quote' });
export const GetQuotes = async () => await httpGET({ path: 'quotes', data: null });
export const GetQuoteById = async (req) => await httpGET({ path: `quote/${req.queryKey[1]}`, data: null });
export const GetActiveQuotes = async () => await httpGET({ path: 'quotes/active', data: null });
export const GetQuoteByWorkorderId = async (req) => await httpGET({ path: `quote/workorder/${req.queryKey[1]}`, data: null });
export const GetQuoteItemsByQuoteId = async (req) => await httpGET({ path: `quote/items/${req.queryKey[1]}`, data: null });
export const GeneratePdfByQuoteId = async (req) => await httpGET({ path: `quote/${req.queryKey[1]}/pdf`, data: null });
export const GetContactQuotes = async (req) => await httpGET({ path: `quote/contact/${req.queryKey[1]}`, data: null });

// Get sub-items for a specific quote
// ------------------------------------------------------------------------------------------------------------------------------
export const GetQuoteVehicle = async (req) => await httpGET({ path: `quote/${req.queryKey[1]}/vehicle`, data: null });
export const GetQuoteBusiness = async (req) => await httpGET({ path: `quote/${req.queryKey[1]}/business`, data: null });
export const GetQuoteContact = async (req) => await httpGET({ path: `quote/${req.queryKey[1]}/contact`, data: null });
export const GetQuoteReminder = async (req) => await httpGET({ path: `quote/${req.queryKey[1]}/reminder`, data: null });
export const GetQuoteWarranty = async (req) => await httpGET({ path: `quote/${req.queryKey[1]}/warranty`, data: null });

// POST
// -------------------
export const CreateQuote = async (data) => await httpPOST({ path: 'quote', data: data });
export const CreateQuoteFromWorkorder = async (data) => await httpPOST({ path: 'quoteFromWorkorder', data: data });
export const CreateQuoteItem = async (data) => await httpPOST({ path: 'quote/item/create', data: data });

export const DeleteQuoteItem = async (data) => await httpPOST({ path: 'quote/delete/item', data: data });
export const DeleteQuote = async (data) => await httpPOST({ path: 'quote/delete', data: data });

export const UpdateQuoteItem = async (data) => await httpPOST({ path: 'quote/item/update', data: data });
export const UpdateQuoteDiscount = async (data) => await httpPOST({ path: 'quote/discount', data: data });
export const UpdateQuoteStatus = async (data) => await httpPOST({ path: 'quote/status', data: data });
export const UpdateQuoteDate = async (data) => await httpPOST({ path: 'quote/date', data: data });
export const UpdateQuoteVehicle = async (data) => await httpPOST({ path: `quote/vehicle`, data: data });
export const UpdateQuoteBusiness = async (data) => await httpPOST({ path: `quote/business`, data: data });
export const UpdateQuoteContact = async (data) => await httpPOST({ path: `quote/contact`, data: data });
export const UpdateQuoteReminder = async (data) => await httpPOST({ path: `quote/reminder`, data: data });
export const UpdateQuoteWarranty = async (data) => await httpPOST({ path: `quote/warranty`, data: data });
export const UpdateBatchQuoteItems = async (data) => await httpPOST({ path: 'quote/items/update', data: data });

export const AssignQuoteTemplate = async (data) => await httpPOST({ path: 'quote/costs/assign', data: data });
export const CreateQuoteGroupHeaderSaveAsTemplate = async (data) => await httpPOST({ path: 'quote/costs/template', data: data });
export const LoadQuoteTemplate = async (data) => await httpGET({ path: `quote/costs/template/${data.templateName}`, data: data });
export const LoadQuoteTemplateNames = async () => await httpGET({ path: `quote/costs/templates`, data: null });
export const DeleteQuoteGroup = async (data) => await httpPOST({ path: 'quote/costs/deletegroup', data: data });

export const SendQuoteByEmail = async (data) => await httpPOST({ path: 'quote/send', data: data });
