// material
import { makeStyles } from '@material-ui/core/styles';
import { Modal as MuiModal, Slide, Button, Grid, Typography, IconButton, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
	modalDialog: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	modalContent: {
		border: '0.5px solid rgba(255,255,255,0.38)',
		borderRadius: 10,
		display: 'flex',
		position: 'relative',
		flexDirection: 'column',
	},
	modalHeader: {
		backgroundColor: theme.palette.secondary.main,
		backgroundImage: `linear-gradient(to bottom right, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
		color: theme.palette.getContrastText(theme.palette.primary.main),

		marginTop: '0.65rem',
		marginLeft: '0.65rem',
		marginRight: '0.65rem',

		paddingTop: '0.5rem',
		paddingBottom: '0.5rem',
		paddingLeft: '1rem',
		paddingRight: '1rem',

		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,

		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'flex-end',
	},
	modalBody: {
		padding: 10,
		color: theme.palette.getContrastText(theme.palette.background.paper),
		overflow: 'auto',
		flex: 1,
	},
	modalFooter: {
		backgroundColor: theme.palette.background.default,
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: '2rem',
		paddingRight: '2rem',
	},
	savebutton: {
		border: `1px solid ${theme.palette.success.light}`,
		color: `1px solid ${theme.palette.success.light}`,
	},
}));

export default function Modal({
	header = '',
	saveFn = null,
	saveText = 'Save',
	size = 'md',
	fixedSize = false,
	open = false,
	setOpen,
	onClose = null,
	children,
	additionalButtons = [],
}) {
	const classes = useStyles();

	// const { setOpen: setSearchOpen } = useSearchContext()

	let sizeWidth = size;

	if (size === 'sm') {
		sizeWidth = '35vw';
	}
	if (size === 'md') {
		sizeWidth = '65vw';
	}
	if (size === 'lg') {
		sizeWidth = '85vw';
	}
	if (size === 'xxl') {
		sizeWidth = '95vw';
	}

	let sizeStyle = {};
	if (fixedSize) sizeStyle = { width: sizeWidth, minHeight: '65vh', maxHeight: '90vh' };
	if (!fixedSize) sizeStyle = { width: sizeWidth, maxHeight: '90vh', minHeight: '90vh' };

	return (
		<Slide in={open || false}>
			<MuiModal
				className={classes.modalDialog}
				open={open || false}
				onClose={(e, reason) => {
					if (reason === 'backdropClick') return null;
					setOpen(false);
				}}
				keepMounted={true}
				disablePortal
			>
				<Paper elevation={24} style={{ borderRadius: 10 }}>
					<div className={classes.modalContent} style={sizeStyle}>
						{/* HEADER */}
						<div className={classes.modalHeader}>
							<Grid container alignItems="center">
								<Grid item xs={6}>
									<Typography variant="h6">
										<b>{header}</b>
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Grid container justifyContent="flex-end">
										{additionalButtons || null}

										<IconButton
											size="small"
											color="inherit"
											onClick={() => {
												if (onClose) onClose();
												setOpen(false);
											}}
											style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
										>
											<CloseIcon />
										</IconButton>
									</Grid>
								</Grid>
							</Grid>
						</div>

						{/* BODY */}
						<div className={classes.modalBody}>{children}</div>

						{/* FOOTER */}
						{saveFn && (
							<div className={classes.modalFooter}>
								<Grid container alignItems="center" justifyContent="flex-end">
									<Grid xs={2}>
										<Paper elevation={12}>
											<Button fullWidth variant="outlined" className={classes.savebutton} onClick={saveFn}>
												{saveText.toUpperCase()}
											</Button>
										</Paper>
									</Grid>
								</Grid>
							</div>
						)}
					</div>
				</Paper>
			</MuiModal>
		</Slide>
	);
}
