import React, { useState } from 'react';

// Material
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

//Context
import { useContactContext } from '../../../../context/contact-context';

//Pages
import { ContactVehicles } from './contact-vehicles';
import { ContactInvoices } from './contact-invoices';

import { StyledTabs, StyledTab, TabPanel } from '../../../components/tabs';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		border: `1px solid ${theme.palette.divider}`,
	},
	tabBody: {
		height: '50vh',
		overflow: 'auto',
	},
}));

export default function ContactDetailTabs() {
	const classes = useStyles();
	const [value, setValue] = useState(0);
	const { contact } = useContactContext();

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div className={classes.root}>
			<Paper elevation={24} square={true}>
				<StyledTabs value={value} onChange={handleChange} scrollButtons="auto" variant="scrollable">
					<StyledTab label="INVOICES" count={contact?.invoices?.invoices?.length} />
					<StyledTab label="VEHICLES" count={contact?.vehicles?.length} />
				</StyledTabs>

				<TabPanel value={value} index={0} className={classes.tabBody}>
					<ContactInvoices />
				</TabPanel>

				<TabPanel value={value} index={1} className={classes.tabBody}>
					<ContactVehicles />
				</TabPanel>
			</Paper>
		</div>
	);
}
