import React, { useState } from 'react';

// Material
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

//Context
import { useBusinessContext } from '../../../../context/business-context';

//Pages
import { BusinessContacts } from './business-contacts';
import { BusinessInvoices } from './business-invoices';
import { BusinessVehicles } from './business-vehicles';
import { BusinessWorkorders } from './business-workorders';

import { StyledTabs, StyledTab, TabPanel } from '../../../components/tabs';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		border: `1px solid ${theme.palette.divider}`,
	},
	tabBody: {
		height: '50vh',
		overflow: 'auto',
	},
}));

export default function BusinessDetailTabs() {
	const classes = useStyles();
	const [value, setValue] = useState(0);

	const { business } = useBusinessContext();

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div className={classes.root}>
			<Paper elevation={24} square={true}>
				{/* <StyledTabs value={value} onChange={handleChange} scrollButtons="auto" variant="scrollable" activeTab={activeTab}> */}
				<StyledTabs value={value} onChange={handleChange} scrollButtons="auto" variant="scrollable">
					<StyledTab label="VISITS" count={business?.workorders?.workorders?.length} />
					<StyledTab label="CONTACTS" count={business?.contacts?.length} />
					<StyledTab label="VEHICLES" count={business?.vehicles?.length} />
					<StyledTab label="INVOICES" count={business?.invoices?.invoices?.length} />
				</StyledTabs>

				<TabPanel value={value} index={0} className={classes.tabBody}>
					<BusinessWorkorders />
				</TabPanel>

				<TabPanel value={value} index={1} className={classes.tabBody}>
					<BusinessContacts />
				</TabPanel>

				<TabPanel value={value} index={2} className={classes.tabBody}>
					<BusinessVehicles />
				</TabPanel>

				<TabPanel value={value} index={3} className={classes.tabBody}>
					<BusinessInvoices />
				</TabPanel>
			</Paper>
		</div>
	);
}
