import React from 'react';

//@material
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Grid, Card, CardHeader, TextField } from '@material-ui/core';

//Context
import { useNewAppointmentContext } from '../../../../../../context/appointments-new-context';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		input: {
			[`& fieldset`]: {
				borderRadius: 0,
			},
		},
	})
);

export const AppointmentInstructions = ({ defaultValue = '', disabled = false, label = null }) => {
	const classes = useStyles();

	const { appointment, setAppointment, errors } = useNewAppointmentContext();

	return (
		<Card className={classes.root} elevation={0}>
			<CardHeader
				// style={{ marginBottom: 0, paddingBottom: 0 }}
				style={{
					paddingBottom: 0,
				}}
				title={
					<Grid container spacing={0} alignItems="flex-end">
						<Grid item xs={12}>
							<TextField
								label={label || 'Instructions'}
								placeholder={label || 'Instructions'}
								multiline
								rows={6}
								fullWidth={true}
								variant="outlined"
								className={classes.input}
								size="small"
								color="secondary"
								defaultValue={defaultValue}
								// inputError={errors?.main?.bookingInstructions}
								error={errors?.main?.bookingInstructions || false}
								disabled={disabled}
								onChange={(event) => {
									setAppointment({
										...appointment,
										bookingInstructions: event.target.value,
									});
								}}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>

						{errors?.main?.bookingInstructions && (
							<Typography variant="caption" color="error">
								{errors?.main?.bookingInstructions}
							</Typography>
						)}
					</Grid>
				}
			/>
		</Card>
	);
};

export default AppointmentInstructions;
