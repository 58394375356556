// Data
import { useMutation } from 'react-query';
import { UpdateContact, UpdateVehicle, UpdateBusiness } from '../../../../../../app/utils/data';

// MaterialUI
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

// Context
import { useNewAppointmentContext } from '../../../../../../context/appointments-new-context';

// Pages
import { NewContact } from './new-item-contact';
import { NewVehicle } from './new-item-vehicle';
import { NewBusiness } from './new-item-business';

const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: 500,

		'& .MuiDialogTitle-root': {
			backgroundColor: theme.palette.primary.main,
		},
	},
}));

export const NewItemDialog = ({
	table = '',
	contactId = null,
	vehicleId = null,
	businessId = null,
	open,
	setOpen,
	isNew = false,
	isNewInitialItem = '',
	isEditing = false,
}) => {
	const classes = useStyles();
	const theme = useTheme();

	const { appointment, setAppointment, errors, setErrors, isSaving, setIsSaving } = useNewAppointmentContext();

	const createContact = useMutation(UpdateContact, {
		onSettled: async (data) => {
			if (!isEditing) {
				setAppointment({
					...appointment,
					contact: data.contact,
				});
			}
		},
	});

	const createVehicle = useMutation(UpdateVehicle, {
		onSettled: async (data) => {
			if (!isEditing) {
				await setAppointment({
					...appointment,
					vehicle: data.vehicle,
				});
			}
		},
	});

	const createBusiness = useMutation(UpdateBusiness, {
		onSettled: async (data) => {
			if (!isEditing) {
				await setAppointment({
					...appointment,
					business: data.business,
				});
			}
		},
	});

	// FUNCTIONS
	// ----------------------------------------------------------------
	const createClick = async () => {
		if (!setErrors(appointment, table)) {
			setIsSaving(true);
			switch (table) {
				case 'contact':
					await createContact.mutate(appointment.contact);
					break;

				case 'vehicle':
					await createVehicle.mutate(appointment.vehicle);
					break;

				case 'business':
					await createBusiness.mutate(appointment.business);
					break;

				default:
					setOpen(!open);
					break;
			}

			setOpen(!open);
			setIsSaving(false);
		}
	};

	const cancelClick = () => {
		try {
			if (!isEditing) {
				if (errors && table) delete errors[table];
				if (appointment && table) delete appointment[table];
			}
			setOpen(!open);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Dialog open={open} className={classes.root} fullWidth={true}>
			<DialogTitle>{`${isEditing ? 'EDIT ' : 'NEW '}${table.toUpperCase()}`}</DialogTitle>

			<DialogContent style={{ paddingTop: 30 }}>
				<DialogContentText>
					{table === 'contact' && <NewContact isNew={isNew} isNewInitialItem={isNewInitialItem} contactId={contactId} />}
					{table === 'vehicle' && <NewVehicle isNew={isNew} isNewInitialItem={isNewInitialItem} vehicleId={vehicleId} />}

					{table === 'business' && <NewBusiness isNew={isNew} isNewInitialItem={isNewInitialItem} businessId={businessId} />}
				</DialogContentText>
			</DialogContent>

			<DialogActions style={{ backgroundColor: theme.palette.background.default, maringTop: 20 }}>
				<Button variant="outlined" onClick={() => cancelClick()}>
					Cancel
				</Button>
				<Button
					variant="outlined"
					onClick={() => createClick()}
					color="textPrimary"
					startIcon={isSaving ? <CircularProgress size={20} /> : null}
					disabled={isSaving}
				>
					{isSaving && 'Saving'}

					{!isSaving && !isEditing && 'Create'}
					{!isSaving && isEditing && 'Update'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
