import React from 'react';

//Import Template and Styles
import Page from '../../app/components/page';

//Security
import { useAuth0 } from '@auth0/auth0-react';

const Profile = () => {
    const { user } = useAuth0();

	return (
		<Page heading="PROFILE">
			<div>{JSON.stringify(user)}</div>
			<p></p>
			<p></p>
		</Page>
	);
};

export default Profile;
