// MaterialUI
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Divider } from '@material-ui/core';

// Query
import { useQuery } from 'react-query';
// import { CreateInvoiceFromWorkorder } from '../../../../utils/data'
import { NumberFormatCurrencyText } from '../../textbox';

// Styles
const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		backgroundColor: theme.palette.background.default,
	},
	container: {
		borderWidth: '1px',
		borderStyle: 'solid',
		borderColor: theme.palette.text.disabled,
	},
	paid: {
		backgroundColor: theme.palette.success.light,
		borderColor: theme.palette.success.dark,
		color: theme.palette.text.primary,

		border: '3px solid',
		borderRadius: 25,
		margin: 10,
		padding: 10,
	},
	paidText: {
		color: theme.palette.success.dark,
	},
	submitButton: {
		borderColor: theme.palette.warning.dark,
		borderRadius: 0,
	},
	yesButton: {
		color: theme.palette.success.dark,
		borderColor: theme.palette.success.dark,
		borderRadius: 0,
	},
}));

const Totals = ({ config }) => {
	const classes = useStyles();

	const { data, isLoading } = useQuery([config.query.queryKey, config.query.id], config.query.queryFn, { refetchOnWindowFocus: false });

	if (isLoading) return <p>Loading totals....</p>;

	// INVOICE SUB TOTALS
	if (config.type === 'invoice') {
		return (
			<div className={classes.container}>
				<Grid container className={classes.root}>
					<SubTotalKeyPair Key="Sub total" Value={NumberFormatCurrencyText({ amount: data?.invoiceSubTotal })} />
					<SubTotalKeyPair Key="Discount" Value={NumberFormatCurrencyText({ amount: data?.invoiceDiscount })} />
					<SubTotalKeyPair Key="VAT" Value={NumberFormatCurrencyText({ amount: data?.invoiceTAX })} />

					<Spacer />
					<SubTotalKeyPair Key="Total" Value={NumberFormatCurrencyText({ amount: data?.invoiceTotal })} Color="primary" isTotal={true} />

					<Spacer />
					<SubTotalKeyPair
						Key="Balance"
						Value={NumberFormatCurrencyText({ amount: data?.invoiceOutstanding })}
						Color="error"
						isTotal={true}
					/>
				</Grid>
			</div>
		);
	}
	// WORKORDER SUB TOTALS
	if (config.type === 'cost') {
		return (
			<div className={classes.container}>
				<Grid container className={classes.root}>
					<Grid item xs={12}>
						<Grid container justifyContent="center">
							<SubTotalKeyPair
								Key="Total"
								Value={NumberFormatCurrencyText(data?.workorder?.costTotal || 0, 2)}
								Color="primary"
								isTotal={true}
							/>
						</Grid>
					</Grid>
				</Grid>
			</div>
		);
	}
};

const Spacer = () => {
	return (
		<Grid item xs={12}>
			<Divider variant="middle" />
		</Grid>
	);
};

const SubTotalKeyPair = ({ Key = '', Value = 0, isTotal = false, Color = 'textPrimary', variant = 'button' }) => {
	return (
		<>
			<Grid item xs={6}>
				<Typography
					noWrap
					name="total"
					display="block"
					variant={variant}
					color={Color}
					style={isTotal ? { fontSize: '1.1rem' } : { fontSize: '0.8rem' }}
				>
					{Key.toUpperCase()}
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography
					noWrap
					display="block"
					variant={variant}
					color={Color}
					align="right"
					style={isTotal ? { fontSize: '1.1rem' } : { fontSize: '0.8rem' }}
				>
					{Value}
				</Typography>
			</Grid>
		</>
	);
};

export default Totals;
