import MomentUtils from '@date-io/moment';

//@Material UI
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import TodayIcon from '@material-ui/icons/Today';

const useStyles = makeStyles((theme) => ({
    picker: {
        marginTop: theme.spacing(1),
        // marginBottom: theme.spacing(1),
        // padding: 0,
    },
    input: {
        fontSize: 'inherit'
    }
}));


export const DateEdit = ({ field, selectedDate, onChange }) => {
    const classes = useStyles();

    const onChangeLocal = (value) => {
        const e = {
            target: {
                name: field.id,
                value: value,
            }
        }
        onChange(e)
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                id="update-workorder-date"
                name='date-picker'
                className={classes.picker}
                disableToolbar
                variant="inline"
                format="dd, DD MMM YYYY"
                margin="normal"
                value={selectedDate}
                onChange={(date) => onChangeLocal(date)}
                keyboardIcon={<Tooltip title='Overide the recieved date for this workorder' arrow={true} placement='right'><TodayIcon fontSize='small' /></Tooltip>}
                KeyboardButtonProps={{ size: 'small' }}
                autoOk={true}
                size='small'

                inputVariant="outlined"
                inputProps={{
                    // size: "small",
                    margin: "dense",
                    variant: "outlined",
                    style: { borderWidth: 0 },
                    fullWidth: true,
                    disabled: !field.canEdit,
                    color: 'secondary'
                    // variant: 'body2',
                    // style: {
                    //     // fontSize: '0.8rem',
                    // }
                }}
            />
        </MuiPickersUtilsProvider>
    )
}