// MaterialUI
import { useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// Components
import { WorkorderTable } from './components/workorders-table';
import { WorkordersMenu } from './components/workorders-menu';

export const WorkordersView = ({ isLoading }) => {
	const theme = useTheme();
	return (
		<Grid container spacing={0}>
			{/* MENU */}
			<Grid item xs={2} style={{ backgroundColor: theme.palette.background.paper }}>
				<WorkordersMenu />
			</Grid>

			{/* TABLE */}
			<Grid item xs={10} lg={10} style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
				<WorkorderTable isLoading={isLoading} />
			</Grid>
		</Grid>
	);
};
