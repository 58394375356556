import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useBusinessContext } from '../../../../context/business-context'
import { Table } from './table';

export const BusinessContacts = () => {
    const theme = useTheme();
    const { business } = useBusinessContext()

    // COLUMNS
    // -------------------------------
    const columns = [
        {
            title: 'Name', field: 'contactFullname',
            cellStyle: {
                maxWidth: 200,
                borderLeft: `1px solid ${theme.palette.divider}`,
                borderRight: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            render: function render(row) {
                return (
                    <>
                        <Typography variant='body2' display='block'>
                            {row.contactFullname}
                        </Typography>
                    </>
                )
            }
        },
        {
            title: 'Email', field: 'contactEmail',
            cellStyle: {
                maxWidth: 200,
                borderRight: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            render: function render(row) {
                return (
                    <>
                        <Typography variant='body2' display='block'>
                            {row?.contactEmail}
                        </Typography>
                    </>
                )
            }
        },
        {
            title: 'Mobile', field: 'contactMobile',
            cellStyle: {
                maxWidth: 200,
                // borderRight: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            render: function render(row) {
                return (
                    <>
                        <Typography variant='body2' display='block'>
                            {row?.contactMobile}
                        </Typography>
                    </>
                )
            }
        }
    ]

    return (
        <Table
            title='Contact'
            data={business?.contacts}
            columns={columns}
            url='contact'
        />
    )
}