import { useState } from 'react';
// MaterialUI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
// Icons
import DeleteIcon from '@material-ui/icons/Delete';
// Components
import { TextBox } from './TextBox';
import { NumberFormatCurrencyText } from '../textbox';
// Query
import { useMutation } from 'react-query';

// Styles
const useStyles = makeStyles((theme) => ({
	container: {
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
		},
		'&:hover span': {
			visibility: 'visible',
		},
	},
	hide: {
		visibility: 'hidden',
	},
	div_left: {
		float: 'left',
		flex: 0.8,
	},
	div_right: {
		float: 'left',
		flex: 0.2,
	},
	actionButtonsDelete: {
		borderRadius: 15,
		padding: 2,
		border: `1px solid ${theme.palette.error.dark}`,
		color: theme.palette.error.dark,
	},
}));

export const Row = ({ rowItem, rowIndex, itemsCount, config, disabled, refetch = null }) => {
	const classes = useStyles();

	// ---------------------------------------------------------------------------------------------------------------
	// HOOKS and STATE
	// ---------------------------------------------------------------------------------------------------------------
	const [row, setRow] = useState(rowItem);

	// ---------------------------------------------------------------------------------------------------------------
	// DATA
	// ---------------------------------------------------------------------------------------------------------------

	// Add Group Header before this row
	const addGroupHeader = useMutation(config.query.addGroupHeaderFn, {
		onSettled: () => {
			if (refetch) refetch();
			// if (config.query.queryKey === 'invoice') refetchInvoice()
			// if (config.query.queryKey === 'workorder') refetchWorkorder()
		},
	});

	// Update Row
	const update = useMutation(config.query.updateFn, {
		onSettled: () => {
			if (refetch) refetch();
			// if (config.query.queryKey === 'invoice') refetchInvoice()
			// if (config.query.queryKey === 'workorder') refetchWorkorder()
		},
	});

	// Delete Row
	const remove = useMutation(config.query.deleteFn, {
		onSettled: () => {
			if (refetch) refetch();
			// if (config.query.queryKey === 'invoice') refetchInvoice()
			// if (config.query.queryKey === 'workorder') refetchWorkorder()
		},
	});

	// ---------------------------------------------------------------------------------------------------------------
	// EVENTS
	// ---------------------------------------------------------------------------------------------------------------
	// Set the state
	// ---------------------------------------------------------------------
	const onChange = (e) => {
		setRow({
			...row,
			[e.target.name]: e.target.value,
		});
	};

	//Save on blur when value is different
	// ---------------------------------------------------------------------
	const onBlur = () => {
		if (row !== rowItem) {
			update.mutate(row);
		}
	};

	// Keyboard Events
	// ---------------------------------------------------------------------
	const onKeyPress = async (e) => {
		// Save on Enter key if value is different
		// ---------------------------------------------------------------------
		if (e.keyCode == 13) {
			if (row !== rowItem) {
				// Do not save if the required fields are empty
				config.headers.map((header) => {
					if (header.required) {
						if (config.query.dbReturnSubKey) {
							if (!row?.[config.query.dbReturnKey]?.[config.query.dbReturnSubKey]?.[header.dbField]) {
								return null;
							}
						}
					}
				});

				//Submit value to db
				update.mutate(row);
			}
		}

		// Move Cursor UP when Up Arrow pressed
		// ---------------------------------------------------------------------
		if (e.keyCode == 38 && !row.isGroupHeader) {
			//Get row and col from the fields id
			let tableRow = Number(e.target.id.split('_')[0]) - 1;
			let col = Number(e.target.id.split('_')[1]);

			// Get the element
			let el = document.getElementById(`${tableRow}_${col}`);

			if (el) {
				// Move up until it reaches the first line
				if (tableRow >= 0) el.focus();

				// Set focus to end of text
				if (tableRow >= 0)
					setTimeout(function () {
						el.selectionStart = el.selectionEnd = 500;
					}, 0);
			}
		}

		// Move Cursor DOWN when Down Arrow pressed
		// ---------------------------------------------------------------------
		if (e.keyCode == 40 && !row.isGroupHeader) {
			//Get row and col from the fields id and set to new row to focus
			let tableRow = Number(e.target.id.split('_')[0]) + 1;
			let col = Number(e.target.id.split('_')[1]);

			// Get the element
			let el = document.getElementById(`${tableRow}_${col}`);

			if (el) {
				// Move up until it reaches the last line
				if (tableRow < itemsCount) document.getElementById(`${tableRow}_${col}`).focus();
			}

			//Gone past thelast lie, so skip to 'New Line' control
			if (tableRow === itemsCount) document.getElementById(`new_${col}`).focus();
		}
	};

	const addGroupHeaderOnClick = async () => {
		await addGroupHeader.mutate({
			id: row?.workorderID || row?.invoiceID,
			addAboveId: row._id,
		});
	};

	// MAIN
	// ---------------------------------------------------------------------------------------------------------------
	return (
		// ROW
		// ---------------------------------------------------------------------------
		<Grid
			container
			id={`row_${rowIndex}_item`}
			// spacing={1}
			alignItems="center"
			style={{ marginBottom: 1 }}
			className={classes.container}
		>
			{/* MAP COLUMNS 
            --------------------------------------------------------------------------- */}
			{config.headers.map((header, i) => {
				if (!header.isTotal)
					return (
						// NORMAL COLUMN
						// ---------------------------------------------------------------------------
						<Grid item xs={header.size.xs} key={i}>
							<TextBox
								name={header.dbField}
								id={`${rowIndex}_${i}`}
								defaultValue={rowItem?.[header.dbField]}
								onChange={onChange}
								onKeyDown={onKeyPress}
								onBlur={onBlur}
								showDragger={i === 0 ? true : false}
								addGroupHeaderOnClick={addGroupHeaderOnClick}
								center={header.align || false}
								align={header.align}
								isFinancial={header.type === 'financial' ? true : false}
								isPercentage={header.type === 'percentage' ? true : false}
								isNumeric={header.type === 'numeric' ? true : false}
								type={header.type || 'text'}
								disabled={disabled}
							/>
						</Grid>
					);

				//* TOTAL
				if (header.isTotal)
					return (
						<Grid item xs={header.size.xs} key={i}>
							<div
								style={{
									width: '100%',
									overflow: 'hidden',
									display: 'flex',
									flexDirection: 'row',
									flex: 1,
								}}
							>
								{/* //* total */}
								<div className={classes.div_left}>
									<Typography
										name={header.dbField}
										id={`${rowIndex}_${i}`}
										variant="button"
										noWrap
										display="block"
										align="right"
										color={disabled ? 'textSecondary' : 'textPrimary'}
									>
										{/* // INT-CURRENCY */}
										{NumberFormatCurrencyText(rowItem?.[header.dbField] || 0, 2)}
									</Typography>
								</div>

								{/* //* actions */}
								<div className={classes.div_right}>
									<span className={classes.hide}>
										<Tooltip title="Delete this item" arrow={true} placement="left">
											<IconButton
												color="primary"
												component="span"
												onClick={() => remove.mutate(rowItem._id)}
												size="small"
												style={{ marginLeft: 5 }}
												disabled={disabled}
											>
												<DeleteIcon className={classes.actionButtonsDelete} style={{ fontSize: '1.2rem' }} />
											</IconButton>
										</Tooltip>
									</span>
								</div>
							</div>
						</Grid>
					);
			})}
		</Grid>
	);
};
