import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

// @MaterialUI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, FormControl, TextField, Avatar, Typography, alpha } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

// Context
import { useAppointmentsContext } from '../../../../../context/appointments-context';
import { useSettingsContext } from '../../../../../context/settings-context';

const useStylesDatePicker = makeStyles((theme) => ({
	container: {
		'& .MuiToolbar-root': {
			backgroundColor: theme.palette.background.default,
		},
		'& .MuiPickersBasePicker-pickerView': {
			display: 'flex',
			flex: 1,
			maxWidth: '100%',
		},
		'& .MuiPickersDay-current': {
			color: 'white',
			'& .MuiIconButton-label': {
				'& .MuiTypography-root': {
					fontWeight: '900',
					fontSize: '1.2rem',
				},
			},
		},
	},
	avatarCount: {
		height: 20,
		width: 20,
		position: 'absolute',
		top: -5,
		left: -5,
		color: theme.palette.text.disabled,
		zIndex: 99,
	},
	dayWithDotContainer: {
		zIndex: 101,
		position: 'relative',
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	dayWithDotContainerBlanked: {
		backgroundColor: 'rgba(244, 67, 54, 0.2)',
		zIndex: 101,
		position: 'relative',
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	dayWithDot: {
		position: 'absolute',
		height: 0,
		width: 0,
		border: '2px solid',
		borderRadius: 4,
		borderColor: theme.palette.success.light,
		right: '50%',
		transform: 'translateX(1px)',
		top: '80%',
	},
	dayBlanked: {
		backgroundColor: 'rgba(244, 67, 54, 0.4)',
	},
	input: {
		[`& fieldset`]: {
			borderRadius: 0,
		},
	},
}));

export const ByDateAppointmentsInSideBar = () => {
	const { appointments } = useAppointmentsContext();
	return (
		<Grid container spacing={0}>
			<Grid item xs={12}>
				<SearchQueryFilterDate name="From" searchKey="bookingDateFrom" values={appointments?.query?.bookingDateFrom} />

				<SearchQueryFilterDate name="To" searchKey="bookingDateTo" values={appointments?.query?.bookingDateTo} />
			</Grid>
		</Grid>
	);
};

export const SearchAppointmentsInSideBar = () => {
	const { appointments } = useAppointmentsContext();
	const { lists } = useSettingsContext();

	return (
		<Grid container spacing={0}>
			<SearchQueryFilterInput
				name="Ref #"
				searchKey="bookingNumber"
				primaryKey="bookingNumber"
				options={lists?.bookings}
				values={appointments?.query?.bookingNumber}
			/>

			<SearchQueryFilterInput
				name="Status"
				searchKey="bookingStatus"
				primaryKey="bookingStatus"
				options={lists?.bookingStatus}
				values={appointments?.query?.bookingStatus}
			/>

			<SearchQueryFilterInput
				name="Contact"
				searchKey="contactIDs"
				primaryKey="contactFullname"
				options={lists?.contacts}
				values={appointments?.query?.contactIDs}
			/>

			<SearchQueryFilterInput
				name="Vehicle"
				searchKey="vehicleIDs"
				primaryKey="vehicleRegistration"
				options={lists?.vehicles}
				values={appointments?.query?.vehicleIDs}
			/>

			<SearchQueryFilterInput
				name="Business"
				searchKey="businessIDs"
				primaryKey="businessName"
				options={lists?.business}
				values={appointments?.query?.businessIDs}
			/>

			<Grid item xs={12}>
				<SearchQueryFilterDate name="From" searchKey="bookingDateFrom" values={appointments?.query?.bookingDateFrom} />

				<SearchQueryFilterDate name="To" searchKey="bookingDateTo" values={appointments?.query?.bookingDateTo} />
			</Grid>
		</Grid>
	);
};

// Search item with INPUT SELECT
const SearchQueryFilterInput = ({ name, primaryKey, searchKey, options = [], values = [] }) => {
	const theme = useTheme();
	const { searchQuery, setView } = useAppointmentsContext();

	return (
		<Grid container alignItems="center">
			<Grid item xs={12}>
				<FormControl hiddenLabel={true} fullWidth={true}>
					<Autocomplete
						id={`SearchQueryFilterInput_${name}`}
						multiple
						clearOnBlur
						value={values}
						options={options}
						getOptionLabel={(option) => option?.[primaryKey] || ''}
						renderInput={(params) => (
							<TextField
								{...params}
								fullWidth={true}
								variant="outlined"
								size="small"
								label={name}
								InputProps={{
									...params.InputProps,
									style: {
										fontSize: '0.8rem',
										borderRadius: 0,
										maxWidth: 200,
										backgroundColor: theme.palette.background.paper,
									},
								}}
								style={{
									transition: theme.transitions.create(['border-color', 'box-shadow']),
									marginBottom: 5,
								}}
							/>
						)}
						type="search"
						size="small"
						onChange={(event, newValue) => {
							searchQuery(searchKey, newValue);
							setView('search');
						}}
					/>
				</FormControl>
			</Grid>
		</Grid>
	);
};

// Search item with DATE
export const SearchQueryFilterDate = ({ name, searchKey, values = null, variant = 'inline' }) => {
	const classes = useStylesDatePicker();
	const theme = useTheme();
	let history = useHistory();
	const { settings } = useSettingsContext();

	const { searchQuery, setView } = useAppointmentsContext();
	const { blankedDates } = useSettingsContext();

	// Blanks the dates as specified in thee database and public holidays API
	const renderDayInPicker = ({ data, day, dayInCurrentMonth, dayComponent }) => {
		// * BLANKED DAYS - PUBLIC HOLIDAYS
		const isPublic = data?.blanked?.public?.findIndex((date) => date === moment(day).format('YYYY-MM-DD'));
		if (isPublic > -1 && dayInCurrentMonth) return <div className={classes.dayBlanked}>{dayComponent}</div>;

		// * BLANKED DAYS - RECURRING DAYS
		const isBlanked = data?.blanked?.blockedDays?.findIndex((date) => Number(date) === Number(moment(day).format('e')));
		if (isBlanked > -1 && dayInCurrentMonth) return <div className={classes.dayBlanked}>{dayComponent}</div>;

		// * BLANKED DAYS - COMPANY HOLIDAYS
		const isCompanyHoliday = data?.blanked?.blockedDates?.findIndex((date) => date === moment(day).format('YYYY-MM-DD'));
		if (isCompanyHoliday > -1 && dayInCurrentMonth) return <div className={classes.dayBlanked}>{dayComponent}</div>;

		// * APPOINTMENTS
		const countIndex = data?.blanked?.appointment_counts.findIndex(
			(date) => moment(date.bookingDate).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD')
		);

		const appointmentCount = countIndex > -1 ? data?.blanked?.appointment_counts[countIndex].count : -1;

		if (appointmentCount > 0 && dayInCurrentMonth) {
			const bg = () => {
				if (appointmentCount >= settings?.maxDailyAppointments) return alpha(theme.palette.error.main, 0.4);
				if (appointmentCount >= settings?.maxDailyAppointments / 2) return alpha(theme.palette.warning.main, 0.6);
				return alpha(theme.palette.success.main, 0.6);
			};

			const col = () => {
				if (appointmentCount >= settings?.maxDailyAppointments) return theme.palette.error.light;
				return 'white';
			};

			return (
				<div
					className={appointmentCount >= settings?.maxDailyAppointments ? classes.dayWithDotContainerBlanked : classes.dayWithDotContainer}
				>
					{dayComponent}
					<Avatar
						className={classes.avatarCount}
						style={{
							backgroundColor: bg(),
							color: col(),
						}}
					>
						<Typography variant="caption" noWrap>
							{appointmentCount}
						</Typography>
					</Avatar>
				</div>
			);
		}

		return dayComponent;
	};

	return (
		<Grid container alignItems="center" className={classes.container}>
			{/* Date picker */}
			<Grid item xs={12}>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<KeyboardDatePicker
						id={`SearchQueryFilterDate_${name}`}
						disableToolbar={variant === 'static' ? false : true}
						clearable={true}
						variant={variant}
						format="DD MMM YYYY"
						inputVariant="outlined"
						autoOk={true}
						value={values}
						inputProps={{
							style: {
								borderRadius: 0,
								padding: 10,
							},
							placeholder: name,
						}}
						InputProps={{
							size: 'small',
							style: {
								color: theme.palette.text.primary,
								paddingLeft: 5,
								borderRadius: 0,
								maxWidth: 200,
								backgroundColor: theme.palette.background.paper,
								margin: 0,
							},
						}}
						KeyboardButtonProps={{
							size: 'small',
						}}
						renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) =>
							renderDayInPicker({
								data: blankedDates,
								day,
								selectedDate,
								dayInCurrentMonth,
								dayComponent,
							})
						}
						onChange={(date, newValue) => {
							setView('search');
							searchQuery(searchKey, moment(newValue));

							history.replace({
								pathname: 'appointments',
								search: `?date=${moment(newValue).format('YYYY/MM/DD')}&view=search`,
							});
						}}
						style={{
							transition: theme.transitions.create(['border-color', 'box-shadow']),
							marginBottom: 5,
						}}
					/>
				</MuiPickersUtilsProvider>
			</Grid>
		</Grid>
	);
};
