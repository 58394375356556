import React, { useState } from 'react';
import axios from 'axios';
import download from 'downloadjs';
import process from 'process';
import config from '../../../../../../config.json';
const domain = process.env.NODE_ENV === 'production' ? config.api.domain : config.api.domain_dev;

// MaterialUI
import { useTheme } from '@material-ui/core/styles';
import { Tooltip, IconButton } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

export const InvoiceDownloadButton = ({ invoiceId }) => {
	const theme = useTheme();
	const [loading, setIsLoading] = useState(false);

	const downloadFile = async () => {
		try {
			setIsLoading(true);
			await axios({
				url: `${domain}/invoice/${invoiceId}/pdf`,
				method: 'GET',
				responseType: 'blob',
			})
				.then((response) => {
					let filename = response.headers['content-disposition'].split('filename=')[1].replaceAll("'", '');
					download(response.data, filename, 'application/pdf');
					setIsLoading(false);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} catch (error) {
			setIsLoading(false);
			if (error.response && error.response.status === 400) {
				alert('Error while downloading file. Try again later');
			}
		}
	};

	return (
		<Tooltip title="Download invoice" key={'download'}>
			{loading ? (
				<IconButton component="span" size="small" style={{ color: theme.palette.warning.main, marginRight: 10 }}>
					<HourglassEmptyIcon />
				</IconButton>
			) : (
				<IconButton component="span" size="small" color="inherit" onClick={() => downloadFile()} style={{ marginRight: 10 }}>
					<CloudDownloadIcon />
				</IconButton>
			)}
		</Tooltip>
	);
};
