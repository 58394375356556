import { Card } from '../components/card';
import { GetVehicle, GetVehicles, UpdateVehicle, ReassignEntity } from '../utils/data';

export const VehicleCard = ({ vehicleId, parentId, parentTable, refetchQueryNames, refetchQueryFn, reassign = false, noLink = false }) => {
	// Shows the standard contact form
	return (
		<Card
			id={vehicleId}
			queryFn={GetVehicle}
			mutationFn={UpdateVehicle}
			createFn={UpdateVehicle}
			reassign={reassign}
			reassignFn={ReassignEntity}
			reassignGetFn={GetVehicles}
			reassignConfig={{
				parentTable: parentTable,
				parentId: parentId,
				key: 'vehicleID',
				control: {
					label: 'Vehicles',
					returnField: 'vehicles',
					placeholder: 'Search or create new vehicle',
					listboxDisplayValue: 'vehicleRegistration',
				},
			}}
			refetchQueryNames={refetchQueryNames}
			refetchQueryFn={refetchQueryFn}
			template={{
				heading: 'Vehicle',
				returnKey: 'vehicle',
				parentTable: parentTable,
				fields: [
					{
						name: 'Registration',
						id: 'vehicleRegistration',
						canEdit: true,
						showmore: false,
						isRequired: true,
						type: noLink ? null : 'link',
						link: noLink ? null : `/vehicle/${vehicleId}`,
					},
					{
						name: 'Make',
						id: 'vehicleMake',
						canEdit: true,
						showmore: false,
					},
					{
						name: 'Model',
						id: 'vehicleModel',
						canEdit: true,
						showmore: false,
					},
					{
						name: 'Year',
						id: 'vehicleYear',
						canEdit: true,
						showmore: false,
						type: 'year',
					},
					{
						name: 'Color',
						id: 'vehicleColor',
						canEdit: true,
						showmore: true,
					},
					{
						name: 'VIN #',
						id: 'vehicleVINNumber',
						canEdit: true,
						showmore: true,
					},
					{
						name: 'Engine #',
						id: 'vehicleEngineNumber',
						canEdit: true,
						showmore: true,
					},
					{
						name: 'Chassis #',
						id: 'vehicleChassisNumber',
						canEdit: true,
						showmore: true,
					},
				],
			}}
		/>
	);
};

export default VehicleCard;
