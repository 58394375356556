import { createContext, useContext } from 'react';

const NewAppointmentContext = createContext({
	appointment: null,
	setAppointment: () => {},
	errors: null,
	setErrors: () => {},
	isSaving: false,
	setIsSaving: () => {},
});

export function useNewAppointmentContext() {
	return useContext(NewAppointmentContext);
}

export default NewAppointmentContext;
