import React, { useState, useMemo } from 'react';
//Query
import { useQuery, useMutation } from 'react-query';
import { GetInvoice } from '../../../utils/data';
import { UpdateInvoiceStatus } from '../../../utils/data';
//Material
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Grid, Link, Tooltip, Divider, Typography, CircularProgress } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PanToolIcon from '@material-ui/icons/PanTool';
import BlockIcon from '@material-ui/icons/Block';
import ReceiptIcon from '@material-ui/icons/Receipt';
//Context
import InvoiceContext from '../../../../context/invoice-context';
import { InvoiceCostItemsTable } from '../../../../context/config.tables';
//Shared Components
import Modal from '../../../components/modal';
import { Box } from '../../../components/box';
import { Loading } from '../../../components/loading';
import { KeyPairDisplay } from '../../../components/key-pair';
//Components
import { NumberFormatCurrencyText } from 'app/components/textbox';
import InvoiceDetailTabs from './components/tabs';
import { NewPayment } from '../../payments/components/new-payment';
import { DiscountTextBox } from 'app/components/finance-table/discount';
//Buttons
import { InvoiceDownloadButton } from './components/buttons/download';
import { InvoicePrintButton } from './components/buttons/print';
import { InvoicePaymentButton } from './components/buttons/payment';
import { EmailButton } from './components/buttons/email';
import { StatusUpdateButton } from './components/buttons/status-update-button';

const useStyles = makeStyles(() => ({
	root: {
		width: 'fit-content',
	},
}));

// Main Code -->
const InvoicePopup = ({ invoiceId, invoiceNumber, type = 'link', meta = {}, refreshPage = () => {} }) => {
	const classes = useStyles();
	const theme = useTheme();

	// STATE
	const [open, setOpen] = useState(false);
	const [loaded, setReloaded] = useState(false);
	const [openPaymentNew, setOpenPaymentNew] = useState(false);
	const [openEmail, setOpenEmail] = useState(false);

	// DATA
	const updateStatus = useMutation(UpdateInvoiceStatus);
	const { data, isLoading, refetch } = useQuery(['invoice_detailed', invoiceId], GetInvoice, {
		refetchOnWindowFocus: false,
		enabled: !!invoiceId && open && !loaded,
		onSettled: () => {
			setReloaded(true);
		},
	});

	const onClose = async () => {
		refreshPage();
	};

	const paymentDisabled = useMemo(() => {
		if (data?.invoiceBalance <= 0) return true;
		if (data?.invoiceStatus === 'Void') return true;
		return false;
	}, [data]);

	const ActionButtons = (
		<Grid key={1} container alignItems="center" className={classes.root}>
			{/* ADD PAYMENT */}
			<InvoicePaymentButton invoiceId={invoiceId} onClick={() => setOpenPaymentNew(true)} disabled={paymentDisabled} />

			<div
				style={{
					borderRight: '1px solid white',
					width: 1,
					height: '80%',
					marginRight: 10,
				}}
			/>

			{/* PRINT */}
			<InvoicePrintButton invoiceId={invoiceId} />

			{/* DOWNLOAD */}
			<InvoiceDownloadButton invoiceId={invoiceId} />

			<div
				style={{
					borderRight: '1px solid white',
					width: 1,
					height: '80%',
					marginRight: 10,
				}}
			/>

			{/* EMAIL */}
			<EmailButton setOpen={setOpenEmail} open={openEmail} invoice={data} />
		</Grid>
	);

	// Main Return ->
	return (
		<>
			{type === 'link' && (
				<Link component="button" onClick={() => setOpen(true)} variant="body2" display="block" color="secondary" style={{ marginTop: 0 }}>
					{invoiceNumber}
				</Link>
			)}

			{type === 'action' && (
				<>
					{!isLoading && <ReceiptIcon fontSize="small" style={{ paddingRight: 5 }} />}
					{isLoading && <CircularProgress fontSize="small" style={{ display: 'inline-block', width: 15, height: 15 }} />}
					<Tooltip
						title={
							<span style={{ fontSize: '12px' }}>
								<b>Name</b>: {invoiceNumber} <br />
								<b>Mobile</b>: {meta?.invoiceMobile} <br />
								<b>Email</b>: {meta?.invoiceEmail} <br />
							</span>
						}
						placement="top"
						arrow={true}
					>
						<Link
							disabled={isLoading}
							component="button"
							onClick={() => setOpen(true)}
							variant="caption"
							color="textSecondary"
							display="block"
							style={{
								marginTop: 0,
								paddingRight: 10,
								marginLeft: isLoading ? 5 : 0,
								color: !isLoading ? theme.palette.text.secondary : theme.palette.primary.main,
							}}
							gutterBottom={false}
						>
							{toProperCase(invoiceNumber)}
						</Link>
					</Tooltip>
				</>
			)}

			{data && (
				<InvoiceContext.Provider value={{ invoice: data, refetch: refreshPage }}>
					<Modal
						header={`Invoice: ${invoiceNumber}`}
						size="xxl"
						open={open}
						setOpen={setOpen}
						onClose={() => onClose()}
						fixedSize={true}
						additionalButtons={ActionButtons}
					>
						<Grid container spacing={2}>
							{data?.status === 'error' && <p>Error Occured</p>}
							{isLoading && (
								<Grid container spacing={2} justifyContent="center" alignItems="center">
									<Loading />
								</Grid>
							)}
							{!isLoading && (
								<>
									<Grid item xs={4} style={{ paddingRight: 25 }}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<Box heading="Totals">
													<Totals
														id={invoiceId}
														refetch={refetch}
														data={data}
														config={InvoiceCostItemsTable({
															invoiceId,
															refreshPage,
														})}
													/>
												</Box>
											</Grid>

											<Grid item xs={12}>
												<Box heading="Invoice">
													<KeyPairDisplay
														label="Status"
														value={data?.invoiceStatus}
														template={{
															type: 'status',
														}}
													/>
													<KeyPairDisplay label="Invoice #" value={data?.invoiceNumber} />
													<KeyPairDisplay
														label="Invoice date"
														value={data?.invoiceDate}
														template={{
															type: 'dateAgo',
														}}
													/>

													{/* //* QUOTE */}
													{data?.quoteID && (
														<>
															<div style={{ marginTop: 10, marginBottom: 10 }}>
																<Divider variant="fullWidth" />
															</div>
															<KeyPairDisplay label="Quote #" value={data?.quote?.quoteNumber} />
															<KeyPairDisplay label="Status" value={data?.quote?.quoteStatus} />
															{(data?.quote?.quoteStatus === 'Approved' || data?.quote?.quoteStatus === 'Rejected') && (
																<>
																	<KeyPairDisplay
																		label={`${data?.quote?.quoteStatus} on`}
																		value={data?.quote?.quoteApproveDate}
																		template={{
																			type: 'dateAgo',
																		}}
																	/>
																	<KeyPairDisplay
																		label={`${data?.quote?.quoteStatus} by`}
																		value={data?.quote?.approvedBy}
																	/>
																</>
															)}
														</>
													)}
												</Box>
											</Grid>

											{/* //* BUTTONS */}
											<Grid item xs={12}>
												{data?.invoiceStatus !== 'Paid' && (
													<Box heading="Mark invoice as">
														<div
															style={{
																display: 'flex',
																flex: 1,
																flexDirection: 'row',
																flexWrap: 'wrap',
															}}
														>
															{data?.invoiceStatus !== 'Void' && (
																<>
																	{/* //* paid */}
																	<StatusUpdateButton
																		label="Paid"
																		startIcon={<CheckCircleIcon />}
																		backgroundColor={theme.palette.success.light}
																		parentId={invoiceId}
																		onClick={async () => {
																			await updateStatus.mutate({
																				_id: invoiceId,
																				invoiceStatus: 'Paid',
																			});
																			refetch();
																		}}
																	/>
																	{/* //* parking */}
																	<StatusUpdateButton
																		label="Parking"
																		startIcon={<CheckCircleIcon />}
																		backgroundColor={theme.palette.warning.dark}
																		parentId={invoiceId}
																		onClick={async () => {
																			await updateStatus.mutate({
																				_id: invoiceId,
																				invoiceStatus: 'Parking',
																			});
																			refreshPage();
																		}}
																	/>
																	{/* //* write off */}
																	<StatusUpdateButton
																		label="Write-off"
																		startIcon={<PanToolIcon />}
																		backgroundColor={theme.palette.error.dark}
																		parentId={invoiceId}
																		onClick={async () => {
																			await updateStatus.mutate({
																				_id: invoiceId,
																				invoiceStatus: 'Write-off',
																			});
																			refreshPage();
																		}}
																	/>

																	{/* //* void */}
																	<StatusUpdateButton
																		label="Void"
																		startIcon={<BlockIcon />}
																		backgroundColor={theme.palette.error.dark}
																		parentId={invoiceId}
																		onClick={async () => {
																			await updateStatus.mutate({
																				_id: invoiceId,
																				invoiceStatus: 'Void',
																			});
																			refreshPage();
																		}}
																	/>
																</>
															)}
														</div>
													</Box>
												)}
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={8}>
										<InvoiceDetailTabs refreshPage={refreshPage} refetchItem={refetch} />
									</Grid>
								</>
							)}
						</Grid>
					</Modal>

					<NewPayment invoiceId={data?._id} initialTotals={data} open={openPaymentNew} setOpen={setOpenPaymentNew} refetch={refetch} />
				</InvoiceContext.Provider>
			)}
		</>
	);
};

function Totals({ id, data: inData, config, refetch }) {
	const classes = useStyles();
	const theme = useTheme();
	const data = inData?.totals;

	return (
		<Grid item xs={12} className={classes.tableTotals}>
			<Grid container spacing={1} alignItems="center">
				{/* Totals */}
				<Grid item xs={12}>
					<Grid container alignItems="center">
						<Grid item xs={8}>
							<Typography variant="body2" color="textSecondary" style={{ paddingRight: 10 }}>
								Sub Total
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="body2" color="textSecondary" align="right" style={{ paddingRight: 10 }}>
								{NumberFormatCurrencyText(data?.invoiceSubTotal, 2)}
							</Typography>
						</Grid>

						<>
							<Grid item xs={8}>
								<Typography variant="body2" color="textSecondary" style={{ paddingRight: 10 }}>
									Discount
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<DiscountTextBox
									id={id}
									config={config}
									totals={data}
									disabled={data?.invoiceSubTotal ? false : true}
									refetch={refetch}
								/>
							</Grid>

							<Grid item xs={8}>
								<Typography variant="body2" color="textSecondary" style={{ paddingRight: 10 }}>
									VAT
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography variant="body2" color="textSecondary" align="right" style={{ paddingRight: 10 }}>
									{NumberFormatCurrencyText(data?.invoiceVATAmount, 2)}
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<Divider style={{ marginTop: 5, marginBottom: 5 }} />
							</Grid>
							<Grid item xs={8}>
								<Typography variant="body2" style={{ paddingRight: 10 }}>
									<b>Total</b>
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography variant="h6" align="right" style={{ paddingRight: 10 }}>
									<b>{NumberFormatCurrencyText(data?.invoiceTotal, 2)}</b>
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<Divider style={{ marginTop: 5, marginBottom: 5 }} />
							</Grid>
							<Grid item xs={8}>
								<Typography variant="body2" style={{ paddingRight: 10, color: theme.palette.error.main }}>
									<b>Owed to you</b>
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography variant="h6" align="right" style={{ paddingRight: 10, color: theme.palette.error.main }}>
									<b>{NumberFormatCurrencyText(data?.invoiceBalance, 2)}</b>
								</Typography>
							</Grid>
						</>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

function toProperCase(str) {
	if (str)
		return str.replace(/(^|\s)\S/g, function (t) {
			return t.toUpperCase();
		});
	return null;
}

export default InvoicePopup;
