import React from 'react';
import moment from 'moment';

//Data
import {
	GetInvoiceById,
	UpdateBatchInvoiceCostItems,
	UpdateInvoiceCostItem,
	DeleteInvoiceCostItem,
	DeleteInvoiceGroup,
	CreateInvoiceCostItem,
	CreateInvoiceGroupHeader,
	CreateInvoiceGroupHeaderSaveAsTemplate,
	AssignInvoiceTemplate,
	LoadInvoiceTemplate,
	LoadInvoiceTemplateNames,
} from '../app/utils/data';

import {
	GetQuoteById,
	UpdateBatchQuoteCostItems,
	UpdateQuoteCostItem,
	DeleteQuoteCostItem,
	DeleteQuoteGroup,
	CreateQuoteCostItem,
	CreateQuoteGroupHeader,
	CreateQuoteGroupHeaderSaveAsTemplate,
	AssignQuoteTemplate,
} from '../app/utils/data';

import {
	GetWorkorderById,
	UpdateBatchCostItems,
	UpdateCostItem,
	DeleteCostItem,
	CreateCostItem,
	CreateWorkorderGroupHeader,
	CreateWorkorderGroupHeaderSaveAsTemplate,
	DeleteWorkorderGroup,
	AssignWorkorderTemplate,
} from '../app/utils/data';

//Material
import { Typography } from '@material-ui/core';

//Pages
import { DurationDescription } from '../app/components/textbox/dates';
import VehiclePopup from '../app/widgets/vehicle';
import WorkorderPopup from '../app/widgets/workorder';
import ShowMoreTextBox from '../app/components/textbox/show-more-multiline-textbox';

// LIST ALL APPOINTMENTS
export const AppointmentsTableColumns = () => {
	return [
		{
			title: 'Date',
			field: 'bookingDate',
			render: function render(row) {
				return (
					<>
						<Typography variant="body2" display="block">
							{moment(row?.bookingDate).format('ddd, DD MMM YYYY')}
						</Typography>
						<DurationDescription value={row?.bookingDate} redIfOld={true} />
					</>
				);
			},
		},
		{
			title: 'Booking #',
			field: 'bookingNumber',
			render: function render(row) {
				return (
					<>
						<Typography
							variant="body2"
							display="block"
							style={{
								paddingBottom: '5px',
							}}
						>
							{row?.bookingNumber}
						</Typography>
					</>
				);
			},
		},
		{
			title: 'Vehicle',
			field: 'vehicle.vehicleRegistration',
			render: function render(row) {
				return (
					<>
						<VehiclePopup
							vehicleId={row?.vehicle?._id}
							vehicleRegistration={row?.vehicle?.vehicleRegistration}
							meta={row?.vehicle}
							type="link"
						/>
						<Typography variant="caption" color="textSecondary" display="block">
							{row?.vehicle?.vehicleMake}
						</Typography>
						<Typography variant="caption" color="textSecondary" display="block">
							{row?.vehicle?.vehicleModel}
						</Typography>
					</>
				);
			},
		},
		{
			title: 'Instructions',
			field: 'bookingInstructions',
			minWidth: 350,
			disableClick: true,
			render: function render(row) {
				return (
					<>
						<ShowMoreTextBox text={row?.bookingInstructions} />
					</>
				);
			},
		},
	];
};

// LISTS AL WORK ORDERS
export const WorkordersTableColumns = () => {
	return [
		{
			title: 'Date',
			field: 'workorderDate',
			render: function render(row) {
				return (
					<>
						<Typography variant="body2" display="block">
							{moment(row?.workorderDate).format('ddd, DD MMM YYYY')}
						</Typography>
						<DurationDescription value={row?.workorderDate} redIfOld={true} />
					</>
				);
			},
		},
		{
			title: 'Work Order',
			field: 'workorderNumber',
			render: function render(row) {
				return (
					<>
						<WorkorderPopup workorderId={row?._id} workorderNumber={row?.workorderNumber} meta={row} type="link" />
					</>
				);
			},
		},
		{
			// width: '10%',
			title: 'Vehicle',
			field: 'vehicle.vehicleRegistration',
			render: function render(row) {
				return (
					<>
						<VehiclePopup
							vehicleId={row?.vehicle?._id}
							vehicleRegistration={row?.vehicle?.vehicleRegistration}
							type="link"
							meta={row?.vehicle}
						/>
						<Typography variant="caption" color="textSecondary" display="block" noWrap>
							{row?.vehicle?.vehicleMake} {row?.vehicle?.vehicleModel}
						</Typography>
						<Typography variant="caption" color="textSecondary" display="block">
							{row?.workorderOdometer?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || ''} km
						</Typography>
					</>
				);
			},
		},
		{
			title: 'Instructions',
			field: 'workorderInstructions',
			minWidth: 350,
			disableClick: true,
			render: function render(row) {
				return (
					<>
						<ShowMoreTextBox text={row?.workorderInstructions} />
					</>
				);
			},
		},
		{ title: 'Make', field: 'vehicle.vehicleMake', hidden: true, searchable: true },
		{ title: 'Model', field: 'vehicle.vehicleModel', hidden: true, searchable: true },
		{ title: 'Name', field: 'contact.contactFullname', hidden: true, searchable: true },
		{ title: 'Email', field: 'contact.contactEmail', hidden: true, searchable: true },
		{ title: 'Status', field: 'workorderStatus', hidden: true, searchable: true },
	];
};

// FINANCE TABLE FOR INVOICES
export const InvoiceCostItemsTable = ({ invoiceId, refetch = null }) => {
	const config = {
		type: 'cost',
		newRowPlacement: 'bottom',
		messages: {
			nodata: 'Start by adding adding new cost items below.',
		},
		query: {
			queryFn: GetInvoiceById,
			mutationFn: UpdateBatchInvoiceCostItems,
			updateFn: UpdateInvoiceCostItem,
			deleteFn: DeleteInvoiceCostItem,
			createFn: CreateInvoiceCostItem,

			deleteGroupFn: DeleteInvoiceGroup,
			addGroupHeaderFn: CreateInvoiceGroupHeader,

			saveAsTemplateFn: CreateInvoiceGroupHeaderSaveAsTemplate,
			loadGroupTemplateFn: LoadInvoiceTemplate,
			loadGroupTemplateNamesFn: LoadInvoiceTemplateNames,

			assignTemplateFn: AssignInvoiceTemplate,

			refetchFn: refetch,

			id: invoiceId,
			queryKey: 'invoice',
			dbReturnKey: 'invoice',
			dbReturnSubKey: 'costItems',
			dbSubTotalKey: 'costAmount',
			dbTotalKey: 'costTotal',
			hideVAT: true,
			hideTotal: true,
			select: {
				parent: '_id',
				child: 'invoiceID',
			},
		},
		headers: [
			{
				label: 'Qty',
				placeholder: 'Qty',
				dbField: 'costQty',
				type: 'numeric',
				align: 'center',
				size: { xs: 2 },
				defaultValue: 0,
			},
			{
				label: 'Description',
				placeholder: 'Description',
				dbField: 'costDescription',
				isRowHeaderField: true,
				required: true,
				type: 'text',
				size: { xs: 5 },
				focusAfterNew: true,
			},
			{
				label: 'Unit Price',
				placeholder: 'Unit Price',
				dbField: 'costUnitPrice',
				type: 'financial',
				align: 'right',
				size: { xs: 2 },
				defaultValue: 0,
			},
			{
				label: 'Disc.',
				placeholder: 'Disc.',
				dbField: 'costDiscount',
				type: 'percentage',
				align: 'center',
				size: { xs: 1 },
				defaultValue: 0,
			},
			{
				label: 'Total',
				dbField: 'costAmount',
				type: 'total',
				align: 'right',
				size: { xs: 2 },
				defaultValue: 0,
				isTotal: true,
			},
		],
	};

	return config;
};

export const QuoteCostItemsTable = ({ quoteId, refetch = null }) => {
	const config = {
		type: 'cost',
		newRowPlacement: 'bottom',
		messages: {
			nodata: 'Start by adding adding new cost items below.',
		},
		query: {
			queryFn: GetQuoteById,
			mutationFn: UpdateBatchQuoteCostItems,
			updateFn: UpdateQuoteCostItem,
			deleteFn: DeleteQuoteCostItem,
			createFn: CreateQuoteCostItem,

			deleteGroupFn: DeleteQuoteGroup,
			addGroupHeaderFn: CreateQuoteGroupHeader,

			saveAsTemplateFn: CreateQuoteGroupHeaderSaveAsTemplate,
			loadGroupTemplateFn: LoadInvoiceTemplate,
			loadGroupTemplateNamesFn: LoadInvoiceTemplateNames,

			assignTemplateFn: AssignQuoteTemplate,

			refetchFn: refetch,

			id: quoteId,
			queryKey: 'quote',
			dbReturnKey: 'quote',
			dbReturnSubKey: 'costItems',
			dbSubTotalKey: 'costAmount',
			dbTotalKey: 'costTotal',
			hideVAT: true,
			hideTotal: true,
			select: {
				parent: '_id',
				child: 'quoteID',
			},
		},
		headers: [
			{
				label: 'Qty',
				placeholder: 'Qty',
				dbField: 'costQty',
				type: 'numeric',
				align: 'center',
				size: { xs: 2 },
				defaultValue: 0,
			},
			{
				label: 'Description',
				placeholder: 'Description',
				dbField: 'costDescription',
				isRowHeaderField: true,
				required: true,
				type: 'text',
				size: { xs: 5 },
				focusAfterNew: true,
			},
			{
				label: 'Unit Price',
				placeholder: 'Unit Price',
				dbField: 'costUnitPrice',
				type: 'financial',
				align: 'right',
				size: { xs: 2 },
				defaultValue: 0,
			},
			{
				label: 'Disc.',
				placeholder: 'Disc.',
				dbField: 'costDiscount',
				type: 'percentage',
				align: 'center',
				size: { xs: 1 },
				defaultValue: 0,
			},
			{
				label: 'Total',
				dbField: 'costAmount',
				type: 'total',
				align: 'right',
				size: { xs: 2 },
				defaultValue: 0,
				isTotal: true,
			},
		],
	};

	return config;
};

export const WorkorderCostItemsTable = ({ workorderId, refetch = null }) => {
	const config = {
		type: 'cost',
		newRowPlacement: 'bottom',
		messages: {
			nodata: 'Start by adding adding new cost items below.',
		},
		query: {
			queryFn: GetWorkorderById,
			mutationFn: UpdateBatchCostItems,
			updateFn: UpdateCostItem,
			deleteFn: DeleteCostItem,
			createFn: CreateCostItem,

			deleteGroupFn: DeleteWorkorderGroup,
			addGroupHeaderFn: CreateWorkorderGroupHeader,

			saveAsTemplateFn: CreateWorkorderGroupHeaderSaveAsTemplate,
			loadGroupTemplateFn: LoadInvoiceTemplate,
			loadGroupTemplateNamesFn: LoadInvoiceTemplateNames,

			assignTemplateFn: AssignWorkorderTemplate,

			refetchFn: refetch,

			id: workorderId,
			queryKey: 'workorder',
			dbReturnKey: 'workorder',
			dbReturnSubKey: 'costitems',
			dbSubTotalKey: 'costAmount',
			dbTotalKey: 'costTotal',
			hideVAT: true,
			hideTotal: true,
			select: {
				parent: '_id',
				child: 'workorderID',
			},
		},
		headers: [
			{
				label: 'Qty',
				placeholder: 'Qty',
				dbField: 'costQty',
				type: 'numeric',
				align: 'center',
				size: { xs: 2 },
				defaultValue: 0,
			},
			{
				label: 'Description',
				placeholder: 'Description',
				dbField: 'costDescription',
				isRowHeaderField: true,
				required: true,
				type: 'text',
				size: { xs: 5 },
				focusAfterNew: true,
			},
			{
				label: 'Unit Price',
				placeholder: 'Unit Price',
				dbField: 'costUnitPrice',
				type: 'financial',
				align: 'right',
				size: { xs: 2 },
				defaultValue: 0,
			},
			{
				label: 'Disc.',
				placeholder: 'Disc.',
				dbField: 'costDiscount',
				type: 'percentage',
				align: 'center',
				size: { xs: 1 },
				defaultValue: 0,
			},
			{
				label: 'Total',
				dbField: 'costAmount',
				type: 'total',
				align: 'right',
				size: { xs: 2 },
				defaultValue: 0,
				isTotal: true,
			},
		],
	};

	return config;
};
