import { useState } from 'react';
//Data
import { useQuery, useMutation } from 'react-query';
import { GetAppointment, CreateWorkorder } from '../../../../app/utils/data';
//Material
import { useTheme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
//Icons
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
//Context
import { useAppointmentsContext } from '../../../../context/appointments-context';
import CheckInContext from '../../../../context/appointments-checkin-context';
// Config
import { CheckInMandatoryFields } from '../../../../context/config.mandatory-fields';
//Components
import { AppointmentCheckInInstructions, AppointmentCheckInMileage } from './components';
import { AppointmentCheckInQuoteFirst } from './components/appointment-checkin-quote-first';
//Pages
import { Loading } from '../../../../app/components/loading';
import { PopOut } from '../../../../app/components/popout';

export const CheckInButton = ({ bookingId = null, open = false, setOpen = null, disabled = false, iconSize = 'small' }) => {
	const theme = useTheme();

	//*state
	const { refreshPage } = useAppointmentsContext();
	const [appointment, setInternalAppointment] = useState();
	const [errors, setInternalErrors] = useState();
	//Manages the state of controls when saving
	const [isSaving, setIsSaving] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const setAppointment = (data) => {
		if (data) setInternalAppointment(data);
	};

	// Input Validation
	const setErrors = (appointment, source) => {
		let isCheckInMandatoryFields = CheckInMandatoryFields?.[source];
		let newErrors = null;

		if (isCheckInMandatoryFields) {
			Object.keys(isCheckInMandatoryFields).map((key) => {
				let isMandatory = isCheckInMandatoryFields?.[key];

				let value = appointment?.[source]?.[key];
				if (source === 'root') value = appointment?.[key];

				if (value === undefined || value === null || value.toString().trim() === '') value = null;
				if (isMandatory && !value) {
					newErrors = {
						...newErrors,
						[source]: {
							...newErrors?.[source],
							[key]: 'This is a required field',
						},
					};
				}
			});

			if (!newErrors) {
				if (errors && errors?.[source]) {
					const copyErrors = Object.assign({}, errors);
					delete copyErrors[source];
					setErrors(copyErrors);
					return false;
				}
			}
			if (newErrors) {
				setInternalErrors({
					...errors,
					...newErrors,
				});
				return true;
			}
		}
	};

	let state = {
		appointment,
		setAppointment,
		errors,
		setErrors,
		isSaving,
		setIsSaving,
	};

	// DATA
	// --------------------------------------------------------------
	const { data, isLoading: queryIsLoading } = useQuery(['appointment', bookingId], GetAppointment, {
		enabled: !!bookingId && !!open,
		refetchOnWindowFocus: false,
		onSuccess: async (data) => {
			setInternalAppointment(data.bookings[0]);
		},

		onSettled: () => {
			setIsLoading(false);
		},
	});

	const checkAppointment = useMutation(CreateWorkorder);

	// FUNCTIONS
	// --------------------------------------------------------------
	let saveInstance = 0;
	const saveClick = async () => {
		saveInstance += 1;

		if (saveInstance > 1) return;

		setIsSaving(true);
		if (!setErrors(appointment, 'root') && !isSaving) {
			//Check vehicle in
			await checkAppointment.mutate({
				workorderInstructions: appointment.bookingInstructions,
				workorderOdometer: appointment.workorderOdometer,

				workorderInsuranceCompany: appointment.workorderInsuranceCompany,
				workorderInsurancePolicy: appointment.workorderInsurancePolicy,
				workorderInsuranceClaim: appointment.workorderInsuranceClaim,
				workorderInsuranceAuthorization: appointment.workorderInsuranceAuthorization,

				bookingID: appointment._id,
				...(appointment.quoteFirst && { quoteFirst: appointment.quoteFirst }),
			});

			//Reset page
			await setInternalAppointment(null);
			await setInternalErrors(false);

			setIsSaving(false);

			await setOpen(false);
			await refreshPage();
			saveInstance = 0;
		}

		setIsSaving(false);
	};

	const cancelClick = () => {
		setInternalAppointment(null);
		setInternalErrors(false);
		setOpen(false);
	};

	if (data?.status === 'error') return <p>Error Occured</p>;
	if (data?.status === 'access-denied') return <p>Access Denied</p>;

	return (
		<CheckInContext.Provider value={state}>
			<PopOut
				// GENERAL
				heading="Check vehicle in"
				tooltip="Check vehicle in for service"
				// STYLE
				noBorder={true}
				// STATE
				open={open}
				setOpen={setOpen}
				disabled={disabled}
				// ICON
				iconOnly={true}
				iconSize={iconSize}
				icon={
					<CheckCircleOutlineIcon
						style={disabled ? { color: theme.palette.text.disabled } : { color: theme.palette.success.main }}
						fontSize={iconSize === 'small' ? 'small' : 'default'}
					/>
				}
				// FOOTER
				isSaving={isSaving}
				saveButtonText="Save"
				saveButtonFn={saveClick}
				cancelButtonText="Cancel"
				cancelButtonFn={cancelClick}
			>
				<>
					{/* LOADING */}
					{(queryIsLoading || isLoading) && <Loading />}

					{/* BODY */}
					{(!queryIsLoading || !isLoading) && (
						<>
							<AppointmentCheckInInstructions defaultValue={appointment?.bookingInstructions} disabled={isSaving} />

							<AppointmentCheckInMileage disabled={isSaving} />

							{data && !data.bookings[0].quoteFirst && !data.bookings[0]?.quoteID && (
								<AppointmentCheckInQuoteFirst disabled={isSaving} />
							)}

							{data?.bookings.owed.contact > 0 && (
								<Alert
									severity="error"
									style={{ marginBottom: 5 }}
								>{`The contact has an outstanding balance of R ${data?.bookings?.owed?.contact
									.toFixed(2)
									.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Alert>
							)}
							{data?.bookings.owed.vehicle > 0 && (
								<Alert
									severity="error"
									style={{ marginBottom: 5 }}
								>{`The vehicle has an outstanding balance of R ${data?.bookings?.owed?.vehicle
									.toFixed(2)
									.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Alert>
							)}
							{data?.bookings.owed.business > 0 && (
								<Alert
									severity="error"
									style={{ marginBottom: 5 }}
								>{`The business has an outstanding balance of R ${data?.bookings?.owed?.business
									.toFixed(2)
									.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Alert>
							)}
						</>
					)}
				</>
			</PopOut>
		</CheckInContext.Provider>
	);
};

export default CheckInButton;
