import React, { useState } from 'react';

// MaterialUI
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Tooltip, IconButton, Badge, Modal, Divider } from '@material-ui/core';

// Icons
import TextsmsIcon from '@material-ui/icons/Textsms';
import LanguageIcon from '@material-ui/icons/Language';
import BeenhereIcon from '@material-ui/icons/Beenhere';

//Context
import { useAppointmentsContext } from '../../../../../context/appointments-context';

//Controls
import ContactPopup from '../../../../../app/widgets/contact';
import BusinessPopup from '../../../../../app/widgets/business';

import { CheckInButton } from '../../check-in';
import { CommentsV2 } from '../../../../../app/components/comments';
import { AppointmentStatusBadge, CustomStatusBadge } from '../../../../../app/components/card/status';
// import { AppointmentMultiPDF } from './appointments-print-multi-pdf';
import AppointmentNew from '../../new/';
import { QuoteFirstChip } from 'app/components/quote-first';
import { AppointmentPrintButton } from 'pages/appointments/components/print';

const useStyles = makeStyles((theme) => ({
	cardfooterContainer: {
		padding: 8,
		// backgroundColor: theme.palette.divider,
		backgroundColor: theme.palette.type === 'dark' ? theme.palette.divider : theme.palette.action.hover,
	},

	// MODAL
	modalDialog: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		maxWidth: '30%',
		maxHeight: '80vh',
		margin: '1.75rem auto',
		overflow: 'auto',
	},
	modalContent: {
		boxShadow: '0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15)',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		pointerEvents: 'auto',
		backgroundColor: 'darkest',
		backgroundClip: 'padding-box',
		outline: 0,
	},
	modalBody: {
		backgroundColor: theme.palette.background.paper,
	},
}));

export const TableRowActions = ({ appointment }) => {
	const classes = useStyles();
	const theme = useTheme();

	//Context
	const { refreshPage } = useAppointmentsContext();

	//Modals
	const [openComments, setOpenComments] = useState(false);
	const [openEditAppointment, setOpenEditAppointment] = useState(false);
	const [openCheckIn, setOpenCheckIn] = useState(false);

	return (
		<>
			<Grid item xs={12}>
				<Grid container alignItems="center" className={classes.cardfooterContainer}>
					{/* LEFT COL */}
					<Grid item xs={10} style={{ paddingLeft: 5 }}>
						<Grid container spacing={1} alignItems="center">
							{/* STATUS */}
							<AppointmentStatusBadge initialStatus={appointment?.bookingStatus} chipStyle={{ marginRight: 5 }} />

							{/* QUOTE FIRST */}
							{(appointment?.quoteFirst || appointment?.quoteID) && (
								<QuoteFirstChip row={appointment} table="appointment" refreshPage={refreshPage} />
							)}

							{/* WORK ORDER REFERENCE */}
							{appointment?.workorder?.workorderNumber && (
								<CustomStatusBadge
									label={`W/O: ${appointment?.workorder?.workorderNumber}`}
									itemDescription="Workorder"
									status={appointment?.workorder?.workorderStatus}
									url={`/workorder/${appointment?.workorder?._id}`}
									chipStyle={{ marginRight: 5 }}
								/>
							)}

							{/* INVOICE(s) REFERENCE */}
							{appointment?.invoices?.length > 0 &&
								appointment?.invoices.map((invoice, idx) => {
									return (
										<CustomStatusBadge
											key={idx}
											label={`I: ${invoice.invoiceNumber}`}
											itemDescription="Invoice"
											status={invoice.invoiceStatus}
											url={`/invoice/${invoice._id}`}
											chipStyle={{ marginRight: 5 }}
										/>
									);
								})}

							{/* BOOKING CONFIRMATION */}
							{appointment?.bookingConfirmedByClient && (
								<>
									<Tooltip title="This booking was confirmed by the customer" placement="top" arrow={true}>
										<BeenhereIcon
											fontSize="small"
											style={{
												marginLeft: 5,
												marginRight: 5,
												color: theme.palette.success.main,
											}}
										/>
									</Tooltip>
								</>
							)}

							{/* WEB BOOKING */}
							{appointment?.source === 'web' && (
								<>
									<Tooltip title="This is a 'self service' web appointment" placement="top" arrow={true}>
										<LanguageIcon fontSize="small" style={{ marginLeft: 5, marginRight: 5 }} />
									</Tooltip>
								</>
							)}

							{/* CONTACT */}
							<ButtonDivider />
							<ContactPopup
								contactId={appointment?.contact?._id}
								contactFullname={appointment?.contact?.contactFullname}
								meta={appointment?.contact}
								type="action"
							/>

							{/* BUSINESS */}
							{appointment?.business && (
								<BusinessPopup
									businessId={appointment?.business?._id}
									businessName={appointment?.business?.businessName}
									type="action"
									meta={appointment?.business}
								/>
							)}

							{/* COMMENTS */}
							<ButtonDivider />
							<>
								<Tooltip
									arrow={true}
									placement="top"
									title={
										!appointment?.comments?.length
											? 'No comments yet. Click to add.'
											: `${appointment?.comments?.length} comments. Click to edit`
									}
								>
									<IconButton size="small" onClick={() => setOpenComments(!openComments)}>
										<Badge
											overlap="rectangular"
											size="small"
											variant="dot"
											badgeContent={appointment?.comments ? appointment?.comments?.length : 0}
											color="secondary"
										>
											<TextsmsIcon color={!appointment?.comments?.length ? 'disabled' : 'action'} fontSize="small" />
										</Badge>
									</IconButton>
								</Tooltip>
							</>
						</Grid>
					</Grid>

					{/* ACTIONS */}
					<Grid item xs={2} style={{ paddingRight: 10 }}>
						<Grid container spacing={1} alignItems="center" justifyContent="flex-end">
							{/* PRINT */}
							{/* //!! THIS IS THE PDF ERROR */}
							{/* // TODO  REPLACE THIS WITH v2 */}
							{/* <AppointmentMultiPDF iconOnly={true} fontSize="small" appointments={[appointment]} /> */}
							<AppointmentPrintButton appointmentId={[appointment?._id]} />

							{/* EDIT */}
							<ButtonDivider />
							<AppointmentNew
								bookingId={appointment._id}
								// editAppointmentItem={appointment}
								open={openEditAppointment}
								setOpen={setOpenEditAppointment}
								iconSize="small"
								disabled={appointment.bookingStatus === 'Recieved' ? true : false}
							/>

							{/* CHECK IN */}
							<ButtonDivider />
							<CheckInButton
								bookingId={appointment._id}
								open={openCheckIn}
								setOpen={setOpenCheckIn}
								disabled={appointment.bookingStatus === 'Recieved' ? true : false}
							/>
						</Grid>
					</Grid>
				</Grid>

				<Modal
					className={classes.modalDialog}
					open={openComments || false}
					onClose={(e, reason) => {
						if (reason === 'backdropClick') return null;
						setOpenComments(false);
					}}
					disablePortal
					closeAfterTransition
				>
					<div className={classes.modalContent}>
						<div className={classes.modalBody}>
							<CommentsV2
								comments={appointment.comments}
								parentId={appointment?._id}
								handleClick={() => setOpenComments(false)}
								open={openComments}
								setOpen={setOpenComments}
								refreshPageFn={refreshPage}
							/>
						</div>
					</div>
				</Modal>
			</Grid>
		</>
	);
};

function ButtonDivider() {
	return <Divider flexItem={true} orientation="vertical" variant="fullWidth" style={{ marginRight: 5, marginLeft: 5 }} />;
}
