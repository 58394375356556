import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import config from './config.json';
import history from './app/utils/history';
import './theme/styles/index.css';

const onRedirectCallback = (appState) => {
	history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	// <React.StrictMode>
	<Auth0Provider
		domain={config.auth.domain}
		clientId={config.auth.clientId}
		audience={config.auth.audience}
		redirectUri={window.location.origin}
		onRedirectCallback={onRedirectCallback}
		useRefreshTokens={true}
	>
		<App />
	</Auth0Provider>
	// </React.StrictMode>
);

reportWebVitals();
