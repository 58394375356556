import React, { useState } from 'react';
import axios from 'axios';

import config from '../../../../../../config.json';
const domain = process.env.NODE_ENV === 'production' ? config.api.domain : config.api.domain_dev;

// MaterialUI
import { useTheme } from '@material-ui/core/styles';
import { Tooltip, IconButton } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

export const QuotePrintButton = ({ quoteId }) => {
    const [loading, setIsLoading] = useState(false);
    const theme = useTheme();

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const showPDFinNewWindows = async () => {
        try {
            setIsLoading(true)
            await axios
                .get(`${domain}/quote/${quoteId}/pdf`, {
                    responseType: "blob",
                })
                .then((response) => {
                    const file = URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
                    openInNewTab(file)
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setIsLoading(false)
                });
        } catch (error) {
            setIsLoading(false)
            alert('Error while downloading file. Try again later')
        }
    }

    return (
        <Tooltip title="Print quote" key={'print'}>
            {loading
                ?
                <IconButton component="span" size="small" style={{ color: theme.palette.warning.main, marginRight: 10 }}>
                    <HourglassEmptyIcon />
                </IconButton>
                :
                <IconButton component="span" size="small" color="inherit"
                    onClick={() => showPDFinNewWindows()}
                    style={{ marginRight: 10 }}
                >
                    <PrintIcon />
                </IconButton>
            }
        </Tooltip>
    )
}