import axios from 'axios';
import config from '../../config.json';
// VEHICLE
import { GetVehicle, GetVehicleDetailed, GetVehicles, GetPreferedSelectionsByVehicleId, UpdateVehicle } from './data-layer/vehicles';
// CONTACT
import { GetContact, GetContactDetailed, GetContacts, GetPreferedSelectionsByContactId, UpdateContact } from './data-layer/contacts';
// BUSINESS
import { GetBusinessDetailed, GetPreferedSelectionsByBusinessId } from './data-layer/business';
// STATS
import { GetMenuStats } from './data-layer/menustats';
import { GetDashboard } from './data-layer/dashboard';
// WORKORDERS
import {
	ImportQuoteToWorkorder,
	GetWorkorderById,
	GetWorkorderWarranty,
	NewWorkorder,
	CreateWorkorder,
	UpdateWorkorderStatus,
	UpdateWorkorderInsurance,
	UpdateWorkorderNextServiceReminder,
	UpdateWorkorderGeneralInformation,
	UpdateWorkorderWarranty,
	CreateCostItem,
	UpdateBatchCostItems,
	DeleteCostItem,
	UpdateCostItem,
	CreateWorkorderGroupHeader,
	CreateWorkorderGroupHeaderSaveAsTemplate,
	AssignWorkorderTemplate,
	// LoadWorkorderTemplate,
	// LoadWorkorderTemplateNames,
	DeleteWorkorderGroup,
} from './data-layer/workorders';
// INVOICE
import {
	GetInvoice,
	GetInvoiceById,
	GetInvoices,
	GetInvoiceByWorkorderId,
	GetInvoiceItemsByInvoiceId,
	GetContactInvoices,
	CreateInvoiceFromWorkorder,
	UpdateInvoiceItem,
	UpdateInvoiceDiscount,
	UpdateBatchInvoiceItems,
	CreateInvoice,
	CreateInvoiceItem,
	DeleteInvoiceItem,
	DeleteInvoiceGroup,
	DeleteInvoice,
	UpdateInvoiceVehicle,
	GetInvoiceVehicle,
	GetInvoiceContact,
	UpdateInvoiceContact,
	GetInvoiceBusiness,
	UpdateInvoiceBusiness,
	GetInvoiceReminder,
	UpdateInvoiceReminder,
	GetInvoiceWarranty,
	UpdateInvoiceWarranty,
	GetActiveInvoices,
	UpdateInvoiceStatus,
	UpdateInvoiceDate,
	CreateInvoiceGroupHeaderSaveAsTemplate,
	LoadInvoiceTemplate,
	LoadInvoiceTemplateNames,
	AssignInvoiceTemplate,
	SendInvoiceByEmail,
	ImportQuoteToInvoice,
} from './data-layer/invoices';
// Quote
import {
	GetQuote,
	GetQuoteById,
	GetQuotes,
	GetQuoteByWorkorderId,
	GetQuoteItemsByQuoteId,
	GetContactQuotes,
	CreateQuoteFromWorkorder,
	UpdateQuoteItem,
	UpdateQuoteDiscount,
	UpdateBatchQuoteItems,
	CreateQuote,
	CreateQuoteItem,
	DeleteQuoteItem,
	DeleteQuoteGroup,
	DeleteQuote,
	UpdateQuoteVehicle,
	GetQuoteVehicle,
	GetQuoteContact,
	UpdateQuoteContact,
	GetQuoteBusiness,
	UpdateQuoteBusiness,
	GetQuoteReminder,
	UpdateQuoteReminder,
	GetActiveQuotes,
	UpdateQuoteStatus,
	UpdateQuoteDate,
	CreateQuoteGroupHeaderSaveAsTemplate,
	LoadQuoteTemplate,
	LoadQuoteTemplateNames,
	AssignQuoteTemplate,
	SendQuoteByEmail,
	GetPublicQuote,
} from './data-layer/quotes';
//PAYMENTS
import { AddPayment, DeleteInvoicePayment, UpdateInvoicePayment } from './data-layer/payments';

//WARRANTY
import { AddWarranty, GetWarrantyByCustomValue, UpdateWarrantyByCustomValue } from './data-layer/warranties';

//COMMENTS
import { GetComments, DeleteComment, CreateComment } from './data-layer/comments';

// COMPANY SETTINGS
import {
	GetCompanySettings,
	UpdateCompanySettings,
	UpdateSettings,
	// CreateCompanySettings,
	GetNonWorkingDates,
	GetNonWorkingDatesSummary,
	UpdateCompanyTheme,
	RequestCustomDomainAuthentication,
} from './data-layer/company-settings';

//LISTS
import { GetListAll, GetAddressSearch } from './data-layer/lists';

//SEARCH
import { SearchQuotes, SearchInvoices, ExportInvoices, SearchWorkorders, SearchAppointments } from './data-layer/search';

import {
	UpdateAppointmentStatus,
	GetAppointment,
	GetOpenAppointmentsBetweenDates,
	CreateAppointment,
	UpdateAppointment,
	DeleteAppointment,
} from './data-layer/bookings';

import { GetAllUsers } from './data-layer/users';

import { GetHistory } from './data-layer/history';

// eslint-disable-next-line no-undef
const domain = process.env.NODE_ENV === 'production' ? config.api.domain : config.api.domain_dev;

// ---------------------------------------------------------------------------------------------------------------
// Add interceptor to add token to headers
// ---------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------
axios.interceptors.request.use(
	function (config) {
		config = {
			...config,
			headers: {
				...config.headers,
				'content-type': 'application/json',
				Authorization: `Bearer ${sessionStorage.getItem('token')}`,
			},
		};
		return config;
	},
	function (error) {
		return { status: 'error', error };
	}
);

const _handleError = (e) => {
	if (e.response.status === 401) {
		return { status: 'access-denied' };
	}

	return { status: 'error', e };
};

// RE-ASSIGN
export const ReassignEntity = async ({ reassignConfig }) => {
	return await axios
		.post(`${domain}/reassign`, {
			reassignConfig,
		})
		.then((data) => {
			return data.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

// INVOICE COST ITEMS
export const CreateInvoiceGroupHeader = async (data) => {
	return await axios
		.post(`${domain}/invoice/costs/addgroup/`, {
			data,
		})
		.then((data) => {
			return data.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

export const CreateInvoiceCostItem = async (data) => {
	return await axios
		.post(`${domain}/invoice/costs/create/`, {
			data,
		})
		.then((data) => {
			return data.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

export const UpdateBatchInvoiceCostItems = async (data) => {
	return await axios
		.post(`${domain}/invoice/costs/update`, {
			data,
		})
		.then((data) => {
			return data.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

export const UpdateInvoiceCostItem = async (data) => {
	return await axios
		.post(`${domain}/invoice/cost/update`, {
			data,
		})
		.then((data) => {
			return data.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

export const DeleteInvoiceCostItem = async (data) => {
	return await axios
		.post(`${domain}/invoice/cost/delete`, {
			data,
		})
		.then((data) => {
			return data.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

export const FinaliseInvoice = async (invoiceId) => {
	return await axios
		.post(`${domain}/invoice/close/${invoiceId}`)
		.then((data) => {
			return data.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

// QUOTE COST ITEMS
// ---------------------------------------------------------------------------------------------------------------

export const CreateQuoteGroupHeader = async (data) => {
	return await axios
		.post(`${domain}/quote/costs/addgroup/`, {
			data,
		})
		.then((data) => {
			return data.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

export const CreateQuoteCostItem = async (data) => {
	return await axios
		.post(`${domain}/quote/costs/create/`, {
			data,
		})
		.then((data) => {
			return data.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

export const UpdateBatchQuoteCostItems = async (data) => {
	return await axios
		.post(`${domain}/quote/costs/update`, {
			data,
		})
		.then((data) => {
			return data.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

export const UpdateQuoteCostItem = async (data) => {
	return await axios
		.post(`${domain}/quote/cost/update`, {
			data,
		})
		.then((data) => {
			return data.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

export const DeleteQuoteCostItem = async (data) => {
	return await axios
		.post(`${domain}/quote/cost/delete`, {
			data,
		})
		.then((data) => {
			return data.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

export const FinaliseQuote = async (quoteId) => {
	return await axios
		.post(`${domain}/quote/close/${quoteId}`)
		.then((data) => {
			return data.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

// ---------------------------------------------------------------------------------------------------------------
// BUSINESS
// ---------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------
export const GetBusinessAll = async () => {
	return await axios
		.get(`${domain}/business/`)
		.then((data) => {
			return data.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};
export const GetBusiness = async (req) => {
	let id = req.queryKey[1] ? req.queryKey[1] : req.queryKey[0];
	return await axios
		.get(`${domain}/business/${id}`)
		.then((data) => {
			return data.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};
export const UpdateBusiness = async (data) => {
	return await axios
		.put(`${domain}/business/`, {
			data,
		})
		.then((data) => {
			return data.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};
export const CreateBusiness = async (data) => {
	return await axios
		.post(`${domain}/business/`, {
			// businessName: data.businessName,
			businessName: data,
		})
		.then((data) => {
			return data.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

// ---------------------------------------------------------------------------------------------------------------
// CAR DATA
// ---------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------
// export const GetMakes = async () => {
// 	return await axios.get(`${domain}/public/makes/`).then((data) => {
// 		return data.data;
// 	});
// };
// export const GetModels = async (req) => {
// 	let make = req.queryKey[1] ? req.queryKey[1] : req.queryKey[0];
// 	return await axios.get(`${domain}/public/models/${make}`).then((data) => {
// 		return data.data;
// 	});
// };

// ---------------------------------------------------------------------------------------------------------------
// GRAPHS
// ---------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------
// Cache Key - ['graphs_workorders_status']
export const GetWorkorderStatusGraphs = async () => {
	return await axios
		.get(`${domain}/graphs/workorders/status/`)
		.then((data) => {
			return data.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

// Cache Key - ['graphs_workorders_time']
export const GetWorkorderTimeGraphs = async () => {
	return await axios
		.get(`${domain}/graphs/workorders/time/`)
		.then((data) => {
			return data.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

// ---------------------------------------------------------------------------------------------------------------
// VIOLATIONS
// ---------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------
// Cache Key - ['violations']
export const GetViolations = async (req) => {
	const data = req.queryKey[1];

	return await axios
		.post(`${domain}/violations/`, {
			data,
		})
		.then((data) => {
			return data.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

export {
	//VEHICLE
	GetVehicle,
	GetVehicleDetailed,
	GetVehicles,
	GetPreferedSelectionsByVehicleId,
	UpdateVehicle,
	//CONTACT
	//---get
	GetContact,
	GetContactDetailed,
	GetPreferedSelectionsByContactId,
	GetContacts,
	//---post
	UpdateContact,
	//BUSINESS
	GetBusinessDetailed,
	GetPreferedSelectionsByBusinessId,
	// STATS
	GetMenuStats,
	GetDashboard,
	//WORKORDER
	ImportQuoteToWorkorder,
	GetWorkorderById,
	GetWorkorderWarranty,
	NewWorkorder,
	CreateWorkorder,
	UpdateWorkorderStatus,
	UpdateWorkorderInsurance,
	UpdateWorkorderNextServiceReminder,
	UpdateWorkorderGeneralInformation,
	UpdateWorkorderWarranty,
	CreateCostItem,
	UpdateBatchCostItems,
	DeleteCostItem,
	UpdateCostItem,
	CreateWorkorderGroupHeader,
	CreateWorkorderGroupHeaderSaveAsTemplate,
	AssignWorkorderTemplate,
	// LoadWorkorderTemplate,
	// LoadWorkorderTemplateNames,
	DeleteWorkorderGroup,
	//INVOICE
	GetInvoice,
	GetInvoiceById,
	GetInvoices,
	GetActiveInvoices,
	GetInvoiceByWorkorderId,
	GetInvoiceItemsByInvoiceId,
	GetContactInvoices,
	GetInvoiceVehicle,
	GetInvoiceContact,
	GetInvoiceBusiness,
	GetInvoiceReminder,
	GetInvoiceWarranty,
	CreateInvoice,
	CreateInvoiceItem,
	CreateInvoiceFromWorkorder,
	UpdateInvoiceItem,
	UpdateInvoiceDiscount,
	UpdateBatchInvoiceItems,
	UpdateInvoiceVehicle,
	UpdateInvoiceContact,
	UpdateInvoiceBusiness,
	UpdateInvoiceReminder,
	UpdateInvoiceWarranty,
	DeleteInvoiceItem,
	DeleteInvoiceGroup,
	DeleteInvoice,
	UpdateInvoiceStatus,
	UpdateInvoiceDate,
	CreateInvoiceGroupHeaderSaveAsTemplate,
	LoadInvoiceTemplate,
	LoadInvoiceTemplateNames,
	AssignInvoiceTemplate,
	SendInvoiceByEmail,
	ImportQuoteToInvoice,
	//PAYMENTS
	AddPayment,
	DeleteInvoicePayment,
	UpdateInvoicePayment,
	//QUOTE
	GetQuote,
	GetQuoteById,
	GetQuotes,
	GetActiveQuotes,
	GetQuoteByWorkorderId,
	GetQuoteItemsByQuoteId,
	GetContactQuotes,
	GetQuoteVehicle,
	GetQuoteContact,
	GetQuoteBusiness,
	GetQuoteReminder,
	CreateQuote,
	CreateQuoteItem,
	CreateQuoteFromWorkorder,
	UpdateQuoteItem,
	UpdateQuoteDiscount,
	UpdateBatchQuoteItems,
	UpdateQuoteVehicle,
	UpdateQuoteContact,
	UpdateQuoteBusiness,
	UpdateQuoteReminder,
	DeleteQuoteItem,
	DeleteQuoteGroup,
	DeleteQuote,
	UpdateQuoteStatus,
	UpdateQuoteDate,
	CreateQuoteGroupHeaderSaveAsTemplate,
	LoadQuoteTemplate,
	LoadQuoteTemplateNames,
	AssignQuoteTemplate,
	SendQuoteByEmail,
	GetPublicQuote as GetPublicQuote,
	//WARRANTY
	AddWarranty,
	GetWarrantyByCustomValue,
	UpdateWarrantyByCustomValue,
	// APPOINTMENTS
	UpdateAppointmentStatus,
	GetAppointment,
	GetOpenAppointmentsBetweenDates,
	CreateAppointment,
	UpdateAppointment,
	DeleteAppointment,
	//COMMENTS
	GetComments,
	DeleteComment,
	CreateComment,
	//SETTIINGS
	GetCompanySettings,
	UpdateCompanySettings,
	GetNonWorkingDates,
	GetNonWorkingDatesSummary,
	UpdateCompanyTheme,
	RequestCustomDomainAuthentication,
	UpdateSettings,
	//LISTS
	GetListAll,
	GetAddressSearch,
	//SEARCH
	SearchQuotes,
	ExportInvoices,
	SearchInvoices,
	SearchWorkorders,
	SearchAppointments,
	//HISTORY
	GetHistory,
	//ADMIN
	GetAllUsers,
};
