import { useState } from 'react';
// Material
import { Grid } from '@material-ui/core';

// Pages
import Page from '../../app/components/page';
import Box from '../../app/components/box';
import SettingsBlock from './components/settings-block';
import { CompanySettings } from './popups/company-settings';
import { CustomThemeSettings } from './popups/custom-theme-settings';
import { WorkingDaysSettings } from './popups/working-days-settings';
import { EmailSettings } from './popups/email-settings';
import { UserManagement } from './popups/user-management';

export const Settings = () => {
	const [open, setInternalOpen] = useState({
		companySettings: false,
		customThemeColors: false,
		subscription: false,
		calendar: false,
		emails: false,
		users: false,
	});

	const setOpen = (name, openState) => {
		setInternalOpen({
			...open,
			[name]: openState,
		});
	};

	const saveCustomeThemeClick = () => {
		setOpen(false);
	};

	// MAIN
	return (
		<Page heading="Settings" id="settings_page" transparent={true}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Box heading="My Company">
						<Grid container spacing={2}>
							<SettingsBlock
								name="companySettings"
								heading="Company Settings"
								body="Edit the address details, contact details and other company related information."
								open={open.companySettings}
								setOpen={setOpen}
							>
								<CompanySettings />
							</SettingsBlock>

							<SettingsBlock
								name="customThemeColors"
								heading={`Company Logo & Theme`}
								body="Upload or change your company logo for use on emails, invoices and estimates."
								open={open.customThemeColors}
								setOpen={setOpen}
							>
								<CustomThemeSettings setOpen={setOpen} saveFn={saveCustomeThemeClick} />
							</SettingsBlock>

							<SettingsBlock
								name="users"
								heading="Users"
								body="Create or manage users, passwords and control access roles."
								open={open.users}
								setOpen={setOpen}
								size="md"
							>
								<UserManagement open={open.users} />
							</SettingsBlock>

							{/* <SettingsBlock
								heading='Export all data'
								body='Export all data currently in the database.'
							/>*/}

							<SettingsBlock
								name="subscription"
								heading="Subscription"
								body="Manage your subscription, payment methods or delete account."
								open={open.subscription}
							/>
						</Grid>
					</Box>
				</Grid>

				<Grid item xs={12}>
					<Box heading="My Workshop">
						<Grid container spacing={2}>
							<SettingsBlock
								name="calendar"
								heading="Calendar"
								body="Add or modify business hours, company holidays and maximium vehicle capacity."
								open={open.calendar}
								setOpen={setOpen}
							>
								<WorkingDaysSettings />
							</SettingsBlock>

							{/* 
							<SettingsBlock
								heading='Automotive Technicians'
								body='Create or manage automotive technician user profiles.'
							/> */}
						</Grid>
					</Box>
				</Grid>

				<Grid item xs={12}>
					<Box heading="Emails, Appointments, Work Orders and Invoices">
						<Grid container spacing={2}>
							<SettingsBlock
								name="emails"
								heading="Sending emails"
								body="Manage the email address and email templates used for sending emails"
								open={open.emails}
								setOpen={setOpen}
								size={'md'}
							>
								<EmailSettings />
							</SettingsBlock>

							{/* <SettingsBlock
								heading='Appointments'
								body='Manage Appointment related status, email notifications and other settings'
							/>
							<SettingsBlock
								heading='Work Orders'
								body='Manage Work Order related status, email notifications and other settings'
							/>
							<SettingsBlock
								heading='Invoices'
								body='Manage Invoice related status, email notifications and other settings' 
							/>*/}
						</Grid>
					</Box>
				</Grid>

				<Grid item xs={12}>
					<Box heading={`Accounting, Tax & VAT`}>
						<Grid container spacing={2}>
							{/* <SettingsBlock
								heading='Accounting Dates'
								body='Set your Company and other Accounting Dates'
							/>
							<SettingsBlock
								heading='VAT Registration'
								body='Set your VAT registration status, including the VAT options.'
							/>
							<SettingsBlock
								heading='Currency and Number Format'
								body='Manage currency and number display formats.'
							/> */}
						</Grid>
					</Box>
				</Grid>

				<Grid item xs={12}>
					<Box heading="Security">
						<Grid container spacing={2}>
							{/* <SettingsBlock
								heading='My Details'
								body='Update your profile picture, personal details and password'
							/> */}
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</Page>
	);
};

export default Settings;
