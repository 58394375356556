import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useLocation } from 'react-router-dom';
import process from 'process';
import download from 'downloadjs';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
//material
import { useTheme } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Grid, Typography, Button, Container, useMediaQuery, AppBar, Box, Divider, CircularProgress } from '@material-ui/core';
import ThumbUpIcon from '@material-ui/icons/ThumbUp'; // components

//components
import config from '../../config.json';
import colours from '../../colour-schemes.json';

const domain = process.env.NODE_ENV === 'production' ? config.api.domain_public : config.api.domain_dev_public;

const Page = ({ invoiceAppId }) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [data, setData] = useState();
	const [busyPrint, setBusyPrint] = useState();
	const [busyDownload, setBusyDownload] = useState();
	const [notFound, setNotFound] = useState(false);

	//* get invoice
	useEffect(() => {
		const getInvoice = async () => {
			return await axios
				.get(`${domain}/invoice/${invoiceAppId}`)
				.then((ret) => {
					if (ret.data.data.length === 0) setNotFound(true);
					setData(ret.data.data[0]);
				})
				.catch((e) => {
					console.error(e);
				});
		};

		if (!data) getInvoice();
	}, [data]);

	//* open
	const openPDF = async () => {
		setBusyPrint(true);
		const openInNewTab = (url) => {
			const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
			if (newWindow) newWindow.opener = null;
		};

		try {
			await axios
				.get(`${domain}/invoice/${invoiceAppId}/pdf`, {
					responseType: 'blob',
				})
				.then((response) => {
					const file = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
					openInNewTab(file);
					setBusyPrint(false);
				})
				.catch((error) => {
					console.error(error);
					setBusyPrint(false);
				});
		} catch (error) {
			alert('Error while downloading file. Try again later');
			setBusyPrint(false);
		}
	};

	//* download
	const downloadFile = async () => {
		setBusyDownload(true);
		try {
			await axios({
				url: `${domain}/invoice/${invoiceAppId}/pdf`,
				method: 'GET',
				responseType: 'blob',
			}).then(async (response) => {
				let filename = response.headers['content-disposition'].split('filename=')[1].replaceAll("'", '');
				await download(response.data, filename, 'application/pdf');
				setBusyDownload(false);
			});
		} catch (error) {
			alert('Error while downloading file. Try again later');
			setBusyDownload(false);
		}
	};

	if (notFound)
		return (
			<Container
				style={{
					height: '100vh',
					width: '100vw',
				}}
			>
				<Typography variant="h6" align="center">
					Item not found
				</Typography>
			</Container>
		);

	if (!data) return null;

	return (
		<div
			style={{
				width: '100%',
				minHeight: '100vh',
				backgroundImage: `linear-gradient( rgba(225,225,225,0.7), rgba(225,225,225,0.7) ), url('https://www.germanautosalon.co.za/images/bg_1.jpg')`,
				backgroundPosition: '50% 50%',
				backgroundRepeat: 'no-repeat',
				backgroundAttachment: 'fixed',
			}}
		>
			<AppBar
				position="static"
				color="transparent"
				style={{
					padding: 10,
					backgroundColor: 'rgb(250,250,250)',
				}}
			>
				<Container maxWidth="md">
					<Grid container>
						<Grid item xs={12} md={6}>
							<Typography
								variant="h5"
								noWrap
								style={{
									mr: 2,
									fontWeight: 700,
									color: 'black',
									textDecoration: 'bold',
									letterSpacing: '0.2em',
								}}
							>
								<b>German Auto Salon</b>
							</Typography>
							<Typography
								style={{
									color: theme.palette.text.secondary,
									letterSpacing: '0.2em',
									textTransform: 'uppercase',
								}}
							>
								<b>Customer service portal</b>
							</Typography>
						</Grid>

						<Grid
							item
							xs={12}
							md={6}
							styles={{
								borderTop: mobile ? `1px solid ${theme.palette.divider}` : 0,
							}}
						>
							<Box
								style={{
									display: 'flex',
									flex: 1,
									flexDirection: mobile ? 'row' : 'column',
									justifyContent: mobile ? 'space-between' : 'flex-end',
									alignItems: mobile ? 'space-between' : 'flex-end',
								}}
							>
								<Typography>
									<b>011-672-9161</b>
								</Typography>
								<Typography>
									<b>accounts@germanautosalon.co.za</b>
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</AppBar>

			<Container
				style={{
					maxWidth: mobile ? '100%' : '50%',
					paddingTop: 10,
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Grid container>
							{/* //* title */}
							<Grid item xs={12} md={8} style={{ marginTop: '10px' }}>
								<Typography variant="h4" paragraph>
									<b>{`Invoice: ${data?.invoiceNumber}`}</b>
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					{/* //* totals */}
					<Section label="Invoice">
						<Row label="Total" value={data?.totals?.invoiceTotal} isTotal={true} isCurrency={true} />

						<Row label="Status" value={data?.invoiceStatus} isPaid={data?.invoiceStatus === 'Paid' ? true : false} />

						<Row label="Date issued" value={data?.updatedAt} isDate={true} />
						<Row label="Invoice Ref #" value={data?.invoiceNumber} />
						<Row label="Booking Ref #" value={data?.bookingNumber} />

						<Row divider={true} />

						<Row label="Client instructions" value={data?.workorderInstructions} />
						<Row label="Reminder for next service" value={data?.workorderNextServiceReminder} />

						<Row divider={true} />

						{/* //* butons */}
						<Grid item xs={12}>
							<Button
								variant="outlined"
								onClick={() => openPDF()}
								disabled={busyDownload || busyPrint}
								style={{
									textTransform: 'none',
									textDecoration: 'underline',
									color: '#8cabd9',
									fontSize: '0.8rem',
									marginRight: 5,
									padding: 0,
									paddingLeft: 10,
									paddingRight: 10,
								}}
							>
								{busyPrint && (
									<CircularProgress
										size={16}
										style={{
											marginRight: '5px',
										}}
									/>
								)}
								Print
							</Button>
							<Button
								variant="outlined"
								onClick={() => downloadFile()}
								disabled={busyDownload || busyPrint}
								style={{
									textTransform: 'none',
									textDecoration: 'underline',
									color: '#8cabd9',
									fontSize: '0.8rem',
									padding: 0,
									paddingLeft: 10,
									paddingRight: 10,
								}}
							>
								{busyDownload && (
									<CircularProgress
										size={16}
										style={{
											marginRight: '5px',
										}}
									/>
								)}
								Download
							</Button>
						</Grid>
					</Section>

					{/* //* Vehicle and Contact */}
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							{/* //* vehicle */}
							<Section label="Vehicle">
								<Row label="Registration No." value={data?.vehicle.vehicleRegistration} />
								<Row label="Make & Model" value={`${data?.vehicle.vehicleMake} ${data?.vehicle.vehicleModel}`} />
								<Row label="Mileage" value={`${data?.workorderOdometer} km's`} />
							</Section>
						</Grid>
						<Grid item xs={12} md={6}>
							{/* //* contact */}
							<Section label="Contact">
								<Row label="Name" value={data?.contact?.contactFullname} />
								<Row label="Email" value={data?.contact?.contactEmail} />
								<Row label="Mobile" value={data?.contact?.contactMobile} />

								<Row divider={true} />

								<Row label="Customer" value={data?.business?.businessName} />
								<Row label="Phone" value={data?.business?.businessPhone} />
								<Row label="Email" value={data?.business?.businessEmail} />
							</Section>
						</Grid>
					</Grid>

					{/* //* History */}
					{/* <Grid container spacing={2}>
						<Grid item xs={12} md={12}>
							<Section label="Previous Invoices">history goes here</Section>
						</Grid>
					</Grid> */}
				</Grid>
			</Container>
		</div>
	);
};

const Section = ({ label, maxWidth = '100%', children }) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<>
			<Grid
				container
				style={{
					borderRadius: 5,
					border: `1px solid ${theme.palette.divider}`,
					marginBottom: 10,
					padding: 10,
					maxWidth: mobile ? '100%' : maxWidth,
					backgroundColor: 'rgb(250,250,250)',
				}}
			>
				<Grid item xs={12}>
					<Typography
						variant="h6"
						styles={{
							marginBottom: 10,
						}}
					>
						<b>{label}</b>
					</Typography>
					<Divider variant="fullWidth" style={{ marginTop: 10, marginBottom: 10 }} />
				</Grid>
				{children}
			</Grid>
		</>
	);
};

const Row = ({ label, value, divider = false, isCurrency = false, isDate = false, isPaid = false }) => {
	const theme = useTheme();

	if (divider)
		return (
			<Grid item xs={12}>
				<Divider variant="fullWidth" style={{ marginTop: 10, marginBottom: 10 }} />
			</Grid>
		);

	return (
		<>
			<Grid item xs={6} md={6}>
				<Typography
					style={{
						color: theme.palette.text.secondary,
					}}
				>
					{label}
				</Typography>
			</Grid>

			<Grid item xs={6} md={6} align="right">
				{isDate && <Typography>{value ? moment(value).format('ddd, DD MMMM YYYY') : '-'}</Typography>}

				{isCurrency && (
					<Typography
						noWrap={false}
						variant="h6"
						paragraph={true}
						style={{
							whiteSpace: 'pre-wrap',
							// wordWrap: 'break-word',
						}}
					>
						<CurrencyFormat
							value={Math.abs(value)}
							displayType={'text'}
							thousandSeparator={true}
							prefix={'R '}
							decimalScale={2}
							fixedDecimalScale={true}
						/>
					</Typography>
				)}

				{isPaid && (
					<Box
						style={{
							display: 'flex',
							flex: 1,
							alignItems: 'center',
							justifyContent: 'flex-end',
							flexDirection: 'row',
						}}
					>
						<Typography
							style={{
								fontSize: '1.2rem',
								marginRight: 15,
								color: theme.palette.success.dark,
							}}
						>
							<b>{value || '-'}</b>
						</Typography>
						<ThumbUpIcon
							style={{
								fontSize: '1.2rem',
								color: theme.palette.success.dark,
							}}
						/>
					</Box>
				)}

				{isPaid === false && !isCurrency && !isDate && <Typography>{value || '-'}</Typography>}
			</Grid>
		</>
	);
};

export default function PubliInvoicesPage() {
	const queryClient = new QueryClient();
	const { search } = useLocation();
	const params = useMemo(() => new URLSearchParams(search), [search]);
	const invoiceApprovelID = useMemo(() => {
		if (params) return params.get('id');
	}, [params]);

	const customTheme = {
		type: 'light',
		...colours.uptune,
	};

	const appliedTheme = createTheme({
		palette: customTheme,
		typography: {
			fontFamily: [
				'Nunito Sans',
				// 'Source Sans Pro',
				'sans-serif',
			].join(','),
		},
		overrides: {
			MuiTypography: {
				body1: { fontSize: '0.765rem' },
				body2: { fontSize: '0.765rem' },
				subtitle1: { fontSize: '0.765rem' },
				subtitle2: { fontSize: '0.765rem' },
			},
			MuiTooltip: {
				tooltip: {
					fontSize: '1em',
					color: 'white',
					backgroundColor: customTheme.secondary.main,
				},
				arrow: {
					'&:before': {
						border: `1px solid ${customTheme.secondary.main}`,
					},
					color: customTheme.secondary.main,
				},
			},
		},
	});

	return (
		<ThemeProvider theme={appliedTheme}>
			<CssBaseline />

			<QueryClientProvider client={queryClient}>
				<Page invoiceAppId={invoiceApprovelID} />
			</QueryClientProvider>
		</ThemeProvider>
	);
}
