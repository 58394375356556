import React, { useState } from 'react';
import moment from 'moment';

// Data
import { useQuery } from 'react-query';
import { GetAllUsers } from '../../../app/utils/data';

//Material
import { useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Paper, Avatar } from '@material-ui/core';
import MaterialTable from 'material-table';

//Icons
// import EditIcon from '@material-ui/icons/Edit';

//Pages
import Loading from '../../../app/components/loading';
import PopOutNoButton from '../../../app/components/popout/popout-no-button';
import EditUser from '../components/edit-user';

export const UserManagement = ({ open }) => {
	const theme = useTheme();
	const [openEditUser, setOpenEditUser] = useState();
	const [user, setUser] = useState();

	const { data, isLoading } = useQuery(['users'], GetAllUsers, { enabled: !!open, refetchOnWindowFocus: false });

	const columns = [
		{
			width: '1%',
			cellStyle: { maxWidth: 10 },
			title: 'Avatar',
			field: 'avatar',
			render: function render(row) {
				return <Avatar alt={row.name} src={row.picture} />;
			},
		},
		{
			title: 'E-mail',
			field: 'email',
			render: function render(row) {
				return (
					<Typography variant="body2" noWrap>
						{row.email}
					</Typography>
				);
			},
		},
		{
			title: 'Name',
			field: 'name',
			render: function render(row) {
				return (
					<>
						<Typography variant="body2" noWrap>
							{row.name}
						</Typography>
						<Typography variant="caption" noWrap>
							{row.app_metadata.company}
						</Typography>
					</>
				);
			},
		},
		{
			title: 'Logins',
			field: 'last_login',
			render: function render(row) {
				return (
					<Grid container>
						<Grid item xs={6}>
							<Typography variant="body2" color="textSecondary" noWrap>
								Last login:
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography variant="body2" noWrap>
								{moment(row.last_login).format('DD MMM YYYY')}
							</Typography>
						</Grid>

						<Grid item xs={6}>
							<Typography variant="body2" color="textSecondary" noWrap>
								Login count:
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography variant="body2" noWrap>
								{row.logins_count}
							</Typography>
						</Grid>
					</Grid>
				);
			},
		},
		{
			title: 'Permissions',
			render: function render(row) {
				// <Grid container>
				return row.app_metadata.roles.map((role, idx) => {
					return (
						<Grid item key={idx}>
							<p>{role}</p>
						</Grid>
					);
				});
				// }
				// </Grid>
			},
		},
	];

	if (isLoading) return <Loading />;

	return (
		<>
			<MaterialTable
				title="User Management"
				data={data?.users}
				columns={columns}
				components={{
					Container: (props) => <Paper {...props} elevation={0} />,
				}}
				onRowClick={(event, rowData) => {
					setUser(rowData);
					setOpenEditUser(true);
				}}
				options={{
					// GENERAL
					padding: 'dense',
					toolbar: false,
					// grouping: true,

					//HEADER
					headerStyle: {
						backgroundColor: theme.palette.background.default,
						borderLeft: `1px solid ${theme.palette.divider}`,
						borderTop: `1px solid ${theme.palette.divider}`,
					},

					//BODY
					maxBodyHeight: '44vh',

					//ROW
					selection: false,
					rowStyle: {
						verticalAlign: 'top',
					},
					cellStyle: {
						borderLeft: `1px solid ${theme.palette.divider}`,
					},

					//PAGING
					paging: false,
					emptyRowsWhenPaging: false,
				}}
			/>

			<PopOutNoButton heading="Edit user" buttonText="Edit" open={openEditUser} setOpen={setOpenEditUser}>
				<EditUser user={user} />
			</PopOutNoButton>
		</>
	);
};

export default UserManagement;
