import { createContext, useContext } from 'react';

const WorkorderContext = createContext({
    workorder: null,
    refetch: () => {},
});

export function useWorkorderContext() {
    return useContext(WorkorderContext);
}

export default WorkorderContext;