import { useState } from 'react';
import { Box } from '@material-ui/core';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import AppointmentNew from 'pages/appointments/views/new';

export const CreateAppointmentFromQuoteButton = ({ quote, refetch }) => {
	const [open, setOpen] = useState(false);
	return (
		<Box style={{ marginRight: 5 }}>
			<AppointmentNew
				open={open}
				setOpen={setOpen}
				iconSize="small"
				type="appointment"
				refetchFn={refetch}
				hideQuoteFirst={true}
				customIcon={<EventAvailableIcon />}
				customTooltip="Create a booking with this quote attached."
				initialData={{
					quoteID: quote?._id,
					contact: quote?.contact,
					vehicle: quote?.vehicle,
					business: quote?.business,
					bookingInstructions: quote?.quoteInstructions,
					quoteFirst: true,
				}}
				disabled={quote?.bookingID || quote?.workorderID}
			/>
		</Box>
	);
};
