import React from 'react';

//Material
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Badge, Grid } from '@material-ui/core';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

//Icons
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import LanguageIcon from '@material-ui/icons/Language';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import UpdateIcon from '@material-ui/icons/Update';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';

//Query
import { useQuery } from 'react-query';
import { GetHistory } from '../../../../utils/data';

const useStyles = makeStyles(() => ({
	paper: {
		padding: '6px 16px',
	},
}));

export default function InvoiceHistoryTimeline({ parentId }) {
	const { data, isLoading } = useQuery(['history', parentId], GetHistory, {
		refetchOnWindowFocus: false,
	});

	if (isLoading) return <p>Loading...</p>;

	if (!data)
		return (
			<Grid container alignItems="center" justifyContent="center" style={{ height: 100 }}>
				<Typography variant="body2">No historical data for this item</Typography>
			</Grid>
		);

	return (
		<>
			<Timeline align="alternate">
				{data?.history?.map((item, idx) => {
					return (
						<StyledTimeLineItem
							key={idx}
							type={item?.type}
							category={item?.category}
							time={item?.time}
							day={item?.day}
							title={item?.title}
							message={item?.message}
							isLast={idx === data?.history?.length - 1}
						/>
					);
				})}
			</Timeline>
		</>
	);
}

const StyledTimeLineItem = ({ type, category, time, day, title, message, isLast = false }) => {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<TimelineItem>
			<TimelineOppositeContent>
				<Typography variant="body2" color="textSecondary">
					{day}
				</Typography>
				<Typography variant="body2" color="textSecondary">
					{time}
				</Typography>
			</TimelineOppositeContent>
			<TimelineSeparator>
				<TimelineDot
					style={
						type === 'complete'
							? { backgroundColor: theme.palette.success.main }
							: type === 'start'
							? { backgroundColor: theme.palette.success.main }
							: {}
					}
				>
					<TimelineIcon type={type} category={category} />
				</TimelineDot>
				{!isLast && <TimelineConnector />}
			</TimelineSeparator>
			<TimelineContent>
				<Paper elevation={3} className={classes.paper}>
					<Typography variant="button" component="h1">
						{title}
					</Typography>
					{message && <Typography variant="caption">{message}</Typography>}
				</Paper>
			</TimelineContent>
		</TimelineItem>
	);
};

const TimelineIcon = ({ type, category }) => {
	const theme = useTheme();

	let icon = null;
	const iconStyle = { color: theme.palette.info.main, backgroundColor: 'rgba(255,255,255,0.7', borderRadius: 5, fontSize: 18 };

	let badgeIcon = null;
	const badgeStyle = { color: theme.palette.secondary.main };

	switch (type) {
		case 'appointment':
			badgeIcon = <TodayOutlinedIcon style={{ ...badgeStyle }} />;
			break;

		case 'workorder':
			badgeIcon = <DirectionsCarIcon style={{ ...badgeStyle }} />;
			break;

		case 'invoice':
			badgeIcon = <DescriptionOutlinedIcon style={{ ...badgeStyle }} />;
			break;

		case 'payment':
			badgeIcon = <AttachMoneyIcon style={{ ...badgeStyle }} />;
			break;

		default:
			badgeIcon = null;
	}

	switch (category) {
		case 'new':
			icon = <NewReleasesIcon style={{ ...iconStyle }} fontSize="small" />;
			break;

		case 'update':
			icon = <UpdateIcon style={{ ...iconStyle }} fontSize="small" />;
			break;

		case 'confirmed':
			icon = <LoyaltyIcon style={{ ...iconStyle }} fontSize="small" />;
			break;

		case 'new-web':
			icon = <LanguageIcon style={{ ...iconStyle }} fontSize="small" />;
			break;

		case 'reschedule':
			icon = <PauseCircleOutlineIcon style={{ ...iconStyle }} fontSize="small" />;
			break;

		case 'email':
			icon = <MailOutlineIcon style={{ ...iconStyle }} fontSize="small" />;
			break;

		case 'status':
			icon = <InfoOutlinedIcon style={{ ...iconStyle }} fontSize="small" />;
			break;

		case 'complete':
			icon = <CheckCircleOutlineOutlinedIcon style={{ ...iconStyle }} fontSize="small" />;
			break;

		case 'delete':
			icon = <DeleteOutlinedIcon style={{ ...iconStyle }} fontSize="small" />;
			break;

		default:
			icon = null;
	}

	return (
		<Badge badgeContent={icon} overlap="rectangular">
			{badgeIcon}
		</Badge>
	);
};
