import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { OutlinedInput } from '@material-ui/core/';

export const MultiLineDisplay = ({ name = 'non-name-mld', value = '', template }) => {
    return (
        <TextareaAutosize
            id={name}
            name={name}
            maxRows={template?.multiline ? template?.multiline : 6}
            value={value || null}
            style={{ width: '100%', border: 0, backgroundColor: 'transparent', font: 'inherit', fontSize: 'inherit', color: 'inherit' }}
            readOnly={true}
            color='secondary'
        />
    )
}

export const MultiLineEdit = ({ item, initialValue, onChange, autoFocus = false }) => {
    return (
        <OutlinedInput
            type={item?.type}
            name={item?.id}
            size="small"
            margin="dense"
            variant="outlined"
            fullWidth={true}
            multiline
            rows={6}
            disabled={!item?.canEdit}
            defaultValue={initialValue ? initialValue : ''}
            onChange={(e) => onChange(e)}
            autoFocus={autoFocus}
            color='secondary'
        />
    )
}