import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	root: {
		height: '100vh',
	},
}));

export const deleteAllCookies = () => {
	sessionStorage.removeItem('token');

	var cookies = document.cookie.split(';');

	for (var i = 0; i < cookies.length; i++) {
		var cookie = cookies[i];
		var eqPos = cookie.indexOf('=');
		var name = eqPos > -1 ? cookie.toString().substr(0, eqPos) : cookie;
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
	}
};

const Home = () => {
	const classes = useStyles();
	let history = useHistory();
	const { isAuthenticated, loginWithRedirect } = useAuth0();
	if (isAuthenticated) history.push('/appointments');

	const doLogin = () => {
		deleteAllCookies();
		loginWithRedirect();
	};

	useEffect(() => {
		if (!isAuthenticated) doLogin();
	}, []);

	return (
		<Grid container direction="column" justifyContent="center" alignItems="center" className={classes.root}>
			<Typography align="center" fullWidth variant="h2">
				Please wait...
			</Typography>
		</Grid>
	);
};

export default Home;
