import moment from 'moment';
// data
import { useQuery } from 'react-query';
import { GetDashboard } from 'app/utils/data';
// material
import { Container, Grid } from '@material-ui/core';
// componenets
import BarChart from 'app/widgets/dashboard/BarChart';
import DashboardContainer from 'app/widgets/dashboard/DashboardContainer';
import AgeingTable from 'app/widgets/dashboard/AgeingTable';
import TopDebtorsTable from 'app/widgets/dashboard/TopDebtors';
import LatestComments from 'app/widgets/dashboard/LatestComments';

const Dashboard = () => {
	const { data } = useQuery(['dashboard'], GetDashboard, { refetchOnWindowFocus: true, refetchInterval: 60000 });

	return (
		<Container
			maxWidth={false}
			style={{
				padding: 0,
				paddingTop: 20,
			}}
		>
			<Grid container spacing={5}>
				{/* //* LEFT */}
				<Grid item xs={9}>
					<Grid container spacing={5}>
						{/* //> ageing */}
						<Grid item xs={6}>
							<DashboardContainer title="Ageing">
								<AgeingTable data={data?.ageing} />
							</DashboardContainer>
						</Grid>

						{/* //> debtors */}
						<Grid item xs={6}>
							<DashboardContainer title="Top debtors">
								<TopDebtorsTable data={data?.topDebtors} />
							</DashboardContainer>
						</Grid>

						{/* //> collections */}
						<Grid item xs={12}>
							<DashboardContainer title={`Collections - ${moment().format('MMMM yyyy')}`}>
								<BarChart data={data?.collections} />
							</DashboardContainer>
						</Grid>
					</Grid>
				</Grid>

				{/* //* RIGHT */}
				<Grid item xs={3}>
					<DashboardContainer title="Latest Comments" invert={true}>
						<LatestComments data={data?.comments} />
					</DashboardContainer>
				</Grid>
			</Grid>
		</Container>
	);
};

export default Dashboard;
