import moment from 'moment';
import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// Context
import { useBusinessContext } from '../../../../context/business-context'

// Pages
import { Table } from './table';
import { NumberFormatMileageText } from '../../../components/textbox'
import { CustomStatusBadge } from '../../../components/card/status'

export const BusinessWorkorders = () => {
    const theme = useTheme();
    const { business } = useBusinessContext()

    // COLUMNS
    const columns = [
        {
            title: 'Status', field: 'workorderStatus',
            cellStyle: {
                maxWidth: 200,
                borderLeft: `1px solid ${theme.palette.divider}`,
                borderRight: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            render: function render(row) {
                return (
                    <CustomStatusBadge
                        label={row?.workorderStatus}
                        status={row?.workorderStatus}
                    />
                )
            }
        },
        {
            title: 'Work Order #', field: 'workorderNumber',
            cellStyle: {
                maxWidth: 200,
                borderRight: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            render: function render(row) {
                return (
                    <>
                        <Typography variant='body2' display='block'>
                            {row.workorderNumber}
                        </Typography>
                    </>
                )
            }
        },
        {
            title: 'Date', field: 'workorderDate',
            cellStyle: {
                maxWidth: 200,
                borderRight: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            render: function render(row) {
                return (
                    <Typography variant='body2' display='block'>
                        {moment(row?.workorderDate).format('DD MMM YYYY')}
                    </Typography>
                )
            }
        },
        {
            title: 'Contact', field: 'contact.contactFullname',
            cellStyle: {
                maxWidth: 200,
                borderRight: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            render: function render(row) {
                return (
                    <>
                        <Typography variant='body2' display='block' noWrap>
                            {row?.contact?.contactFullname}
                        </Typography>

                        <Typography variant='caption' display='block' color="textSecondary" noWrap>
                            {row?.contact?.contactEmail}
                        </Typography>
                        <Typography variant='caption' display='block' color="textSecondary">
                            {row?.contact?.contactMobile}
                        </Typography>
                    </>
                )
            }
        },
        {
            title: 'Mileage', field: 'workorderOdometer',
            cellStyle: {
                maxWidth: 200,
                // borderRight: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            defaultSort: 'desc',
            render: function render(row) {
                return (
                    <Typography variant='body2' display='block'>
                        {NumberFormatMileageText(row?.workorderOdometer)}
                    </Typography>
                )
            }
        },
    ]

    return (
        <Table
            title='Work orders'
            data={business?.workorders?.workorders}
            columns={columns}
            url='workorder'
        />
    )
}