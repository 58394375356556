import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useBusinessContext } from '../../../../context/business-context'
import { Table } from './table';

export const BusinessVehicles = () => {
    const theme = useTheme();
    const { business } = useBusinessContext()

    // COLUMNS
    // -------------------------------
    const columns = [
        {
            title: 'Registration', field: 'vehicleRegistration',
            cellStyle: {
                maxWidth: 200,
                borderLeft: `1px solid ${theme.palette.divider}`,
                borderRight: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            render: function render(row) {
                return (
                    <>
                        <Typography variant='body2' display='block'>
                            {row.vehicleRegistration}
                        </Typography>
                    </>
                )
            }
        },
        {
            title: 'Vehicle', field: 'vehicleMake',
            cellStyle: {
                maxWidth: 200,
                // borderRight: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            render: function render(row) {
                return (
                    <>
                        {row?.vehicleMake && (
                            <Typography variant='body2' display='block'>
                                {row?.vehicleMake}{' '}{row?.vehicleModel}
                            </Typography>
                        )}
                    </>
                )
            }
        }
    ]

    return (
        <Table
            title='Vehicles'
            data={business?.vehicles}
            columns={columns}
            url='vehicle'
        />
    )
}