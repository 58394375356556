import { useState } from 'react';

//Query
import { useMutation, useQueryClient } from 'react-query';

//@Material
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';

//Icons
import NoteAddIcon from '@material-ui/icons/NoteAdd';

//Data
import {
    AddWarranty,
    UpdateWarrantyByCustomValue,
} from '../../../../utils/data';

//Pages
import PopOut from '../../../../components/popout';
import HelpBox from '../../../../components/help'
import { Section } from '../../../../components/card/section'

export default function WarrantyNew({ warrantyData = null, parentId }) {
    const queryClient = useQueryClient()

    // STATE
    const [open, setOpen] = useState(false);

    // DATA
    const [inputData, setInputData] = useState({
        _id: warrantyData?._id || null,
        parentId: parentId,
        firstService: 1000,
        subsequentServices: 10000,
        upUntil: 30000,
        otherWorkshopsAllowed: false,
    });

    const createWarranty = useMutation(AddWarranty, {
        onSuccess: () => {
            queryClient.refetchQueries(['warranty'], { active: true })
        },
    });

    const updateWarranty = useMutation(UpdateWarrantyByCustomValue, {
        onSuccess: () => {
            queryClient.refetchQueries(['warranty'], { active: true })
        },
    });


    // FUNCTIONS
    const saveClick = async () => {
        await createWarranty.mutate(inputData)
        setOpen(false)
    }

    const updateClick = async () => {
        await updateWarranty.mutate(inputData)
        setOpen(false)
    }

    const onChange = (e, isOption = false) => {
        setInputData(prevState => ({ ...prevState, [e.target.name]: isOption ? e.target.checked : e.target.value }))
    }


    // Main Return -->
    return (
        <PopOut
            heading="Warranties"
            buttonText="New"
            icon={<NoteAddIcon style={{ fontSize: '1.2rem' }} />}
            tooltip="Load a service warranty against this vehicle"
            open={open}
            setOpen={setOpen}
            refetchQueryNames={['active_appointments']}
            iconSize='small'

            cancelButtonFn={() => setOpen(false)}
            saveButtonText={!warrantyData ? 'Add Warranty' : 'Update Warranty'}
            saveButtonFn={!warrantyData ? saveClick : updateClick}
        >
            <div style={{ margin: 3, paddingLeft: 20, paddingRight: 20 }}>
                {/* HELP */}
                <HelpBox text="Here you can add or edit the warranty conditions for the client for the currently selected job." />

                <Grid container alignItems='center' spacing={2}>
                    <Section text='Warranty conditions' />

                    {/* FIRST SERVICE */}
                    <Label text='First service required at' />
                    <Input name='firstService' defaultValue={warrantyData?.firstService || 1000} onChange={onChange} />
                    <Grid item xs={1}>
                        KM&apos;s
                    </Grid>

                    {/* SUBSEQUENT SERVICES */}
                    <Label text='Subsequent services every' />
                    <Input name='subsequentServices' defaultValue={warrantyData?.subsequentServices || 10000} onChange={onChange} />
                    <Grid item xs={1}>
                        KM&apos;s
                    </Grid>

                    {/* UP TO KM */}
                    <Label text='Up to a maximum of' />
                    <Input name='upUntil' defaultValue={warrantyData?.upUntil || 30000} onChange={onChange} />
                    <Grid item xs={1}>
                        KM&apos;s
                    </Grid>

                    {/* CAN CUSTOMER SERVICE ELSEWHERE */}
                    <Label text='This customer can service the vehicle elsewhere' />
                    <Option name='otherWorkshopsAllowed' defaultValue={warrantyData?.otherWorkshopsAllowed || false} onChange={onChange} />
                    <Grid item xs={1} />
                </Grid>
            </div>
        </PopOut>
    );
}


const Label = ({ text }) => (
    <Grid item xs={6}>
        <Typography
            variant="subtitle2"
        >
            {text}
        </Typography>
    </Grid>
)

const Input = ({ name, defaultValue, onChange }) => (
    <Grid item xs={3}>
        <TextField
            name={name}
            size="small"
            margin="dense"
            variant="outlined"
            style={{ borderWidth: 0 }}
            fullWidth={true}
            defaultValue={defaultValue || 0}
            onChange={(e) => onChange(e)}
        />
    </Grid>
)

const Option = ({ name, onChange }) => (
    <Grid item xs={3}>
        <Switch
            name={name}
            onChange={(e) => onChange(e, true)}
        />
    </Grid>
)