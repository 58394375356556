import React from 'react';
//@material
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Grid, Card, CardHeader, Checkbox, FormControlLabel } from '@material-ui/core';
//Context
import { useNewAppointmentContext } from '../../../../../../context/appointments-new-context';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		input: {
			[`& fieldset`]: {
				borderRadius: 0,
			},
		},
	})
);

export const AppointmentQuoteFirst = ({ defaultValue = '', disabled = false }) => {
	const classes = useStyles();

	const { appointment, setAppointment, errors } = useNewAppointmentContext();

	return (
		<Card className={classes.root} elevation={0}>
			<CardHeader
				style={{
					paddingTop: 0,
					paddingBottom: 0,
				}}
				title={
					<Grid container spacing={0} alignItems="flex-end" style={{ padding: 0 }}>
						<Grid item xs={12}>
							<FormControlLabel
								label="Quote first"
								disabled={disabled}
								checked={Boolean(defaultValue) || false}
								control={
									<Checkbox
										color="primary"
										onChange={(event) => {
											setAppointment({
												...appointment,
												quoteFirst: event.target.checked,
											});
										}}
									/>
								}
							/>
						</Grid>

						{errors?.main?.workorderOdometer && (
							<Typography variant="caption" color="error">
								{errors?.main?.workorderOdometer}
							</Typography>
						)}
					</Grid>
				}
			/>
		</Card>
	);
};

export default AppointmentQuoteFirst;
