import moment from 'moment';

//Material
import { useTheme } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';

//Icons
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

// Context
import { useVehicleContext } from '../../../../context/vehicle-context'

// Pages
import { Table } from './table';
import { NumberFormatCurrencyText, NumberFormatMileageText } from '../../../components/textbox'
import { CustomStatusBadge } from '../../../components/card/status'

export const VehicleInvoices = () => {
    const theme = useTheme();
    const { vehicle } = useVehicleContext()

    // COLUMNS
    const columns = [
        {
            title: 'Status', field: 'invoiceStatus',
            cellStyle: {
                maxWidth: 200,
                borderRight: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            render: function render(row) {
                return (
                    <CustomStatusBadge
                        label={row?.invoiceStatus}
                        status={row?.invoiceStatus}
                    />
                )
            }
        },
        {
            title: 'Inv #', field: 'invoiceNumber',
            cellStyle: {
                maxWidth: 200,
                borderRight: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            render: function render(row) {
                return (
                    <>
                        <Typography variant='body2' display='block'>
                            {row.invoiceNumber}
                        </Typography>
                    </>
                )
            }
        },
        {
            title: 'Date', field: 'invoiceDate',
            cellStyle: {
                maxWidth: 200,
                borderRight: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            render: function render(row) {
                return (
                    <Typography variant='body2' display='block'>
                        {moment(row?.invoiceDate).format('DD MMM YYYY')}
                    </Typography>
                )
            }
        },
        {
            title: 'Contact', field: 'contact.contactFullname',
            cellStyle: {
                maxWidth: 200,
                borderRight: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            render: function render(row) {
                return (
                    <>
                        <Typography variant='body2' display='block' noWrap={true}>
                            {row?.contact?.contactFullname}
                        </Typography>

                        <Typography variant='caption' display='block' color='textSecondary' noWrap={true}>
                            {row?.contact?.contactEmail}
                        </Typography>

                        <Typography variant='caption' display='block' color='textSecondary' noWrap={true}>
                            {row?.contact?.contactMobile}
                        </Typography>
                    </>
                )
            }
        },
        {
            title: 'Mileage', field: 'workorderOdometer',
            cellStyle: {
                maxWidth: 200,
                borderRight: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            render: function render(row) {
                return (
                    <Typography variant='body2' display='block'>
                        {NumberFormatMileageText(row?.workorderOdometer)}
                    </Typography>
                )
            }
        },
        {
            title: 'Total', field: 'invoiceSubTotal',
            cellStyle: {
                maxWidth: 200,
                borderRight: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            render: function render(row) {
                return (
                    <Typography variant='body2' display='block'>
                        {NumberFormatCurrencyText(row?.invoiceSubTotal)}
                    </Typography>
                )
            }
        },
        {
            title: 'Balance', field: 'invoiceBalance',
            cellStyle: {
                maxWidth: 200,
                // borderRight: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            render: function render(row) {
                return (
                    <Grid container alignItems="center">
                        <Typography variant='body2' display='block' style={{ paddingRight: 10 }}>
                            {NumberFormatCurrencyText(row?.invoiceBalance)}
                        </Typography>

                        {row?.invoiceBalance > 0 && (
                            <ReportProblemIcon style={{ fontSize: "15px", color: yellow[500] }} />
                        )}
                    </Grid>
                )
            }
        },
    ]

    return (
        <Table
            title='Invoices'
            data={vehicle?.invoices?.invoices}
            columns={columns}
            url='invoice'
        />
    )
}