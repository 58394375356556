import { useBusinessContext } from '../../../../context/business-context'
import { Box } from '../../../components/box';
import { KeyPairDisplay } from '../../../components/key-pair';
import { Divider } from '@material-ui/core';

export default function ContactAccountStatus() {
    const { business } = useBusinessContext()

    return (
        <Box heading="Account Status">
            <>
                <KeyPairDisplay
                    label="Account Balance"
                    value={business?.invoices?.overallBalance || "0"}
                    template={{ type: 'currency' }}
                />

                {/* {business?.invoices?.overallBalance > 0 && (
                    <KeyPairDisplay
                        label="Last payment"
                        value={business?.stats?.lastPayment || new Date()}
                        template={{ type: 'dateAgo' }}
                    />
                )} */}

                <br />
                <Divider />
                <br />

                <KeyPairDisplay
                    label="Last visit"
                    value={business?.stats?.lastVisit}
                    template={{ type: 'dateAgo' }}
                />

                <KeyPairDisplay
                    label="Last Invoice"
                    value={business?.stats?.lastInvoice}
                    template={{ type: 'dateAgo' }}
                />

                <KeyPairDisplay
                    label="# of visits"
                    value={business?.stats?.numberOfVisits}
                />
            </>
        </Box>
    )
}