import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useVehicleContext } from '../../../../context/vehicle-context'
import { Table } from './table';

export const VehicleBusiness = () => {
    const theme = useTheme();
    const { vehicle } = useVehicleContext()

    // COLUMNS
    const columns = [
        {
            title: 'Name', field: 'businessName',
            cellStyle: {
                maxWidth: 200,
                borderLeft: `1px solid ${theme.palette.divider}`,
                borderRight: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            render: function render(row) {
                return (
                    <>
                        <Typography variant='body2' display='block'>
                            {row.businessName}
                        </Typography>
                    </>
                )
            }
        },
        {
            title: 'Email', field: 'businessEmail',
            cellStyle: {
                maxWidth: 200,
                borderRight: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            render: function render(row) {
                return (
                    <>
                        <Typography variant='body2' display='block'>
                            {row?.businessEmail}
                        </Typography>
                    </>
                )
            }
        },
        {
            title: 'Phone', field: 'businessPhone',
            cellStyle: {
                maxWidth: 200,
                // borderRight: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            render: function render(row) {
                return (
                    <>
                        <Typography variant='body2' display='block'>
                            {row?.businessPhone}
                        </Typography>
                    </>
                )
            }
        }
    ]

    return (
        <Table
            title='Business'
            data={vehicle?.business}
            columns={columns}
            url='business'
        />
    )
}