import { useMemo } from 'react';
// material
import { Box, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
// componenets
import { useSettingsContext } from '../../../../../../context/settings-context';

export default function EmailToAddressInput({ name, label, onChange, defaultValues = [], error = '' }) {
	const { lists } = useSettingsContext();

	var contact = lists?.contacts?.filter((option) => option?.contactEmail && option?.contactEmail != null);
	var business = lists?.business?.filter((option) => option?.businessEmail && option?.businessEmail != null);

	const options = useMemo(() => {
		const ret = [];
		contact.map((contact) => {
			if (contact.contactEmail) ret.push(contact);
		});
		business.map((business) => {
			if (business?.businesseEmail)
				ret.push({
					contactFullname: business?.businessName,
					contactEmail: business?.businessEmail,
				});
		});

		return ret;
	}, [contact, business]);

	return (
		<Autocomplete
			multiple
			freeSolo
			name={name}
			id={`email-${label}`}
			options={options}
			value={defaultValues}
			color="secondary"
			size="small"
			getOptionLabel={(option) => option?.contactEmail}
			onChange={(event, newValue) => {
				var value = [];
				newValue.map((item) => {
					//In contacts
					if (typeof item === 'object') value.push({ ...item });

					//Not in contacts
					if (typeof item === 'string')
						value.push({
							contactEmail: item,
							contactFullname: item,
						});
				});

				onChange({
					target: {
						name,
						value,
					},
				});
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="outlined"
					label={label}
					placeholder="Search email address..."
					color="secondary"
					size="small"
					helperText={
						<Typography variant="caption" color="error">
							{error}
						</Typography>
					}
				/>
			)}
			renderOption={(option) => (
				<Box>
					<Typography noWrap display="block" variant="body2">
						{option.contactFullname}
					</Typography>
					<Typography noWrap display="block" variant="caption" color="textSecondary">
						{option.contactEmail.toLowerCase()}
					</Typography>
				</Box>
			)}
		/>
	);
}
