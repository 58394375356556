import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

export const ListBoxDisplay = ({ value, template }) => {
    return value.map((item) => {
        return (
            <div
                key={item._id}
                style={{ margin: 0 }}
            >
                <Link
                    href={'/' + template.id + '/' + item._id}
                    onClick={(e) =>
                        e.preventDefault
                    }
                >
                    {item?.[template.displayName] ? item?.[template.displayName] : 'UNKNOWN'}
                </Link>

                {template.subText?.length === 1 && (
                    <div style={{ margin: 0 }}>
                        <Typography
                            variant="caption"
                            style={{
                                color: '#9e9e9e',
                            }}
                        >
                            {item?.[template.subText[0]] ? item?.[template.subText[0]] : ''}
                        </Typography>
                    </div>
                )}

                {template.subText?.length === 2 && (
                    <div style={{ margin: 0 }}>
                        <Typography
                            variant="caption"
                            style={{
                                color: '#9e9e9e',
                            }}
                        >
                            {item?.[template.subText[0]] ? item?.[template.subText[0]] : ''}
                            {' '}
                            {item?.[template.subText[1]] ? item?.[template.subText[1]] : ''}
                        </Typography>
                    </div>
                )}

            </div>
        )
    })
}

export default ListBoxDisplay