import { Grid, Typography } from '@material-ui/core';
import { Card, CardContent, CardActionArea } from '@material-ui/core';

import Modal from '../../../app/components/modal'

export const SettingsBlock = ({ name, heading, body, saveFn = null, size = 'sm', open = 'false', setOpen, children }) => {
    const internalSetOpen = (value) => {
        setOpen(name, value)
    }

    return (
        <>
            <Grid item xs={2} onClick={() => setOpen(name, !open)}>
                <Card >
                    <CardActionArea>
                        <CardContent>
                            <Grid container style={{ paddingBottom: 10 }}>
                                <Typography variant='subtitle2' color='primary'><b>{heading}</b></Typography>
                            </Grid>

                            <Typography variant='body2'>{body}</Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>

            <Modal
                header={heading}
                size={size}
                open={open}
                setOpen={internalSetOpen}
                saveFn={saveFn}
                fixedSize={true}
            >
                {children}
            </Modal>

        </>
    )
}

export default SettingsBlock;