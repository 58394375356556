import React from 'react';
import { Route, Switch } from 'react-router-dom';

//Import Utils
import PrivateRoute from '../app/utils/private-route';

//Private Routes
import Dashboard from './dashboard/Dashboard';
import Appointments from './appointments/';
import Profile from './profile/Profile';

import Invoices from './invoices/';
import Quotes from './quotes/';

import Workorders from './workorders/';
import WorkorderEdit from './workorders/views/edit/';

import Contacts from './contacts/';
import Vehicles from './vehicles/';
import Companies from './companies/Companies';

import Settings from './settings/index';
import Home from './home/Home';
import NotFound from '../theme/pages/404';

const Routes = () => {
	return (
		<Switch>
			<Route path="/" exact component={Home} />
			<Route path="/frontpage" exact component={Home} />

			<PrivateRoute path="/dashboard" exact component={Dashboard} />

			{/* APPOINTMENTS */}
			<Route path="/appointments" render={(props) => <Appointments {...props} />} />

			{/* WORK ORDERS */}
			<PrivateRoute path="/workorders" component={Workorders} />
			<PrivateRoute path="/workorder/:id" exact component={WorkorderEdit} />

			{/* CONTACTS */}
			<PrivateRoute path="/contacts" exact component={Contacts} />

			{/* VEHICLES */}
			<PrivateRoute path="/vehicles" exact component={Vehicles} />

			{/* BUSINESS */}
			<PrivateRoute path="/companies" component={Companies} />

			{/* INVOICES */}
			<PrivateRoute path="/invoices" exact component={Invoices} />

			{/* QUOTES */}
			<PrivateRoute path="/quotes" exact component={Quotes} />

			<PrivateRoute path="/profile" component={Profile} />
			<PrivateRoute path="/settings" exact component={Settings} />

			<Route component={NotFound} />
		</Switch>
	);
};

export default Routes;
