import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// MaterialUI
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, MenuItem, MenuList, Typography, Avatar } from '@material-ui/core';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

//Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { deepOrange, grey } from '@material-ui/core/colors';

// Pages
import { useAppointmentsContext } from '../../../../../context/appointments-context';
import { SearchAppointmentsInSideBar } from './appointments-search-sidebar';

const Accordion = withStyles({
	root: {
		boxShadow: 'none',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
	root: {
		backgroundColor: 'transparent',
		marginBottom: -1,
		minHeight: 36,
		paddingLeft: theme.spacing(2),
		'&$expanded': {
			backgroundColor: 'rgba(255, 255, 255, 0.16)',
			minHeight: 36,
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
	menuContainer: {
		backgroundImage: `linear-gradient(${theme.palette.background.default} 60%, ${theme.palette.background.paper})`,
	},
	menu: {
		width: '100%',
	},
	menuItem: {
		// width: '100%'
	},
	itemCount: {
		backgroundColor: deepOrange[500],
		color: theme.palette.getContrastText(deepOrange[500]),
		width: theme.spacing(3),
		height: theme.spacing(3),
		marginLeft: 10,
	},
	itemCountDisabled: {
		backgroundColor: grey[400],
		color: theme.palette.getContrastText(grey[400]),
		width: theme.spacing(3),
		height: theme.spacing(3),
		marginLeft: 10,
	},
}));

export const AppointmentsMenu = () => {
	const classes = useStyles();
	let history = useHistory();
	const { search } = useLocation();
	const selectedView = useMemo(() => new URLSearchParams(search), [search]);
	const view = selectedView.get('view') || 'active';

	const { appointments: data, setView, searchCriteria, setSearchCriteria } = useAppointmentsContext();

	const handleViewChange = async ({ view }) => {
		await setView(view);
		await setSearchCriteria(null);
		history.replace({
			pathname: '/appointments',
			search: `?view=${view}`,
		});
	};

	return (
		<Grid container className={classes.menuContainer}>
			<MenuList className={classes.menu}>
				<MenuItem
					selected={view === 'active' ? true : false}
					className={classes.menuItem}
					onClick={() => handleViewChange({ view: 'active' })}
				>
					<Typography variant="subtitle2" noWrap>
						Today
					</Typography>
					<Avatar className={data?.appointments?.counts?.active ? classes.itemCount : classes.itemCountDisabled}>
						<Typography variant="caption" noWrap>
							{data?.appointments?.counts?.active || 0}
						</Typography>
					</Avatar>
				</MenuItem>

				<MenuItem
					selected={view === 'overdue' ? true : false}
					className={classes.menuItem}
					onClick={() => handleViewChange({ view: 'overdue' })}
				>
					<Typography variant="subtitle2" noWrap>
						Overdue
					</Typography>
					<Avatar className={data?.appointments?.counts?.overdue ? classes.itemCount : classes.itemCountDisabled}>
						<Typography variant="caption" noWrap>
							{data?.appointments?.counts?.overdue || 0}
						</Typography>
					</Avatar>
				</MenuItem>

				{/* SEARCH */}
				<Grid container>
					<Grid item xs={12}>
						<Accordion square={true} defaultExpanded={searchCriteria !== null ? true : false} style={{ backgroundColor: 'transparent' }}>
							<AccordionSummary expandIcon={<ExpandMoreIcon fontSize="small" />} IconButtonProps={{ size: 'small' }}>
								<Typography variant="subtitle2" noWrap>
									Search
								</Typography>
								<Avatar className={data?.appointments?.counts?.search ? classes.itemCount : classes.itemCountDisabled}>
									<Typography variant="caption" noWrap>
										{data?.appointments?.counts?.search || 0}
									</Typography>
								</Avatar>
							</AccordionSummary>

							<AccordionDetails>
								<SearchAppointmentsInSideBar />
							</AccordionDetails>
						</Accordion>
					</Grid>
				</Grid>
			</MenuList>
		</Grid>
	);
};
