import axios from 'axios';
import config from '../../../config.json';
import process from 'process';
const domain = process.env.NODE_ENV === 'production' ? config.api.domain : config.api.domain_dev;

axios.interceptors.request.use(
	function (config) {
		config = {
			...config,
			headers: {
				...config.headers,
				'content-type': 'application/json',
				Authorization: `Bearer ${sessionStorage.getItem('token')}`,
			},
		};
		return config;
	},
	function (error) {
		return { status: 'error', error };
	}
);

const _handleError = (e) => {
	if (e.response.status === 401) {
		return http401();
	}

	return { status: 'error', e };
};

const http401 = () => {
	// Delete token
	sessionStorage.removeItem('token');

	// Delete cookies
	var cookies = document.cookie.split(';');
	for (var i = 0; i < cookies.length; i++) {
		var cookie = cookies[i];
		var eqPos = cookie.indexOf('=');
		var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
	}
	return 'access-denied';
};

export const httpGET = async (request) => {
	let { path, retKey } = request;
	return await axios
		.get(`${domain}/${path}/`)
		.then((ret) => {
			if (retKey) return ret?.data?.[retKey];
			return ret.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

export const httpPOST = async (request) => {
	let { path, data, retKey, debug = false } = request;
	return await axios
		.post(`${domain}/${path}/`, {
			data,
		})
		.then((ret) => {
			if (debug) console.info(ret?.data?.[retKey]);
			if (retKey) return ret?.data?.[retKey];
			return ret.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

export const httpPATCH = async (request) => {
	let { path, data, retKey } = request;
	return await axios
		.patch(`${domain}/${path}/`, {
			data,
		})
		.then((ret) => {
			if (retKey) return ret?.data?.[retKey];
			return ret.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

export const httpDELETE = async (request) => {
	let { path, data, retKey } = request;
	return await axios
		.delete(`${domain}/${path}/`, {
			data,
		})
		.then((ret) => {
			if (retKey) return ret?.data?.[retKey];
			return ret.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};

export const httpPUT = async (request) => {
	let { path, data, retKey } = request;
	return await axios
		.put(`${domain}/${path}/`, {
			data,
		})
		.then((ret) => {
			if (retKey) return ret?.data?.[retKey];
			return ret.data;
		})
		.catch((e) => {
			return _handleError(e);
		});
};
