import React from 'react';
import moment from 'moment';

//Material
import { useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

//Icons
// import TextsmsIcon from '@material-ui/icons/Textsms';

// Shared Components
import { NumberFormatCurrencyText } from '../../../app/components/textbox/currency';
import { DurationDescription } from '../../../app/components/textbox/dates';
import InvoicePopup from '../../../app/widgets/invoice/popup';
import VehiclePopup from '../../../app/widgets/vehicle';
import ContactPopup from '../../../app/widgets/contact';
import BusinessPopup from '../../../app/widgets/business';
import { QuoteFirstChip } from 'app/components/quote-first';

// LISTS ALL INVOICES
export const InvoicesTableColumns = (orderColumn, orderDirection, refreshPage) => {
	const theme = useTheme();

	const styles = {
		blueColumn: {
			backgroundColor: 'rgba(0, 88, 155, 0.3)',
		},
		orangeColumn: {
			backgroundColor: theme.palette.type === 'dark' ? theme.palette.warning.dark : theme.palette.warning.main,
		},
		redColumn: {
			backgroundColor: 'rgba(255, 77, 65, 0.3)',
		},
		normalCell: {},
		header: {
			backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.primary.main,
			color: theme.palette.type === 'dark' ? 'inherit' : theme.palette.common.white,
			border: 0,
		},
	};

	return [
		{
			title: 'Invoice',
			field: 'invoiceNumber',
			disableClick: true,
			cellStyle: {
				...styles.normalCell,
			},
			headerStyle: { ...styles.header },
			defaultSort: orderColumn === 'invoiceNumber' ? orderDirection : null,
			render: function render(row) {
				return (
					<>
						<InvoicePopup invoiceId={row?._id} invoiceNumber={row?.invoiceNumber} meta={row} type="link" refreshPage={refreshPage} />

						{row?.vehicle?.vehicleRegistration && (
							<Grid container>
								<VehiclePopup
									vehicleId={row?.vehicle?._id}
									vehicleRegistration={row?.vehicle?.vehicleRegistration}
									meta={row?.vehicle}
									type="action"
								/>
							</Grid>
						)}

						{row?.contact?.contactFullname && (
							<Grid container>
								<ContactPopup
									contactId={row?.contact?._id}
									contactFullname={row?.contact?.contactFullname}
									meta={row?.contact}
									type="action"
								/>
							</Grid>
						)}

						{row?.business?.businessName && (
							<Grid container>
								<BusinessPopup
									businessId={row?.business?._id}
									businessName={row?.business?.businessName}
									meta={row?.business}
									type="action"
								/>
							</Grid>
						)}

						{(row?.quoteFirst || row?.quoteID) && <QuoteFirstChip row={row} table="invoice" refreshPage={refreshPage} />}
					</>
				);
			},
		},
		{
			title: 'Date',
			field: 'invoiceDate',
			cellStyle: {
				...styles.normalCell,
			},
			headerStyle: { ...styles.header },
			defaultSort: orderColumn === 'invoiceDate' ? orderDirection : null,
			render: function render(row) {
				return (
					<>
						<Typography variant="body2" display="block" noWrap>
							{moment(row?.invoiceDate).format('DD MMM YYYY')}
						</Typography>
						<DurationDescription value={row?.invoiceDate} redIfOld={true} daysOnly={true} />
					</>
				);
			},
		},
		{
			title: 'Gross',
			field: 'totals.invoiceSubTotal',
			align: 'right',
			cellStyle: (data) => {
				if (Number(data) === 0)
					return {
						...styles.normalCell,
					};
				if (Number(data) !== 0)
					return {
						...styles.blueColumn,
					};
			},
			headerStyle: { ...styles.header },
			defaultSort: orderColumn === 'invoiceSubTotal' ? orderDirection : null,
			render: function render(row) {
				return (
					<>
						<Typography variant="body2" display="block" color={row?.totals.invoiceSubTotal > 0 ? 'inherit' : 'textSecondary'}>
							{NumberFormatCurrencyText(row?.totals.invoiceSubTotal, 2)}
						</Typography>
					</>
				);
			},
		},
		{
			title: 'Discount',
			field: 'totals.invoiceDiscount',
			align: 'right',
			cellStyle: { ...styles.normalCell },
			headerStyle: { ...styles.header },
			defaultSort: orderColumn === 'totals.invoiceDiscount' ? orderDirection : null,
			render: function render(row) {
				return (
					<>
						<Typography variant="body2" display="block" color={row?.totals.invoiceDiscount > 0 ? 'inherit' : 'textSecondary'}>
							{NumberFormatCurrencyText(row?.totals.invoiceDiscount, 2)}
						</Typography>
					</>
				);
			},
		},
		{
			title: 'Cash',
			field: 'totals.payments.cash',
			align: 'right',
			cellStyle: { ...styles.normalCell },
			headerStyle: { ...styles.header },
			defaultSort: orderColumn === 'totals.payments.cash' ? orderDirection : null,
			render: function render(row) {
				return (
					<>
						<Typography variant="body2" display="block" color={row?.totals.payments?.cash > 0 ? 'inherit' : 'textSecondary'}>
							{NumberFormatCurrencyText(row?.totals?.payments?.cash, 2)}
						</Typography>
					</>
				);
			},
		},
		{
			title: 'Card',
			field: 'totals.payments.card',
			align: 'right',
			cellStyle: { ...styles.normalCell },
			headerStyle: { ...styles.header },
			defaultSort: orderColumn === 'totals.payments.card' ? orderDirection : null,
			render: function render(row) {
				return (
					<>
						<Typography variant="body2" display="block" color={row?.totals.payments?.card > 0 ? 'inherit' : 'textSecondary'}>
							{NumberFormatCurrencyText(row?.totals?.payments?.card, 2)}
						</Typography>
					</>
				);
			},
		},
		{
			title: 'EFT',
			field: 'totals.payments.eft',
			align: 'right',
			cellStyle: { ...styles.normalCell },
			headerStyle: { ...styles.header },
			render: function render(row) {
				return (
					<>
						<Typography variant="body2" display="block" color={row?.totals.payments?.eft > 0 ? 'inherit' : 'textSecondary'}>
							{NumberFormatCurrencyText(row?.totals?.payments?.eft, 2)}
						</Typography>
					</>
				);
			},
		},
		{
			title: 'Balance',
			field: 'totals.invoiceBalance',
			align: 'right',
			cellStyle: (data) => {
				if (Number(data) === 0)
					return {
						...styles.normalCell,
					};
				if (Number(data) !== 0)
					return {
						...styles.redColumn,
					};
			},
			headerStyle: { ...styles.header },
			defaultSort: orderColumn === 'totals.invoiceBalance' ? orderDirection : null,
			render: function render(row) {
				return (
					<>
						<Typography variant="body2" display="block" color={row?.totals.invoiceBalance > 0 ? 'inherit' : 'textSecondary'}>
							{NumberFormatCurrencyText(row?.totals.invoiceBalance, 2)}
						</Typography>
					</>
				);
			},
		},
		{ field: 'contact.contactFullname', hidden: true, searchable: true },
		{ field: 'contact.contactEmail', hidden: true, searchable: true },
		{ field: 'vehicle.vehicleRegistration', hidden: true, searchable: true },
		{ field: 'vehicle.vehicleMake', hidden: true, searchable: true },
		{ field: 'vehicle.vehicleModel', hidden: true, searchable: true },
		{ field: 'totals.invoiceBalance', hidden: true, searchable: true },
		{ field: 'totals.invoiceSubtotal', hidden: true, searchable: true },
		{ field: 'totals.payments.cash', hidden: true, searchable: true },
		{ field: 'totals.payments.eft', hidden: true, searchable: true },
	];
};
