import { Card } from '../../../../components/card'
import {
    GetWorkorderById,
    UpdateWorkorderNextServiceReminder,
} from '../../../../utils/data';

export const CardNextService = ({ workorderId }) => {
    return (
        <Card
            Card
            id={workorderId}
            queryFn={GetWorkorderById}
            mutationFn={UpdateWorkorderNextServiceReminder}
            primaryKey='workorderNextServiceReminder'
            helpText="Add a reminder to this vehiclefor the next service. This will be automatically added to the Instructions of the next service appoointment."
            template={{
                heading: 'Next Service',
                returnKey: 'workorder',
                fields: [
                    {
                        id: 'workorderNextServiceReminder',
                        name: 'Next Service',
                        type: 'multiline',
                        canEdit: true,
                        noLabel: true,
                    },
                ],
            }}
        />
    )
}

export default CardNextService