// material
import { Typography, TableContainer, Table, TableRow, TableCell, TableBody, useTheme, Grid, Box, alpha, Link } from '@material-ui/core';
import { ShowMoreTextBox } from 'app/components/textbox';
// components
import { formatDistanceToNow } from 'date-fns';
import { useHistory } from 'react-router-dom';

export const LatestComments = (props) => {
	const theme = useTheme();
	const history = useHistory();
	const { data } = props;

	return (
		<TableContainer
			style={{
				width: '100%',
			}}
		>
			<Table>
				<TableBody>
					{data?.length === 0 && (
						<TableRow key={`ageing-row-empty`}>
							<TableCell align="center" size="small" padding="none" style={{ border: 0 }}>
								<Typography style={{ fontSize: '0.8rem' }}>No new comments</Typography>
							</TableCell>
						</TableRow>
					)}

					{data?.length > 0 &&
						data?.map((row, rowIdx) => {
							return (
								<TableRow key={`ageing-row-${rowIdx}`}>
									<TableCell size="small" padding="none" style={{ border: 0 }}>
										<div
											style={{
												borderRadius: 10,
												border: `1px solid ${theme.palette.divider}`,
												backgroundColor: alpha(theme.palette.background.paper, 0.4),
												padding: 10,
												margin: 5,
											}}
										>
											<Grid container>
												<Grid item xs={6}>
													<Typography style={{ fontSize: '0.65rem' }}>
														<b>{row?.loggedBy}</b>
													</Typography>
												</Grid>
												<Grid item xs={6} align="right">
													<Typography style={{ fontSize: '0.65rem' }}>
														{`${formatDistanceToNow(new Date(row?.dateLogged), {
															addSuffix: true,
														})}`}
													</Typography>
												</Grid>
											</Grid>

											<div
												style={{
													borderRadius: 20,
													border: `1px solid ${theme.palette.divider}`,
													// backgroundColor: theme.palette.background.paper,
													padding: 10,
													paddingLeft: 15,
													paddingRight: 15,
													marginTop: 5,
												}}
											>
												<ShowMoreTextBox text={row?.comment} />
											</div>

											<Grid container alignItems="center" style={{ marginTop: 5 }}>
												<Grid item xs={8}>
													<Box>
														<Link
															component="button"
															variant="caption"
															color="textSecondary"
															onClick={() => row.link && history.push(row.link.trim().slice(0, -1))}
															style={{
																fontSize: '0.6rem',
																marginTop: 0,
																color: theme.palette.text.primary,
																textDecorationLine: 'underline',
																textDecorationStyle: 'dotted',
																textDecorationColor: theme.palette.text.disabled,
																'& :hover': {
																	textDecorationColor: theme.palette.text.primary,
																},
															}}
															noWrap={true}
															display="inline"
														>
															{row?.refNumber}
														</Link>
													</Box>
												</Grid>
												<Grid item xs={4} align="right">
													<Typography
														color="textSecondary"
														style={{
															fontSize: '0.6rem',
														}}
													>
														{row?.refStatus}
													</Typography>
												</Grid>
											</Grid>
										</div>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default LatestComments;
