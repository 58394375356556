import { httpGET, httpPOST } from './data-handler';

// GET
// --------------------------------
// [company_settings]
export const GetCompanySettings = async () => await httpGET({ path: `settings`, data: null })
export const GetNonWorkingDatesSummary = async () => await httpGET({ path: `settings/non-working-days-summary`, data: null })
export const GetNonWorkingDates = async () => await httpGET({ path: `settings/non-working-days`, data: null })

//POST
// --------------------------------
export const UpdateCompanySettings = async (data) => await httpPOST({
    path: `settings`,
    data: data
})

export const UpdateSettings = async (data) => await httpPOST({
    path: `settings1`,
    data: data
})

export const UpdateCompanyTheme = async (theme) => await httpPOST({
    path: `settings/theme`,
    data: theme
})

export const RequestCustomDomainAuthentication = async (data) => await httpPOST({
    path: `settings/domain`,
    data: data
})
