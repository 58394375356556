import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// MaterialUI
import { Grid } from '@material-ui/core';
//Context and Config
import WorkordersContext from '../../context/workorders-context';
// Query
import { useQuery, useQueryClient } from 'react-query';
import { SearchWorkorders } from '../../app/utils/data';
// Pages
import { Page } from '../../app/components';
import { WorkordersView } from './views/workorders';
import { AppointmentNew } from '../appointments/views/new';

// Components
// import { WorkorderMultiPDF } from './views/workorders/components/workorders-print-multi-pdf';

export default function Workorders() {
	// ----------------------------------------------------------------
	// View Params
	// ----------------------------------------------------------------
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	let viewName = searchParams.getAll('view')[0] || null;
	const queryClient = new useQueryClient();

	// ----------------------------------------------------------------
	// CONTEXT STATE
	// ----------------------------------------------------------------
	const [state, setState] = useState({
		workorders: null,
		searchCriteria: null,
		view: 'today',
		setSearchCriteria: () => {},
		setView: () => {},
		searchQuery: () => {},
	});

	//* state
	const [open, setOpen] = useState(false);
	const [reload, setReload] = useState(true);
	const [searchCriteria, setSearchCriteria] = useState(null);
	const [view, setInternalView] = useState(viewName || 'today');
	const [isReloading, setisReloading] = useState(false);

	// DATA
	// ----------------------------------------------------------------
	const { data, isLoading, refetch } = useQuery(['workorders', searchCriteria], SearchWorkorders, {
		enabled: reload,
		refetchOnWindowFocus: false,
	});

	// FUNCTIONS
	// ----------------------------------------------------------------

	// Build/update search queries
	const searchQuery = async (source, value) => {
		if (source === null || value === null || value.length === 0) {
			delete searchCriteria[source];
			if (Object.keys(searchCriteria).length === 0) {
				setSearchCriteria(null);
				await setInternalView('today');
			}
		} else {
			await setSearchCriteria({
				...searchCriteria,
				[source]: value,
			});
		}

		//refresh screen
		setReload(true);
	};

	const setView = async (newView) => {
		await setInternalView(newView);
		setReload(true);
	};

	// Refresh
	const refreshPage = async () => {
		setisReloading(true);
		await refetch();
		queryClient.refetchQueries(['workorders']);
		setReload(true);
		setisReloading(false);
	};

	// STATE
	// --------------------------------------------------------------
	useEffect(() => {
		if (reload)
			if (!searchCriteria || !view) {
				setReload(false);
			}
	}, [searchCriteria, view]);

	useEffect(() => {
		if (data !== state?.workorders || reload) {
			setState({
				workorders: data,
				searchCriteria,
				view,
				setSearchCriteria,
				setView,
				searchQuery,
				refreshPage,
			});
		}
	}, [data, reload]);

	// ----------------------------------------------------------------
	// Error Handling
	// ----------------------------------------------------------------
	if (data?.status === 'error') return <p>Error Occured</p>;
	if (data?.status === 'access-denied') return <p>Access Denied</p>;

	// ----------------------------------------------------------------
	// MAIN
	// ----------------------------------------------------------------
	return (
		<WorkordersContext.Provider value={state}>
			<Page
				heading="Work orders"
				refreshPageFn={refreshPage}
				actionButtons={
					<Grid container alignItems="center" justifyContent="flex-end">
						<AppointmentNew open={open} setOpen={setOpen} iconSize="large" type="workorder" refetchFn={refreshPage} />
					</Grid>
				}
			>
				<WorkordersView isLoading={isLoading || isReloading} />
			</Page>
		</WorkordersContext.Provider>
	);
}
