import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
//Material UI
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, alpha, Paper, Button, Box } from '@material-ui/core';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import MaterialTable, { MTableHeader } from 'material-table';
import DashboardIcon from '@material-ui/icons/Dashboard';
//Data
import { useQuery } from 'react-query';
import { SearchInvoices, ExportInvoices } from '../../app/utils/data';
//Context
import { useSettingsContext } from '../../context/settings-context';
import InvoicesContext from '../../context/invoices-context';
//Components
import Page from '../../app/components/page';
import { InvoicesTableColumns } from './config/config.tables';
import { AppointmentNew } from '../appointments/views/new';
import { InvoicesMenu } from './components/menu';
import { NumberFormatCurrencyText } from '../../app/components/textbox';
import ExportInvoicesButton from './components/export-button';

// Style -->
const useStyles = makeStyles((theme) => ({
	table: {
		'& tbody': {
			'& tr:hover': {
				transition: 'none',
				backgroundColor: alpha(theme.palette.background.default, 0.5),
			},
			'& td:first-child': {
				transition: 'none',
				borderLeft: `1px solid ${theme.palette.divider}`,
			},
			'& td:last-child': {
				borderRight: `1px solid ${theme.palette.divider}`,
			},
			'& td:nth-child(3)': {
				borderRight: `1px solid ${theme.palette.divider}`,
			},
			'& td:nth-child(4)': {
				borderRight: `1px solid ${theme.palette.divider}`,
			},
			'& td:nth-child(8)': {
				borderRight: `1px solid ${theme.palette.divider}`,
			},
		},
	},
	//Headers
	firstRowHeaders: {
		border: 0,
		borderRight: `1px solid ${theme.palette.divider}`,
		backgroundColor: theme.palette.background.paper,
		fontWeight: 800,
	},
	firstRowHeadersLastOne: {
		border: 0,
		borderRight: `1px solid ${theme.palette.background.default}`,
	},
}));

// Main Code -->
export const Invoices = () => {
	let history = useHistory();
	const { search } = useLocation();
	const { lists } = useSettingsContext();
	const theme = useTheme();
	const classes = useStyles();
	const selectedStatus = useMemo(() => new URLSearchParams(search), [search]);
	const csvLink = useRef();

	// DEFAULTS
	let view = selectedStatus.get('status') || 'new';
	let srch = selectedStatus.get('search');

	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(250);
	const [totalCount, setTotalCount] = useState(10);
	const [totals, setTotals] = useState(1);

	//* state
	const [open, setOpen] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [reload, setReload] = useState(false);
	const [searchTerm, setSearchTerm] = useState();

	const [invoices, setInvoices] = useState([]);
	const [query, setQuery] = useState({
		query: {
			...(!srch && { invoiceStatus: [toProperCase(view)] }),
			...(srch && { search: srch }),
		},
		view,
		limit,
		page,
		totalCount,
		totals,

		orderColumn: 'invoiceDate',
		orderDirection: 'desc',
	});

	//* data
	const { data, isLoading, isFetching, refetch } = useQuery(['invoices', query], SearchInvoices, {
		enabled: reload,
		onSettled: () => {
			setReload(false);
		},
		onSuccess: (data) => {
			setReload(false);
			setTotalCount(data?.data?.query?.totalCount);
			setLimit(data?.data?.query?.limit);
			setPage(data?.data?.query?.page);
			setTotals(data?.data?.query?.totals);
			if (!_.isEqual(invoices, data?.data?.invoices)) setInvoices(data?.data?.invoices || []);
		},
	});

	useEffect(() => {
		setReload(true);
	}, []);

	//* refresh
	const refreshPage = async () => {
		setReload(true);
	};

	//* handlers
	const [exportData, setExportData] = useState({
		data: [],
		source: '',
	});

	const ExportToCSVRange = async (startDate, endDate) => {
		setDownloading(true);

		let csvRawData = await ExportInvoices({
			queryKey: [
				'export',
				{
					query: {
						filter: {
							invoiceDateFrom: moment(startDate).format('YYYY-MM-DD'),
							invoiceDateTo: moment(endDate).format('YYYY-MM-DD'),
						},
					},
					limit: 0,
				},
			],
		});

		if (csvRawData) {
			let csvData = [
				['Status', 'Invoice #', 'Date', 'Name', 'Mobile', 'Email', 'Vehicle', 'Gross Total', 'Discount', 'Cash', 'Card', 'EFT', 'Balance'],
			];

			csvRawData?.data?.invoices?.map((invoice) => {
				csvData.push([
					invoice?.invoiceStatus,
					invoice?.invoiceNumber,
					moment(invoice?.invoiceDate).format('ddd, DD MMM YYYY'),
					invoice?.contact?.contactFullname,
					invoice?.contact?.contactMobile,
					invoice?.contact?.contactEmail,
					invoice?.vehicle?.vehicleRegistration,
					invoice?.totals?.invoiceSubTotal,
					invoice?.totals?.invoiceDiscountAmount,
					invoice?.totals?.payments.cash,
					invoice?.totals?.payments.card,
					invoice?.totals?.payments.eft,
					invoice?.totals?.invoiceBalance,
				]);
			});

			setExportData({
				source: 'CSV',
				data: csvData,
			});
		}

		// this is to prevent the click before the data is commited ot state
		await sleep(1);

		csvLink.current.link.click();
		setDownloading(false);
	};

	const sleep = (s) =>
		new Promise((resolve) => {
			setTimeout(resolve, s * 1000);
		});

	const ExportToCSV = async () => {
		setDownloading(true);

		let csvRawData = await ExportInvoices({
			queryKey: [
				'export',
				{
					query: {
						...query.query,
					},
					limit: 0,
				},
			],
		});

		if (csvRawData) {
			let csvData = [['Invoice #', 'Date', 'Name', 'Mobile', 'Email', 'Vehicle', 'Gross Total', 'Discount', 'Cash', 'Card', 'EFT', 'Balance']];

			await Promise.all(
				csvRawData?.data?.invoices
					?.filter((itm) => itm.invoiceStatus !== 'Void')
					?.map((invoice) => {
						csvData.push([
							invoice?.invoiceNumber,
							moment(invoice?.invoiceDate).format('ddd, DD MMM YYYY'),
							invoice?.contact?.contactFullname,
							invoice?.contact?.contactMobile,
							invoice?.contact?.contactEmail,
							invoice?.vehicle?.vehicleRegistration,
							invoice?.totals?.invoiceSubTotal,
							invoice?.totals?.invoiceDiscountAmount,
							invoice?.totals?.payments.cash,
							invoice?.totals?.payments.card,
							invoice?.totals?.payments.eft,
							invoice?.totals?.invoiceBalance,
						]);
					}),

				setExportData({
					source: 'CSV',
					data: csvData,
				})
			);

			// this is to prevent the click before the data is commited ot state
			await sleep(1);

			csvLink.current.link.click();
		}

		setDownloading(false);
	};

	const ExportToXero = async () => {
		setDownloading(true);

		let csvRawData = await ExportInvoices({
			queryKey: [
				'export',
				{
					query: {
						...query.query,
					},
					limit: 0,
				},
			],
		});

		if (csvRawData) {
			let csvData = [
				[
					'*ContactName',
					'EmailAddress',
					'POAddressLine1',
					'POAddressLine2',
					'POAddressLine3',
					'POAddressLine4',
					'POCity',
					'PORegion',
					'POPostalCode',
					'POCountry',
					'*InvoiceNumber',
					'Reference',
					'*InvoiceDate',
					'*DueDate',
					'Total',
					'InventoryItemCode',
					'*Description',
					'*Quantity',
					'*UnitAmount',
					'Discount',
					'*AccountCode',
					'*TaxType',
					'TaxAmount',
					'TrackingName1',
					'TrackingOption1',
					'TrackingName2',
					'TrackingOption2',
					'Currency',
					'BrandingTheme',
				],
			];

			csvRawData?.data?.invoices
				?.filter((itm) => itm.invoiceStatus !== 'Void')
				?.map((invoice) => {
					invoice.costItems.map((costItem) => {
						csvData.push([
							//*ContactName
							invoice?.contact?.contactFullname || '',
							//EmailAddress
							invoice?.contact?.contactEmail || '',
							//POAddressLine1
							'',
							//POAddressLine2
							'',
							//POAddressLine3
							'',
							//POAddressLine4
							'',
							//POCity
							'',
							//PORegion
							'',
							//POPostalCode
							'',
							//POCountry
							'',
							//*InvoiceNumber
							invoice?.invoiceNumber,
							//Reference
							'',
							//*InvoiceDate
							moment(invoice?.invoiceDate).format('DD/MM/YYYY'),
							//*DueDate
							moment(invoice?.invoiceDate).format('DD/MM/YYYY'),
							//Total
							invoice?.totals?.invoiceTotal || 0,
							//InventoryItemCode
							'',
							//*Description
							costItem?.costDescription || '',
							//*Quantity
							costItem?.costQty || 0,
							//*UnitAmount
							costItem?.costUnitPrice || 0,
							//Discount
							invoice?.totals?.invoiceDiscountPercentage || 0,
							//*AccountCode
							200,
							//*TaxType
							'NONE',
							//TaxAmount
							invoice?.totals?.invoiceVATAmount || 0,
							//TrackingName1
							'',
							//TrackingOption1
							'',
							//TrackingName2
							'',
							//TrackingOption2
							'',
							//Currency
							'',
							//BrandingTheme
							'',
						]);
					});
				});

			setExportData({
				source: 'XERO',
				data: csvData,
			});

			// this is to prevent the click before the data is commited ot state
			await sleep(1);

			csvLink.current.link.click();
		}

		setDownloading(false);
	};

	const handleStatusFilterClick = async (e, status) => {
		e.stopPropagation();
		let newStatus = null;

		if (e.ctrlKey) {
			let existIndex = query.query.invoiceStatus.indexOf(status);

			if (existIndex >= 0) {
				newStatus = query.query.invoiceStatus;
				newStatus.splice(existIndex, 1);
			}

			if (existIndex < 0) {
				newStatus = query.query.invoiceStatus;
				newStatus.push(status);
			}
		} else {
			newStatus = [status];
		}

		await setQuery({
			...query,
			query: {
				...query.query,
				invoiceStatus: newStatus,
			},
		});

		await refetch();
	};

	//* search
	const handleSearch = async () => {
		//Set search value
		if (searchTerm) {
			setIsSearching(true);

			let statusArray = [];
			lists &&
				lists.invoiceStatus.map((status) => {
					statusArray.push(status.invoiceStatus);
				});

			await setQuery({
				...query,
				query: {
					...query.query,
					search: searchTerm,
					invoiceStatus: statusArray,
				},
			});

			setReload(true);
			setSearchTerm(null);
			await refetch();
		}

		//reset to default view
		if (!searchTerm) {
			setIsSearching(false);

			document.getElementById('search-invoices').value = '';

			setQuery({
				query: {
					invoiceStatus: [toProperCase(view)],
				},
				view,
				limit: Number(limit),
				page: Number(page),
				totalCount: Number(totalCount),
			});

			setReload(true);
			await refetch();
		}
	};

	const handleSearchChange = (e) => {
		setSearchTerm(e.target.value);
		if (e.keyCode == 13) {
			handleSearch(e);
		}
	};

	const handleSearchClear = () => {
		history.replace('/invoices');
		// window.location.reload(false);
	};

	const handleSearchKeyDown = (e) => {
		if (e.keyCode == 13) {
			handleSearch(searchTerm);
		}
	};

	//* finances
	const grossTotal = useMemo(() => {
		return invoices?.reduce((a, b) => {
			return a + b?.totals?.invoiceSubTotal;
		}, 0);
	}, [data]);

	const discountTotal = useMemo(() => {
		return invoices?.reduce((a, b) => {
			return a + b?.totals?.invoiceDiscountAmount;
		}, 0);
	}, [data]);

	const cashTotal = useMemo(() => {
		return invoices?.reduce((a, b) => {
			return a + b?.totals?.payments?.cash;
		}, 0);
	}, [data]);

	const cardTotal = useMemo(() => {
		return invoices?.reduce((a, b) => {
			return a + b?.totals?.payments?.card;
		}, 0);
	}, [data]);

	const eftTotal = useMemo(() => {
		return invoices?.reduce((a, b) => {
			return a + b?.totals?.payments?.eft;
		}, 0);
	}, [data]);

	const balanceTotal = useMemo(() => {
		return invoices?.reduce((a, b) => {
			return a + b?.totals?.invoiceBalance;
		}, 0);
	}, [data]);

	const fileName = useMemo(() => {
		const type = exportData?.source?.toString().toUpperCase();

		if (type === 'CSV') return `Invoices (${view}) - ${moment().format('DD-MM-YYYY')}.csv`;
		if (type === 'XERO') return `Invoices (${view}) - XERO upload - ${moment().format('DD-MM-YYYY')}.csv`;
	}, [exportData]);

	if (data?.status === 'error') return <p>Error Occured</p>;
	if (data?.status === 'access-denied') return <p>Access Denied</p>;

	// -- main
	return (
		<InvoicesContext.Provider value={{ refetch }}>
			<Page
				heading="Invoices"
				refreshPageFn={refreshPage}
				// transparent={true}
				subheadingIsControl={true}
				actionButtons={
					<Box display="flex" alignItems="center">
						<Box>
							<Button
								startIcon={<DashboardIcon />}
								size="small"
								style={{
									borderRadius: 20,
									paddingLeft: 10,
									paddingRight: 10,
								}}
								onClick={() => history.push('/dashboard')}
							>
								Dashboard
							</Button>
						</Box>

						{/* NEW INVOICE */}
						<AppointmentNew open={open} setOpen={setOpen} iconSize="large" type="invoice" refetchFn={refreshPage} />

						{/* EXPORT */}
						<ExportInvoicesButton
							downloading={downloading}
							exportCSV={ExportToCSV}
							exportCSVRange={ExportToCSVRange}
							exportXero={ExportToXero}
						/>
					</Box>
				}
			>
				<Grid container spacing={1}>
					{/* MENU */}
					<Grid item xs={2}>
						<InvoicesMenu
							statusTotals={totals}
							data={data}
							handleStatusFilterClick={handleStatusFilterClick}
							onChange={handleSearchChange}
							onKeyDown={handleSearchKeyDown}
							onSearchClear={handleSearchClear}
							isSearching={isSearching}
							allSelected={isSearching || srch}
							setQuery={setQuery}
							setIsSearching={setIsSearching}
						/>
					</Grid>

					{/* TABLE */}
					<Grid item xs={10}>
						<div className={classes.table}>
							<MaterialTable
								name="invoices_table"
								title=""
								isLoading={isLoading || isFetching}
								columns={InvoicesTableColumns(query?.orderColumn, query?.orderDirection, refreshPage)}
								data={invoices}
								//Paging
								page={Number(page) - 1}
								totalCount={Number(totalCount)}
								onChangePage={(newPage) => {
									if (page === newPage) {
										setQuery((query) => ({
											...query,
											page: newPage + 1,
										}));
										setReload(true);
									}
								}}
								options={{
									// GENERAL
									padding: 'dense',
									minBodyHeight: '70vh',
									maxBodyHeight: '70vh',
									draggable: false,

									//SEARCH
									search: false,
									toolbar: false,

									//HEADER
									headerStyle: {
										backgroundColor: theme.palette.background.default,
									},

									//ROW
									selection: false,

									//PAGING
									pageSize: limit ? Number(limit) : 10,
									pageSizeOptions: [10, 20, 30],
									initialPage: page ? Number(page) - 1 : 0,
									page: page ? Number(page) - 1 : 1,
									paging: false,
									emptyRowsWhenPaging: false,

									//SORT
									defaultSort: query.orderDirection,
								}}
								components={{
									Container: (props) => <Paper {...props} elevation={0} />,
									Header: (props) => (
										<>
											<TableHead
												style={{
													padding: 0.5,
													border: `1px solid ${theme.palette.divider}`,
													backgroundColor: alpha(theme.palette.background.default, 0.66),
												}}
											>
												<TableRow>
													<TableCell size="small" align="center" className={classes.firstRowHeaders} colSpan={2}>
														Invoice
													</TableCell>
													<TableCell size="small" align="center" className={classes.firstRowHeaders} colSpan={1}>
														Debit
													</TableCell>
													<TableCell size="small" align="center" className={classes.firstRowHeaders} colSpan={4}>
														Credit
													</TableCell>
													<TableCell
														size="small"
														align="center"
														className={classes.firstRowHeaders}
														colSpan={1}
													></TableCell>
												</TableRow>
											</TableHead>

											<MTableHeader {...props} />

											<TableHead
												style={{
													padding: 0.5,
													border: `1px solid ${theme.palette.divider}`,
													backgroundColor: alpha(theme.palette.background.default, 0.66),
												}}
											>
												<TableRow>
													<TableCell size="small" align="center" padding="none" colSpan={2} />
													<TableCell size="small" align="center" padding="none">
														<Typography
															variant="body2"
															align="right"
															style={{ paddingRight: theme.spacing(3), color: theme.palette.text.disabled }}
														>
															<b>{NumberFormatCurrencyText(grossTotal)}</b>
														</Typography>
													</TableCell>
													<TableCell size="small" align="center" padding="none">
														<Typography
															variant="body2"
															align="right"
															style={{ paddingRight: theme.spacing(3), color: theme.palette.text.disabled }}
														>
															<b>{NumberFormatCurrencyText(discountTotal)}</b>
														</Typography>
													</TableCell>
													<TableCell size="small" align="center" padding="none">
														<Typography
															variant="body2"
															align="right"
															style={{ paddingRight: theme.spacing(3), color: theme.palette.text.disabled }}
														>
															<b>{NumberFormatCurrencyText(cashTotal)}</b>
														</Typography>
													</TableCell>
													<TableCell size="small" align="center" padding="none">
														<Typography
															variant="body2"
															align="right"
															style={{ paddingRight: theme.spacing(3), color: theme.palette.text.disabled }}
														>
															<b>{NumberFormatCurrencyText(cardTotal)}</b>
														</Typography>
													</TableCell>
													<TableCell size="small" align="center" padding="none">
														<Typography
															variant="body2"
															align="right"
															style={{ paddingRight: theme.spacing(3), color: theme.palette.text.disabled }}
														>
															<b>{NumberFormatCurrencyText(eftTotal)}</b>
														</Typography>
													</TableCell>
													<TableCell size="small" align="center" padding="none">
														<Typography
															variant="body2"
															align="right"
															style={{ paddingRight: theme.spacing(3), color: theme.palette.text.disabled }}
														>
															<b>{NumberFormatCurrencyText(balanceTotal)}</b>
														</Typography>
													</TableCell>
												</TableRow>
											</TableHead>
										</>
									),
								}}
								localization={{
									body: {
										emptyDataSourceMessage: <h3 style={{ textAlign: 'center' }}>No invoices to display</h3>,
									},
								}}
							/>
						</div>
						<CSVLink data={exportData?.data} filename={fileName} className="hidden" ref={csvLink} target="_blank" />
					</Grid>
				</Grid>
			</Page>
		</InvoicesContext.Provider>
	);
};

function toProperCase(str) {
	if (str)
		return str.replace(/(^|\s)\S/g, function (t) {
			return t.toUpperCase();
		});
	return null;
}

export default Invoices;
