//MaterialUI
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

//Context
import { useNewPaymentsContext } from '../../../../../../context/payments-context';

// Pages
import { PaymentTextBox, TextBox, PaymentDateSelect, PaymentTypeSelect } from './controls';

// Styles
const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}));

export const PaymentBody = ({ errors, max }) => {
	const classes = useStyles();
	const { totals } = useNewPaymentsContext();

	return (
		<Grid container spacing={1} className={classes.root} justifyContent="flex-start" alignItems="center">
			{/* PAYMENT */}
			<>
				<Grid item xs={4}>
					Payment
				</Grid>
				<Grid item xs={8}>
					<PaymentTextBox name="payment" error={errors.payment} max={max} />
				</Grid>
			</>

			{/* PAYMENT TYPE*/}
			<>
				<Grid item xs={4}>
					Payment Type
				</Grid>
				<Grid item xs={8}>
					<PaymentTypeSelect name="paymentType" error={errors.paymentType} />
				</Grid>
			</>

			{/* DATE */}
			<>
				<Grid item xs={4}>
					Date
				</Grid>
				<Grid item xs={8}>
					<PaymentDateSelect name="date" value={totals?.date} />
				</Grid>
			</>

			{/* REFERENCE */}
			<>
				<Grid item xs={4}>
					Reference
				</Grid>
				<Grid item xs={8}>
					<TextBox name="ref" />
				</Grid>
			</>

			{/* NOTES */}
			<>
				<Grid item xs={4}>
					Notes
				</Grid>
				<Grid item xs={8}>
					<TextBox name="notes" multiline={true} />
				</Grid>
			</>
		</Grid>
	);
};

export default PaymentBody;
