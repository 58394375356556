import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const BarChart = (props) => {
	const { data } = props;
	const { displayXAxis, displayYAxis } = props;

	const options = {
		responsive: true,
		aspectRatio: 8,
		maintainAspectRatio: true,
		plugins: {
			legend: {
				display: false,
			},
		},
		options: {
			legend: {
				display: false,
			},
		},
		scales: {
			xAxis: {
				display: displayXAxis,
			},
			yAxis: {
				display: displayYAxis,
			},
		},
	};

	if (!data) return;

	return <Bar options={options} data={data} />;
};

BarChart.defaultProps = {
	displayXAxis: true,
	displayYAxis: true,
};

export default BarChart;
