import React, { useState } from 'react';

//Query
import { useQuery } from 'react-query';

//@Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Box, Tabs, Tab } from '@material-ui/core';

//Data
import { GetWorkorderById } from '../../../../app/utils/data';

//Pages
import Page from '../../../../app/components/page';
import { Loading } from '../../../../app/components/loading';

// Sections
import WorkorderEditDetails from './workorder-edit-details';
import WorkorderEditData from './workorder-edit-data';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	tabsHeader: {
		brder: '1px solid black',
		backgroundColor: theme.palette.background.default,
	},
}));

export const WorkorderEdit = ({ match }) => {
	if (!match.params.id) return <p>Work order not found.</p>;

	// --------------------------------------------------------------------------------------------------
	// HOOKS
	// --------------------------------------------------------------------------------------------------
	const classes = useStyles();
	const [value, setValue] = useState(0);

	//Get Data
	const { data, isLoading, refetch } = useQuery(['workorder', match.params.id], GetWorkorderById, {
		enabled: !!match.params.id,
		refetchOnWindowFocus: false,
	});

	// --------------------------------------------------------------------------------------------------
	// FUNCTIONS
	// --------------------------------------------------------------------------------------------------
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	// --------------------------------------------------------------------------------------------------
	// Action Bar
	// --------------------------------------------------------------------------------------------------

	// --------------------------------------------------------------------------------------------------
	// ERROR HANDLING - Reviewed 2
	// --------------------------------------------------------------------------------------------------
	if (data?.status === 'error') return <p>Error Occured</p>;
	if (isLoading) return <Loading />;
	if (!data) return null;

	// --------------------------------------------------------------------------------------------------
	// MAIN
	// --------------------------------------------------------------------------------------------------
	return (
		<Page
			heading={`WORK ORDER | ${data.workorder.workorderNumber}`}
			// subheading={<UpdateWorkorderDate workorderId={data.workorder._id} date={data.workorder.workorderDate} />}
			// subheadingIsControl={true}
			transparent={false}
		>
			<Tabs id="contact_tab" value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" className={classes.tabsHeader}>
				<LinkTab label="Work Order" {...a11yProps(0)} />
				<LinkTab label="Work Sheet" {...a11yProps(1)} />
			</Tabs>

			<TabPanel key={123} value={value} index={0}>
				{/* <WorkorderEditDetails workorderId={data.workorder._id} data={data} /> */}
				<WorkorderEditDetails data={data} refetch={refetch} />
			</TabPanel>
			<TabPanel key={789} value={value} index={1}>
				<WorkorderEditData workorderId={data.workorder._id} />
			</TabPanel>
		</Page>
	);
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`nav-tabpanel-${index}`} aria-labelledby={`nav-tab-${index}`} {...other}>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `nav-tab-${index}`,
		'aria-controls': `nav-tabpanel-${index}`,
	};
}

function LinkTab(props) {
	return (
		<Tab
			component="a"
			onClick={(event) => {
				event.preventDefault();
			}}
			{...props}
		/>
	);
}

export default WorkorderEdit;
