import { httpGET, httpPOST } from './data-handler';

// GET
// -------------------

// [comments]
export const GetComments = async (req) => await httpGET({ path: `comments/${req.queryKey[1]}`, data: null });

// POST
// -------------------
export const CreateComment = async (data) => await httpPOST({ path: `comments`, data });
export const DeleteComment = async (commentId) => await httpPOST({ path: `comments/${commentId}`, data: null });
export const MakeCommentPublic = async (data) => await httpPOST({ path: 'publicity', data: data });
