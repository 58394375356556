// import { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import CurrencyFormat from 'react-currency-format';
import SettingsContext from '../../../context/settings-context';
import { forwardRef } from 'react';

// NORMAL FINANCIAL ROW
export function NumberFormatCurrency(props) {
	const { inputRef, defaultValue, value, onChange, ...other } = props;

	return (
		<SettingsContext.Consumer>
			{({ settings }) => (
				<>
					<NumberFormat
						{...other}
						defaultValue={value || defaultValue}
						getInputRef={inputRef}
						onValueChange={(values) => {
							onChange({
								target: {
									name: props.name,
									value: values.value || 0,
								},
							});
						}}
						prefix={`${settings?.locale?.currencySymbol} ` || 'R '}
						thousandSeparator
						isNumericString
						fixedDecimalScale={true}
						style={{ textAlign: 'right' }}
					/>
				</>
			)}
		</SettingsContext.Consumer>
	);
}

export function NumberFormatNumericTextBox(props) {
	const { inputRef, defaultValue, value, align, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			defaultValue={value || defaultValue}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value || 0,
					},
				});
			}}
			thousandSeparator
			isNumericString
			fixedDecimalScale={true}
			style={{ textAlign: align || 'center' }}
		/>
	);
}

export function NumberFormatMileageTextBox(props) {
	const { inputRef, defaultValue, value, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			defaultValue={value || defaultValue}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value || 0,
					},
				});
			}}
			suffix={' km'}
			thousandSeparator
			isNumericString
			fixedDecimalScale={true}
		/>
	);
}

export function NumberFormatPercentageTextBox(props) {
	const { inputRef, defaultValue, value, align, onChange, ...other } = props;
	const MAX_VAL = 100;
	const withValueLimit = ({ floatValue }) => floatValue <= MAX_VAL;

	return (
		<NumberFormat
			{...other}
			defaultValue={value || defaultValue}
			getInputRef={inputRef}
			isAllowed={withValueLimit}
			isNumericString
			suffix=" %"
			format="### %"
			style={{ textAlign: align || 'center' }}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value || 0,
					},
				});
			}}
		/>
	);
}

// NEW FINANCIAL ROW
export const NumberFormatNewRow = forwardRef(function NumberFormatCustom(props, ref) {
	const { onChange, value, ...other } = props;

	return (
		<NumberFormat
			{...other}
			value={value}
			getInputRef={ref}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value ? parseFloat(values.value) : 0,
					},
				});
			}}
			thousandSeparator
			isNumericString
			allowLeadingZeros={false}
		/>
	);
});

export function NumberFormatCurrencyNewRow(props) {
	const { inputRef, defaultValue, value, onChange, ...other } = props;

	return (
		<SettingsContext.Consumer>
			{({ settings }) => (
				<>
					<NumberFormat
						{...other}
						value={value || defaultValue}
						getInputRef={inputRef}
						onValueChange={(values) => {
							onChange({
								target: {
									name: props.name,
									value: values.value || 0,
								},
							});
						}}
						prefix={`${settings?.locale?.currencySymbol} ` || 'R '}
						thousandSeparator
						isNumericString
						fixedDecimalScale={true}
						style={{ textAlign: 'right' }}
					/>
				</>
			)}
		</SettingsContext.Consumer>
	);
}

export function NumberFormatNumericTextBoxNewRow(props) {
	const { inputRef, defaultValue, value, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			value={value || defaultValue || undefined}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value || 0,
					},
				});
			}}
			thousandSeparator
			isNumericString
			fixedDecimalScale={true}
			style={{ textAlign: 'center' }}
		/>
	);
}

export function NumberFormatPercentageTextBoxNewRow(props) {
	const { inputRef, defaultValue, onChange, ...other } = props;
	const MAX_VAL = 100;
	const withValueLimit = ({ floatValue }) => floatValue <= MAX_VAL;

	return (
		<NumberFormat
			{...other}
			value={defaultValue}
			getInputRef={inputRef}
			isAllowed={withValueLimit}
			isNumericString
			suffix=" %"
			format="### %"
			style={{ textAlign: 'center' }}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value || 0,
					},
				});
			}}
		/>
	);
}

// OTHER

export function NumberFormatCurrencyPDF(amount = 0, decimal = 0) {
	return (
		<SettingsContext.Consumer>
			{({ settings }) => (
				<CurrencyFormat
					value={Math.abs(amount || 0)}
					displayType={'text'}
					thousandSeparator={true}
					prefix={`${settings?.locale?.currencySymbol} ` || 'R '}
					decimalScale={decimal || 2}
					fixedDecimalScale={true}
				/>
			)}
		</SettingsContext.Consumer>
	);
}

export function NumberFormatCurrencyText(amount = 0, decimal = 0) {
	return (
		<SettingsContext.Consumer>
			{({ settings }) =>
				new Intl.NumberFormat(settings?.locale?.locale || 'en-ZA', {
					style: 'currency',
					currency: settings?.locale?.currency || 'ZAR',
					minimumFractionDigits: decimal,
					maximumFractionDigits: decimal,
				}).format(amount)
			}
		</SettingsContext.Consumer>
	);
}
