import { createContext, useContext } from 'react';

const NewPaymentsContext = createContext({
    totals: {},
    paymentChange: () => { },
    handleChange: () => { },
});

export function useNewPaymentsContext() {
    return useContext(NewPaymentsContext);
}

export default NewPaymentsContext;