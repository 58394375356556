import { useState } from 'react'
import { Alert } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Link from '@material-ui/core/Link';

import CloseIcon from '@material-ui/icons/Close';

export const HelpBox = ({ severity = 'info', text }) => {
    const [open, setOpen] = useState(localStorage.getItem('help-mode') == 'true');

    return (
        <p>
            <Collapse in={open}>
                <Alert
                    severity={severity}
                    action={
                        <IconButton
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }>
                    {text}{' '}
                    <Link
                        href={'link'}
                        onClick={(e) =>
                            e.preventDefault
                        }
                        style={{fontSize: '0.8rem'}}
                    >
                        Read more...
                    </Link>
                </Alert>
            </Collapse>
        </p >
    )
}

export default HelpBox