import React from 'react';

//@material
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Grid, Card, CardHeader, TextField } from '@material-ui/core';

//Context
import { useCheckInContext } from '../../../../../context/appointments-checkin-context'


const useStyles = makeStyles(() =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		input: {
			[`& fieldset`]: {
				borderRadius: 0
			},
		}
	})
);

export const AppointmentCheckInInstructions = ({ defaultValue = '', disabled = false }) => {
	const classes = useStyles();

	const { appointment, setAppointment, errors } = useCheckInContext()

	return (
		<Card className={classes.root} elevation={0}>
			<CardHeader
				title={
					<Grid container spacing={0} alignItems="flex-end">
						<Grid item xs={12}>
							<TextField
								label="Instructions"
								placeholder="Instructions"
								multiline
								rows={6}
								fullWidth={true}
								variant="outlined"
								className={classes.input}
								color='secondary'

								defaultValue={defaultValue}
								// inputError={errors?.root?.bookingInstructions}
								error={errors?.root?.bookingInstructions || false}
								disabled={disabled}

								onChange={(event) => {
									setAppointment({
										...appointment,
										bookingInstructions: event.target.value
									})
								}}

								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>

						{errors?.root?.bookingInstructions && <Typography variant='caption' color='error'>{errors?.root?.bookingInstructions}</Typography>}
					</Grid>
				}
			/>
		</Card>
	);
};

export default AppointmentCheckInInstructions;
