import { useState, useRef } from 'react';

// Query
import { useMutation } from 'react-query';

// MaterialUI
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Typography, Tooltip, useTheme } from '@material-ui/core';

//Icons
import QueueIcon from '@material-ui/icons/Queue';

// Components
import { NewRowTextBox } from './TextBox';
import { NumberFormatCurrencyText } from '../textbox';
import { TextBoxFinance } from './Textbox/NewRow.Financial';

// Styles
const useStyles = makeStyles((theme) => ({
	container: {
		padding: 10,
		borderColor: theme.palette.divider,
		borderStyle: 'solid',
		borderWidth: '1px',
	},
	newCell: {
		borderColor: theme.palette.divider,
		borderStyle: 'solid',
		borderWidth: '1px',
		fontSize: '0.8rem',
		padding: '0px 10px 0px 10px',
		backgroundColor: 'white',
		color: 'black',
	},
}));

// ---------------------------------------------------------------------------------------------------------------
// FUNCTION: 	This allows you to add new entries to the table
// OUTPUT:		Fully styled row component for adding new lines. Built with the Grid system
// FUNCTIONS:	Add Row
// ---------------------------------------------------------------------------------------------------------------
export const NewRowInvoice = ({ config, parentId, itemsCount, disabled, refetch = null }) => {
	const classes = useStyles();
	const theme = useTheme();

	// INPUT REFs
	const refQty = useRef(null);
	const refDescription = useRef(null);
	const refUnitPrice = useRef(null);
	const refDiscount = useRef(null);

	// LOCAL STATE
	// ----------------------------------------------------------------
	const [row, setRow] = useState({
		_id: 'new',
		[config.query.select.child]: parentId,
	});

	// DATA
	// ----------------------------------------------------------------
	const create = useMutation(config.query.createFn, {
		onSettled: () => {
			setRow({
				_id: 'new',
				[config.query.select.child]: parentId,
			});
			if (refetch) refetch();
		},
	});

	// ACTIONS
	// ----------------------------------------------------------------
	const onChange = (e) => {
		if (e.target.name === 'costQty') {
			let unitPrice = parseFloat(row.costUnitPrice) || 0;
			let unitQty = parseFloat(e.target.value) || 0;
			let unitDiscount = parseFloat(row.costDiscount) || 0;
			let total = unitPrice * unitQty;
			total = total - total * (unitDiscount / 100);

			setRow({
				...row,
				costUnitPrice: parseFloat(unitPrice),
				costQty: parseFloat(unitQty),
				isGroupHeader: row.isGroupHeader || false,
				costDiscount: parseFloat(unitDiscount),
				costAmount: parseFloat(total),
			});
		}

		if (e.target.name === 'costUnitPrice') {
			let unitPrice = parseFloat(e.target.value) || 0;
			let unitQty = parseFloat(row.costQty) || 0;
			let unitDiscount = parseFloat(row.costDiscount) || 0;
			let total = unitPrice * unitQty;
			total = total - total * (unitDiscount / 100);

			setRow({
				...row,
				costUnitPrice: parseFloat(unitPrice),
				costQty: parseFloat(unitQty),
				isGroupHeader: row.isGroupHeader || false,
				costDiscount: parseFloat(unitDiscount),
				costAmount: parseFloat(total),
			});
		}

		if (e.target.name === 'costDiscount') {
			let unitPrice = parseFloat(row.costUnitPrice) || 0;
			let unitQty = parseFloat(row.costQty) || 0;
			let unitDiscount = parseFloat(e.target.value) || 0;
			let total = unitPrice * unitQty;
			total = total - total * (unitDiscount / 100);

			setRow({
				...row,
				costUnitPrice: parseFloat(unitPrice),
				costQty: parseFloat(unitQty),
				isGroupHeader: row.isGroupHeader || false,
				costDiscount: parseFloat(unitDiscount),
				costAmount: parseFloat(total),
			});
		}

		if (e.target.name === 'costDescription') {
			setRow({
				...row,
				[e.target.name]: e.target.value,
			});
		}
	};

	const AddNewLine = async () => {
		config.headers.map((header) => {
			if (header.required) {
				if (config.query.dbReturnSubKey) {
					if (!row?.[config.query.dbReturnKey]?.[config.query.dbReturnSubKey]?.[header.dbField]) {
						return null;
					}
				}
			}
		});

		await create.mutate(row);
		refQty.current.focus();
	};

	const onKeyPress = async (e) => {
		if (e.keyCode == 13) {
			await AddNewLine();
			document.getElementById(`new_2`).value = new Number(0);
		}

		// Move UP when Up Arrow pressed
		// ---------------------------------------------------------------------
		if (e.keyCode == 38) {
			// Get the element
			let el = document.getElementById(`${itemsCount - 1}_${0}`);

			// Move to the last line in the table
			el.focus();

			// Set focus to end of text
			setTimeout(function () {
				el.selectionStart = el.selectionEnd = 500;
			}, 0);
		}
	};

	const o = 0;

	if (o === 0)
		return (
			<>
				{/* //--> QTY */}
				<Grid item xs={2}>
					<NewRowTextBox
						name="costQty"
						defaultValue={row?.costQty}
						placeholder="Qty"
						type="numeric"
						ref={refQty}
						id="costQty"
						// --
						isNumeric={true}
						onKeyDown={onKeyPress}
						onChange={onChange}
						disabled={disabled}
						className={classes.newCell}
					/>
				</Grid>
				{/* //--> Description */}
				<Grid item xs={5}>
					<NewRowTextBox
						name="costDescription"
						defaultValue={row?.costDescription}
						placeholder="Description"
						type="text"
						ref={refDescription}
						id={`new_${1}`}
						// --
						onKeyDown={onKeyPress}
						onChange={onChange}
						disabled={disabled}
						className={classes.newCell}
					/>
				</Grid>
				{/* //--> Unit Price */}
				<Grid item xs={2}>
					<TextBoxFinance
						id={`new_2`}
						name="costUnitPrice"
						placeholder="Unit Price"
						value={row?.costUnitPrice}
						className={classes.newCell}
						onChange={onChange}
						onKeyDown={onKeyPress}
						disabled={disabled}
					/>
					{/* <NewRowTextBox
						name="costUnitPrice"
						defaultValue={row?.costUnitPrice}
						placeholder="Unit Price"
						// -----
						isFinancial={true}
						// -----
						ref={refUnitPrice}
						id={`new_${2}`}
						// --
						onKeyDown={onKeyPress}
						onChange={onChange}
						isNumeric={true}
						disabled={disabled}
						className={classes.newCell}
					/> */}
				</Grid>
				{/* //--> Discount */}
				<Grid item xs={1}>
					<NewRowTextBox
						name="costDiscount"
						defaultValue={row?.costDiscount}
						placeholder="Disc. (%)"
						type="numeric"
						ref={refDiscount}
						id={`new_${3}`}
						// --

						onKeyDown={onKeyPress}
						onChange={onChange}
						// isPercentage={true}
						disabled={disabled}
						className={classes.newCell}
					/>
				</Grid>
				{/* //--> Total */}
				<Grid item xs={2}>
					<Grid container alignItems="center">
						<Grid item xs={6}>
							{!disabled && (
								<>
									<Typography variant="caption" style={{ color: theme.palette.text.disabled, visibility: 'hidden' }}>
										spacer
									</Typography>
									<Tooltip title="Press enter or click to add new line" arrow={true} placement="top">
										<IconButton onClick={() => AddNewLine()} disabled={disabled}>
											<QueueIcon color="disabled" style={{ fontSize: '1.2rem' }} />
										</IconButton>
									</Tooltip>
								</>
							)}
						</Grid>
						<Grid item xs={6}>
							<Grid container justity="flex-end">
								<>
									<Typography variant="caption" style={{ color: theme.palette.text.disabled, visibility: 'hidden' }}>
										spacer
									</Typography>
									<Typography
										variant="button"
										noWrap
										display="block"
										align="right"
										color={disabled ? 'textSecondary' : 'textSecondary'}
										style={{ paddingRight: 10 }}
									>
										{/* // INT-CURRENCY */}
										{NumberFormatCurrencyText(row.costAmount, 2)}
									</Typography>
								</>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</>
		);

	// ----------------------------------------------------------------
	// MAIN
	// ----------------------------------------------------------------
	return (
		<>
			<button
				onClick={() => {
					refQty.current.focus();
				}}
			>
				Focus
			</button>

			{config.headers.map((header, i) => {
				console.table(header);

				if (!header.isTotal) {
					let currentRef = null;

					if (i === 0) currentRef = refQty;
					if (i === 1) currentRef = refDescription;
					if (i === 2) currentRef = refUnitPrice;
					if (i === 3) currentRef = refDiscount;

					return (
						<Grid key={i} item xs={header.size.xs}>
							<NewRowTextBox
								autoFocus={i === 0}
								ref={currentRef}
								placeholder={header.placeholder}
								id={`new_${i}`}
								name={header.dbField}
								key={i}
								onKeyDown={onKeyPress}
								onChange={onChange}
								defaultValue={row?.[header.dbField] || ''}
								className={classes.newCell}
								showDraggerSpacer={i === 0 ? true : false}
								isFinancial={header.type === 'financial' ? true : false}
								isPercentage={header.type === 'percentage' ? true : false}
								isNumeric={header.type === 'numeric' ? true : false}
								type={header.type || 'text'}
								disabled={disabled}
							/>
						</Grid>
					);
				}

				if (header.isTotal) {
					// TOTAL COLUMN
					// ---------------------------------------------------------------------------
					return (
						<Grid item xs={header.size.xs} key={i}>
							<Grid container alignItems="center">
								<Grid item xs={6}>
									{!disabled && (
										<>
											<Typography variant="caption" style={{ color: theme.palette.text.disabled, visibility: 'hidden' }}>
												sapcer
											</Typography>
											<Tooltip title="Press enter or click to add new line" arrow={true} placement="top">
												<IconButton onClick={() => AddNewLine()} disabled={disabled}>
													<QueueIcon color="disabled" style={{ fontSize: '1.2rem' }} />
												</IconButton>
											</Tooltip>
										</>
									)}
								</Grid>
								<Grid item xs={6}>
									<Grid container justity="flex-end">
										<>
											<Typography variant="caption" style={{ color: theme.palette.text.disabled, visibility: 'hidden' }}>
												spacer
											</Typography>
											<Typography
												variant="button"
												noWrap
												display="block"
												align="right"
												color={disabled ? 'textSecondary' : 'textSecondary'}
												style={{ paddingRight: 10 }}
											>
												{/* // INT-CURRENCY */}
												{NumberFormatCurrencyText(row.costAmount, 2)}
											</Typography>
										</>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					);
				}
				return null;
			})}
		</>
	);
};
