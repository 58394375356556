import { Card } from '../components/card'
import {
    GetInvoiceWarranty,
    UpdateInvoiceWarranty,
} from '../utils/data';

export const InvoiceWarrantyCard = ({ invoiceId, refetchQueryFn }) => {
    // --------------------------------------------------------------------------------------------------
    // MAIN
    // --------------------------------------------------------------------------------------------------
    
    // Shows the standard contact form
    return (
        <Card
            id={invoiceId}
            queryFn={GetInvoiceWarranty}
            mutationFn={UpdateInvoiceWarranty}
            refetchQueryFn={refetchQueryFn}
            template={{
                heading: 'Warranty',
                returnKey: 'warranty',
                fields: [
                    {
                        name: 'First service required at',
                        id: 'firstService',
                        type: 'mileage',
                        canEdit: true,
                        showmore: false,
                        isRequired: true,
                    },
                    {
                        name: 'Subsequent services every',
                        id: 'subsequentServices',
                        type: 'mileage',
                        canEdit: true,
                        showmore: false,
                    },
                    {
                        name: 'Up to a maximum of',
                        id: 'upUntil',
                        type: 'mileage',
                        canEdit: true,
                        // showmore: config?.limitedView || false,
                    },
                ],
            }}
        />
    )
}

export default InvoiceWarrantyCard