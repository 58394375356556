import React, { useState } from 'react';
//Query
import { useQuery } from 'react-query';
import { GetWorkorderById } from '../../utils/data';
//Material
import { Grid, Link, Tooltip } from '@material-ui/core';
//Icons
import ReceiptIcon from '@material-ui/icons/Receipt';
//Context
import WorkorderContext from '../../../context/workorder-context';
import { useWorkordersContext } from '../../../context/workorders-context';
// Components
import { WorkorderActions_Delete, WorkorderActions_Delete_ReninstateBooking, WorkorderActions_Print } from './components/actions';
import WorkorderDetailTabs from './components/tabs';
import WorkorderInformationCard from '../workorder-information-card';
import Modal from '../../components/modal';
import { Loading } from '../../components/loading';
import Box from 'app/components/box';

const WorkorderPopup = ({ workorderId, workorderNumber, type = 'link', meta = {} }) => {
	const [open, setOpen] = useState(false);
	const [setOpenPrintWorkorder] = useState(false);
	const { refreshPage: refreshWorkorders } = useWorkordersContext();

	//Get Data
	const { data, isLoading, refetch } = useQuery(['workorder_detailed', workorderId], GetWorkorderById, {
		refetchOnWindowFocus: false,
		enabled: !!workorderId && open,
	});

	const onClose = () => {
		refreshWorkorders();
	};

	// MAIN
	// --------------------------------------------------------------------------------------------------
	return (
		<>
			{type === 'link' && (
				<Link component="button" onClick={() => setOpen(true)} variant="body2" display="block" color="secondary" style={{ marginTop: 0 }}>
					{workorderNumber}
				</Link>
			)}

			{type === 'action' && (
				<>
					<ReceiptIcon fontSize="small" style={{ paddingRight: 5 }} />
					<Tooltip
						title={
							<span style={{ fontSize: '12px' }}>
								<b>Name</b>: {workorderNumber} <br />
								<b>Mobile</b>: {meta?.workorderMobile} <br />
								<b>Email</b>: {meta?.workorderEmail} <br />
							</span>
						}
						placement="top"
						arrow={true}
					>
						<Link
							component="button"
							onClick={() => setOpen(true)}
							variant="caption"
							color="textSecondary"
							display="block"
							style={{ marginTop: 0, paddingRight: 10 }}
							gutterBottom={false}
						>
							{toProperCase(workorderNumber)}
						</Link>
					</Tooltip>
				</>
			)}

			{data && (
				<WorkorderContext.Provider value={{ workorder: data.workorder, refetch }}>
					<Modal header={`Work Order: ${workorderNumber}`} size="xxl" open={open} setOpen={setOpen} fixedSize={true} onClose={onClose}>
						<Grid container spacing={2}>
							{data?.status === 'error' && <p>Error Occured</p>}
							{isLoading && (
								<Grid container spacing={2} justifyContent="center" alignItems="center">
									<Loading />
								</Grid>
							)}
							{!isLoading && (
								<>
									<Grid item xs={4} style={{ paddingRight: 25 }}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<WorkorderInformationCard workorderId={data?.workorder._id} />
											</Grid>
											<Grid item xs={12}>
												<Box heading="Actions">
													<div
														style={{
															display: 'flex',
															flex: 1,
															flexDirection: 'row',
															flexWrap: 'wrap',
														}}
													>
														<WorkorderActions_Print setOpen={setOpenPrintWorkorder} />
														<WorkorderActions_Delete
															workorderId={data?.workorder._id}
															closeAndRefresh={async () => {
																await refreshWorkorders();
																setOpen(false);
															}}
														/>
														<WorkorderActions_Delete_ReninstateBooking
															workorderId={data?.workorder._id}
															closeAndRefresh={async () => {
																await refreshWorkorders();
																setOpen(false);
															}}
														/>
													</div>
												</Box>
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={8}>
										<WorkorderDetailTabs refetch={refetch} />
									</Grid>
								</>
							)}
						</Grid>
					</Modal>
				</WorkorderContext.Provider>
			)}
		</>
	);
};

function toProperCase(str) {
	if (str)
		return str.replace(/(^|\s)\S/g, function (t) {
			return t.toUpperCase();
		});
	return null;
}

export default WorkorderPopup;
