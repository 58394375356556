import React, { useState } from 'react'

// Material
import { Grid } from '@material-ui/core';

//Context
import { useSettingsContext } from '../../../context/settings-context'

//Pages
import { KeyPairInput } from '../../../app/components/key-pair'
import { TextBoxLabel } from '../../../app/components/card/label'
import { AddressEdit } from '../../../app/components/card/textbox'
import { Section } from '../../../app/components/card/section'

export const CompanySettings = () => {
    const { settings } = useSettingsContext()

    const [newSettings, setNewSettings] = useState({
        name: settings.name,
        registeredName: settings.registeredName,
        registrationNumber: settings.registrationNumber,
        VAT: settings.VAT,
        address: settings.address,
        mobile: settings.mobile,
        telephone: settings.telephone,
        email: settings.email,
        web: settings.web,

        bankName: settings.bankName,
        bankAccount: settings.bankAccount,
        bankBranch: settings.bankbranch,
    })

    const onChange = (e) => {
        setNewSettings({
            ...newSettings,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Grid container alignItems='center' spacing={1}>
            {/* COMPANY DETAILS */}
            <Grid item xs={12}><Section text="Company details" /></Grid>
            <KeyPairInput label='Name' name='name' defaultValue={newSettings?.name || ''}
                type='text'
                onChange={onChange}
            />
            <KeyPairInput label='Email' name='email' defaultValue={newSettings?.email || ''}
                type='email'
                onChange={onChange}
            />
            <KeyPairInput label='Phone' name='telephone' defaultValue={newSettings?.telephone || ''}
                type='phone'
                onChange={onChange}
            />
            <Grid item xs={4}><TextBoxLabel label='Address' /></Grid>
            <Grid item xs={8}>
                <AddressEdit
                    item={{ id: 'address' }}
                    initialValue={newSettings?.address}
                    onChange={onChange}
                />
            </Grid>


            {/* REGISTRATION DETAILS */}
            <Grid item xs={12}><Section text="Registration details" /></Grid>
            <KeyPairInput label='Registered Name' name='registeredName' defaultValue={newSettings?.registeredName || ''}
                type='text'
                onChange={onChange}
            />
            <KeyPairInput label='Registration Number' name='registrationNumber' defaultValue={newSettings?.registrationNumber || ''}
                type='text'
                onChange={onChange}
            />
            <KeyPairInput label={settings.locale.taxLabel} name='VAT' defaultValue={newSettings?.VAT || ''}
                type='text'
                onChange={onChange}
            />


            {/* BANK DETAILS */}
            <Grid item xs={12}><Section text="Bank details" /></Grid>
            <KeyPairInput label='Bank Name' name='bankName' defaultValue={newSettings?.bankName || ''}
                type='text'
                onChange={onChange}
            />
            <KeyPairInput label='Bank Account' name='bankAccount' defaultValue={newSettings?.bankAccount || ''}
                type='text'
                onChange={onChange}
            />
            <KeyPairInput label='Bank Branch' name='bankBranch' defaultValue={newSettings?.bankBranch || ''}
                type='text'
                onChange={onChange}
            />
        </Grid>
    )
}