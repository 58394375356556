import React from 'react';
import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { MainLoading } from '../components/loading';

const PrivateRoute = ({ component, ...args }) => (
	<Route
		component={withAuthenticationRequired(component, {
			onRedirecting: function () { <MainLoading /> },
		})}
		{...args}
	/>
);

export default PrivateRoute;
