import { useState } from 'react';
import process from 'process';
// material
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AttachFileIcon from '@material-ui/icons/AttachFile';
// componenets
import DropZone from './upload.dropzone';
import axios from 'axios';
import config from '../../../config.json';

const domain = process.env.NODE_ENV === 'production' ? config.api.domain : config.api.domain_dev;

export default function UploadDialog({ parentId, title = '', existingFiles, refetch = () => {} }) {
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const [files, setFiles] = useState();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleUpload = async () => {
		const formData = new FormData();

		files.forEach((file) => {
			formData.append('file', file);
		});

		await axios({
			method: 'POST',
			url: `${domain}/upload/${parentId}`,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
			.then(() => {
				refetch();
				setOpen(false);
			})
			.catch((err) => {
				alert(err);
			});

		setOpen(false);
	};

	return (
		<div>
			{/* UPLOAD BUTTON */}
			<Tooltip title={title} arrow={true} placement="right">
				<IconButton
					color="inherit"
					component="span"
					onClick={handleClickOpen}
					size="small"
					style={{
						borderRight: '1px solid',
						borderRightColor: theme.palette.divider,
						borderRadius: 0,
						paddingLeft: 10,
						paddingRight: 10,
					}}
				>
					<AttachFileIcon style={{ fontSize: '1.2rem' }} />
				</IconButton>
			</Tooltip>

			{/* DIALOG */}
			<Dialog maxWidth="sm" fullWidth={true} fullScreen={fullScreen} open={open} onClose={handleClose}>
				{/* DROP ZONE */}
				<DialogContent
					style={{
						backgroundColor: theme.palette.background.default,
						paddingBottom: 20,
					}}
				>
					<DropZone setFiles={setFiles} existingFiles={existingFiles} />
				</DialogContent>

				{/* FOOTER */}
				<DialogActions
					style={{
						paddingRight: 30,
						backgroundColor: theme.palette.primary.main,
					}}
				>
					<Button
						autoFocus
						onClick={handleClose}
						variant="contained"
						size="small"
						style={{
							marginRight: 10,
						}}
					>
						Cancel
					</Button>
					<Button
						onClick={handleUpload}
						autoFocus
						variant="contained"
						color="secondary"
						size="small"
						disabled={!files || files?.length === 0}
					>
						Upload
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
