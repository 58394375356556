import { useState, useMemo } from 'react';
import process from 'process';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import config from '../../../config.json';

const domain = process.env.NODE_ENV === 'production' ? config.api.domain_public : config.api.domain_dev_public;

export const CommentsDialog = ({ open, setOpen, refetch = () => {} }) => {
	const { search } = useLocation();
	const params = useMemo(() => new URLSearchParams(search), [search]);
	const quoteApprovelID = useMemo(() => {
		if (params) return params.get('id');
	}, [params]);

	const [submitting, setSubmitting] = useState(false);
	const [comment, setComment] = useState();
	const handleClose = () => {
		setOpen(false);
	};

	const handleSubmit = async () => {
		// setOpen(false);
		try {
			setSubmitting(true);
			await axios.post(`${domain}/quote/comment/${quoteApprovelID}`, {
				comment,
			});

			setOpen(false);
			refetch();
			setSubmitting(false);
		} catch (error) {
			alert('Error while performing action. Try again later');
		}
	};

	return (
		<div>
			<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
				<DialogTitle>Add a comment</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Comment"
						rows={10}
						multiline
						fullWidth
						onChange={(e) => setComment({ comment: e.target.value })}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary" disabled={submitting}>
						Cancel
					</Button>
					<Button onClick={handleSubmit} color="secondary" variant="contained" disabled={!comment || comment === '' || submitting}>
						Submit
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default CommentsDialog;
