import React, { useState, useMemo } from 'react';
// material
import { makeStyles } from '@material-ui/core/styles';
import { Grid, MenuItem, MenuList, Typography, Tooltip, Box, useTheme } from '@material-ui/core';
import { Button, IconButton, InputBase, Divider } from '@material-ui/core';
import { deepOrange, grey } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

// Styles -->
const useStyles = makeStyles((theme) => ({
	searchBox: {
		borderRadius: 0,
		font: theme.typography.subtitle1,
		marginLeft: 15,
		paddingTop: 3,
	},
	menuContainer: {
		// marginTop: 10,
		backgroundImage: `linear-gradient(${theme.palette.background.default}, ${theme.palette.background.paper})`,
		height: '100%',
		alignItems: 'flex-start',
	},
	menu: {
		width: '100%',
		'& .Mui-selected': {
			backgroundColor: `${theme.palette.secondary.main} !important`,
			borderTopRightRadius: 100,
			borderBottomRightRadius: 100,
			marginRight: 5,
			paddingRight: 11,
		},
	},
	menuItem: {
		// width: '100%'
	},
	itemCount: {
		backgroundColor: deepOrange[500],
		color: theme.palette.getContrastText(deepOrange[500]),
		width: theme.spacing(3),
		height: theme.spacing(3),
		marginLeft: 10,
	},
	itemCountDisabled: {
		backgroundColor: grey[400],
		color: theme.palette.getContrastText(grey[400]),
		width: theme.spacing(3),
		height: theme.spacing(3),
		marginLeft: 10,
	},
}));

// Main Code -->
export const QuotesMenu = ({ data, statusTotals, handleStatusFilterClick, allSelected = false, onChange }) => {
	const classes = useStyles();

	const [menuItems] = useState(['Draft', 'Awaiting Approval']);
	const [menuItemsShowMore] = useState(['Approved', 'Rejected', 'Void']);
	const [showMore, setShowMore] = useState(false);

	return (
		<Grid container className={classes.menuContainer}>
			<Grid item xs={12}>
				<Grid container alignItems="center" style={{ paddingBottom: 5, paddingTop: 5 }}>
					<Grid item xs={10}>
						<InputBase
							id={'search-quotes'}
							placeholder={'find something...'}
							onChange={onChange}
							className={classes.searchBox}
							defaultValue={data?.data?.query?.query?.search || ''}
							size="small"
							margin="dense"
						/>
					</Grid>
					<Grid item xs={2}>
						<IconButton
							onClick={() => {
								if (data?.data?.query?.query?.search) {
									onChange('');
								}
							}}
							size="small"
							disableRipple
							disableFocusRipple
						>
							{!data?.data?.query?.query?.search ? <SearchIcon /> : <CloseIcon />}
						</IconButton>
					</Grid>
				</Grid>
				{/* </Grid> */}

				<Divider />
				<Grid container alignItems="flex-start">
					<MenuList className={classes.menu}>
						{menuItems?.map((status, idx) => {
							let selected = data?.data?.query?.query?.quoteStatus?.includes(status);
							if (allSelected) selected = true;
							if (selected && menuItemsShowMore?.includes(toProperCase(status))) setShowMore(true);

							return (
								<SideMenuItem
									key={`sideMenuItem-${idx}`}
									status={toProperCase(status)}
									total={statusTotals?.[status]}
									selected={selected}
									classes={classes}
									onClick={(e) => handleStatusFilterClick(e, status)}
								/>
							);
						})}

						{showMore &&
							menuItemsShowMore?.map((status, idx) => {
								let selected = data?.data?.query?.query?.quoteStatus?.includes(status);
								if (allSelected) selected = true;

								return (
									<SideMenuItem
										key={`sideMenuItem-${idx}`}
										status={toProperCase(status)}
										total={statusTotals?.[status]}
										selected={selected}
										classes={classes}
										onClick={(e) => handleStatusFilterClick(e, status)}
									/>
								);
							})}

						<Box style={{ width: '!00%', textAlign: 'right' }}>
							<Button
								align="right"
								size="small"
								style={{ paddingTop: 5, paddingLeft: 0, fontSize: '0.6rem', color: 'textSecondary', textTransform: 'none' }}
								endIcon={showMore == true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
								onClick={() => {
									setShowMore((show) => !show);
								}}
							>
								{showMore == false ? 'show more' : 'show less'}
							</Button>
						</Box>
					</MenuList>
				</Grid>
			</Grid>
		</Grid>
	);
};

const SideMenuItem = ({ status, total, selected, onClick, classes }) => {
	const theme = useTheme();

		const style = () => {
			if (selected) return { backgroundColor: 'white', color: 'black' };

			if (!total) return { backgroundColor: theme.palette.action.selected, color: 'darkgrey' };
			if (status === 'Write-off') return { backgroundColor: theme.palette.error.main, color: theme.palette.common.white };
			if (status === 'Paid') return { backgroundColor: theme.palette.success.dark, color: theme.palette.common.white };
			if (status === 'New') return { backgroundColor: theme.palette.warning.main, color: theme.palette.common.black };
			if (status === 'In Progress') return { backgroundColor: theme.palette.success.dark, color: theme.palette.common.white };
			return { backgroundColor: theme.palette.action.selected, color: theme.palette.common.black };
		};


	const itemTotal = useMemo(() => {
		if (total > 99) return '99+';
		if (total === 0) return '-';
		if (!total) return '-';
		return total;
	}, [total]);

	return (
		<MenuItem selected={selected} className={classes.menuItem} onClick={onClick}>
			<Grid container justifyContent="space-between">
				<Grid item xs={6}>
					<Tooltip title="Ctrl-Click to select multiple">
						<Typography variant="subtitle2" noWrap>
							{toProperCase(status)}
						</Typography>
					</Tooltip>
				</Grid>
				<Grid item xs={6} align="right">
					<div
						style={{
							borderRadius: 20,
							width: 20,
							height: 20,
							alignItems: 'center',
							justifyContent: 'center',
							display: 'flex',
							felx: 1,
							...style(),
						}}
					>
						<Typography style={{ fontSize: '0.55rem' }}>{itemTotal}</Typography>
					</div>
				</Grid>
			</Grid>
		</MenuItem>
	);
};

function toProperCase(str) {
	if (str)
		return str.replace(/(^|\s)\S/g, function (t) {
			return t.toUpperCase();
		});
	return null;
}
