import { Fragment, useMemo } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
//material
import MaterialTable, { MTableBodyRow } from 'material-table';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { alpha, Grid, Paper, Typography } from '@material-ui/core';
//components
import { useAppointmentsContext } from '../../../../../context/appointments-context';
import { AppointmentsTableColumns } from '../../../../../context/config.tables';
import { TableRowActions } from './appointments-table-action-row';

const useStyles = makeStyles((theme) => ({
	table: {
		// ROW HOVER
		'& tbody': {
			'& tr:nth-child(1n - 1):hover, tr:nth-child(1n-1):hover + tr': {
				transition: 'none',
				backgroundColor: alpha(theme.palette.background.default, 0.5),
			},
			'& td:first-child': {
				transition: 'none',
				borderLeft: `1px solid ${theme.palette.divider}`,
			},
			'& td:last-child': {
				borderRight: `1px solid ${theme.palette.divider}`,
			},
		},

		'& thead': {
			border: 0,
		},

		// TOOLBAR
		'& .MuiToolbar-root': {
			paddingLeft: 10,
			backgroundColor: theme.palette.background.default,
			border: 0,
			minHeight: 30,
		},
	},
	card: {
		'& .MuiToolbar-root': {
			backgroundColor: theme.palette.background.paper,
			border: 0,
		},
	},
}));

export const AppointmentsTable = () => {
	const classes = useStyles();

	return (
		<div className={classes.table}>
			<AppointmentTableContainer />
		</div>
	);
};

function AppointmentTableContainer() {
	const theme = useTheme();
	const classes = useStyles();
	const { search } = useLocation();
	const selectedView = useMemo(() => new URLSearchParams(search), [search]);
	const view = selectedView.get('view') || 'active';

	//Context
	let { appointments } = useAppointmentsContext();

	//TITLE
	const title = (view) => (
		<Typography color="secondary" variant="h6" style={{ marginLeft: 0 }}>
			<b>{TitleText(view)}</b>
		</Typography>
	);

	// MAIN
	return (
		<Grid container spacing={0} alignItems="flex-start" className={classes.card}>
			<Grid item xs={12}>
				<MaterialTable
					name="appointments_table"
					elevation={0}
					title={title(view)}
					data={appointments?.appointments?.[view]}
					columns={AppointmentsTableColumns()}
					options={{
						// GENERAL
						padding: 'dense',
						minBodyHeight: '60vh',
						maxBodyHeight: '60vh',

						//SEARCH
						searchFieldVariant: 'outlined',
						searchFieldStyle: {
							maxHeight: 30,
							borderRadius: 20,
						},

						//HEADER
						headerStyle: {
							backgroundColor: theme.palette.background.default,
							border: 0,
						},

						//ROW
						selection: false,
						rowStyle: {
							border: 0,
							verticalAlign: 'top',
						},

						// EXPORT
						exportFileName: `Appointments-${moment().format('YYYY-MM-DD')}`,
						exportAllData: true,
						exportButton: true,

						//PAGING
						pageSize: 10,
						paging: appointments?.appointments?.[view]?.length > 10 ? true : false,
						emptyRowsWhenPaging: false,
					}}
					components={{
						Container: (props) => <Paper {...props} elevation={0} />,
						Row: (props) => {
							return (
								<>
									<tr
										style={{
											border: 0,
											borderBottom: `1px solid ${theme.palette.divider}`,
											height: 15,
										}}
									/>
									<MTableBodyRow {...props} />
									<tr
										style={{
											border: 0,
										}}
									>
										<td
											colSpan="4"
											style={{
												padding: 0,
												border: `1px solid ${theme.palette.divider}`,
											}}
										>
											<TableRowActions appointment={props.data} />
										</td>
									</tr>
								</>
							);
						},
					}}
					localization={{
						body: {
							emptyDataSourceMessage: (
								<Typography variant="body2" style={{ textAlign: 'center' }}>
									No appointments to display
								</Typography>
							),
						},
						toolbar: {
							searchPlaceholder: 'Filter results',
						},
					}}
				/>
			</Grid>
		</Grid>
	);
}

const TitleText = (view = '') => {
	let viewText = '';

	if (view === 'active') viewText = 'TODAY';
	if (view === 'overdue') viewText = 'OVERDUE';
	if (view === 'search') viewText = 'SEARCH';

	return viewText.toUpperCase();
};
