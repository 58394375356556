import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, Link } from 'react-router-dom';
//Material
import { makeStyles } from '@material-ui/core/styles';
import { alpha, AppBar, Badge, Toolbar, Tooltip, useTheme } from '@material-ui/core';
import { Button, Typography, Grid, Hidden } from '@material-ui/core';
//Icons
import ScheduleIcon from '@material-ui/icons/Schedule';
import BuildIcon from '@material-ui/icons/Build';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
//Context
import { useSearchContext } from '../context/search-context';
//Components
import { SearchGlobal } from '../app/widgets/search';
import { MobileDrawer } from './MobileDrawer';
import UserMenu from './UserMenu';
import AppointmentNew from '../pages/appointments/views/new/index';
import CheckAccess from '../app/utils/access';
// data
import { GetMenuStats } from '../app/utils/data';
import { useQuery } from 'react-query';

//Styles
const useStyles = makeStyles((theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		padding: 0,
		paddingTop: 5,
	},
	button: {
		borderRadius: 0,
		border: 0,
		backgroundColor: 'transparent',
	},
	buttonSelected: {
		borderRadius: 0,
		border: 0,
		boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.type === 'dark' ? theme.palette.text.primary : theme.palette.primary.main,
		fontWeight: 700,
	},
}));

export const ActiniumAppBar = ({ openEditAppointment, setOpenEditAppointment }) => {
	const classes = useStyles();
	const theme = useTheme();
	const { open, setOpen } = useSearchContext();
	const { isAuthenticated, loginWithRedirect } = useAuth0();

	const { data } = useQuery(['menu-stats', null], GetMenuStats, {
		refetchInterval: 15000,
		refetchOnWindowFocus: true,
		refetchIntervalInBackground: 15000,
		refetchOnMount: true,
	});

	return (
		<AppBar position="fixed" className={classes.appBar} elevation={0} color="primary" variant="outlined">
			{/* TOOLBAR */}
			<Toolbar variant="dense">
				<Grid container alignItems="center" direction="row">
					<Grid item xs={11} md={10} style={{ display: 'flex', justifyContent: 'flex-start' }}>
						<Grid container>
							{/* //* LOGO */}
							<Grid item xs={2}>
								<div
									style={{
										width: 190,
										padding: 0,
										paddingLeft: 25,
										paddingRight: 25,
										borderRadius: 20,
										border: `2px solid ${alpha(theme.palette.text.primary, 0.6)}`,
										backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
										boxShadow: `1px 1px 13px ${theme.palette.background.default}`,
										alignItems: 'center',
										justifyContent: 'center',
										display: 'flex',
									}}
								>
									<Link href="/" style={{ color: 'white', textDecoration: 'none' }} to="">
										<Tooltip title="Go home">
											<Typography
												style={{
													fontSize: '1rem',
													fontWeight: 900,
													letterSpacing: -1,
													color: alpha(theme.palette.text.primary, 0.8),
												}}
											>
												<b>German Auto Salon</b>
											</Typography>
										</Tooltip>
									</Link>
								</div>
							</Grid>

							{/* //* MENU */}
							<Grid item xs={9}>
								<Hidden smDown>
									<Grid container spacing={0} justifyContent="center" alignItems="center">
										<MenuItem
											label="Appointments"
											icon={<ScheduleIcon />}
											url="/appointments"
											count={data?.stats?.bookings || 0}
										/>
										<MenuItem label="Work Orders" icon={<BuildIcon />} url="/workorders" count={data?.stats?.workorders || 0} />
										<MenuItem label="Quotes" icon={<FormatQuoteIcon />} url="/quotes" count={data?.stats?.quotes || 0} />
										{CheckAccess('write-finance') && (
											<MenuItem label="Invoices" icon={<ReceiptIcon />} url="/invoices" count={data?.stats?.invoices || 0} />
										)}

										<MenuItem label="Contacts" icon={<ContactMailIcon />} url="/contacts" />
										<MenuItem label="Vehicles" icon={<DriveEtaIcon />} url="/vehicles" />
									</Grid>
								</Hidden>
							</Grid>
							<Grid item xs={1} />
						</Grid>
					</Grid>

					{/* //* MOBILE AND PROFILE  */}
					<Grid item xs={1} md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Hidden lgUp>
							<MobileDrawer />
						</Hidden>

						<Hidden mdDown>
							{/* SEARCH */}
							<SearchGlobal open={open} setOpen={setOpen} />
							{/* New Appointment */}
							{isAuthenticated && (
								<AppointmentNew
									editAppointmentItem={null}
									open={openEditAppointment}
									setOpen={setOpenEditAppointment}
									iconSize="medium"
									disabled={false}
									refetchFn={() => {}}
								/>
							)}
						</Hidden>

						{/* User Menu */}
						{isAuthenticated && <UserMenu />}
						{!isAuthenticated && (
							<Button variant="outlined" color="inherit" onClick={loginWithRedirect}>
								Login
							</Button>
						)}
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
};

const MenuItem = ({ label, url, count }) => {
	const location = useLocation();

	const classes = useStyles();
	return (
		<Badge badgeContent={count || null} color="secondary">
			<Button
				variant="contained"
				color="secondary"
				className={location.pathname === url ? classes.buttonSelected : classes.button}
				size="small"
				disableElevation={true}
				component={Link}
				to={url}
				style={{
					borderRadius: 5,
					marginLeft: 15,
				}}
			>
				{label}
			</Button>
		</Badge>
	);
};
