import { useState } from 'react';

//@Material
import { Button, Tooltip } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

// Icons
import UndoIcon from '@material-ui/icons/Undo';

// Query
import { useMutation } from 'react-query';
import {
    DeleteAppointment,
} from '../../../../../../app/utils/data';

// State
import { useAppointmentsContext } from '../../../../../../context/appointments-context'
import { useNewAppointmentContext } from '../../../../../../context/appointments-new-context'

export default function DeleteAppointmentDialog({
    id,
    title,
    message,
    icon,
    setParentOpen,
    tootlip = '',
    disabled = false
}) {
    const [open, setOpen] = useState(false);
    const { refreshPage } = useAppointmentsContext()
    const { setAppointment, setErrors } = useNewAppointmentContext()

    const deleteAppointment = useMutation(DeleteAppointment)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        deleteAppointment.mutate({ id })

        setAppointment(null)
        setErrors(null)

        refreshPage()

        setOpen(false);
        setParentOpen(false)
    };

    return (
        <>
            <Tooltip title={tootlip} arrow={true} placement='right'>
                <Button
                    onClick={handleClickOpen}
                    fullWidth={true}
                    disabled={disabled}
                    size="small"
                    variant='outlined'
                    startIcon={icon || <UndoIcon />}
                >
                    Delete
                </Button>
            </Tooltip>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <>
                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDelete} color="primary">
                            Yes
                        </Button>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            No
                        </Button>
                    </DialogActions>
                </>
            </Dialog>
        </>
    );
}
