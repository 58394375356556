import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useLocation } from 'react-router-dom';
import process from 'process';
import download from 'downloadjs';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
//material
import { useTheme } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import AddCommentIcon from '@material-ui/icons/AddComment';

import {
	Grid,
	Typography,
	Button,
	Container,
	useMediaQuery,
	AppBar,
	Box,
	Divider,
	CircularProgress,
	Link,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@material-ui/core';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp'; // components
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

//components
import config from '../../config.json';
import colours from '../../colour-schemes.json';
import { formatDistanceToNow } from 'date-fns';
import CommentsDialog from 'app/components/dialog/comment.dialog';
// import Table from 'app/components/finance-table/Table';

const domain = process.env.NODE_ENV === 'production' ? config.api.domain_public : config.api.domain_dev_public;

const Page = ({ quoteAppId }) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [data, setData] = useState();
	const [busyPrint, setBusyPrint] = useState();
	const [openComment, setOpenComment] = useState();
	const [busyDownload, setBusyDownload] = useState();
	const [notFound, setNotFound] = useState(false);

	//* get quote
	useEffect(() => {
		const getQuote = async () => {
			return await axios
				.get(`${domain}/quote/${quoteAppId}`)
				.then((ret) => {
					if (ret.data.data.length === 0) setNotFound(true);
					setData(ret.data.data[0]);
				})
				.catch((e) => {
					console.error(e);
				});
		};

		if (!data) getQuote();
	}, [data]);

	const refreshData = async () => {
		return await axios
			.get(`${domain}/quote/${quoteAppId}`)
			.then((ret) => {
				if (ret.data.data.length === 0) setNotFound(true);
				setData(ret.data.data[0]);
			})
			.catch((e) => {
				console.error(e);
			});
	};

	//* open
	const openPDF = async () => {
		setBusyPrint(true);
		const openInNewTab = (url) => {
			const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
			if (newWindow) newWindow.opener = null;
		};

		try {
			await axios
				.get(`${domain}/quote/${quoteAppId}/pdf`, {
					responseType: 'blob',
				})
				.then((response) => {
					const file = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
					openInNewTab(file);
					setBusyPrint(false);
				})
				.catch((error) => {
					console.error(error);
					setBusyPrint(false);
				});
		} catch (error) {
			alert('Error while downloading file. Try again later');
			setBusyPrint(false);
		}
	};

	//* download
	const downloadFile = async () => {
		setBusyDownload(true);
		try {
			await axios({
				url: `${domain}/quote/${quoteAppId}/pdf`,
				method: 'GET',
				responseType: 'blob',
			}).then((response) => {
				let filename = response.headers['content-disposition'].split('filename=')[1].replaceAll("'", '');
				download(response.data, filename, 'application/pdf');
				setBusyDownload(false);
			});
		} catch (error) {
			alert('Error while downloading file. Try again later');
			setBusyDownload(false);
		}
	};

	const approve = async () => {
		try {
			await axios.post(`${domain}/quote/${quoteAppId}`, {
				status: 'Approved',
			});
		} catch (error) {
			alert('Error while performing action. Try again later');
		}
	};

	const reject = async () => {
		try {
			await axios.post(`${domain}/quote/${quoteAppId}`, {
				status: 'Rejected',
			});
		} catch (error) {
			alert('Error while performing action. Try again later');
		}
	};

	if (notFound)
		return (
			<Container
				style={{
					height: '100vh',
					width: '100vw',
				}}
			>
				<Typography variant="h6" align="center">
					Item not found
				</Typography>
			</Container>
		);
	if (!data) return null;

	return (
		<div
			style={{
				width: '100%',
				minHeight: '100vh',
				backgroundImage: `linear-gradient( rgba(225,225,225,0.7), rgba(225,225,225,0.7) ), url('https://www.germanautosalon.co.za/images/bg_1.jpg')`,
				backgroundPosition: '50% 50%',
				backgroundRepeat: 'no-repeat',
				backgroundAttachment: 'fixed',
			}}
		>
			<AppBar
				position="static"
				color="transparent"
				style={{
					padding: 10,
					backgroundColor: 'rgb(250,250,250)',
				}}
			>
				<Container maxWidth="md">
					<Grid container>
						<Grid item xs={12} md={6}>
							<Typography
								variant="h5"
								noWrap
								style={{
									mr: 2,
									fontWeight: 700,
									color: 'black',
									textDecoration: 'bold',
									letterSpacing: '0.2em',
								}}
							>
								<b>German Auto Salon</b>
							</Typography>
							<Typography
								style={{
									color: theme.palette.text.secondary,
									letterSpacing: '0.2em',
									textTransform: 'uppercase',
								}}
							>
								<b>Customer service portal</b>
							</Typography>
						</Grid>

						<Grid
							item
							xs={12}
							md={6}
							styles={{
								borderTop: mobile ? `1px solid ${theme.palette.divider}` : 0,
							}}
						>
							<Box
								style={{
									display: 'flex',
									flex: 1,
									flexDirection: mobile ? 'row' : 'column',
									justifyContent: mobile ? 'space-between' : 'flex-end',
									alignItems: mobile ? 'space-between' : 'flex-end',
								}}
							>
								<Typography>
									<b>011-672-9161</b>
								</Typography>
								<Typography>
									<b>accounts@germanautosalon.co.za</b>
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</AppBar>

			<Container
				style={{
					maxWidth: mobile ? '100%' : '50%',
					paddingTop: 10,
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Grid container>
							{/* //* title */}
							<Grid item xs={12} md={8}>
								<Typography variant="h4" paragraph>
									<b>{`Quote: ${data?.quoteNumber}`}</b>
								</Typography>
							</Grid>

							{/* //* approve/reject buttons */}
							{data?.quoteStatus === 'Awaiting Approval' && (
								<Grid
									item
									xs={12}
									md={4}
									style={{
										display: 'flex',
										justifyContent: 'space-between',
									}}
								>
									<Button
										size="small"
										startIcon={<ThumbUpIcon />}
										style={{
											padding: 15,
											paddingTop: 5,
											paddingBottom: 5,
											backgroundColor: theme.palette.success.light,
											color: 'white',
										}}
										onClick={async () => {
											await approve();
											setData(null);
										}}
									>
										Approve
									</Button>
									<Button
										size="small"
										startIcon={<ThumbDownIcon />}
										style={{
											padding: 15,
											paddingTop: 5,
											paddingBottom: 5,
											backgroundColor: theme.palette.error.light,
											color: 'white',
										}}
										onClick={async () => {
											await reject();
											setData(null);
										}}
									>
										Reject
									</Button>
								</Grid>
							)}
						</Grid>
					</Grid>

					{/* //* totals */}
					<Section label="Quote">
						<Row label="Total" value={data?.totals?.quoteTotal} isTotal={true} isCurrency={true} />

						<Row divider={true} />

						<Row
							label="Status"
							value={data?.quoteStatus}
							isApproved={data?.quoteStatus === 'Approved' ? true : false}
							isRejected={data?.quoteStatus === 'Rejected' ? true : false}
							isWaiting={data?.quoteStatus === 'Awaiting Approval' ? true : false}
						/>
						<Row label="Approved By" value={data?.approvedBy || ''} />
						<Row label="Approved/Rejected on" value={data?.quoteApproveDate || ''} isDate={true} />

						<Row divider={true} />

						<Row label="Date issued" value={data?.updatedAt} isDate={true} />
						<Row label="Quote Ref #" value={data?.quoteNumber} />
						<Row label="Booking Ref #" value={data?.bookingNumber} />
						<Row label="Instructions" value={data?.quoteInstructions} />

						<Row divider={true} />

						{/* //* butons */}
						<Grid item xs={12}>
							<Button
								color="secondary"
								variant="contained"
								onClick={() => openPDF()}
								disabled={busyDownload || busyPrint}
								style={{
									textTransform: 'none',
									textDecoration: 'underline',
									color: '#FFF',
									fontSize: '0.8rem',
									marginRight: 5,
									padding: 0,
									paddingLeft: 10,
									paddingRight: 10,
								}}
							>
								{busyPrint && (
									<CircularProgress
										size={16}
										style={{
											marginRight: '5px',
										}}
									/>
								)}
								View
							</Button>
							<Button
								color="secondary"
								variant="contained"
								onClick={() => downloadFile()}
								disabled={busyDownload || busyPrint}
								style={{
									textTransform: 'none',
									textDecoration: 'underline',
									color: '#FFF',
									fontSize: '0.8rem',
									padding: 0,
									paddingLeft: 10,
									paddingRight: 10,
								}}
							>
								{busyDownload && (
									<CircularProgress
										size={16}
										style={{
											marginRight: '5px',
										}}
									/>
								)}
								Download
							</Button>
						</Grid>
					</Section>

					{/* //* insurance doucments */}
					<Grid container spacing={2}>
						<Grid item xs={12} md={12}>
							<Section label="Additional documents">
								<DocumentsTable quoteAppId={quoteAppId} files={data?.files} />
							</Section>
						</Grid>
					</Grid>

					{/* //* vehicle & contact */}
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							{/* //* vehicle */}
							<Section label="Vehicle">
								<Row label="Registration No." value={data?.vehicle.vehicleRegistration} />
								<Row label="Make & Model" value={`${data?.vehicle.vehicleMake} ${data?.vehicle.vehicleModel}`} />
								<Row label="Mileage" value={`${data?.workorderOdometer} km's`} />
							</Section>
						</Grid>
						<Grid item xs={12} md={6}>
							{/* //* contact */}
							<Section label="Contact">
								<Row label="Name" value={data?.contact?.contactFullname} />
								<Row label="Email" value={data?.contact?.contactEmail} />
								<Row label="Mobile" value={data?.contact?.contactMobile} />

								<Row divider={true} />

								<Row label="Customer" value={data?.business?.businessName} />
								<Row label="Phone" value={data?.business?.businessPhone} />
								<Row label="Email" value={data?.business?.businessEmail} />
							</Section>
						</Grid>
					</Grid>

					{/* //* comments */}
					<Grid container spacing={2}>
						<Grid item xs={12} md={12}>
							<Section
								label="Comments"
								action={
									<Button
										color="secondary"
										variant="contained"
										startIcon={<AddCommentIcon style={{ fontSize: '1rem' }} />}
										disabled={data?.quoteStatus === 'Approved'}
										style={{
											textTransform: 'none',
											textDecoration: 'underline',
											color: '#FFF',
											fontSize: '0.8rem',
											marginRight: 5,
											padding: 0,
											paddingLeft: 10,
											paddingRight: 10,
										}}
										onClick={() => setOpenComment(true)}
									>
										Add comment
									</Button>
								}
							>
								<CommentsTable comments={data?.comments} />
							</Section>
						</Grid>
					</Grid>
				</Grid>
			</Container>

			<CommentsDialog open={openComment} setOpen={setOpenComment} refetch={() => refreshData()} />
		</div>
	);
};

const Section = ({ label, maxWidth = '100%', action, children }) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<>
			<Grid
				container
				style={{
					borderRadius: 5,
					border: `1px solid ${theme.palette.divider}`,
					marginBottom: 10,
					padding: 10,
					maxWidth: mobile ? '100%' : maxWidth,
					backgroundColor: 'rgb(250,250,250)',
				}}
			>
				<Grid item xs={12}>
					<Grid container alignItems="center">
						<Grid item xs={8}>
							<Typography
								variant="h6"
								styles={{
									marginBottom: 10,
								}}
							>
								<b>{label}</b>
							</Typography>
						</Grid>
						<Grid item xs={4} align="right">
							{action}
						</Grid>
					</Grid>
					<Divider variant="fullWidth" style={{ marginTop: 10, marginBottom: 10 }} />
				</Grid>
				{children}
			</Grid>
		</>
	);
};

const Row = ({ label, value, divider = false, isCurrency = false, isDate = false, isApproved = false, isRejected = false, isWaiting = false }) => {
	const theme = useTheme();

	if (divider)
		return (
			<Grid item xs={12}>
				<Divider variant="fullWidth" style={{ marginTop: 10, marginBottom: 10 }} />
			</Grid>
		);

	return (
		<>
			<Grid item xs={6} md={6}>
				<Typography
					style={{
						color: theme.palette.text.secondary,
					}}
				>
					{label}
				</Typography>
			</Grid>

			<Grid item xs={6} md={6} align="right">
				{isDate && <Typography>{value ? moment(value).format('ddd, DD MMMM YYYY') : '-'}</Typography>}

				{isCurrency && (
					<Typography
						noWrap={false}
						variant="h6"
						paragraph={true}
						style={{
							whiteSpace: 'pre-wrap',
							// wordWrap: 'break-word',
						}}
					>
						<CurrencyFormat
							value={Math.abs(value)}
							displayType={'text'}
							thousandSeparator={true}
							prefix={'R '}
							decimalScale={2}
							fixedDecimalScale={true}
						/>
					</Typography>
				)}

				{isApproved && (
					<Box
						style={{
							display: 'flex',
							flex: 1,
							alignItems: 'center',
							justifyContent: 'flex-end',
							flexDirection: 'row',
						}}
					>
						<Typography
							style={{
								fontSize: '1.2rem',
								marginRight: 15,
								color: theme.palette.success.dark,
							}}
						>
							<b>{value || '-'}</b>
						</Typography>
						<ThumbUpIcon
							style={{
								fontSize: '1.2rem',
								color: theme.palette.success.dark,
							}}
						/>
					</Box>
				)}

				{isRejected && (
					<Box
						style={{
							display: 'flex',
							flex: 1,
							alignItems: 'center',
							justifyContent: 'flex-end',
							flexDirection: 'row',
						}}
					>
						<Typography
							style={{
								fontSize: '1.2rem',
								color: theme.palette.error.light,
								marginRight: 15,
							}}
						>
							<b>{value || '-'}</b>
						</Typography>
						<ThumbDownIcon
							style={{
								fontSize: '1.2rem',
								color: theme.palette.error.light,
							}}
						/>
					</Box>
				)}

				{isWaiting && (
					<Box
						style={{
							display: 'flex',
							flex: 1,
							alignItems: 'center',
							justifyContent: 'flex-end',
							flexDirection: 'row',
						}}
					>
						<Typography
							style={{
								fontSize: '1.2rem',
								color: theme.palette.secondary.main,
								marginRight: 15,
							}}
						>
							<b>{value || '-'}</b>
						</Typography>
						<QueryBuilderIcon
							style={{
								fontSize: '1.2rem',
								color: theme.palette.secondary.main,
							}}
						/>
					</Box>
				)}

				{isApproved === false && isRejected === false && isWaiting === false && !isCurrency && !isDate && (
					<Typography>{value || '-'}</Typography>
				)}
			</Grid>
		</>
	);
};

const DocumentsTable = ({ quoteAppId, files = [] }) => {
	const downloadFile = async (id, fileName, mimetype) => {
		try {
			await axios({
				url: `${domain}/quote/${quoteAppId}/download/${id}`,
				method: 'GET',
				responseType: 'blob',
			}).then((response) => {
				download(response.data, fileName, mimetype);
			});
		} catch (error) {
			if (error.response && error.response.status === 400) {
				alert('Error occured while downloading file.');
			}
		}
	};

	return (
		<TableContainer>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell padding="none" size="small">
							<Typography variant="body2">
								<b>File</b>
							</Typography>
						</TableCell>
						<TableCell padding="none" size="small">
							<Typography variant="body2">
								<b>Size</b>
							</Typography>
						</TableCell>
						<TableCell padding="none" size="small">
							<Typography variant="body2">
								<b>Uploaded on</b>
							</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{(!files || files?.length === 0) && (
						<TableRow key={`additional-files-nodata`}>
							<TableCell colSpan={3} align="center">
								<Typography>No additional files available</Typography>
							</TableCell>
						</TableRow>
					)}

					{files.map((file, fileIdx) => {
						return (
							<TableRow key={`additional-files-${fileIdx}`}>
								<TableCell
									padding="none"
									size="small"
									style={{
										borderBottom: 0,
										paddingBottom: 0.5,
									}}
								>
									<Link
										component="button"
										variant="caption"
										color="textPrimary"
										onClick={() => downloadFile(file._id, file.meta_data.originalname, file.meta_data.mimetype)}
										style={{
											textDecoration: 'underline',
											'& :hover': {
												color: 'blue',
											},
										}}
									>
										{file.meta_data.originalname}
									</Link>
								</TableCell>

								<TableCell
									padding="none"
									size="small"
									style={{
										borderBottom: 0,
										paddingBottom: 0.5,
									}}
								>
									<Typography variant="body2">{humanFileSize(file?.meta_data?.size)}</Typography>
								</TableCell>

								<TableCell
									padding="none"
									size="small"
									style={{
										borderBottom: 0,
										paddingBottom: 0.5,
									}}
								>
									<Typography variant="body2">
										{`${moment(file?.updatedAt).format('DD MMM YYYY')} (${formatDistanceToNow(new Date(file?.updatedAt), {
											addSuffix: true,
										})})`}
									</Typography>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const CommentsTable = ({ comments = [] }) => {
	return (
		<TableContainer>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell padding="none" size="small">
							<Typography variant="body2">
								<b>Comment</b>
							</Typography>
						</TableCell>
						<TableCell padding="none" size="small">
							<Typography variant="body2">
								<b></b>
							</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{(!comments || comments?.length === 0) && (
						<TableRow key={`additional-files-nodata`}>
							<TableCell colSpan={2} align="center">
								<Typography>No comments</Typography>
							</TableCell>
						</TableRow>
					)}

					{comments.map((comment, commentIdx) => {
						return (
							<TableRow
								key={`comments-${commentIdx}`}
								style={{
									verticalAlign: 'top',
								}}
							>
								<TableCell
									width="60%"
									padding="none"
									size="small"
									style={{
										borderBottom: 0,
										paddingBottom: 0.5,
									}}
								>
									<Typography variant="body2">{comment?.comment}</Typography>
								</TableCell>
								<TableCell
									width="60%"
									padding="none"
									size="small"
									style={{
										borderBottom: 0,
										paddingBottom: 0.5,
									}}
								>
									<Typography variant="caption" color="textSecondary" display="block">
										{comment?.loggedBy}
									</Typography>
									<Typography variant="caption" color="textSecondary">
										{`${moment(comment?.dateLogged).format('DD MMM YYYY')} (${formatDistanceToNow(new Date(comment?.dateLogged), {
											addSuffix: true,
										})})`}
									</Typography>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

function humanFileSize(bytes, si = false, dp = 1) {
	const thresh = si ? 1000 : 1024;

	if (Math.abs(bytes) < thresh) {
		return bytes + ' B';
	}

	const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
	let u = -1;
	const r = 10 ** dp;

	do {
		bytes /= thresh;
		++u;
	} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

	return bytes.toFixed(dp) + ' ' + units[u];
}

export default function PublicApprovalPage() {
	const queryClient = new QueryClient();
	const { search } = useLocation();
	const params = useMemo(() => new URLSearchParams(search), [search]);
	const quoteApprovelID = useMemo(() => {
		if (params) return params.get('id');
	}, [params]);

	const customTheme = {
		type: 'light',
		...colours.uptune,
	};

	const appliedTheme = createTheme({
		palette: customTheme,
		typography: {
			fontFamily: [
				'Nunito Sans',
				// 'Source Sans Pro',
				'sans-serif',
			].join(','),
		},
		overrides: {
			MuiTypography: {
				body1: { fontSize: '0.765rem' },
				body2: { fontSize: '0.765rem' },
				subtitle1: { fontSize: '0.765rem' },
				subtitle2: { fontSize: '0.765rem' },
			},
			MuiTooltip: {
				tooltip: {
					fontSize: '1em',
					color: 'white',
					backgroundColor: customTheme.secondary.main,
				},
				arrow: {
					'&:before': {
						border: `1px solid ${customTheme.secondary.main}`,
					},
					color: customTheme.secondary.main,
				},
			},
		},
	});

	return (
		<ThemeProvider theme={appliedTheme}>
			<CssBaseline />

			<QueryClientProvider client={queryClient}>
				<Page quoteAppId={quoteApprovelID} />
			</QueryClientProvider>
		</ThemeProvider>
	);
}
