import React, { useState } from 'react';
import process from 'process';
import axios from 'axios';
import download from 'downloadjs';
import config from '../../../../../../config.json';
// MaterialUI
import { Tooltip, IconButton } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { ButtonLoading } from 'app/components/loading';

const domain = process.env.NODE_ENV === 'production' ? config.api.domain : config.api.domain_dev;

export const QuoteDownloadButton = ({ quoteId }) => {
	const [loading, setIsLoading] = useState(false);

	const downloadFile = async () => {
		try {
			setIsLoading(true);
			await axios({
				url: `${domain}/quote/${quoteId}/pdf`,
				method: 'GET',
				responseType: 'blob',
			})
				.then((response) => {
					let filename = response.headers['content-disposition'].split('filename=')[1].replaceAll("'", '');
					download(response.data, filename, 'application/pdf');
					setIsLoading(false);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} catch (error) {
			setIsLoading(false);
			if (error.response && error.response.status === 400) {
				alert('Error while downloading file. Try again later');
			}
		}
	};

	return (
		<Tooltip title="Download quote" key={'download'}>
			{loading ? (
				<ButtonLoading label="" />
			) : (
				<IconButton component="span" size="small" color="inherit" onClick={() => downloadFile()} style={{ marginRight: 10 }}>
					<CloudDownloadIcon />
				</IconButton>
			)}
		</Tooltip>
	);
};
