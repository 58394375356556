import { useTheme } from '@material-ui/core/styles';
import { Grid, Hidden } from '@material-ui/core';

// Pages
import { Page } from '../../app/components'
import { VehiclesTable } from './components/vehicles-table'

export default function Vehicles() {
    const theme = useTheme();

    // ----------------------------------------------------------------
    // Action Bar
    // ----------------------------------------------------------------
    const ActionRow = () => {
        return (
            <Grid container alignItems='center'>
                <Grid item xs={9} >

                </Grid>
                <Grid item xs={3} >

                </Grid>
            </Grid>
        )
    }

    // ----------------------------------------------------------------
    // MAIN
    // ----------------------------------------------------------------
    return (
        <Page heading="Vehicles" actions={<ActionRow />}>
            <Grid container spacing={0}>

                {/* MENU */}
                <Grid item xs={2} style={{ backgroundColor: theme.palette.background.paper }}>
                </Grid>


                {/* TABLE */}
                <Grid item xs={10} lg={7} style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
                    <VehiclesTable />
                </Grid>

                {/* GRAPHS */}
                <Hidden mdDown>
                    <Grid item xs={3} style={{ backgroundImage: `linear-gradient(${theme.palette.background.default} 80%, ${theme.palette.background.paper})`, }}>
                    </Grid>
                </Hidden>
            </Grid>
        </Page>
    )
}