import React, { forwardRef } from 'react';
// material
import { makeStyles } from '@material-ui/core/styles';
import { Grid, InputBase, Tooltip, IconButton, Typography, useTheme } from '@material-ui/core';
// icons
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// components
import { NumberFormatCurrency, NumberFormatPercentageTextBox, NumberFormatNumericTextBox } from '../textbox';

// Styles
const useStyles = makeStyles((theme) => ({
	dragger: {
		color: theme.palette.text.disabled,
		'&:hover': {
			cursor: 'move',
		},
	},
	addHeader: {
		color: theme.palette.text.disabled,
		'&:hover': {
			cursor: 'pointer',
		},
	},
	hide: {
		visibility: 'hidden',
	},
	cell: {
		borderColor: theme.palette.divider,
		borderStyle: 'solid',
		borderWidth: '1px',
		fontSize: '0.8rem',
		padding: '0px 10px 0px 10px',

		align: 'right',
		textAlign: 'right',
	},
}));

// ---------------------------------------------------------------------------------------------------------------
// FUNCTION: 	Provides a consistent Input for use in the table
// INPUT:		id: 			Id for input control
// 				name: 			Name for input control
// 				placeholder:	Placholder text
// 				onChange:		On change event handler
// 				defaultValue:	Initial value
// 				className:		Styling
// 				onKeyDown:		Key down event handler
// 				onBlur:			On text box leave event handler
// 				center:			Center controls if true
// OUTPUT:		Fully styled row component for adding new lines. Built with the Grid system
// FUNCTIONS:	Add Row
// ---------------------------------------------------------------------------------------------------------------
export const TextBox = ({
	id,
	name,
	placeholder = '',
	defaultValue = null,
	inputRef = null,

	type = 'text',
	className,
	center = false,
	autoFocus = false,
	disabled = false,

	onChange,
	onKeyDown,
	onBlur,

	showDragger = false,
	showDraggerSpacer = false,
	addGroupHeaderOnClick,

	isFinancial = false,
	isPercentage = false,
	isNumeric = false,
}) => {
	const classes = useStyles();

	const handleFocusNumerical = (event) => {
		event.preventDefault();
		const { target } = event;
		setTimeout(function () {
			target.setSelectionRange(0, 100);
		}, 10);
	};

	return (
		<div
			style={{
				width: '100%',
				overflow: 'hidden',
				display: 'flex',
				flex: 1,
			}}
		>
			{showDragger && (
				<span className={classes.hide}>
					<div style={{ float: 'left' }}>
						<Grid container alignItems="center">
							<Tooltip title="Add group heading above this line" arrow={true} placement="top">
								<IconButton size="small" disabled={disabled} onClick={() => addGroupHeaderOnClick()}>
									<AddCircleOutlineIcon style={{ fontSize: '1.2rem' }} className={classes.addHeader} />
								</IconButton>
							</Tooltip>
						</Grid>
					</div>
				</span>
			)}
			{showDraggerSpacer && (
				<div style={{ float: 'left' }}>
					<DragIndicatorIcon fontSize="small" className={classes.dragger} style={{ visibility: 'hidden' }} />
				</div>
			)}
			<div style={{ float: 'left', flex: 1, marginLeft: 5 }}>
				{isNumeric && (
					<InputBase
						id={id || name}
						name={name}
						className={className || classes.cell}
						placeholder={placeholder || 0}
						inputComponent={NumberFormatNumericTextBox}
						inputRef={inputRef}
						size="small"
						fullWidth={true}
						onChange={onChange}
						value={defaultValue || undefined}
						onKeyDown={onKeyDown || null}
						onBlur={onBlur || null}
						display="block"
						align={center ? 'center' : 'inherit'}
						autoFocus={autoFocus}
						onFocus={(e) => handleFocusNumerical(e)}
						disabled={disabled}
					/>
				)}
				{isPercentage && (
					<InputBase
						id={id || name}
						name={name}
						className={className || classes.cell}
						placeholder={placeholder || 0}
						inputComponent={NumberFormatPercentageTextBox}
						inputRef={inputRef}
						size="small"
						fullWidth={true}
						onChange={onChange}
						value={defaultValue || undefined}
						// defaultValue={defaultValue}
						onKeyDown={onKeyDown || null}
						onBlur={onBlur || null}
						display="block"
						align={center ? 'center' : 'inherit'}
						autoFocus={autoFocus}
						onFocus={(e) => handleFocusNumerical(e)}
						disabled={disabled}
					/>
				)}
				{isFinancial && (
					<InputBase
						id={id || name}
						name={name}
						className={className || classes.cell}
						placeholder={placeholder || 0}
						inputComponent={NumberFormatCurrency}
						inputRef={inputRef}
						size="small"
						fullWidth={true}
						onChange={onChange}
						value={defaultValue || undefined}
						onKeyDown={onKeyDown || null}
						onBlur={onBlur || null}
						display="block"
						align={center ? 'center' : 'inherit'}
						autoFocus={autoFocus}
						onFocus={(e) => handleFocusNumerical(e)}
						disabled={disabled}
					/>
				)}
				{!isFinancial && !isPercentage && !isNumeric && (
					<InputBase
						id={id || name}
						name={name}
						className={className || classes.cell}
						placeholder={placeholder || 0}
						inputRef={inputRef}
						size="small"
						fullWidth={true}
						onChange={onChange}
						defaultValue={defaultValue || undefined}
						onKeyDown={onKeyDown || null}
						onBlur={onBlur || null}
						display="block"
						align={center ? 'center' : 'inherit'}
						type={type || 'text'}
						autoFocus={autoFocus}
						disabled={disabled}
					/>
				)}
			</div>
		</div>
	);
};

export const NewRowTextBox = forwardRef((props, ref) => {
	const {
		id,
		name,
		placeholder,
		onChange,
		// inputRef = null,
		defaultValue = null,
		className,
		onKeyDown,
		onBlur,
		center,
		isFinancial = false,
		isPercentage = false,
		isNumeric = false,
		type = 'text',
		autoFocus = false,
		disabled = false,
	} = props;

	const classes = useStyles();
	const theme = useTheme();

	return (
		<div
			style={{
				width: '100%',
				overflow: 'hidden',
				display: 'flex',
				flex: 1,
			}}
		>
			<div style={{ float: 'left', flex: 1 }}>
				{isNumeric && (
					<>
						<Typography variant="caption" style={{ color: theme.palette.text.disabled }}>
							{placeholder}
						</Typography>
						<InputBase
							id={id || name}
							name={name}
							className={className || classes.cell}
							placeholder={placeholder || 0}
							// inputComponent={NumberFormatNewRow}
							inputRef={ref}
							size="small"
							fullWidth={true}
							onChange={onChange}
							value={defaultValue || ''}
							onKeyDown={onKeyDown || null}
							onBlur={onBlur || null}
							display="block"
							align={center ? 'center' : 'inherit'}
							autoFocus={autoFocus}
							// onFocus={() => {
							// 	if (defaultValue === 0) inputRef?.current?.select();
							// }}
							disabled={disabled}
						/>
					</>
				)}
				{isPercentage && (
					<>
						<Typography variant="caption" style={{ color: theme.palette.text.disabled }}>
							{placeholder}
						</Typography>
						<InputBase
							id={id || name}
							name={name}
							className={className || classes.cell}
							placeholder={placeholder || 0}
							// inputComponent={NumberFormatNewRow}
							inputRef={ref}
							size="small"
							fullWidth={true}
							onChange={onChange}
							value={defaultValue}
							onKeyDown={onKeyDown || null}
							onBlur={onBlur || null}
							display="block"
							align={center ? 'center' : 'inherit'}
							// autoFocus={autoFocus}
							// onFocus={() => {
							// 	if (defaultValue === 0) inputRef?.current?.select();
							// }}
							disabled={disabled}
						/>
					</>
				)}
				{isFinancial && (
					<>
						<Typography variant="caption" style={{ color: theme.palette.text.disabled }}>
							{placeholder}
						</Typography>
						<InputBase
							id={id || name}
							name={name}
							className={className || classes.cell}
							placeholder={placeholder || 0}
							inputRef={ref}
							size="small"
							fullWidth={true}
							onChange={onChange}
							value={defaultValue}
							onKeyDown={onKeyDown || null}
							onBlur={onBlur || null}
							display="block"
							align={'right'}
							// autoFocus={autoFocus}
							// onFocus={() => {
							// 	if (defaultValue === 0) inputRef?.current?.select();
							// }}
							disabled={disabled}
						/>
					</>
				)}
				{!isFinancial && !isPercentage && !isNumeric && (
					<>
						<Typography variant="caption" style={{ color: theme.palette.text.disabled }}>
							{placeholder}
						</Typography>
						<InputBase
							id={id || name}
							name={name}
							className={className || classes.cell}
							placeholder={placeholder || ''}
							inputRef={ref}
							size="small"
							fullWidth={true}
							onChange={onChange}
							value={defaultValue || ''}
							onKeyDown={onKeyDown}
							onBlur={onBlur || null}
							display="block"
							align={center ? 'center' : 'inherit'}
							type={type || 'text'}
							// autoFocus={autoFocus}
							// onFocus={() => {
							// 	if (defaultValue === 0) inputRef?.current?.select();
							// }}
							disabled={disabled}
						/>
					</>
				)}
			</div>
		</div>
	);
});
