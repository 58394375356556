import { useState } from 'react';
import moment from 'moment';
//Security
import { useAuth0 } from '@auth0/auth0-react';
//Query
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { GetComments, CreateComment, DeleteComment } from '../../utils/data';
//Material
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Paper, InputBase, Typography, Grid, Link, IconButton, Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
// Icons
import ChatIcon from '@material-ui/icons/Chat';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
// Pages
import { Loading } from '../loading';
import { Box } from '../box';
import { MakeCommentPublic } from 'app/utils/data-layer/comments';

const useStyles = makeStyles((theme) => ({
	container: {
		borderWidth: '1px',
		borderStyle: 'solid',
		borderColor: theme.palette.divider.main,
	},
	heading: {
		padding: 7,
		backgroundColor: theme.palette.background.default,
	},
	body: {
		padding: theme.spacing(2),
	},
	comment: {
		marginTop: '5px',
		padding: '5px 10px 5px 10px',
		borderRadius: '12px',
		backgroundColor: 'rgba(224,224,224, 0.2)',
	},
	inputfield: {
		borderWidth: '1px',
		borderStyle: 'solid',
		borderColor: theme.palette.background.paper,
		padding: '0px 10px 0px 10px',
		margin: '0px 0px 15px 0px',
		fontSize: '0.8rem',
	},
}));

const useStylesV2 = makeStyles((theme) => ({
	headerContainer: {
		height: 50,
		paddingLeft: 15,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	headerActions: {
		paddingRight: 15,
	},
	financeTableHeader: {
		backgroundColor: theme.palette.background.default,
	},
	financeTableFooter: {
		backgroundColor: theme.palette.background.default,
		// color: theme.palette.getContrastText(theme.palette.primary.main)
	},

	infoContainer: {
		backgroundColor: theme.palette.background.default,
		marginTop: 5,
		marginBottom: 15,
	},
	infoBox: {
		padding: 15,
		border: `5px solid ${theme.palette.background.paper}`,
	},

	body: {
		minHeight: '20vh',
		maxHeight: '60vh',
		overflowY: 'auto',
	},

	button: {
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
}));

export const Comments = ({ parentId, handleClick = null }) => {
	const classes = useStyles();
	const { user } = useAuth0();
	const queryClient = useQueryClient();

	//* data
	const { data, isLoading, refetch } = useQuery(['comments', parentId], GetComments, {
		enabled: !!parentId,
		refetchOnWindowFocus: false,
	});

	//* handlers
	const deleteComment = useMutation(DeleteComment, {
		onSettled: () => {
			queryClient.refetchQueries(['comments'], {
				active: true,
			});
		},
	});

	const changeCommentPublic = useMutation(MakeCommentPublic, {
		onSettled: () => {
			queryClient.refetchQueries(['comments'], {
				active: true,
			});
		},
	});

	// * error
	if (data?.status === 'error') return <p>Error Occured</p>;
	if (data?.status === 'access-denied') return <p>Access Denied</p>;
	if (isLoading) return <Loading />;

	return (
		<Box
			heading="Comments"
			rightComponent={
				handleClick ? (
					<IconButton size="small" color="inherit" component="span" onClick={handleClick}>
						<CloseIcon />
					</IconButton>
				) : null
			}
		>
			<AddComment parentId={parentId} />
			{data.comments.length === 0 && (
				<div
					style={{
						display: 'flex',
						flex: 1,
						padding: 10,
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<ChatIcon
						disabled={true}
						style={{
							maring: 10,
						}}
					/>
					<Typography
						variant="caption"
						color="textSecondary"
						display="block"
						style={{
							maring: 10,
							paddingLeft: 10,
						}}
					>
						The are no comments yet...
					</Typography>
				</div>
			)}
			{data.comments.map((comment) => {
				let itsMe = comment.loggedById === user.sub ? true : false;

				return (
					<Grid container key={comment._id} spacing={0} style={{ marginBottom: 5 }}>
						<Grid item xs={12}>
							<Grid container>
								{itsMe && <Grid item xs={5}></Grid>}
								<Grid item xs={7} className={classes.comment}>
									<Grid container>
										<Grid item xs={12}>
											{/* LOGGED BY */}
											<Grid container justifyContent="space-between" direction="row">
												<Grid item xs={6}>
													<Typography
														id="loggedOnLabel"
														variant="caption"
														color="inherit"
														style={{ paddingRight: 5 }}
														display="block"
													>
														<b>{comment.loggedBy}</b>
													</Typography>
												</Grid>
												<Grid item xs={6} align="right">
													{comment?.public && (
														<Tooltip title={`This comment is visible to the customer. \n Click to make PRIVATE`}>
															<IconButton
																size="small"
																style={{
																	borderRadius: 0,
																}}
																onClick={async () => {
																	await changeCommentPublic.mutate({
																		id: comment?._id,
																		public: false,
																	});
																	await refetch();
																}}
															>
																<VisibilityIcon style={{ fontSize: '0.8rem', color: 'red' }} />
																<Typography
																	style={{
																		fontSize: '0.7rem',
																		paddingLeft: 2.5,
																		color: 'red',
																	}}
																>
																	Public
																</Typography>
															</IconButton>
														</Tooltip>
													)}

													{!comment?.public && (
														<Tooltip title={`This comment is private. \n Click to make PUBLIC`}>
															<IconButton
																size="small"
																style={{
																	borderRadius: 0,
																}}
																onClick={async () => {
																	await changeCommentPublic.mutate({
																		id: comment._id,
																		public: true,
																	});
																	await refetch();
																}}
															>
																<VisibilityOffIcon style={{ fontSize: '0.8rem', color: '#3c3c3c' }} />
															</IconButton>
														</Tooltip>
													)}
												</Grid>
											</Grid>
										</Grid>

										<Grid item xs={12}>
											{/* COMMENT */}
											<Typography id={comment._id} variant="caption" color="inherit" display="block">
												{comment.comment}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} style={{ padding: 5 }}>
							<Grid container justifyContent="flex-start">
								{/* <Grid item xs={8} style={!itsMe ? { paddingLeft: 10 } : { paddingLeft: 55 }}> */}
								{itsMe && <Grid item xs={5} align="right" />}
								<Grid item xs={4} align="left">
									{/* DATE LOGGED */}
									<Typography id={comment._id} variant="caption" color="inherit" display="block" style={{ fontSize: '0.6rem' }}>
										{moment(comment.dateLogged).format('DD MMM YYYY HH:MM')}
									</Typography>
								</Grid>

								<Grid item xs={3} align="right">
									<Grid container justifyContent="flex-end">
										{comment.loggedById === user.sub && (
											<Link
												component="button"
												variant="caption"
												color="inherit"
												display="block"
												style={{ fontSize: '0.6rem', marginRight: 10, color: 'orange' }}
												onClick={() => deleteComment.mutate(comment._id)}
											>
												Delete
											</Link>
										)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				);
			})}
		</Box>
	);
};

export const CommentsV2 = ({ parentId = null, handleClick = null, open = false, setOpen = null, refreshPageFn = null }) => {
	const classes = useStylesV2();
	const theme = useTheme();

	// DATA
	const { data, isLoading } = useQuery(['comments', parentId], GetComments, { enabled: !!parentId && open === true, refetchOnWindowFocus: false });

	// FUNCTIONS
	const handleClose = async () => {
		if (refreshPageFn) refreshPageFn();
		setOpen(false);
	};

	// --------------------------------------------------------------------------------------------------
	// ERROR HANDLING - Reviewed 2
	// --------------------------------------------------------------------------------------------------
	if (data?.status === 'error') return <p>Error Occured</p>;
	if (data?.status === 'access-denied') return <p>Access Denied</p>;
	if (isLoading) return <Loading />;

	//MAIN
	// --------------------------------------------------------------------------------------------------
	if (data) {
		return (
			<Paper elevation={24} style={{ cursor: 'default' }}>
				<Grid container>
					{/* CARD HEADER */}
					<Grid item xs={12}>
						<Grid container alignItems="center" className={classes.headerContainer}>
							<Grid item xs={8}>
								<Typography variant="h5">Comments</Typography>
							</Grid>

							<Grid item xs={4}>
								<Grid container alignItems="center" justifyContent="flex-end" className={classes.headerActions}>
									<IconButton onClick={() => handleClose()} size="small">
										<CloseIcon />
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					{/* TABLE */}
					<Grid item xs={12} style={{ padding: theme.spacing(2) }} className={classes.body}>
						<ShowComments data={data} />
					</Grid>

					<Grid item xs={12} className={classes.financeTableFooter}>
						<div style={{ marginLeft: 15, marginRight: 30, paddingTop: 10 }}>
							<AddComment parentId={parentId} />
						</div>
					</Grid>
				</Grid>
			</Paper>
		);
	}

	if (data.status === 'eee')
		return (
			<Box
				heading="Comments"
				rightComponent={
					handleClick ? (
						<IconButton size="small" color="inherit" component="span" onClick={handleClick}>
							<CloseIcon />
						</IconButton>
					) : null
				}
			>
				<AddComment parentId={parentId} />
			</Box>
		);
};

const ShowComments = ({ data }) => {
	const classes = useStyles();
	const { user } = useAuth0();
	const theme = useTheme();
	const queryClient = useQueryClient();

	const deleteComment = useMutation(DeleteComment, {
		onSettled: () => {
			queryClient.refetchQueries(['comments'], { active: true });
		},
	});

	return (
		<>
			{data.comments.length === 0 && (
				<Grid
					container
					alignItems="center"
					justifyContent="center"
					style={{ height: '100%', backgroundColor: theme.palette.background.default }}
				>
					<ChatIcon disabled={true} style={{ maring: 10 }} />

					<Typography
						variant="caption"
						color="textSecondary"
						display="block"
						style={{
							maring: 10,
							paddingLeft: 10,
						}}
					>
						The are no comments yet...
					</Typography>
				</Grid>
			)}
			{data.comments.map((comment) => {
				let itsMe = comment.loggedById === user.sub ? true : false;

				return (
					<Grid container key={comment._id} spacing={0} style={{ marginBottom: 5 }}>
						<Grid item xs={12}>
							<Grid container>
								{itsMe && <Grid item xs={2}></Grid>}
								<Grid item xs={10} className={classes.comment}>
									{/* Header */}
									<Grid container>
										<Grid item xs={12}>
											{/* //* user */}
											<Grid container alignItems="center">
												<Grid item xs={9}>
													{/* LOGGED BY */}
													<Typography id="loggedOnLabel" variant="caption" color="inherit" display="block">
														<b>{comment.loggedBy}</b>
													</Typography>
												</Grid>
												<Grid item>
													<Typography
														id={comment._id}
														variant="caption"
														color="inherit"
														display="block"
														style={{ fontSize: '0.6rem' }}
													>
														{moment(comment.dateLogged).format('DD MMM YYYY HH:MM')}
													</Typography>
												</Grid>
											</Grid>
										</Grid>

										{/* //* comment */}
										<Grid item xs={12}>
											<Typography
												id={comment._id}
												variant="caption"
												color="inherit"
												display="block"
												style={{ paddingTop: 5, paddingBottom: 5 }}
											>
												{comment.comment}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								{!itsMe && <Grid item xs={1}></Grid>}
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container justifyContent="flex-start">
								<Grid item xs={8} style={!itsMe ? { paddingLeft: 10 } : { paddingLeft: 55 }}></Grid>
								<Grid item xs={4}>
									<Grid container justifyContent="flex-end">
										{comment.loggedById === user.sub && (
											<Link
												component="button"
												variant="caption"
												color="inherit"
												display="block"
												style={{ fontSize: '0.7rem', marginRight: 10 }}
												onClick={() => deleteComment.mutate(comment._id)}
											>
												Delete
											</Link>
										)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				);
			})}
		</>
	);
};

export function AddComment({ parentId }) {
	const classes = useStyles();
	const { user } = useAuth0();
	const queryClient = useQueryClient();
	const [values, setValue] = useState({});
	const theme = useTheme();

	//* mutate
	const create = useMutation(CreateComment, {
		onSettled: () => {
			queryClient.refetchQueries(['comments'], { active: true });
		},
	});

	//* handlers
	const onChange = (e) => {
		setValue({
			...values,
			[e.target.name]: e.target.value,
		});
	};

	const keyPress = async (e) => {
		if (e.keyCode == 13) {
			let newComment = {
				...values,
				public: values?.public || false,
				parentId: parentId,
				loggedBy: user.name,
			};

			await create.mutate(newComment);
			setValue({
				comment: '',
				public: false,
			});

			e.target.value = '';
		}
	};

	return (
		<Grid
			container
			spacing={2}
			alignItems="center"
			style={{
				marginBottom: 10,
			}}
		>
			<Grid item xs={12} md={10} alignItems="flexStart">
				<InputBase
					name="comment"
					fullWidth={true}
					variant="outlined"
					size="small"
					type="text"
					className={classes.inputfield}
					onChange={onChange}
					onKeyDown={keyPress}
					style={{ borderRadius: '10px', border: `1px solid ${theme.palette.divider}`, margin: 0 }}
					placeholder="Add a comment..."
				/>
			</Grid>
			<Grid item xs={12} md={2} alignItems="flexStart">
				<FormControlLabel
					label="Make public"
					disabled={!values?.comment}
					value={values?.public === true ? true : false}
					control={
						<Checkbox
							size="small"
							color="primary"
							onChange={(e) => {
								setValue((values) => ({
									...values,
									public: e.target.checked,
								}));
							}}
						/>
					}
				/>
			</Grid>
		</Grid>
	);
}

export default Comments;
