import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
//Data
import { useQuery, useQueryClient } from 'react-query';
import { GetCompanySettings, GetListAll, GetNonWorkingDates } from '../app/utils/data';

//Material
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Backdrop from '@material-ui/core/Backdrop';

//Shared Components
import { NewProfile } from '../pages/first-use';
import { MainLoading } from '../app/components/loading';
import { GetAccessToken } from '../app/utils/getAccessToken';

//Components
import { ActiniumAppBar } from './app-bar';

//Context
import SettingsContext from '../context/settings-context';
import SearchContext from '../context/search-context';

//Styles
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
	'@global': {
		'*::-webkit-scrollbar': {
			width: '15px',
		},
		'*::-webkit-scrollbar-track': {
			'-webkit-box-shadow': 'inset 0 0 6px rgba(255,255,255,0.00)',
			background: theme.palette.type === 'dark' ? '#1e1e1e' : '1px solid #E5E5E5',
		},
		'*::-webkit-scrollbar-thumb': {
			backgroundColor: theme.palette.type === 'dark' ? theme.palette.secondary.main : theme.palette.grey[300],
			outline: theme.palette.type === 'dark' ? '1px solid slategrey' : '1px solid #E5E5E5',
			width: '10px',
		},
		'*::-webkit-scrollbar-thumb:hover': {
			background: theme.palette.type === 'dark' ? theme.palette.secondary.dark : theme.palette.secondary.light,
		},
	},
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		height: theme.spacing(10),
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	content: {
		flexGrow: 1,
		marginTop: theme.spacing(0),
	},
	container: {
		marginTop: theme.spacing(8),
		// paddingTop: theme.spacing(3),
	},
	grow: {
		flexGrow: 1,
	},
	sectionDesktop: {
		display: 'none',
		alignItems: 'center',
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
		},
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	toolbar: {
		display: 'flex',
		height: theme.spacing(8) + 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

export default function Layout({ children }) {
	//Security
	let history = useHistory();
	const classes = useStyles();
	const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
	const [loaded, setLoaded] = useState(false);
	const [openEditAppointment, setOpenEditAppointment] = useState(false);
	const [openSearch, setOpenSearch] = useState(false);
	const [settingsState, setSettingState] = useState({
		settings: null,
		lists: null,
	});

	// ----------------------------------------------------------------------------------------
	// AUTH
	// ----------------------------------------------------------------------------------------

	// Forward to login page if not authenticated
	if (!isAuthenticated) history.push('/');

	// Get the token on every page refresh
	useEffect(() => {
		let token = async () => {
			if (!loaded) {
				await GetAccessToken(getAccessTokenSilently, isAuthenticated);
				setLoaded(true);
				return true;
			}
		};
		token();
	}, [!loaded]);

	const logoutUser = () => {
		deleteAllCookies();
		sessionStorage.removeItem('token');
		logout({ returnTo: window.location.origin });
	};

	// ----------------------------------------------------------------------------------------
	// DATA
	// ----------------------------------------------------------------------------------------

	const {
		data: dataCompanySettings,
		isLoading: loadingCompanySettings,
		error: errorCompanySettings,
	} = useQuery(['company_settings'], GetCompanySettings, {
		enabled: !!loaded,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

	const {
		data: dataBlankedDates,
		isLoading: loadingBlankedDates,
		error: errorBlankedDates,
	} = useQuery(['appointmentsBlankedDates'], GetNonWorkingDates, {
		enabled: !!loaded,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

	const {
		data: dataListData,
		isLoading: loadingListData,
		error: errorListData,
	} = useQuery(['lists'], GetListAll, {
		enabled: !!loaded,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

	const queryClient = new useQueryClient();
	const refreshSettings = () => {
		queryClient.refetchQueries(['lists'], {
			active: true,
		});
		setLoaded(false);
	};

	//* STATE
	useEffect(() => {
		if (
			dataCompanySettings?.companySettings !== settingsState?.settings ||
			dataListData !== settingsState?.lists ||
			dataBlankedDates !== dataBlankedDates
		) {
			setSettingState({
				settings: dataCompanySettings?.companySettings,
				lists: dataListData?.items,
				blankedDates: dataBlankedDates,
				refreshSettings: refreshSettings,
			});
		}
	}, [dataCompanySettings, dataListData, dataBlankedDates]);

	//* FUNCTIONS

	// SET HEADER
	useEffect(() => {
		if (dataCompanySettings) {
			if (dataCompanySettings?.companySettings?.name) {
				document.title = dataCompanySettings?.companySettings?.name;
			}
		}
	}, [dataCompanySettings]);

	//* ERROR HANDLERS
	if (errorCompanySettings || errorListData || errorBlankedDates)
		return (
			<Button variant="outlined" color="primary" onClick={logoutUser}>
				Log out
			</Button>
		);

	if (!loaded || loadingCompanySettings || loadingListData || loadingBlankedDates) return <MainLoading />;

	if (!settingsState?.settings?.name) {
		return (
			<Backdrop className={classes.backdrop} open={true}>
				<NewProfile />
			</Backdrop>
		);
	}

	return (
		<SettingsContext.Provider value={settingsState}>
			<SearchContext.Provider value={{ open: openSearch, setOpen: setOpenSearch }}>
				<div className={classes.root}>
					<CssBaseline />

					{/* APP BAR */}
					{/* -------------------------------------------------------------- */}
					<ActiniumAppBar openEditAppointment={openEditAppointment} setOpenEditAppointment={setOpenEditAppointment} />

					{/* BODY */}
					{/* -------------------------------------------------------------- */}
					<main className={classes.content} id="main-content">
						<Container maxWidth={false} className={classes.container}>
							<Box>{children}</Box>

							<Box pt={1}>
								<Copyright />
							</Box>
						</Container>
					</main>
				</div>
			</SearchContext.Provider>
		</SettingsContext.Provider>
	);
}

export const deleteAllCookies = () => {
	sessionStorage.removeItem('token');

	var cookies = document.cookie.split(';');

	for (var i = 0; i < cookies.length; i++) {
		var cookie = cookies[i];
		var eqPos = cookie.indexOf('=');
		var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
	}
};

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href="https://ac2.co.za">
				AC^2
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}
