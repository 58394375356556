export const newUserConfig = {
    tableName: 'contact',
    listName: 'contacts',
    getOptionLabel: 'contactFullname',
    label: 'Contact',
    placeholder: 'Search on contact name',
}

export const newVehicleConfig = {
    tableName: 'vehicle',
    listName: 'vehicles',
    getOptionLabel: 'vehicleRegistration',
    label: 'Vehicle',
    placeholder: 'Search on vehicle registration',
}

export const newBusinessConfig = {
    tableName: 'business',
    listName: 'business',
    getOptionLabel: 'businessName',
    label: 'Business',
    placeholder: 'Search on business name',
}

export const mandatoryFields = {
    main: {
        contact: true,
        vehicle: true,
        bookingDate: true,
        bookingInstructions: true,
    },
    contact: {
        contactFullname: true,
        contactMobile: true,
        contactEmail: true,
    },
    vehicle: {
        vehicleRegistration: true,
        vehicleMake: true,
        vehicleModel: true,
    },
    business: {
        businessName: true,
        businessEmail: true,
    },
}

export const newWorkorderMandatoryFields = {
    main: {
        contact: true,
        vehicle: true,
        bookingDate: true,
        bookingInstructions: true,
        workorderOdometer: true,
    },
    contact: {
        contactFullname: true,
        contactMobile: true,
        contactEmail: true,
    },
    vehicle: {
        vehicleRegistration: true,
        vehicleMake: true,
        vehicleModel: true,
    },
    business: {
        businessName: true,
        businessEmail: true,
    },
}

export const newInvoiceMandatoryFields = {
    main: {
        contact: true,
        bookingDate: true,
        bookingInstructions: true,
    },
    contact: {
        contactFullname: true,
        contactMobile: true,
        contactEmail: true,
    },
    vehicle: {
        vehicleRegistration: true,
        vehicleMake: true,
        vehicleModel: true,
    },
    business: {
        businessName: true,
        businessEmail: true,
    },
}

export const newQuoteMandatoryFields = {
	main: {
		contact: true,
		bookingDate: true,
		bookingInstructions: true,
	},
	contact: {
		contactFullname: true,
		contactMobile: true,
		contactEmail: true,
	},
	vehicle: {
		vehicleRegistration: true,
		vehicleMake: true,
		vehicleModel: true,
	},
	business: {
		businessName: true,
		businessEmail: true,
	},
};

export const newAppointmentMandatoryFields = {
    main: {
        contact: true,
        vehicle: true,
        bookingDate: true,
        bookingInstructions: true,
    },
    contact: {
        contactFullname: true,
        contactMobile: true,
        contactEmail: true,
    },
    vehicle: {
        vehicleRegistration: true,
        vehicleMake: true,
        vehicleModel: true,
    },
    business: {
        businessName: true,
        businessEmail: true,
    },
}


export const CheckInMandatoryFields = {
    root: {
        workorderOdometer: true,
    },
}