import React, { useState, useEffect } from 'react'
import moment from 'moment'

// MaterialUI
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, CircularProgress, Typography } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

//Context
import { useInvoiceContext } from '../../../../context/invoice-context';

//Data
import { useMutation } from 'react-query'
import { UpdateInvoicePayment } from '../../../utils/data'

//Pages
import { KeyPairInput } from '../../../components/key-pair'

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 500,

        '& .MuiDialogTitle-root': {
            backgroundColor: theme.palette.primary.main
        }
    },
    header: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
    }
}));

export const EditPayment = ({ paymentData, open, setOpen }) => {
    const classes = useStyles();

    const { refetch } = useInvoiceContext()
    const [updatedPaymentData, setUpdatedPaymentData] = useState(null)
    const [isSaving, setIsSaving] = useState(false)

    const updatePayment = useMutation(UpdateInvoicePayment)

    useEffect(() => {
        if (!updatedPaymentData) setUpdatedPaymentData(paymentData)
    }, [paymentData])


    //FUNCTIONS
    const handleChange = (e) => {
        setUpdatedPaymentData({
            ...updatedPaymentData,
            [e.target.name]: e.target.value
        })
    }

    const saveClick = async () => {
        await setIsSaving(true)
        await updatePayment.mutate(updatedPaymentData)
        await setOpen(false)
        await setIsSaving(false)
        await refetch()
    }

    const cancelClick = async () => {
        setOpen(false)
    }

    return (
        <Dialog
            open={open}
            className={classes.root}
            fullWidth={true}
        >
            <DialogTitle><Typography variant='h6' className={classes.header}>EDIT PAYMENT</Typography></DialogTitle>

            <DialogContent style={{ paddingTop: 30 }}>
                <DialogContentText>

                    <Grid container alignItems="center" justifyContent='center'>
                        <Grid item xs={10}>
                            <Grid container alignItems="center" spacing={2}>
                                <KeyPairInput
                                    label="Amount"
                                    name="paymentAmount"
                                    defaultValue={updatedPaymentData?.paymentAmount}
                                    type='currency'
                                    disabled={true}
                                />

                                <KeyPairInput
                                    label="Date"
                                    name="paymentDate"
                                    defaultValue={moment(updatedPaymentData?.paymentDate).format('dddd, MMMM DD YYYY')}
                                    disabled={true}
                                />

                                <KeyPairInput
                                    label="Reference"
                                    name="paymentRef"
                                    defaultValue={updatedPaymentData?.paymentRef}
                                    onChange={handleChange}
                                    disabled={isSaving}
                                />

                                <KeyPairInput
                                    label="Notes"
                                    name="paymentNotes"
                                    defaultValue={updatedPaymentData?.paymentNotes}
                                    onChange={handleChange}
                                    disabled={isSaving}
                                    type='multiline'
                                />
                            </Grid>
                        </Grid>
                    </Grid>




                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={() => cancelClick()}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => saveClick()}
                    color="primary"
                    startIcon={isSaving ? <CircularProgress size={20} /> : null}
                    disabled={isSaving}
                >
                    {isSaving && 'Saving'}

                    {!isSaving && 'Save'}

                </Button>
            </DialogActions>
        </Dialog>
    )
}