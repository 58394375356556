import React, { useCallback, useState, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import debounce from 'lodash.debounce';
//Material UI
import { useTheme } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
//Data
import { useQuery, useQueryClient } from 'react-query';
import { SearchQuotes } from '../../app/utils/data';
//Context
import { useSettingsContext } from '../../context/settings-context';
import QuotesContext from '../../context/quotes-context';
//Config
import { QuotesTableColumns } from './config/config.tables';
// Shared Components
import Page from '../../app/components/page';
import Loading from '../../app/components/loading';
import { AppointmentNew } from '../appointments/views/new';
//Components
import { QuotesMenu } from './components/menu';
import ExportQuotesButton from './components/export-button';
import history from 'app/utils/history';

// Main Code -->
export const Quotes = () => {
	const theme = useTheme();
	const { search } = useLocation();
	const selectedStatus = useMemo(() => new URLSearchParams(search), [search]);
	const csvLink = useRef();
	const { lists } = useSettingsContext();
	const queryClient = new useQueryClient();

	// DEFAULTS
	let view = selectedStatus.get('status') || 'draft';
	let limit = 250;
	let page = 1;
	let totalCount = 10;

	//* state
	const [open, setOpen] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [isReloading, setisReloading] = useState(false);
	const [reload, setReload] = useState(true);

	const [query, setQuery] = useState({
		query: {
			quoteStatus: [toProperCase(view)],
		},
		view,
		limit: Number(limit),
		page: Number(page),
		totalCount: Number(totalCount),
		totals: 1,

		orderColumn: 'quoteDate',
		orderDirection: 'desc',
	});

	//* data
	const { data, isLoading, isFetching, refetch } = useQuery(['quotes', query], SearchQuotes, {
		enabled: !!reload,
		refetchOnWindowFocus: false,
		onSuccess: () => setReload(false),
	});

	//* handlers
	const [exportData, setExportData] = useState({
		data: [],
		source: '',
	});

	const ExportToCSVRange = async (startDate, endDate) => {
		setDownloading(true);

		let csvRawData = await SearchQuotes({
			queryKey: [
				'export',
				{
					query: {
						filter: {
							quoteDateFrom: moment(startDate).format('YYYY-MM-DD'),
							quoteDateTo: moment(endDate).format('YYYY-MM-DD'),
						},
					},
					limit: 0,
				},
			],
		});

		if (csvRawData) {
			let csvData = [
				['Status', 'Quote #', 'Date', 'Name', 'Mobile', 'Email', 'Vehicle', 'Gross Total', 'Discount', 'Cash', 'Card', 'EFT', 'Balance'],
			];

			csvRawData?.data?.quotes?.map((quote) => {
				csvData.push([
					quote?.quoteStatus,
					quote?.quoteNumber,
					moment(quote?.quoteDate).format('ddd, DD MMM YYYY'),
					quote?.contact?.contactFullname,
					quote?.contact?.contactMobile,
					quote?.contact?.contactEmail,
					quote?.vehicle?.vehicleRegistration,
					quote?.totals?.quoteSubTotal,
					quote?.totals?.quoteDiscountAmount,
					quote?.totals?.payments.cash,
					quote?.totals?.payments.card,
					quote?.totals?.payments.eft,
					quote?.totals?.quoteBalance,
				]);
			});

			setExportData({
				source: 'CSV',
				data: csvData,
			});
			csvLink.current.link.click();
		}

		setDownloading(false);
	};

	const ExportToCSV = async () => {
		setDownloading(true);

		let csvRawData = await SearchQuotes({
			queryKey: [
				'export',
				{
					query: {
						...data.data.query.query,
					},
					limit: 0,
				},
			],
		});

		if (csvRawData) {
			let csvData = [['Quote #', 'Date', 'Name', 'Mobile', 'Email', 'Vehicle', 'Gross Total', 'Discount', 'Cash', 'Card', 'EFT', 'Balance']];

			csvRawData?.data?.quotes?.map((quote) => {
				csvData.push([
					quote?.quoteNumber,
					moment(quote?.quoteDate).format('ddd, DD MMM YYYY'),
					quote?.contact?.contactFullname,
					quote?.contact?.contactMobile,
					quote?.contact?.contactEmail,
					quote?.vehicle?.vehicleRegistration,
					quote?.totals?.quoteSubTotal,
					quote?.totals?.quoteDiscountAmount,
					quote?.totals?.payments.cash,
					quote?.totals?.payments.card,
					quote?.totals?.payments.eft,
					quote?.totals?.quoteBalance,
				]);
			});

			setExportData({
				source: 'CSV',
				data: csvData,
			});
			csvLink.current.link.click();
		}

		setDownloading(false);
	};

	const ExportToXero = async () => {
		setDownloading(true);

		let csvRawData = await SearchQuotes({
			queryKey: [
				'export',
				{
					query: {
						...data.data.query.query,
					},
					limit: 0,
				},
			],
		});

		if (csvRawData) {
			let csvData = [
				[
					'*ContactName',
					'EmailAddress',
					'POAddressLine1',
					'POAddressLine2',
					'POAddressLine3',
					'POAddressLine4',
					'POCity',
					'PORegion',
					'POPostalCode',
					'POCountry',
					'*QuoteNumber',
					'Reference',
					'*QuoteDate',
					'*DueDate',
					'Total',
					'InventoryItemCode',
					'*Description',
					'*Quantity',
					'*UnitAmount',
					'Discount',
					'*AccountCode',
					'*TaxType',
					'TaxAmount',
					'TrackingName1',
					'TrackingOption1',
					'TrackingName2',
					'TrackingOption2',
					'Currency',
					'BrandingTheme',
				],
			];

			csvRawData?.data?.quotes?.map((quote) => {
				quote.costItems.map((costItem) => {
					csvData.push([
						//*ContactName
						quote?.contact?.contactFullname || '',
						//EmailAddress
						quote?.contact?.contactEmail || '',
						//POAddressLine1
						'',
						//POAddressLine2
						'',
						//POAddressLine3
						'',
						//POAddressLine4
						'',
						//POCity
						'',
						//PORegion
						'',
						//POPostalCode
						'',
						//POCountry
						'',
						//*QuoteNumber
						quote?.quoteNumber,
						//Reference
						'',
						//*QuoteDate
						moment(quote?.quoteDate).format('DD/MM/YYYY'),
						//*DueDate
						moment(quote?.quoteDate).format('DD/MM/YYYY'),
						//Total
						quote?.totals?.quoteTotal || 0,
						//InventoryItemCode
						'',
						//*Description
						costItem?.costDescription || '',
						//*Quantity
						costItem?.costQty || 0,
						//*UnitAmount
						costItem?.costUnitPrice || 0,
						//Discount
						quote?.totals?.quoteDiscountPercentage || 0,
						//*AccountCode
						200,
						//*TaxType
						'NONE',
						//TaxAmount
						quote?.totals?.quoteVATAmount || 0,
						//TrackingName1
						'',
						//TrackingOption1
						'',
						//TrackingName2
						'',
						//TrackingOption2
						'',
						//Currency
						'',
						//BrandingTheme
						'',
					]);
				});
			});

			setExportData({
				source: 'XERO',
				data: csvData,
			});
			csvLink.current.link.click();
		}

		setDownloading(false);
	};

	const handleStatusFilterClick = async (e, status) => {
		e.stopPropagation();
		let newStatus = null;

		if (e.ctrlKey) {
			let existIndex = data.data.query.query.quoteStatus.indexOf(status);

			if (existIndex >= 0) {
				newStatus = data.data.query.query.quoteStatus;
				newStatus.splice(existIndex, 1);
			}

			if (existIndex < 0) {
				newStatus = data.data.query.query.quoteStatus;
				newStatus.push(status);
			}
		} else {
			newStatus = [status];
		}

		history.replace({
			pathname: '/quotes',
			search: `?status=${newStatus.toString().toLowerCase()}`,
		});

		await setQuery({
			...data?.data?.query,
			query: {
				...data.data.query.query,
				quoteStatus: newStatus,
			},
		});

		setReload(true);
		await refetch();
	};

	//* refresh
	const refreshPage = async () => {
		setisReloading(true);
		setReload(true);
		await refetch();
		queryClient.refetchQueries(['quotes']);
		setisReloading(false);
	};

	//* search
	const handleSearch = async (e) => {
		//Set search value
		if (e && e.target.value) {
			setIsSearching(true);

			let statusArray = [];
			lists &&
				lists.quoteStatus.map((status) => {
					statusArray.push(status.quoteStatus);
				});

			await setQuery({
				...data?.data?.query,
				query: {
					...data?.data?.query.query,
					search: e.target.value,
					quoteStatus: statusArray,
				},
			});

			setReload(true);
			await refetch();
		}

		//reset to default view
		if (!e || !e.target.value) {
			setIsSearching(false);

			document.getElementById('search-quotes').value = '';

			setQuery({
				query: {
					quoteStatus: [toProperCase(view)],
				},
				view,
				limit: Number(limit),
				page: Number(page),
				totalCount: Number(totalCount),
			});

			setReload(true);
			await refetch();
		}
	};

	const debouncedHandleSearch = useCallback(debounce(handleSearch, 1000), []);

	// Error Handling
	if (data?.status === 'error') return <p>Error Occured</p>;
	if (data?.status === 'access-denied') return <p>Access Denied</p>;

	if (isLoading || isReloading) return <Loading />;

	//-- main
	return (
		<QuotesContext.Provider value={{ refetch }}>
			<Page
				heading="Quotes"
				refreshPageFn={refetch}
				subheadingIsControl={true}
				actionButtons={
					<>
						{/* //? NEW INVOICE */}
						<AppointmentNew open={open} setOpen={setOpen} iconSize="large" type="quote" refetchFn={refreshPage} />

						{/* //? EXPORT */}
						<ExportQuotesButton
							downloading={downloading}
							exportCSV={ExportToCSV}
							exportCSVRange={ExportToCSVRange}
							exportXero={ExportToXero}
						/>
					</>
				}
			>
				<Grid container spacing={1}>
					{/* //? LOADING */}
					{isLoading && (
						<Grid item xs={12}>
							<Loading />
							<Typography variant="caption" text="disabled">
								Loading quotes...
							</Typography>
						</Grid>
					)}

					{!isLoading && (
						<>
							{/*//? MENU */}
							<Grid item xs={2}>
								<QuotesMenu
									statusTotals={data?.data?.query?.totals}
									data={data}
									handleStatusFilterClick={handleStatusFilterClick}
									onChange={debouncedHandleSearch}
									isSearching={isSearching}
									allSelected={isSearching}
									setQuery={setQuery}
									setIsSearching={setIsSearching}
								/>
							</Grid>

							{/*//? TABLE */}
							<Grid item xs={10}>
								<MaterialTable
									name="quotes_table"
									title=""
									isLoading={isLoading || isFetching}
									columns={QuotesTableColumns(query, refetch)}
									data={data?.data?.quotes}
									//Paging
									page={Number(data?.data?.query?.page) - 1}
									totalCount={Number(data?.data?.query?.totalCount)}
									onChangePage={(page) => {
										setQuery({
											...data?.data?.query,
											page: Number(page) + 1,
										});
										refetch();
									}}
									onChangeRowsPerPage={(limit) => {
										setQuery({
											...data?.data?.query,
											limit,
										});
										refetch();
									}}
									onOrderChange={(orderedColumnId, orderDirection) => {
										switch (orderedColumnId) {
											case 0:
												setQuery({
													...query,
													orderColumn: 'quoteStatus',
													orderDirection: orderDirection,
												});
												refetch();
												break;

											case 1:
												setQuery({
													...query,
													orderColumn: 'quoteNumber',
													orderDirection: orderDirection,
												});
												refetch();
												break;

											case 2:
												setQuery({
													...query,
													orderColumn: 'quoteDate',
													orderDirection: orderDirection,
												});
												refetch();
												break;

											case 3:
												setQuery({
													...query,
													orderColumn: 'quoteSubTotal',
													orderDirection: orderDirection,
												});
												refetch();
												break;

											case 4:
												setQuery({
													...query,
													orderColumn: 'quoteDiscount',
													orderDirection: orderDirection,
												});
												refetch();
												break;

											case 5:
												setQuery({
													...query,
													orderColumn: 'quotePaid',
													orderDirection: orderDirection,
												});
												refetch();
												break;

											case 6:
												setQuery({
													...query,
													orderColumn: 'quoteEFT',
													orderDirection: orderDirection,
												});
												refetch();
												break;

											case 7:
												setQuery({
													...query,
													orderColumn: 'totals.quoteBalance',
													orderDirection: orderDirection,
												});
												refetch();
												break;

											default:
												break;
										}
									}}
									options={{
										// GENERAL
										padding: 'dense',
										minBodyHeight: '36vh',
										maxBodyHeight: '66vh',
										draggable: false,
										search: false,
										toolbar: false,
										headerStyle: {
											backgroundColor: theme.palette.background.default,
										},
										selection: false,
										rowStyle: {
											verticalAlign: 'top',
										},
										paging: false,
										emptyRowsWhenPaging: false,
										defaultSort: query.orderDirection,
									}}
									components={{
										Container: (props) => <Paper {...props} elevation={0} />,
									}}
									localization={{
										body: {
											emptyDataSourceMessage: <h3 style={{ textAlign: 'center' }}>No quotes to display</h3>,
										},
									}}
								/>

								{exportData && exportData.data && (
									<CSVLink
										data={exportData.data}
										filename={
											exportData.source === 'CSV'
												? `Quotes (${view}) - ${moment().format('DD-MM-YYYY')}.csv`
												: `Quotes (${view}) - XERO upload - ${moment().format('DD-MM-YYYY')}.csv`
										}
										className="hidden"
										ref={csvLink}
										target="_blank"
									/>
								)}
							</Grid>
						</>
					)}
				</Grid>
			</Page>
		</QuotesContext.Provider>
	);
};

function toProperCase(str) {
	if (str)
		return str.replace(/(^|\s)\S/g, function (t) {
			return t.toUpperCase();
		});
	return null;
}

export default Quotes;
