import { useState } from 'react';
//Material
import { useTheme } from '@material-ui/core/styles';
import { Grid, Button, Typography, Divider } from '@material-ui/core';

//Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

//Pages
import { TextBoxLabel } from './label';
import { InvoiceStatusBadge, WorkorderStatusBadge } from './status';
import { KeyPairDisplay } from '../key-pair';
import _ from 'lodash';

export const DisplayData = ({ template, data }) => {
	const theme = useTheme();
	const [showingMore, setshowingMore] = useState(false);
	const [hasMore, sethasMore] = useState(false);

	// Return NO DATA view on empty data property
	if (!data || !data?.[template.returnKey]) return null;

	//Return key-pair data
	return (
		<>
			{/* -------------------------------------------------------------------------------------------------- */}
			{/* DISPLAY DATA */}
			{/* -------------------------------------------------------------------------------------------------- */}
			<div>
				{template.fields.map((item, i) => {
					if (item.showmore && !hasMore) sethasMore(true);
					const initialValue = _.get(data?.[template.returnKey], item.id);
					const userHasChangedOriginal = data?.[template.returnKey].hasChanged || false;

					if (item.type === 'divider') {
						return (
							<Grid
								container
								key={`divider_${i}`}
								style={{
									marginTop: 10,
									marginBottom: 10,
								}}
							>
								<Grid item xs={12}>
									<Divider variant="fullWidth" />
								</Grid>
							</Grid>
						);
					}

					if (item.type === 'status') {
						return (
							<Grid container key={`multiline_${i}`} alignItems="center" justifyContent="center">
								<Grid item xs={12} sm={4} key={`title_${item.id}`}>
									<TextBoxLabel label={item.name} />
								</Grid>
								<Grid item xs={12} sm={8} key={`value_${item.id}`}>
									{item.id === 'workorderStatus' && (
										<WorkorderStatusBadge workorderId={data?.[template.returnKey]?._id} initialStatus={initialValue} />
									)}
									{item.id === 'invoiceStatus' && (
										<InvoiceStatusBadge invoiceId={data?.[template.returnKey]?._id} initialStatus={initialValue} />
									)}
								</Grid>
							</Grid>
						);
					}

					if (item.type === 'contact') {
						return (
							<Grid container key={`contact_${i}`} alignItems="center" justifyContent="center">
								<Grid item xs={12} sm={4} key={`title_${item.id}`}>
									<TextBoxLabel label={item.name} />
								</Grid>
								<Grid item xs={12} sm={8} key={`value_${item.id}`}>
									DEV!! Need to assign contact
								</Grid>
							</Grid>
						);
					}

					if (item.type === 'address') {
						return (
							<Grid container spacing={2} key={`contact_${i}`} alignItems="flex-start" justifyContent="center">
								<Grid item xs={12} sm={4} key={`title_${item.id}`}>
									<TextBoxLabel label={item.name} />
								</Grid>
								<Grid item xs={12} sm={8} key={`value_${item.id}`}>
									<Typography variant="body2">{initialValue ? initialValue?.toString() : ''}</Typography>
								</Grid>
							</Grid>
						);
					}

					return (
						<KeyPairDisplay
							key={item.name}
							label={item.name}
							value={
								item.isReference
									? initialValue.substring(0, 1) === 'B'
										? initialValue.substring(2, 20)
										: initialValue.substring(3, 20)
									: initialValue
							}
							template={item}
							showingMore={showingMore}
							userHasChangedOriginal={userHasChangedOriginal}
						/>
					);
				})}
			</div>

			{/* -------------------------------------------------------------------------------------------------- */}
			{/* VIEW BUTTONS */}
			{/* -------------------------------------------------------------------------------------------------- */}
			<Grid container justifyContent="flex-end">
				<Grid item>
					{showingMore && hasMore && (
						<Button
							size="small"
							style={{ padding: 0, fontSize: '0.6rem', color: theme.palette.text.secondary, textTransform: 'none' }}
							startIcon={<ExpandLessIcon />}
							onClick={() => setshowingMore(!showingMore)}
						>
							show less
						</Button>
					)}

					{!showingMore && hasMore && (
						<Button
							size="small"
							style={{ padding: 0, fontSize: '0.6rem', color: theme.palette.text.secondary, textTransform: 'none' }}
							startIcon={<ExpandMoreIcon />}
							onClick={() => setshowingMore(!showingMore)}
						>
							show more
						</Button>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default DisplayData;
