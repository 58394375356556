import React, { useMemo, useState } from 'react';

//Query
import { useQuery } from 'react-query';
import { GetBusinessDetailed } from '../../utils/data';

//Material
import { CircularProgress, Grid, Link, Tooltip, useTheme } from '@material-ui/core';

//Icons
import BusinessIcon from '@material-ui/icons/Business';

// Context
import BusinessContext from '../../../context/business-context';

//Pages
import BusinessDetailTabs from './components/tabs';
import BusinessAccountStatus from './components/business-account-status';
import Modal from '../../components/modal';
import BusinessCard from '../company-card';
import { Loading } from '../../components/loading';

const BusinessPopup = ({ businessId, businessName, type = 'link', meta = {}, givenData = [], forceAPICall = true }) => {
	const [open, setOpen] = useState(false);
	const theme = useTheme();

	//Get Data
	const { data, isLoading } = useQuery(['business_detailed', businessId], GetBusinessDetailed, {
		enabled: open && forceAPICall,
		refetchOnWindowFocus: false,
	});

	const internalData = useMemo(() => {
		if (data && forceAPICall) return data;
		if (!data && !forceAPICall && givenData) return givenData;
	}, [data, givenData, forceAPICall]);

	// MAIN
	// --------------------------------------------------------------------------------------------------
	return (
		<>
			{type === 'link' && !isLoading && (
				<Link
					component="button"
					onClick={() => setOpen(true)}
					variant="subtitle1"
					color="primary"
					style={{ marginTop: 0 }}
					noWrap={true}
					display="inline"
				>
					{toProperCase(businessName, true)}
				</Link>
			)}

			{type === 'action' && (
				<>
					{!isLoading && <BusinessIcon fontSize="small" style={{ paddingRight: 5, display: 'inline-block' }} />}
					{isLoading && <CircularProgress fontSize="small" style={{ display: 'inline-block', width: 15, height: 15 }} />}
					<Tooltip
						title={
							<>
								<Grid container>
									<span style={{ fontSize: '12px' }}>
										<b>Name</b>: {businessName} <br />
										<b>Phone</b>: {meta.businessPhone || 'no phone'} <br />
										<b>Email</b>: {meta.businessEmail || 'no email'} <br />
									</span>
								</Grid>
							</>
						}
						placement="top"
						arrow={true}
					>
						<Link
							disabled={isLoading}
							component="button"
							onClick={() => setOpen(true)}
							variant="caption"
							color="textSecondary"
							style={{
								marginTop: 0,
								paddingRight: 10,
								marginLeft: isLoading ? 5 : 0,
								color: !isLoading ? theme.palette.text.secondary : theme.palette.primary.main,
							}}
							gutterBottom={false}
							noWrap={true}
							display="inline"
						>
							{toProperCase(businessName, true)}
						</Link>
					</Tooltip>
				</>
			)}

			{internalData && (
				<BusinessContext.Provider value={{ business: internalData?.business }}>
					<Modal header={businessName} size="lg" open={open || false} setOpen={setOpen} fixedSize={true}>
						<Grid container spacing={2}>
							{internalData?.status === 'error' && <p>Error Occured</p>}
							{isLoading && (
								<Grid container spacing={2} justifyContent="center" alignItems="center">
									<Loading />
								</Grid>
							)}
							{!isLoading && (
								<>
									<Grid item xs={4} style={{ paddingRight: 25 }}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<BusinessCard reassign={false} companyId={businessId} noLink={true} />
											</Grid>
											<Grid item xs={12}>
												<BusinessAccountStatus />
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={8}>
										<BusinessDetailTabs />
									</Grid>
								</>
							)}
						</Grid>
					</Modal>
				</BusinessContext.Provider>
			)}
		</>
	);
};

function toProperCase(str, truncate = false) {
	if (truncate && str && str.length > 20) str = str.substr(0, 20) + '...';
	if (str)
		return str.replace(/(^|\s)\S/g, function (t) {
			return t.toUpperCase();
		});
	return null;
}

export default BusinessPopup;
