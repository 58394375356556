//@Material
import { DropzoneArea } from 'material-ui-dropzone';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
	createStyles({
		previewChip: {
			minWidth: 160,
			maxWidth: 210,
		},
	})
);

export const DropZone = ({ setFiles }) => {
	const classes = useStyles();

	const handleChange = (files) => {
		setFiles(files);
	};

	return (
		<DropzoneArea
			// Icon={AttachFile}
			showPreviews={true}
			showPreviewsInDropzone={false}
			useChipsForPreview
			previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
			previewChipProps={{ classes: { root: classes.previewChip } }}
			dropzoneText={'Click to upload. \n Or drag-n-drop files here.'}
			maxWidth="sm"
			onChange={(files) => handleChange(files)}
		/>
	);
};

export default DropZone;
