import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { InvoiceContainer } from './Container';

const FinanceTable = ({ config, headers, newRow, subTotal, disabled = false, refreshItem }) => {
	return (
		<div>
			<DndProvider backend={HTML5Backend}>
				<InvoiceContainer
					config={config}
					headers={headers}
					newRow={newRow}
					subTotal={subTotal}
					disabled={disabled}
					refreshItem={refreshItem}
				/>
			</DndProvider>
		</div>
	);
};

export default FinanceTable;
