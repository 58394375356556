import { Card } from '../components/card';
import { GetInvoiceReminder, UpdateInvoiceReminder } from '../utils/data';

export const InvoiceNextServiceReminderCard = ({ invoiceId, refetchQueryFn }) => {
	// --------------------------------------------------------------------------------------------------
	// MAIN
	// --------------------------------------------------------------------------------------------------

	// Shows the standard contact form
	return (
		<Card
			id={invoiceId}
			queryFn={GetInvoiceReminder}
			mutationFn={UpdateInvoiceReminder}
			refetchQueryFn={refetchQueryFn}
			template={{
				heading: 'Next Service Reminder',
				returnKey: 'reminder',
				fields: [
					{
						name: 'Reminder',
						id: 'workorderNextServiceReminder',
						type: 'multiline',
						canEdit: true,
						showmore: false,
					},
				],
			}}
		/>
	);
};

export default InvoiceNextServiceReminderCard;
