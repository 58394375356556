import { Card } from '../components/card';
import { GetBusiness, GetBusinessAll, CreateBusiness, UpdateBusiness, ReassignEntity } from '../utils/data';

export const CompanyCard = ({ companyId, parentId, parentTable, refetchQueryNames, refetchQueryFn, reassign = false, noLink = false }) => {
	// Shows the standard company form
	return (
		<Card
			id={companyId}
			queryFn={GetBusiness}
			mutationFn={UpdateBusiness}
			createFn={CreateBusiness}
			reassign={reassign}
			reassignGetFn={GetBusinessAll}
			reassignFn={ReassignEntity}
			reassignConfig={{
				parentTable: parentTable,
				parentId: parentId,
				key: 'businessID',
				control: {
					label: 'Companies',
					returnField: 'business',
					placeholder: 'Search or create new company',
					listboxDisplayValue: 'businessName',
				},
			}}
			refetchQueryNames={refetchQueryNames}
			refetchQueryFn={refetchQueryFn}
			template={{
				heading: 'Company',
				returnKey: 'business',
				parentTable: parentTable,
				fields: [
					{
						name: 'Name',
						id: 'businessName',
						canEdit: true,
						showmore: false,
						type: noLink ? null : 'link',
						link: noLink ? null : `/business/${companyId}`,
					},
					{
						name: 'Email',
						id: 'businessEmail',
						type: 'email',
						canEdit: true,
						showmore: false,
					},
					{
						name: 'Phone',
						id: 'businessPhone',
						type: 'phone',
						canEdit: true,
						showmore: false,
					},
					{
						name: 'Address',
						id: 'businessAddress',
						type: 'address',
						canEdit: true,
						showmore: true,
					},
					{
						name: 'VAT',
						id: 'businessVAT',
						canEdit: true,
						showmore: true,
					},
					{
						name: 'Registration No.',
						id: 'businessRegistrationNo',
						canEdit: true,
						showmore: true,
					},
					// {
					//     name: 'Finance contact',
					//     id: 'businessFinanceContactID',
					//     type: 'contact',
					//     canEdit: true,
					//     showmore: false,
					// },
				],
			}}
		/>
	);
};

export default CompanyCard;
