import { createContext, useContext } from 'react';

const ContactContext = createContext({
    contact: null,
});

export function useContactContext() {
    return useContext(ContactContext);
}

export default ContactContext;