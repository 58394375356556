//Material
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

//Components
import { ContactCard, VehicleCard, CompanyCard, InsuranceCard, WorkorderInformationCard } from '../../../../app/widgets';

//Pages
import Comments from '../../../../app/components/comments';

const WorkorderEditDetails = ({ data, refetch }) => {
	const theme = useTheme();

	// --------------------------------------------------------------------------------------------------
	// MAIN
	// --------------------------------------------------------------------------------------------------
	return (
		<Grid container spacing={3}>
			{/* COLUMN 1  */}
			<Grid item xs={12} sm={9}>
				<Grid container spacing={3}>
					{/* WORKORDER */}
					<Grid xs={12} md={8} item>
						<WorkorderInformationCard workorderId={data.workorder._id} />
					</Grid>

					{/* Vechile */}
					<Grid xs={12} md={4} item>
						<VehicleCard
							vehicleId={data.workorder.vehicleID}
							parentId={data.workorder._id}
							parentTable="workorders"
							refetchQueryNames={['workorder']}
							reassign={true}
						/>
					</Grid>
					{/* Contact */}
					<Grid xs={12} md={4} item>
						<ContactCard
							contactId={data.workorder.contactID}
							parentId={data.workorder._id}
							parentTable="workorders"
							refetchQueryNames={['workorder']}
							reassign={true}
						/>
					</Grid>

					{/* Company */}
					<Grid xs={12} md={4} item>
						{/* <CompanyCard
							companyId={data?.workorder?.businessID}
							parentId={data.workorder._id}
							parentTable='workorders'
							refetchQueryNames={['workorder']}
							reassign={true}
						/> */}

						<CompanyCard
							companyId={data?.workorder?.businessID}
							parentId={data.workorder._id}
							parentTable="invoices"
							refetchQueryFn={refetch}
							reassign={true}
							noLink={true}
						/>
					</Grid>

					{/* Insurance */}
					<Grid xs={12} md={4} item>
						<InsuranceCard workorderId={data?.workorder?._id} />
					</Grid>
				</Grid>
			</Grid>

			{/* COLUMN 2 */}
			<Grid item xs={12} sm={3} style={{ backgroundColor: theme.palette.background.default }}>
				<Grid container spacing={3}>
					<Grid xs={12} item>
						<Comments parentId={data?.workorder?._id} />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default WorkorderEditDetails;
