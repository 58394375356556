import { useMemo, useState } from 'react';
// material
import { Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, useTheme, alpha, Box } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ImportExportIcon from '@material-ui/icons/ImportExport';
// components
import { NumberFormatCurrencyText } from 'app/components/textbox';
import ContactPopup from '../contact';

export const TopDebtorsTable = (props) => {
	const theme = useTheme();
	const { data } = props;
	const [showMore, setShowMore] = useState(false);
	const [sort, setSort] = useState({
		sortColumn: 'total',
		sortDirection: 'desc',
	});

	const debtors = useMemo(() => {
		if (data) {
			let sorted = '';
			if (sort.sortDirection === 'desc') sorted = data.sort((a, b) => b?.[sort?.sortColumn] - a?.[sort?.sortColumn]);
			if (sort.sortDirection === 'asc') sorted = data.sort((a, b) => a?.[sort?.sortColumn] - b?.[sort?.sortColumn]);
			return sorted;
		}
	}, [data, sort]);

	const onSort = (field) => {
		setSort((prev) => {
			if (prev?.sortColumn === field) {
				return {
					sortColumn: field,
					sortDirection: prev?.sortDirection === 'asc' ? 'desc' : 'asc',
				};
			} else {
				return {
					sortColumn: field,
					sortDirection: 'asc',
				};
			}
		});
	};

	const SortIcons = ({ name }) => {
		if (sort?.sortColumn === name && sort?.sortDirection === 'asc') {
			return (
				<ArrowDownwardIcon
					onClick={() => onSort(name)}
					style={{
						fontSize: '0.75rem',
						'& :hover': {
							cursor: 'pointer',
						},
					}}
				/>
			);
		}

		if (sort?.sortColumn === name && sort?.sortDirection === 'desc') {
			return (
				<ArrowUpwardIcon
					onClick={() => onSort(name)}
					style={{
						fontSize: '0.75rem',
						'& :hover': {
							cursor: 'pointer',
						},
					}}
				/>
			);
		}

		if (sort?.sortColumn !== name) {
			return (
				<ImportExportIcon
					onClick={() => onSort(name)}
					style={{
						fontSize: '0.75rem',
						'& :hover': {
							cursor: 'pointer',
						},
					}}
				/>
			);
		}
	};

	const SortableHeader = ({ name, label, align = 'flex-start' }) => {
		return (
			<Box style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: align }}>
				<Typography variant="body2">
					<b>{label}</b>
				</Typography>
				<SortIcons name={name} />
			</Box>
		);
	};

	return (
		<TableContainer
			style={{
				width: '100%',
			}}
		>
			<Table
				style={{
					width: '100%',
					tableLayout: 'fixed',
					minWidth: 200,
				}}
			>
				<TableHead>
					<TableRow>
						<TableCell align="center" size="small" style={{ width: 15 }}>
							<SortableHeader label="Age" name="age" />
						</TableCell>
						<TableCell size="small">
							<Typography variant="body2">
								<b>Contact</b>
							</Typography>
						</TableCell>
						<TableCell align="center" size="small" style={{ width: 80 }}>
							<Typography variant="body2">
								<b># Inv</b>
							</Typography>
						</TableCell>
						<TableCell align="right" size="small">
							<SortableHeader label="Total" name="total" align="right" />
						</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{debtors?.map((row, rowIdx) => {
						const dotColor = () => {
							if (row.age === 0) return alpha(theme.palette.success.main, 0.75);
							if (row.age > 0 && row.age <= 30) return alpha(theme.palette.warning.dark, 0.75);
							return alpha(theme.palette.error.main, 0.75);
						};

						if (rowIdx > 4 && !showMore) return;

						return (
							<TableRow key={`ageing-row-${rowIdx}`}>
								<TableCell align="center" size="small" style={{ border: 0, width: '100px' }}>
									<div
										style={{
											borderRadius: 20,
											backgroundColor: dotColor(),
											width: 20,
											height: 20,
											alignItems: 'center',
											justifyContent: 'center',
											display: 'flex',
											felx: 1,
										}}
									>
										<Typography style={{ fontSize: '0.55rem' }}>{row?.age}</Typography>
									</div>
								</TableCell>

								<TableCell
									size="small"
									style={{
										border: 0,
									}}
								>
									<ContactPopup
										contactId={row?.contactId}
										contactFullname={row?.contactFullname}
										meta={row?.contact}
										type="table"
									/>
								</TableCell>

								<TableCell align="center" size="small" style={{ border: 0 }}>
									<Typography>{row?.count}</Typography>
								</TableCell>

								<TableCell align="right" size="small" style={{ border: 0 }}>
									<Typography>{NumberFormatCurrencyText(row?.total)}</Typography>
								</TableCell>
							</TableRow>
						);
					})}

					{data?.length > 5 && (
						<TableRow>
							<TableCell colSpan={4} padding="none" align="center" style={{ border: 0 }}>
								<Button
									size="small"
									style={{ paddingTop: 5, paddingLeft: 0, fontSize: '0.6rem', color: theme.palette.text.secondary }}
									startIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
									onClick={() => {
										if (showMore) {
											document.body.scrollTop = 0;
											document.documentElement.scrollTop = 0;
										}

										setShowMore((prev) => !prev);
									}}
								>
									{showMore && 'show less'}
									{!showMore && `show ${data?.length ? data?.length - 5 : 0} more`}
								</Button>
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default TopDebtorsTable;
