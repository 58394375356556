import { Card } from '../components/card';
import { GetWorkorderById, UpdateWorkorderGeneralInformation } from '../utils/data';

export const WorkorderInformationCard = ({ workorderId }) => {
	// --------------------------------------------------------------------------------------------------
	// MAIN
	// --------------------------------------------------------------------------------------------------

	return (
		<Card
			id={workorderId}
			queryFn={GetWorkorderById}
			mutationFn={UpdateWorkorderGeneralInformation}
			refetchQueryNames={['workorder']}
			template={{
				heading: 'Work Order',
				returnKey: 'workorder',
				fields: [
					{
						name: 'Number',
						id: 'workorderNumber',
						type: 'text',
						canEdit: false,
						showmore: false,
					},
					{
						name: 'Status',
						id: 'workorderStatus',
						type: 'status',
						canEdit: false,
						showmore: false,
					},
					{
						name: 'Mileage',
						id: 'workorderOdometer',
						type: 'mileage',
						canEdit: true,
						showmore: false,
					},
					{
						name: 'Date Recieved',
						id: 'workorderDate',
						type: 'date',
						canEdit: true,
						showmore: false,
					},
					{
						name: 'Instructions',
						id: 'workorderInstructions',
						type: 'multiline',
						canEdit: true,
						showEmpty: true,
					},
					{
						type: 'divider',
						canEdit: false,
					},
					{
						name: 'Quote first',
						id: 'quoteFirst',
						type: 'checkbox',
						canEdit: true,
						showEmpty: true,
					},
					{
						name: 'Quote #',
						id: 'quote.quoteNumber',
						type: 'text',
						canEdit: false,
						showEmpty: true,
					},
					{
						name: 'Status',
						level: 'sub',
						id: 'quote.quoteStatus',
						type: 'text',
						canEdit: false,
						showEmpty: true,
					},
					{
						name: 'App/Rej on',
						id: 'quote.quoteNumber',
						type: 'text',
						canEdit: false,
						showEmpty: true,
					},
					{
						name: 'App/Rej by',
						id: 'quote.approvedBy',
						type: 'text',
						canEdit: false,
						showEmpty: true,
					},
				],
			}}
		/>
	);
};

export default WorkorderInformationCard;
