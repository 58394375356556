import { createContext, useContext } from 'react';

const SearchContext = createContext({
    open: null,
    setOpen: () => {},
});

export function useSearchContext() {
    return useContext(SearchContext);
}

export default SearchContext;