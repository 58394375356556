import { httpPOST } from './data-handler';

// GET
// -------------------

// POST
// -------------------
export const AddPayment = async (data) =>
	await httpPOST({
		path: `payment`,
		data,
	});

export const UpdateInvoicePayment = async (data) => await httpPOST({ path: `payment/update`, data: data });
export const DeleteInvoicePayment = async (data) => await httpPOST({ path: `payment/delete`, data: data });
